<template>
    <div class="siteNoticeBox">
        <div class="controlBtnBox">
            <el-button :class="{selectBtn:selectBtnIndex===1}" @click="changeNoticeType(1)">{{$t("APPNotice")}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===2}" @click="changeNoticeType(2)">{{$t("DeviceNotice")}}</el-button>
        </div>
        <div v-if="selectBtnIndex === 1">
            <AppNotice :key="appKey"></AppNotice>
        </div>
        <div v-if="selectBtnIndex === 2">
            <DeviceNotice :selectDevNoticeModel="selectDevNoticeModel" :key="timer"></DeviceNotice>
        </div>
    </div>
    
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import {nanoid} from 'nanoid'
import AppNotice from './app/index.vue'
import DeviceNotice from './device/index.vue'

export default {
    name:"Notice",
    data(){
        return{
            selectBtnIndex:1,
            selectDevNoticeModel:1,
            appKey:nanoid(),
            timer:"",
          
        }
    },
    components:{ AppNotice, DeviceNotice},
    mounted(){
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/notice',
                name:this.$t('Notice')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
       
    },
    methods:{
        // ...mapMutations(["OPENINEDITPAGE"]),
         //点击功能切换按钮
         changeNoticeType(type){
            this.selectBtnIndex = type;
            if(type === 1){
               
            }else if(type === 2){
                this.handleLoad();
                this.selectDevNoticeModel = 1;
               
            }
        },
        handleLoad() {
            this.timer = new Date().getTime()
        }
       
    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    watch: {
      
    }
}
</script>

<style scoped>
.siteNoticeBox{
   box-sizing: border-box;
   padding: 15px 30px;
}
@media screen and (max-width: 600px) {
    .siteNoticeBox{
        padding: 20px 0;
    }
}
.controlBtnBox{
    display: flex;
}
.el-button {
    height: 30px;
    /* width: 140px; */
    padding: 6px 20px;
    font-size: 15px;
    background: #ccc;
    color: #000;
}
.el-button:hover{
    background: #6498b5;
    color: #fff;
}
.el-button:active {
    background: #6498b5;
    color: #fff;
}
.el-button:focus {
    background: #6498b5;
    color: #fff;
  }

.selectBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
}
.el-button+.el-button{
    margin-left: 4px;
}
</style>