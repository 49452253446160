<template>
    <div>
        <div class="payFailImg">
        <img :src="failImg" alt="">
        </div>
        <div class="payFailTex">
            <div>{{$t('PayFail')}}!</div>
        </div>
        <div class="payFailbtnTex">
            <button id="payFailbtn" @click="startServer">{{$t('Confirm')}}</button>
        </div>
    </div>
  
</template>

<script>
import failImg from '@/assets/images/fail.png'
export default {
    name:"PayFail",
    data(){
        return{
            failImg
        }
    },
    methods:{
        startServer(){
            window.close();
        }
    }
}
</script>

<style scoped>

.payFailImg {
        display: flex;
        justify-content: center;
        padding-top: 5vw;
    }
    
.payFailTex {
    display: flex;
    justify-content: center;
    padding-top: 2vw;
    color: #6498b5;
    font-size: 18px;
}

.payFailbtnTex {
    display: flex;
    justify-content: center;
    padding-top: 3vw;
}

#payFailbtn {
    width: 30%;
    height: 45px;
    line-height: 45px;
    border-radius: 15px;
    font-size: 18px;
    margin-top: 5vw;
    background: #6498b5;
    /* 标准语法 */
    color: #fff;
    outline: none;
    border: 1px solid #6498b5;
}
</style>