//导入封装好的新的axios
import request from '@/request/index'
//查询App公告列表
export function getClientNoticeList(params) {
    return request({
        url: '/' + params.rgn +'/notice/client/findNotice',
        method: 'get',
        params,
    })
}

//获取Blocks列表，渲染site/Block下拉列表框
export function getSiteBlocksList(params) {
    return request({
        url: '/' + params.rgn + '/site/apartment/list',
        method: 'get',
        params: {
            siteId: params.siteId,
        },
    })
}

//添加App公告
export function addClientNotice(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/client/addNotice',
        method: 'post',
        data
    })
}

//更新App公告
export function updateClientNotice(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/client/updateNoticeContent',
        method: 'post',
        data
    })
}

//删除App公告
export function deleteClientNotice(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/client/deleteNotice',
        method: 'post',
        data
    })
}

//App公告状态更新
export function updateClientNoticeStatus(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/client/updateNoticeStatus',
        method: 'post',
        data
    })
}

//App公告图片删除
export function deleteNoticePic(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/device/picDelete',
        method: 'post',
        data
    })
}

//查询可选择的通知的客户端列表
export function getNoticeUserHouseList(params) {
    return request({
        url: '/' + params.rgn +'/notice/client/findUserHouseList',
        method: 'get',
        params,
    })
}

export default { getClientNoticeList, getSiteBlocksList, addClientNotice, updateClientNotice, deleteClientNotice, updateClientNoticeStatus, deleteNoticePic, getNoticeUserHouseList}