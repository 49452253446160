//导入封装好的新的axios
import request from '@/request/index'
//查询设备公告列表
export function getDevNoticeList(params) {
    return request({
        url: '/' + params.rgn +'/notice/device/findNotice',
        method: 'get',
        params,
    })
}

//添加设备公告
export function addDevNotice(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/device/addNotice',
        method: 'post',
        data
    })
}

//更新设备公告
export function updateDevNotice(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/device/updateNoticeContent',
        method: 'post',
        data
    })
}

//删除设备公告
export function deleteDevNotice(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/device/deleteNotice',
        method: 'post',
        data
    })
}

//设备公告状态更新
export function updateDevNoticeStatus(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/device/updateNoticeStatus',
        method: 'post',
        data
    })
}

//设备公告图片上传
// export function devNoticePicUpload(params, data) {
//     return request({
//         url: '/' + params.rgn + '/notice/device/picUpload',
//         method: 'post',
//         data
//     })
// }

//设备公告图片删除
export function deleteNoticePic(params, data) {
    return request({
        url: '/' + params.rgn + '/notice/device/picDelete',
        method: 'post',
        data
    })
}

//查询可选择的通知的设备列表
export function getNoticeDevList(params) {
    return request({
        url: '/' + params.rgn +'/notice/device/findDevList',
        method: 'get',
        params,
    })
}

export default { getDevNoticeList, addDevNotice, updateDevNotice, deleteDevNotice, updateDevNoticeStatus, deleteNoticePic, getNoticeDevList}