<template>
    <div class="companyDas">
        <!-- <div class="companyInfo" v-for="(item,index)  in 3" :key="index">
            <i class="el-icon-office-building" style="font-size:150px"></i>
            <p>3</p>
            <p>Site</p>
            <div v-if="index === 0">
                <el-button type="primary">+ New Site</el-button>
                <div>
                    <a href="">What is site?</a>
                </div>
            </div>
            <div v-else>
                view you plan ...
            </div>
        </div> -->
        <div class="companyDashItem" v-show="false">
            <div class="companyDashUp">
                <div>Sites</div>
                <div class="companyDashUpRight">More Detail</div>
            </div>
            <div class="companyDashDown">
                <el-button type="primary" class="createBtn">
                    <span class="comPlus"><i class="el-icon-plus"></i></span>
                    <span>Add Site</span>
                </el-button>
            </div>
        </div>
        <div class="companyDashItem" v-show="false">
            <div class="companyDashUp">
                <div>Admins</div>
                <div class="companyDashUpRight">More Detail</div>
            </div>
            <div class="companyDashDown_ad">
                <el-button type="primary" class="createBtn_ad">
                    <span class="comPlus"><i class="el-icon-plus"></i></span>
                    <span>Add Admin</span>
                </el-button>
                <p>Manage Users Who Have Administrator Access To This Conpany</p>
            </div>
        </div>

        <div class="companyDashItem">
            <div class="companyDashUp">
                <div>{{$t('Sites')}}</div>
                <div class="companyDashUpRight" @click="detailCompanySite">{{$t('MoreDetail')}}</div>
            </div>
            <div class="companyItemColumn">
                <div class="companyItemColumnTop">
                    <el-image class="licensesImg" style="width:40px" :src="siteImg" fit="contain"></el-image>
                    <div style="font-size:35px">{{site.siteNum}}</div>
                </div>
                <div style="margin-top:30px">
                    <el-button @click="comNewSite" type="primary" class="createBtn_ad">
                    <span class="comPlus"><i class="el-icon-plus"></i></span>
                    <span>{{$t('CreateSite')}}</span>
                </el-button>
                </div>
            </div>
        </div>
        <div class="companyDashItem">
            <div class="companyDashUp">
                <div>{{$t('Admin')}}</div>
                <div class="companyDashUpRight" @click="detailCompanyAdmin">{{$t('MoreDetail')}}</div>
            </div>
            <div class="companyItemColumn">
                <div class="companyItemColumnTop">
                    <el-image class="licensesImg" style="width:40px" :src="userImg" fit="contain"></el-image>
                    <div style="font-size:35px">{{admin.adminNum}}</div>
                </div>
                <p>{{$t('AdminAccess')}}</p>
            </div>
        </div>     
        <div class="companyDashItem">
            <div class="companyDashUp">
                <div>{{$t('Licenses')}}</div>
                <div class="companyDashUpRight" @click="detailCompanyPurchR">{{$t('MoreDetail')}}</div>
            </div>
            <div class="companyItemColumn">
                <div class="companyItemColumnTop">
                    <el-image class="licensesImg" :src="recordImg" fit="contain"></el-image>
                    <div>{{$t('PurchaseRecord')}}</div>
                </div>
                <p>{{$t('PaymentInvioces')}}</p>
            </div>
        </div>        
    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import { mapState } from 'vuex';
import companydashboardApi from '@/api/companydashboard/index.js'
import siteImg from '@/assets/images/site_c.png'
import userImg from '@/assets/images/user.png'
import recordImg from '@/assets/images/record.png'
export default {
    name:"CompanyDashboard",
    data(){
        return{
            siteImg,
            userImg,
            recordImg,
            site:{
                siteNum:0,
            },
            admin:{
                adminNum:0,
            }
        }
    },
    mounted(){
        this.$store.commit("CHANGEMODEL","company");
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany.id === "createCompany"? "":this.selectCompany.companyName,
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
        if(this.selectCompany == {} || this.selectCompany.role === null){
            return false
        }
        //获取公司下site列表
        this.getCompanySiteList();
        //获取公司管理员列表
        this.getCompanyAdminList();
    },
    methods:{
        //获取公司下site列表
        getCompanySiteList(){
            let conpanyInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "pageSize":10,
                "pageNum":1
            }
            companydashboardApi.getCompanySiteListNoPage(conpanyInfoObj).then(res => {
                if(res.code === 0){
                    if(res.data.length === 0){
                        this.site.siteNum = 0;
                    }else{
                        this.site.siteNum = res.data.total;
                    }
                }else{
                    this.site.siteNum = "--";
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.site.siteNum = "--";
            })
        },
        //conpany dashboard下点击新建site执行方法
        comNewSite(){
            this.$router.push(
                {
                    name:"sitesmanage",
                    params: {
                        createSite:true
                    }
                }
            );
        },
        //site点击more detaile执行函数
        detailCompanySite(){
            this.$router.push(
                {
                    name:"sitesmanage"
                }
            );
        },
         //查询公司管理员列表
         getCompanyAdminList(){
            let comAdminObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "pageSize":10,
                "pageNum":1,
            }
            companydashboardApi.getCompanyAdminList(comAdminObj).then(res => {
                if(res.code === 0){
                    if(res.data.list.length === 0){
                        this.admin.adminNum = 0;
                    }else{
                        this.admin.adminNum = res.data.total;
                    }
                    
                }else{
                    this.$handerrcode(res.code);
                    this.admin.adminNum = "--";
                }
            }).catch((err) => {
                console.log(err);
                this.admin.adminNum = "--";
            })
        },
        //Admins点击more detaile执行函数
        detailCompanyAdmin(){
            this.$router.push(
                {
                    name:"companyadmin"
                }
            );
        },
        //Purchase Record
        detailCompanyPurchR(){
            this.$router.push(
                {
                    name:"purchaserecord"
                }
            );
        }

        
    },
    computed:{
        ...mapState(["selectCompany","companyList"])
    },
    watch:{
         // 监听路由信息对象中的path属性
        // "$route.path"() {
        //     this.$generateBreadcrumb(); // 当path发生变化的时候执行关联操作调用计算函数
        // },
        "selectCompany.id"(){
            //获取公司下site列表
            this.getCompanySiteList();
            //获取公司管理员列表
            this.getCompanyAdminList();
        }
    }
}
</script>

<style scoped>
.companyDas{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 30px;
}
.siteDashBox{
    display: flex;
    justify-content: left;
    padding: 30px 10px;
    flex-wrap:wrap;
}
@media screen and (max-width: 600px) {
    .siteDashBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 10px;
    }
}
.companyDashItem{
    width: 24vw;
    height: 12vw;
    background-color: #EFEFEF;
    margin-left: 1vw;
    min-width: 300px;
    min-height: 200px;
    margin-top: 1vw;
    border-radius: 12px;
}
@media screen and (max-width: 600px) {
    .companyDashItem{
        width: 23vw;
        height: 12vw;
        background-color: #EFEFEF;
        min-width: 360px;
        min-height: 200px;
        margin-top: 10px;
        border-radius: 12px;
    }
}
.companyDashUp{
    width: 100%;
    height: 15%;
    border-bottom: 1px solid #DBE0E3;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    font-weight: bold;
}
.companyDashUpRight{
    color: #6498b5;
    cursor: pointer;
}
.companyDashDown{
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
}
.companyDashDown_ad{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.companyDashDown_ad p{
   margin-top: 1vw;
   padding-left: 2vw;
   padding-right: 2vw;
   line-height: 22px;
   color: #5E6165;
}
.companyItemColumn{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.companyItemColumn p{
   margin-top: 1vw;
   padding-left: 2vw;
   padding-right: 2vw;
   line-height: 22px;
   color: #5E6165;
}
.companyItemColumnTop{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.companyItemColumnTop div{
    margin-right: 15px;
}
.comPlus{
    font-size: 24px;
    margin-top: -3px;
    float: left;
    margin-left: 10px;
}
.createBtn{
    font-size: 18px;
    margin-top: 3.5vw;
    height: 2.5vw;
    min-height: 42px;
    width: 10vw;
    min-width: 175px;
    border-radius: 8px;
    
}
@media screen and (max-width: 600px) {
    .createBtn{
        height: 42px;
        width: 175px;
        border-radius: 9px;
    }
}
.createBtn_ad{
    font-size: 18px;
    height: 2.5vw;
    min-height: 42px;
    width: 10vw;
    min-width: 175px;
    border-radius: 8px;
}
.siteBlocksDown{
    display: flex;
    flex-direction: column;
}
</style>