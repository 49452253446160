<template>
    <div class="profileBox">
        <div class="noUserInfo" v-show="hasNoUserInfo">{{$t('NoRecord')}}</div>
        <div v-show="!hasNoUserInfo">
            <div class="profileItem" v-for="item in userApartmentList" :key="item.id">
                <div class="profileTag">
                    {{item.siteName  + (item.apartmentNo === null ? "" :  (" - " + item.apartmentNo))  + (item.houseNo === null ? "" :  (" - " + item.houseNo))}}
                </div>
                <div class="profileDiv">
                    <div class="profileDetail">
                        <div class="detailItem">
                            <div class="itemProp">{{$t('UserName')}}</div>
                            <div class="itemVal">{{item.userSurname === null ? "" : item.userSurname  + " " + item.userFirstName === null ? "" : item.userFirstName}}</div>
                        </div>
                        <div class="detailItem">
                            <div class="itemProp">{{$t('SiteName').replace('*','')}}</div>
                            <div class="itemVal">{{item.siteName}}</div>
                        </div>
                        <div class="detailItem">
                            <div class="itemProp">{{$t('ServiceStatus')}}</div>
                            <div class="itemVal" :class="{
                                statusGreen:getServerStatusClass(item.licenseStatus) === 'statusGreen',
                                statusOrange:getServerStatusClass(item.licenseStatus) === 'statusOrange',
                                statusRed:getServerStatusClass(item.licenseStatus) === 'statusRed'
                                }">
                                    {{getServerStatusStr(item.licenseStatus)}}
                            </div>
                        </div>
                        <div class="detailItem">
                            <div class="itemProp">{{$t('UserRole')}}</div>
                            <div class="itemVal">{{item.userRole}}</div>
                        </div>
                        <div class="detailItem">
                            <div class="itemProp">{{$t('Blocks')}}</div>
                            <div class="itemVal">{{item.apartmentNo}}</div>
                        </div>
                        <div class="detailItem">
                            <div class="itemProp">{{$t('ServicesRemaining')}}</div>
                            <div class="itemVal">{{item.serverRemaining + " " + $t('Days')}} </div>
                        </div>
                        <div class="detailItem">
                            <div class="itemProp">{{$t('HouseNumber')}}</div>
                            <div class="itemVal">{{item.houseNo}}</div>
                        </div>
                    </div>
                    
                    <div class="profileQRCode">
                        <div class="QRCodeBox" :id="`QRCode${item.id}`"></div>
                        <div class="QRCodeTex">{{$t('UseAPPScanQRCode').replace("QvHome","AES Opyn")}}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import { mapState } from 'vuex';
import QRCode from 'qrcodejs2'
import profileApi from '@/api/profile/index.js'
export default {
    name:"Profile",
    data(){
        return{
           hasNoUserInfo:false,
           userApartmentList:[],
        }
    },
    mounted(){
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                // path:'/main/sitesmanage',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/profile',
                name:this.$t('MyProfile')
            },
           
        ];
        this.$generateBreadcrumb(siteMangeBread);
        //获取用户apartment列表
        this.getUserApartmentList();
    },
    methods:{
        //服务状态翻译函数
        getServerStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('NotEnabled');
                    break;
                case 1:
                    getStr = this.$t('InUse');
                    break
                case 2:
                    getStr = this.$t('Cancel').replace(' ','');
                    break
                case 9:
                    getStr = this.$t('End');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
         //服务状态颜色
         getServerStatusClass(obj){
            let getClass = "";
            let status = obj;
                switch(status){
                    case 0:
                        getClass = "";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusRed";
                        break
                    case 3:
                        getClass = "statusOrange";
                        break
                    default:
                        getClass = "";
                }
            return getClass;
        },
         //生成app加入二维码
         creatQrCode(id,content) {
            id.innerHTML = '';
            new QRCode(id, {
                text: content, // 需要转换为二维码的内容
                width: 150,
                height: 150,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        //获取普通用户园区楼栋信息
        getUserApartmentList(){
            let userInfoObj = {
                "oemGroupId": webConfig.oemGroupId,
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "siteId":this.selectSite.id,
            }
            profileApi.getUserApartmentInfo(userInfoObj).then(res => {
                if(res.code === 0){
                    console.log("res",res);
                    this.userApartmentList = [];
                    if(res.data.length === null || res.data.length === 0){
                        this.userApartmentList = [];
                        this.hasNoUserInfo = false;
                    }else{
                        for(let i=0;i< res.data.length;i++){
                            this.userApartmentList.push(res.data[i]);
                        }
                        
                        this.$nextTick(
                            function(){
                                for(let j=0;j<this.userApartmentList.length;j++){
                                    if (document.getElementById(`QRCode${this.userApartmentList[j].id}`)){
                                        this.creatQrCode(document.getElementById(`QRCode${this.userApartmentList[j].id}`),this.userApartmentList[j].qrCode);
                                    }
                                    
                                }
                            }
                        )
                    }
                    
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    watch:{
        "$store.state.selectSite.id"(){
            //获取用户apartment列表
            this.getUserApartmentList();
        }
    }
}
</script>

<style scoped>

.profileBox{
   box-sizing: border-box;
   padding: 15px 30px;
}
.profileTag{
    text-align: left;
    color: #333;
    font-size: 20px;
    font-weight: bold;
}
.noUserInfo{
    color: #ACACAC;
    margin-top: 10vw;
}
.profileItem{
    margin-bottom: 20px;
}
.profileDiv{
    background-color: #F4F9FD;
    margin-top: 15px;
    border-radius: 12px;
    padding: 0 20px 30px 20px;
    width: 80%;
    display: flex;
    justify-content: space-around;
}
@media screen and (max-width: 800px) {
    .profileDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.profileDetail{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 80%;
}
.detailItem{
    width: 33%;
    margin-top: 30px;
    text-align: left;
}
.profileQRCode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 800px) {
    .profileQRCode{
        margin-top: 25px;
    }
}
.itemProp{
    font-size: 16px;
    font-weight: bold;
    color: #4D4D4F;
}
.itemVal{
    margin-top: 15px;
    color: #606266;
}
.profileQRCode .QRCodeBox{
    width: 150px;
    height: 150px;
    box-sizing: border-box;
}

.QRCodeTex{
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #606266;
}
::v-deep .statusGreen{
    color: green;
}
::v-deep .statusOrange{
    color: orange;
}
::v-deep .statusRed{
    color: red;
}
::v-deep .statusBlue{
    color: blue;
}
</style>