import VueI18n from 'vue-i18n' // 引入i18n模块
import Cookies from 'js-cookie' // 封装了获取，设置，删除cookie的方法
import enLocale from '../langs/en.js' // 本地英文包
import zhLocale from '../langs/zh.js' // 本地中文包
// 引入本地
const messages = {
    en: {
        ...enLocale, // es6的拓展运算符，相当于解析出每个对象
    },
    zh: {
        ...zhLocale,
    }
};

export function generateBreadcrumb(breadObjArr) {
    const breadcrumbList = [];
    for (let i = 0; i < breadObjArr.length; i++) {
        breadcrumbList.push(breadObjArr[i]);
    }
    // 保存面包屑导航数据
    this.$store.commit("GENERATEBREADLIST", breadcrumbList);
}

export function handErrCode(errCode) {
    // 创建国际化实例
    const i18n = new VueI18n({
        locale: Cookies.get('language') || 'en', // set locale，默认中文
        messages // set locale messages。语言包
    })
    let errMsg = "";
    switch (errCode) {
        case -1:
            errMsg = i18n.tc('ServerError')
            break;
        case 2:
            errMsg = i18n.tc('RequestParameterError')
            break;
        case 3:
            errMsg = i18n.tc('InvalidRequest')
            break;
        case 4:
            errMsg = i18n.tc('RequestTimeou')
            break;
        case 5:
            errMsg = i18n.tc('UserNoLogin')
            break;
        case 6:
            errMsg = i18n.tc('RemoteServiceNotFound')
            break;
        case 7:
            errMsg = i18n.tc('ErrorCallingRemoteService')
            break;
        case 8:
            errMsg = i18n.tc('DataNotFound')
            break;
        case 9:
            errMsg = i18n.tc('RequestsTooFrequent')
            break;
        case 10:
            errMsg = i18n.tc('NoOperationPermission')
            break;
        case 11:
            errMsg = i18n.tc('DataAlreadyExists')
            break;
        case 600001:
            errMsg = i18n.tc('UserNamePassword')
            break;
        case 600002:
            errMsg = i18n.tc('AccountAlreadyExists')
            break;
        case 600003:
            errMsg = i18n.tc('VerificationCodeError')
            break;
        case 600007:
            errMsg = i18n.tc('DataSaveError')
            break;
        case 600008:
            errMsg = i18n.tc('DataUpdateError')
            break;
        case 600009:
            errMsg = i18n.tc('DataDeleteError')
            break;
        case 600011:
            errMsg = i18n.tc('CompanyAdminExistsSite')
            break;
        case 600012:
            errMsg = i18n.tc('NotAllowDeleteOurself')
            break;
        case 600013:
            errMsg = i18n.tc('ProductNotSupportBind')
            break;
        case 600014:
            errMsg = i18n.tc('DeviceBound')
            break;
        case 600015:
            errMsg = i18n.tc('DeviceSecurityCheckFail')
            break;
        case 600016:
            errMsg = i18n.tc('DeviceNotSupportServer')
            break;
        case 600017:
            errMsg = i18n.tc('OemConfigNotFount')
            break;
        case 600018:
            errMsg = i18n.tc('EmailSendFail')
            break;
        case 600019:
            errMsg = i18n.tc('UserAccountNotFound')
            break;
        case 600020:
            errMsg = i18n.tc('CompanyDataNotFond')
            break;
        case 600021:
            errMsg = i18n.tc('DevicesDataNotFond')
            break;
        case 600022:
            errMsg = i18n.tc('NoBlock')
            break;
        case 600023:
            errMsg = i18n.tc('DevicesNoPermission')
            break;
        case 600024:
            errMsg = i18n.tc('UserNotBindingHouse')
            break;
        case 600025:
            errMsg = i18n.tc('PkgNotAvailable')
            break;
        case 600026:
            errMsg = i18n.tc('UserNotFond')
            break;
        case 600027:
            errMsg = i18n.tc('SiteNotFound')
            break;
        case 600028:
            errMsg = i18n.tc('LastAdminNotDelete')
            break;
        case 600029:
            errMsg = i18n.tc('CanNotUpdateOwnRoles')
            break;
        case 600030:
            errMsg = i18n.tc('UserUnbindingFailed')
            break;
        case 600031:
            errMsg = i18n.tc('CompanyOverLimit')
            break;
        case 600032:
            errMsg = i18n.tc('SiteOverLimit')
            break;
        case 600033:
            errMsg = i18n.tc('ApartmentOverLimit')
            break;
        case 600034:
            errMsg = i18n.tc('MaximumNoticeReachLimit')
            break;
        case 600101:
            errMsg = i18n.tc('NoAuthorizedCompany')
            break;
        case 600102:
            errMsg = i18n.tc('NoAuthorizedSite')
            break;
        case 600103:
            errMsg = i18n.tc('UserNotJoinHouse')
            break;
        case 600104:
            errMsg = i18n.tc('CloudPushNotEnabled')
            break;
        case 600105:
            errMsg = i18n.tc('DeviceBound')
            break;
        case 600106:
            errMsg = i18n.tc('HouseNumberAlreadyExists')
            break;
        case 600107:
            errMsg = i18n.tc('HouseNumberNotExists')
            break;
        case 600108:
            errMsg = i18n.tc('BlockNumberNotExists')
            break;
        case 600109:
            errMsg = i18n.tc('DeviceUnbound')
            break;
        case 600301:
            errMsg = i18n.tc('AuthNotFound')
            break;
        case 600302:
            errMsg = i18n.tc('IotUnlockFail')
            break;
        case 600303:
            errMsg = i18n.tc('UserMismatchOem')
            break;
        case 600304:
            errMsg = i18n.tc('AlarmCancelShareFail')
            break;
        case 600305:
            errMsg = i18n.tc('AlarmUnbindFail')
            break;
        case 600306:
            errMsg = i18n.tc('AlarmShareFail')
            break;
        case 600307:
            errMsg = i18n.tc('LicensesExpired')
            break;
        case 600308:
            errMsg = i18n.tc('UnallocatedLicense')
            break;
        case 600309:
            errMsg = i18n.tc('InvitationInvalid')
            break;
        case 600310:
            errMsg = i18n.tc('HaveBeenUsed')
            break;
        case 600311:
            errMsg = i18n.tc('UserHasJoined')
            break;
        case 600604:
            errMsg = i18n.tc('LicensesExpired')
            break;
        case 600605:
            errMsg = i18n.tc('UrlExpired')
            break;
        case 600606:
            errMsg = i18n.tc('EmailAccountExists')
            break;
        case 600607:
            errMsg = i18n.tc('VerificationCodeIncorrect')
            break;
        case 600608:
            errMsg = i18n.tc('VerificationCodeInvalid')
            break;
        case 600609:
            errMsg = i18n.tc('VerificationCodeIncorrect')
            break;
        case 600610:
            errMsg = i18n.tc('LimitReached')
            break;
        case 600611:
            errMsg = i18n.tc('AccountNotExist')
            break;
        case 600612:
            errMsg = i18n.tc('PasswordError')
            break;
        case 600615:
            errMsg = i18n.tc('DeleteCompanyLimit')
            break;
        case 600616:
            errMsg = i18n.tc('DeviceReachLimit')
            break;
        case 600617:
            errMsg = i18n.tc('UsersReachLimit')
            break;
        case 600618:
            errMsg = i18n.tc('CardNumberExists')
            break;
        case 600619:
            errMsg = i18n.tc('AdminAccountExists')
            break;
        case 400101001:
            errMsg = i18n.tc('InventoryShortag')
            break;
        case 400101002:
            errMsg = i18n.tc('DataAlreadyExists')
            break;
        case 400101003:
            errMsg = i18n.tc('ErrorCallingRemoteService')
            break;
        case 400101004:
            errMsg = i18n.tc('PaymentOrderVerificationFailed')
            break;
        case 400101005:
            errMsg = i18n.tc('PaymentFailed')
            break;
        case 400101006:
            errMsg = i18n.tc('NoPackageFound')
            break;
        case 400101007:
            errMsg = i18n.tc('NoAvailablePackages')
            break;
        case 400101008:
            errMsg = i18n.tc('PackageHasModified')
            break;
        case 400101009:
            errMsg = i18n.tc('ChangedOnce')
            break;
        case 400101010:
            errMsg = i18n.tc('OrdersNonRefundable')
            break;
        case 400101011:
            errMsg = i18n.tc('PaymentConfigurationNotFound')
            break;
        case 400101012:
            errMsg = i18n.tc('OrderRefundFailed')
            break;
        case 400101013:
            errMsg = i18n.tc('PackageLessThanOneDay')
            break;

        default:
            errMsg = i18n.tc('UnknownError')
            break;
    }
    this.$message.error(errMsg);
}
//根据companyid获取公司对象
export function getCompayInfo(companyArr, companyId) {
    let companyInfo = {};
    companyArr.filter(item => {
        if (item.id === companyId) {
            companyInfo = item;
        }
    })

    return companyInfo;
}

//根据companyid获取公司区域
export function getCompayRegion(companyArr, companyId) {
    //companyArr
    let companyRegion = "";
    companyArr.filter(item => {
        if (item.id === companyId) {
            companyRegion = item.companyRegion;
        }
    })

    return companyRegion;
}

//根据siteid获取site
export function getSiteInfo(SiteArr, siteId) {
    let siteInfo = {};
    SiteArr.filter(item => {
        if (item.id === siteId) {
            siteInfo = item;
        }
    })

    return siteInfo;
}

//根据id获取目标元素
export function getTargetObjInfo(objArr, id) {
    let targetInfo = {};
    objArr.filter(item => {
        if (item.id === id) {
            targetInfo = item;
        }
    })

    return targetInfo;
}

//删除数组中的指定的元素 arr:目标数组，val:数组中指定要删除的元素
export function removeByValue(arr, val) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] == val) {
            arr.splice(i, 1);
            break;
        }
    }
}

//处理字段为null 或者空值时显示
export function handFieldNull(name) {
    let fieldName = "";
    if (name === undefined || name === null || name.trim() === "") {
        fieldName = "--"
    } else {
        fieldName = name;
    }
    return fieldName
}

//日期字符串转换函数
export function stringDate(val, str) {
    if (val > 1) {
        if (str == "Y" || str == "y") {
            return 'years'
        } else if (str == "M" || str == "m") {
            return 'months'
        } else if (str == "D" || str == "d") {
            return 'days'
        }
    } else {
        if (str == "Y" || str == "y") {
            return 'year'
        } else if (str == "M" || str == "m") {
            return 'month'
        } else if (str == "D" || str == "d") {
            return 'day'
        }
    }
}

//输入字段正则校验判断 val:需要校验的值，min：最少字符数 max：最大字符数 promptStr：提示
export function regularCheckLimit(val, min, max, promptStr) {
    // 创建国际化实例
    const i18n = new VueI18n({
        locale: Cookies.get('language') || 'en', // set locale，默认中文
        messages // set locale messages。语言包
    });
    let checkResult = true;
    let reg = new RegExp('^.{' + min + ',' + max + '}$');
    if (!reg.test(val)) {
        this.$message.info(i18n.tc(promptStr));
        checkResult = false;
    }
    return checkResult
}

export default { generateBreadcrumb, handErrCode, getCompayInfo, getCompayRegion, getSiteInfo, removeByValue, getTargetObjInfo, handFieldNull, stringDate, regularCheckLimit }