import CryptoJS from 'crypto-js'
 
/**
 * AES加密处理（CBC模式）
 */
export function encryptCBC(word, keyStr, ivStr) {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, keyStr, {
        iv: ivStr,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    })
    return encrypted.toString()
}
/**
 * AES解密处理（CBC模式）
 */
 export function decryptCBC(word, keyStr, ivStr) {
    var decrypt = CryptoJS.AES.decrypt(word, keyStr, {
        iv: ivStr,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    })
    return decrypt.toString(CryptoJS.enc.Utf8)
}