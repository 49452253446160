<template>
    <div class="siteUserEditBox">
        <!-- Service Activation弹框-->
        <el-dialog :title="$t('ServiceActivation')" 
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="serverActiva.activationListForm" 
                center 
                :show-close="showClose"
            >
                <!-- 等待页面显示 -->
                <div v-show="serverActiva.showWaitForm">
                    <div class="waitmentBox">
                        <img :src="serverActiva.waitmentImg" alt="">
                    </div>
                    <div class="waitText">{{$t('UserServiceActivation')}}</div>
                </div>
                <!-- Activation 选择页面 -->
                <div  v-show="serverActiva.selectActivationForm">
                    <div class="activeTableBox">
                        <template>
                            <el-table
                            :data="serverActiva.siteUsersListData"
                            :cell-class-name="getActivationServerStatusClass"
                            style="width: 100%; border-right: 3px solid #6498b5;"
                            :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                            >
                            <el-table-column
                                prop="surname"
                                :label="$t('UserName')"
                                >
                                <template slot-scope="scope">
                                    {{ $handFieldNull(showUserName(scope.row.surname,scope.row.firstName))}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="licenseStatus"
                                :label="$t('ServiceStatus')"
                                >
                                <template slot-scope="scope">
                                    {{ getServerStatusStr(scope.row.licenseStatus)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="licenseRemaining"
                                :label="$t('ServicesRemaining')"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.licenseRemaining === null ? "--" : (scope.row.licenseRemaining === 0 ? 0 : scope.row.licenseRemaining + " " + $t('Days'))}}
                                </template>
                            </el-table-column>                        
                            </el-table>
                        </template>
                    </div>
                    <div class="selectLicenseBox">
                        <div v-for="item in serverActiva.licenseList" :key="item.id">
                            <el-radio v-model="serverActiva.licenseId" :label="item.id">
                                {{item.licenseName}} 
                            </el-radio> 
                            <span>{{$t('RemainingQuantit')}} {{ item.quantity }}</span>
                        </div>
                    </div>
                    <div slot="footer" class="dialog-footer activationBtn">
                        <el-button @click="closeActivateServiceFrom">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" @click="confirmactivateService">{{$t('Activation')}}</el-button>
                    </div>
                </div>

                <!-- 确认激活用户弹窗 -->
                <div v-show="serverActiva.confirmActivationForm">
                    <div class="confirmActivaTex">
                        <div>
                            <img :src="serverActiva.licenseImg" alt="">
                        </div>
                        <div> 
                            {{serverActiva.selectLicense.licenseName}} 
                            <span>{{$t('RemainingQuantit')}} {{ serverActiva.selectLicense.quantity }}</span>
                        </div>
                    </div>
                    <div class="activeResult">
                        <template>
                            <el-table
                            :data="serverActiva.siteUsersListData"
                            :cell-class-name="getConfirmActivationServerStatusClass"
                            style="width: 100%; border-right: 3px solid #6498b5;"
                            :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                            >
                            
                            <el-table-column
                                prop="surname"
                                :label="$t('UserName')"
                                >
                                <template slot-scope="scope">
                                    {{ $handFieldNull(showUserName(scope.row.surname,scope.row.firstName))}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="licenseStatus"
                                :label="$t('ServiceStatus')"
                                >
                                <template slot-scope="scope">
                                    {{ getServerStatusStr(scope.row.licenseStatus)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="licenseRemaining"
                                :label="$t('ServicesRemaining')"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.licenseRemaining === null ? "--" : (scope.row.licenseRemaining === 0 ? 0 : scope.row.licenseRemaining + " " + $t('Days'))}}
                                </template>
                            </el-table-column>
                            </el-table>
                        </template>
                    </div>
                    <div class="waitmentBox">
                        <img :src="serverActiva.successImg" alt="">
                    </div>
                    <div slot="footer" class="dialog-footer confirmActivationBtn">
                        <!-- <el-button @click="closeConfirmActivateFrom">Cannel</el-button> -->
                        <!-- <el-button type="primary" :disabled="serverActiva.confirmActivationBtn" @click="confirmSelectActivate">Confirm</el-button> -->
                    </div>
                    
                </div>
                
        </el-dialog>
        <!--绑定User弹框-->
        <el-dialog :title="$t('BindingUser')" 
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="binding.bindUserFrom" 
                center 
                :show-close="showClose"
                >
                <!-- 等待页面显示 -->
                <div v-show="binding.showWaitForm">
                    <div class="waitmentBox">
                        <img :src="binding.waitmentImg" alt="">
                    </div>
                    <div class="waitText">{{$t('BindingUserWait')}}</div>
                </div>
                <!-- 绑定site user弹框 -->
                <div class="addsiteUersBox" v-show="binding.bindForm">
                    <div class="addSiteUserLeft">
                        <el-form :model="binding">
                            <el-form-item :label="$t('UserEmail') + ' *' ">
                                <el-input v-model="binding.invitationEmail"></el-input>
                                <!-- <p>if you can't fnd serial number please click here <span>>></span></p> -->
                            </el-form-item>
                            <div class="NameBox">
                                <div style="width:48%">
                                    <el-form-item :label="$t('FirstName')">
                                    <el-input v-model="binding.firstName"></el-input>
                                    </el-form-item>
                                </div>
                                <div style="width:48%">
                                    <el-form-item :label="$t('Surname')">
                                    <el-input v-model="binding.surname"></el-input>
                                </el-form-item>
                                </div>
                            </div>
                            <el-form-item :label="$t('Sites') + $t('Or') + $t('Blocks') + ' *' ">
                                <el-select v-model="binding.apartmentId" :placeholder="$t('Sites') + '/' + $t('Blocks')">
                                    <el-option
                                    v-for="item in binding.apartmentOptions"
                                    :key="item.id"
                                    :label="item.apartmentName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('HouseNumber') + ' *'">
                                <el-input v-model="binding.houseNo"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="addSiteUserRight">
                        <el-form :model="binding">
                            <el-form-item :label="$t('UserRole') + ' *' ">
                                <el-select v-model="binding.userRole" :placeholder="$t('UserRole')">
                                    <el-option
                                    v-for="item in binding.userRoleOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        
                    </div>
                </div>
                <div slot="footer" class="dialog-footer bindingBtn" v-show="binding.bindForm">
                    <el-button @click="binding.bindUserFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="binding.bindBtnState === 1" @click="bindingNewSiteUser">{{$t('Confirm')}}</el-button>
                </div>
                <!-- 绑定成功后弹框 -->
                <div class="addSuccessBox" v-show="binding.bindSuccessForm">
                    <div class="addSuccessDiv">
                        <div class="addSuccessLeft">
                            <div class="addSuccessItem">
                                <div>{{$t('AddUserSuccess')}}</div>
                                <p>{{$t('UserHasJoined')}} {{ binding.siteName + "  " + binding.apartmentName + "  " + binding.houseNo}}</p>
                            </div>
                            <div class="addSuccessItem">
                                <div>{{$t('UserServiceActivated')}}</div>
                                <p>{{$t('ActivationCode')}} :  {{ binding.invitationCode }}</p>
                            </div>
                            <div class="addSuccessItem">
                                <div>{{$t('UserConfrmation')}}</div>
                                <p>{{$t('ConfirmationEmail')}}</p>
                            </div>
                        </div>
                        <div class="addSuccessRight">
                            <div>{{$t('ScanQRCode')}}</div>
                            <div class="addSuccessQRBox">
                                <div class="addSuccessQRCode" ref="bindQRcodeForApp">

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div slot="footer" class="dialog-footer addSuccessBtn">
                        <el-button @click="bindingSucceSiteUser">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" :loading="binding.bindBtnState === 1" @click="bindingSucceSiteUser">{{$t('Confirm')}}</el-button>
                    </div>
                </div>
        </el-dialog>
         <!--解绑User弹框-->
         <el-dialog :title="$t('UnbindingUser')" class="unbindingUserDiv" 
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="unbinding.unbindUserFrom" 
                center 
                :show-close="showClose"
             >
                <div class="unbindingUserBox">
                    <!-- <div class="unbindingUserText">Are you sure to untie the user and house?</div>
                    <ul>
                        <li>After unbinding, the user will lose the permission to useall doorway equipment in the park.</li>
                        <li>The link and QR code that invited the user will be invalid.</li>
                    </ul> -->
                    <p class="deleteDesc">{{$t('UnbindingUserDesc')}}</p>
                </div>
                <div slot="footer" class="dialog-footer unbindingBtn">
                    <el-button @click="unbinding.unbindUserFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="unbinding.unbindBtnState === 1" @click="unbindingSiteUser">{{$t('Confirm')}}</el-button>
                </div>
               
            </el-dialog>
      <div class="controlBtnBox">
        <div>
            <el-button :class="{selectBtn:selectBtnIndex===1}" @click="changeEditType(1)">{{$t('UserDetail')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===2}" @click="changeEditType(2)">{{$t('Devices')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===3}" @click="changeEditType(3)">{{$t('SipSettings')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===4}" @click="changeEditType(4)">{{$t('CardSettings')}}</el-button>
        </div>
        
        <div>
            <el-button v-show="selectBtnIndex === 4" class="selectBtn" @click="addCardSetting">{{$t('Add')}}</el-button>
        </div>
      </div>
      <!-- Site User Detail编辑 -->
      <div class="siteUserContent" v-show="selectBtnIndex === 1">
        <div class="userBigBox">
            <div class="userBox">
                <div class="userBoxLeft">
                    <div class="userBoxLeftTex"><el-divider direction="vertical"></el-divider> <span>{{$t('PersonalInfo')}}</span></div>
                    <div class="userItem">
                        <label>{{$t('UserAccount').replace(':','')}}</label>
                        <div v-text="updateSiteUser.userAccount" style="height:39px;line-height:39px;"></div>
                        <!-- <el-input v-model="updateSiteUser.userAccount" class="editInput" placeholder="User Account"></el-input> -->
                    </div>
                    <div class="userItem">
                        <label>{{$t('FirstName').replace('*','')}}</label>
                        <el-input v-model="updateSiteUser.firstName" :disabled="updateSiteUser.userStatus === 3" class="editInput" :placeholder="$t('FirstName').replace('*','')"></el-input>
                    </div>
                    <div class="userItem">
                        <label>{{$t('Surname').replace('*','')}}</label>
                        <el-input v-model="updateSiteUser.surname" :disabled="updateSiteUser.userStatus === 3" class="editInput" :placeholder="$t('Surname').replace('*','')"></el-input>
                    </div>
                </div>
                <div class="userBoxRight">
                    <div class="userBoxLeftTex"><el-divider direction="vertical"></el-divider> <span>{{$t('UserSetting')}}</span></div>
                    <div class="userItem">
                        <label>{{$t('UserRole')}}</label>
                        <!-- <div v-show="updateSiteUser.userRole === 'owner'" v-text="updateSiteUser.userRole" style="height:39px;line-height:39px;"></div> -->
                        <el-select v-model="updateSiteUser.userRole" :disabled="updateSiteUser.userStatus === 3" class="editInput">
                            <el-option
                            v-for="item in updateSiteUser.userRoleOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="userItem">
                        <label>{{$t('Blocks')}}</label>
                        <el-select v-model="updateSiteUser.apartmentId" :disabled="updateSiteUser.userStatus === 3" class="editInput" :placeholder="$t('Blocks')">
                            <el-option
                            v-for="item in updateSiteUser.apartmentOptions"
                            :key="item.id"
                            :label="item.apartmentName"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="userItem">
                        <label>{{$t('HouseNumber')}}</label>
                        <el-input v-model="updateSiteUser.houseNo" :disabled="updateSiteUser.userStatus === 3" class="editInput" :placeholder="$t('HouseNumber')"></el-input>
                    </div>
                </div>
            </div>
            <div class="userLicenseBox">
                <div class="userLicenseLeft">
                    <span>{{$t('ServiceStatus')}}: 
                    <span :class=" getServerStatusClass(updateSiteUser.licenseStatus)">
                        {{" " + getServerStatusStr(updateSiteUser.licenseStatus)}}
                    </span>
                </span>
                    <span style="margin-left:1vw;">{{$t('ServicesRemaining')}} : 
                        {{updateSiteUser.remainingTxt}} 
                    </span>
                </div>
                <div>
                    <el-button class="augmentBtn" :disabled="updateSiteUser.userStatus === 3" @click="activateServiceFrom">{{$t('Augment')}}</el-button>
                </div>
            </div>
        </div>
        <div class="btnBox">
            <div>
                <el-button class="savetBtn" :loading="updateSiteUser.bindingBtnState == 1" v-show="updateSiteUser.userStatus === 3" @click="bindingUserFrom">{{$t('BindingUser')}}</el-button>
            </div>
            <div>
                <el-button class="savetBtn" :loading="updateSiteUser.unbindingBtnState == 1" v-show="updateSiteUser.userStatus !== 3" @click="unbindingSiteUserFrom">{{$t('UnbindingUser')}}</el-button>
            </div>
            <div>
                <el-button class="savetBtn" :disabled="updateSiteUser.saveBtn" :loading="updateSiteUser.updateBtnState == 1" @click="saveUpdateSiteUser">{{$t('Save')}}</el-button>
            </div>
            
        </div>
       
      </div>
      <!-- Devices编辑 -->
      <div class="siteUserContent" v-show="selectBtnIndex === 2">
        <div class="siteUserDevices">
            <div class="devicesEditDiv">
                <template>
                    <el-table
                    :data="userDevices.deviceList"
                    style="width: 100%;"
                    :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                    >
                        <el-table-column
                            prop="deviceName"
                            :label="$t('DeviceName')"
                            width="150px"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="deviceId"
                            :label="$t('DeviceId')"
                            width="150px"
                            >
                            <template slot-scope="scope">
                                {{scope.row.deviceId === "" ? "--" : scope.row.deviceId}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="apartmentName"
                            :label="$t('Blocks') + '/'+ $t('Sites')"
                            width="150px"
                            >
                            <template slot-scope="scope">
                                {{scope.row.apartmentId === "0" ? $t('Sites') : scope.row.apartmentName}}
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="userPermission"
                            :label="$t('AccessRights')"
                            width="300px"
                            >
                            <template slot-scope="scope">
                                {{userPermissionStr(scope.row.userPermission)}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="accessStatus"
                            :label="$t('AssessStatus')"
                            width="150px"
                            >
                            <template slot-scope="scope">
                                <el-switch 
                                v-model="scope.row.accessStatus"
                                :active-value="1"
                                :inactive-value="2"
                                @change="stateChanged(scope.row)" 
                                />
                            </template>
                        </el-table-column>  
                        <el-table-column
                            prop="createTime"
                            :label="$t('CreateDate')"
                            width="200px"
                            >
                            <template slot-scope="scope">
                            {{ utctolocal(scope.row.createTime)}}
                        </template>
                        </el-table-column>  
                        <el-table-column>
                        </el-table-column>                        
                    </el-table>
                </template>
                <div class="pageBox">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="userDevices.currentPage"
                        :page-size="10"
                        layout="prev, pager, next, jumper"
                        :total="userDevices.totalDevices"
                    >
                    </el-pagination>
                </div>
            </div>
            
        </div>
        
      </div>
       <!-- Sip Setting -->
       <div class="siteUserContent" v-show="selectBtnIndex === 3">
        <div>
           <div class="sipModeDiv">
             <div class="userBoxLeftTex"><el-divider direction="vertical"></el-divider> <span>{{$t('Mode')}}</span></div>
             <el-radio style="margin-top:1vw" v-model="sip.mode" @input="changeSipType" :label="1">{{$t('SeverMode')}}</el-radio>
             <el-radio style="margin-top:1vw" v-model="sip.mode" @input="changeSipType" :label="2">{{$t('P2PMode')}}</el-radio>
           </div>
           <div v-show="sip.mode === 1" style="margin-top:10px;">
               
                <div class="sipModeConfig">
                     <div class="sipModeServerDiv">
                    <div class="userBoxLeftTex">
                        <span style="margin-left:9px;">{{$t('Panels')}}</span>
                    </div>
                    <el-select v-model="sip.entranceDeviceId" @change="selUserSipServerDev" style="width:13vw" class="editInput" :placeholder="$t('Panels')">
                        <el-option
                        v-for="item in sip.entranceDeviceIdOptions"
                        :key="item.id"
                        :label="item.deviceName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="sipModeServerDiv">
                    <div class="userBoxLeftTex">
                        <span style="margin-left:9px;">{{$t('ServerConfig')}}</span>
                    </div>
                    <el-select v-show="!sip.entranceConfigShow" style="width:13vw" v-model="sip.entranceConfigLoding" class="editInput" :placeholder="$t('Loading') + '...'">
                        <el-option :label="$t('Loading') + '...'" :value="$t('Loading') + '...'" disabled></el-option>
                    </el-select>
                    <el-select v-show="sip.entranceConfigShow"  v-model="sip.entranceConfigId" :disabled="sip.entranceConfigIdDis" style="width:13vw" class="editInput" :placeholder="$t('ServerConfig')">
                        <el-option
                        v-for="item in sip.entranceConfigIdOptions"
                        :key="item.entranceConfigId"
                        :label="item.title"
                        :value="item.entranceConfigId">
                        </el-option>
                    </el-select>
                </div>
                    <h3>{{$t('TargetSip')}}</h3>
                    <div>
                        <div class="sipItem">
                            <div>{{ $t('Account') + ' 1'}}</div>
                            <el-input v-model="sip.account1"  class="editInput" :placeholder="$t('Account') + ' 1'"></el-input>
                        </div>
                        <div class="sipItem">
                            <div>{{$t('Account') + ' 2'}}</div>
                            <el-input v-model="sip.account2"  class="editInput" :placeholder="$t('Account') + ' 2'"></el-input>
                        </div>
                        <div class="sipItem">
                            <div>{{$t('Account') + ' 3'}}</div>
                            <el-input v-model="sip.account3"  class="editInput" :placeholder="$t('Account') + ' 3'"></el-input>
                        </div>
                        <div class="sipItem">
                            <div>{{$t('Account') + ' 4'}}</div>
                            <el-input v-model="sip.account4"  class="editInput" :placeholder="$t('Account') + ' 4'"></el-input>
                        </div>
                    </div>
                </div>
                <div class="btnBox">
                    <div>
                        <el-button class="savetBtn" :loading="sip.serverState == 1"  @click="saveUserSipSetting">{{$t('Save')}}</el-button>
                    </div>           
                </div>
           </div>
           <div v-show="sip.mode === 2" style="margin-top:10px;">
                <div class="sipModeConfig">
                    <p>{{$t('P2PConfig')}}</p>
                    <h3>{{$t('TargetSip')}}</h3>
                    <div>
                        <div class="sipItem">
                            <div>IP 1</div>
                            <el-input v-model="sip.ip1"  class="editInput" placeholder="IP 1"></el-input>
                        </div>
                        <div class="sipItem">
                            <div>IP 2</div>
                            <el-input v-model="sip.ip2"  class="editInput" placeholder="IP 2"></el-input>
                        </div>
                        <div class="sipItem">
                            <div>IP 3</div>
                            <el-input v-model="sip.ip3"  class="editInput" placeholder="IP 3"></el-input>
                        </div>
                        <div class="sipItem">
                            <div>IP 4</div>
                            <el-input v-model="sip.ip4"  class="editInput" placeholder="IP 4"></el-input>
                        </div>
                    </div>
                </div>
                <div class="btnBox">
                    <div>
                        <el-button class="savetBtn" :loading="sip.p2pState == 1"  @click="saveUserSipSetting">{{$t('Save')}}</el-button>
                    </div>           
                </div>
           </div>
            
        </div>
        
      </div>
       <!-- Card Settings -->
       <div class="siteUserContent" v-show="selectBtnIndex === 4">

        <!--新增Card弹框-->
        <el-dialog :title="$t('AddCardNumber')" 
            :close-on-click-modal="false" 
            :close-on-press-escape="false" 
            :visible.sync="addCard.addCardFrom" 
            center 
            :show-close="addCard.showClose" 
        >
            <div class="addsiteUersBox">
                <div class="addSiteUserLeft">
                    <el-form>
                        <el-form-item :label="$t('Name') + ' *'">
                            <el-input v-model="addCard.title"></el-input>
                        </el-form-item>
                        <div class="NameBox">
                            <div style="width:48%">
                                <el-form-item :label="$t('CardNumber') + ' *'">
                                    <el-input v-model="addCard.payload" disabled></el-input>
                                </el-form-item>
                                <el-radio v-model="addCard.addCardType" label="1" @input="changeInputType">{{$t('ManualInput')}}</el-radio>
                                <el-radio v-model="addCard.addCardType" label="2" @input="changeInputType">{{$t('Reader')}}</el-radio>
                            </div>
                            <div style="width:48%">
                                <el-form-item :label="$t('UnlockOption') + ' *'">
                                    <el-select v-model="addCard.locks" multiple :placeholder="$t('PleaseSelect')">
                                        <el-option
                                        v-for="item in addCard.locksOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="LimitContent" style="margin-top:20px;padding-top:20px;">
                            <div class="LimitBox">
                                <div style="width:48%;">
                                    <el-form-item :label="$t('Limited')" label-width="100px">
                                        <el-input v-model="addCard.countLimit" :disabled="addCard.countLimitDis"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="cardText">
                                    <span style="font-size: 16px;">{{$t('NumbeOfUses')}}</span>
                                </div>
                            </div>
                            <div class="LimitBox">
                                <div class="cardText">
                                    <span style="font-size: 16px;">{{$t('Unlinmited')}}</span>
                                </div>
                                <div class="cardText">
                                    <el-switch
                                    @change="addCradUnlinmitChage"
                                    v-model="addCard.Unlinmited">
                                    </el-switch>
                                </div>
                            </div>
                        </div>
                        <div class="LimitContent" style="margin-top: 10px;border-bottom: 1px solid #ddd; padding-top:20px;padding-bottom:30px;">
                            <div class="LimitBox">
                                <div>
                                    <el-form-item :label="$t('ValidityTime')" label-width="120px">
                                        <el-date-picker
                                        :disabled="addCard.isFovever"
                                        style="width: 203px;font-size:14px;"
                                        v-model="addCard.startTime"
                                        type="datetime"
                                        value-format="timestamp"
                                        :placeholder="$t('StartTime')">
                                        </el-date-picker> ~ 
                                        <el-date-picker
                                        :disabled="addCard.isFovever"
                                        style="width: 203px;font-size:14px;"
                                        v-model="addCard.endTime"
                                        type="datetime"
                                        value-format="timestamp"
                                        :placeholder="$t('EndTime')">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="FoveverBox">
                                <div style="padding-right: 20px; margin-top: 20px;color: #333;">
                                    <el-form-item :label="$t('Forever')" label-width="120px">
                                        <el-switch
                                            @change="addCradForeverChage"
                                            v-model="addCard.fovever">
                                        </el-switch>
                                    </el-form-item>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </el-form>
                </div>
                <div class="addSiteUserRight">
                    <div>{{$t('PanelSync')}}</div>
                    <div class="licenseBox" style="height: 380px;">
                        <div v-for="item in addCard.devList" :key="item.id" style="margin-bottom: 5px">
                            <el-checkbox  v-model="item.isChecked">{{item.deviceName}}</el-checkbox>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer addCard">
                <!-- <el-button @click="initReadCard">初始化读卡</el-button> -->
                <el-button @click="addCard.addCardFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" :loading="addCard.addBtnState === 1" class="selectBtn" @click="addCardNumber">{{$t('Confirm')}}</el-button>
            </div>
            <el-dialog
                width="30%"
                :title="$t('AddCard')"
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="addCard.inputCardFrom" 
                center 
                :show-close="addCard.showClose"
                @close="closeManualInputFrom"
                append-to-body>
                <div style="text-align: center;">
                        <el-radio v-model="addCard.manualInputType" label="1">{{$t('CardNumber')}}</el-radio>
                        <el-radio v-model="addCard.manualInputType" label="2">{{$t('FacilityCodeCardNumber')}}</el-radio>
                    </div>
                    <div>
                        <div v-show="addCard.manualInputType === '1'" class="manualInputCardBbox">
                            <el-input v-model="addCard.manualFullCardNum" style="width:260px;"></el-input>
                        </div>
                        <div v-show="addCard.manualInputType === '2'" class="manualInputCardBbox">
                            <el-input v-model="addCard.manualFacility" style="width:80px;"></el-input>
                            <span> - </span>
                            <el-input v-model="addCard.manualCardNum" style="width:200px;"></el-input>
                        </div>
                </div>
                <div slot="footer" class="dialog-footer addCard">
                    <el-button @click="addCard.inputCardFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="addCard.addBtnState === 1" class="selectBtn" @click="manualInputCardNumber">{{$t('Confirm')}}</el-button>
                </div>
            </el-dialog>
            <!-- 驱动正常弹框 -->
            <el-dialog
                width="30%"
                :title="$t('AddCard')"
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="addCard.readCardDriverFrom" 
                center 
                :show-close="addCard.showClose"
                @close="closeReadCardFrom"
                append-to-body>
                <div style="text-align: center;">
                    <img :src="addCard.driverImg" alt="">
                </div>
                <div style="text-align: center;margin: 20px 30px;">{{$t('PleasePlacePard')}}</div>
            </el-dialog>
            <!-- 没有驱动或驱动异常弹框 -->
            <el-dialog
                width="30%"
                :title="$t('AddCard')"
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="addCard.readCardNoDriverFrom" 
                center 
                :show-close="addCard.showClose"
                @close="closeReadCardFrom"
                append-to-body>
                <div style="text-align: center;">
                    <img :src="addCard.noDriverImg" alt="">
                </div>
                <div style="margin-top: 20px;word-break: keep-all;padding: 10px 20px;">{{$t('ReaderNotDetected')}}</div>
                <div slot="footer" class="dialog-footer addCard">
                    <el-button icon="el-icon-download" @click="downloadDriver" style="padding-left:5px;">{{$t('DownloadDriver')}}</el-button>
                    <el-button type="primary" icon="el-icon-refresh" :loading="addCard.addBtnState === 1" class="selectBtn" @click="testDriverBtn">{{$t('TestDriver')}}</el-button>
                </div>
            </el-dialog>
            <!-- 下载驱动弹窗 -->
            <el-dialog
                width="30%"
                :title="$t('DownloadDriver')"
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="addCard.readCardDownloadDriverFrom" 
                center 
                :show-close="addCard.showClose"
                append-to-body>
                <div style="padding-left: 10px;word-break: keep-all;">{{$t('ChooseDriverDownload')}}</div>
                <div>
                    <div class="downloadDriver" v-for="item in addCard.driverList" :key="item.url">
                        <img :src="addCard.download2Img" alt=""><a :href="item.url">{{item.name}}</a>
                    </div>
                    
                </div>
                <div slot="footer" class="dialog-footer addCard">
                    <el-button @click="addCard.readCardDownloadDriverFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" class="selectBtn" @click="addCard.readCardDownloadDriverFrom = false">{{$t('Confirm')}}</el-button>
                </div>
            </el-dialog>
             <!-- 测试驱动弹窗 -->
             <el-dialog
                width="30%"
                :title="$t('TestDriver')"
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="addCard.testDriverFrom" 
                center 
                :show-close="addCard.showClose"
                append-to-body>
                <div style="text-align: center;">
                    <img :src="addCard.testDriverLoadImg" alt="">
                </div>
                <div style="margin-top: 20px;word-break: keep-all;padding: 10px 20px;text-align:center;">{{$t('Loading') + "..."}}</div>
                <div slot="footer" class="dialog-footer addCard">
                    <el-button @click="addCard.testDriverFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" class="selectBtn" @click="restartTestDriver">{{$t('RestartTest')}}</el-button>
                </div>
            </el-dialog>
        </el-dialog>
         <!--编辑Card弹框-->
        <el-dialog :title="$t('EditCardNumber')" 
            :close-on-click-modal="false" 
            :close-on-press-escape="false" 
            :visible.sync="editCard.editCardFrom" 
            center 
            :show-close="editCard.showClose" 
        >
            <div class="addsiteUersBox">
                <div class="addSiteUserLeft">
                    <el-form>
                        <el-form-item :label="$t('Name')  + ' *'">
                            <el-input v-model="editCard.title"></el-input>
                        </el-form-item>
                        <div class="NameBox">
                            <div style="width:48%">
                                <el-form-item :label="$t('CardNumber') + ' *'">
                                <el-input v-model="editCard.payload" disabled></el-input>
                                </el-form-item>
                            </div>
                            <div style="width:48%">
                                <el-form-item :label="$t('UnlockOption') + ' *'">
                                    <el-select v-model="editCard.locks" multiple :placeholder="$t('PleaseSelect')">
                                        <el-option
                                        v-for="item in editCard.locksOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                            </el-form-item>
                            </div>
                        </div>
                        <div><span style="font-size:12px;color:#ccc;">{{$t('FacilityCode')}} : {{editCard.facilityCode}}</span><span style="margin-left:20px;font-size:12px;color:#ccc;">{{$t('SerialNumber')}} : {{editCard.cardNumber}}</span></div>
                        <div class="LimitContent" style="margin-top:20px;padding-top:20px;">
                            <div class="LimitBox">
                                <div style="width:48%;">
                                    <el-form-item :label="$t('Limited')" label-width="100px">
                                        <el-input v-model="editCard.countLimit" :disabled="editCard.countLimitDis"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="cardText">
                                    <span style="font-size: 16px;">{{$t('NumbeOfUses')}}</span>
                                </div>
                            </div>
                            <div class="LimitBox">
                                <div class="cardText">
                                    <span style="font-size: 16px;">{{$t('Unlinmited')}}</span>
                                </div>
                                <div class="cardText">
                                    <el-switch
                                    @change="editCradUnlinmitChage"
                                    v-model="editCard.Unlinmited">
                                    </el-switch>
                                </div>
                            </div>
                        </div>
                        <div class="LimitContent" style="margin-top: 10px;border-bottom: 1px solid #ddd; padding-top:20px;padding-bottom:30px;">
                            <div class="LimitBox">
                                <div>
                                    <el-form-item :label="$t('ValidityTime')" label-width="120px">
                                        <el-date-picker
                                        :disabled="editCard.isFovever"
                                        style="width: 203px; font-size:14px;"
                                        v-model="editCard.startTime"
                                        type="datetime"
                                        value-format="timestamp"
                                        :placeholder="$t('StartTime')">
                                        </el-date-picker> ~ 
                                        <el-date-picker
                                        :disabled="editCard.isFovever"
                                        style="width: 203px;font-size:14px;"
                                        v-model="editCard.endTime"
                                        type="datetime"
                                        value-format="timestamp"
                                        :placeholder="$t('EndTime')">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="FoveverBox">
                                <div style="padding-right: 20px; margin-top: 20px;color: #333;">
                                    <el-form-item :label="$t('Forever')" label-width="120px">
                                        <el-switch
                                            @change="editCradForeverChage"
                                            v-model="editCard.fovever">
                                        </el-switch>
                                    </el-form-item>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </el-form>
                </div>
                <div class="addSiteUserRight">
                    <div>{{$t('PanelSync')}}</div>
                    <div class="licenseBox" style="height: 380px;">
                        <div v-for="item in editCard.devList" :key="item.id" style="margin-bottom: 5px;display:flex;justify-content: space-between;">
                            <el-checkbox  v-model="item.isChecked">{{item.deviceName}}</el-checkbox>
                            <div v-show="item.isChecked" style="color:#999;font-size:14px;">{{handPanelApplyStatus(item.applyStatus)}}</div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer addCard">
                <el-button @click="editCard.editCardFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" :loading="editCard.editBtnState === 1" class="selectBtn" @click="editCardNumber">{{$t('Confirm')}}</el-button>
            </div>
        </el-dialog>
         <!--删除Card弹框-->
        <el-dialog
            :title="$t('Delete')"
            :visible.sync="delCard.delCardFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="30%"
            :show-close="delCard.showClose">
            <p class="deleteDesc">{{$t('ConfirmDeleteCard')}}</p>
            <span slot="footer" class="dialog-footer addCard">
                <el-button @click="delCard.delCardFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" class="selectBtn" style="margin-left: 60px !important" :loading="delCard.delBtnState === 1" @click="delCardSetting">{{$t('Confirm')}}</el-button>
            </span>
        </el-dialog>
            
        <div class="noSipsettings" v-show="card.hasNoCard">{{$t('NoData')}}!</div>
        <template>
            <el-table
            v-show="!card.hasNoCard"
            :data="card.cardList"
            style="width: 100%;"
            :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
            >
            <el-table-column
                label="#"
                type="index"
                width="100">
            </el-table-column>
            <el-table-column
                prop="title"
                :label="$t('Name')"
                >
            </el-table-column>
            
            <el-table-column
                prop="payload"
                :label="$t('CardNumber')"
                >
                <template slot-scope="scope">
                    {{decryptCardNumber(scope.row.payload)}}
                </template>
            </el-table-column>
            <el-table-column
                prop="countLimit"
                :label="$t('LimitedUse')"
                >
                <template slot-scope="scope">
                    {{handCountLimit(scope.row.countLimit,scope.row.usedCount)}}
                </template>
            </el-table-column>
            <el-table-column
                prop="startTime"
                :label="$t('ValidityTime')"
                width="300"
                >
                <template slot-scope="scope">
                    {{validityTime(scope.row.startTime,scope.row.endTime)}}
                </template>
            </el-table-column>
            <el-table-column
                prop="entranceDeviceIds"
                :label="$t('PanelSync')"
                show-overflow-tooltip
                width="400"
                >
                <template slot-scope="scope">
                    {{showPanelsName(scope.row.entranceDeviceIds)}}
                </template>
            </el-table-column>
            <el-table-column width="150"  label="..." align="center">
                <template slot-scope="scope">
                    <el-image :src="card.editImg"  @click="editCardSetting(scope.row)" style="width:24px;" fit="fill"></el-image>
                    <el-image :src="card.deleteImg"  @click="openDelCardFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                </template>
            </el-table-column>

            <el-table-column>

            </el-table-column>
        
            </el-table>
        </template>
        <!-- <div class="pageBox">
            <el-pagination
                v-show="!card.hasNoCard"
                @size-change="handleSizeChangeCard"
                @current-change="handleCurrentChangeCard"
                :current-page.sync="card.currentPage"
                :page-size="10"
                layout="prev, pager, next, jumper"
                :total="card.totalCards"
            >
            </el-pagination>
        </div> -->
       </div>

    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import CryptoJS from 'crypto-js';
import { mapState, mapMutations } from 'vuex';
import { encryptCBC, decryptCBC } from "@/util/aes";
import { openWs, closeWs, dc_init, dc_beep, dc_reset, dc_config_card,dc_card_n } from "@/util/readCard";
import QRCode from 'qrcodejs2'
import commonFun from '@/common/index.js'
import sitesUsersApi from '@/api/siteusers/index.js'
import licenseImg from '@/assets/images/license.png'
import waitmentImg from '@/assets/images/waitment.png'
import successImg from '@/assets/images/success.png'
import failImg from '@/assets/images/fail.png'
import editImg from '@/assets/images/edit.png'
import deleteImg from '@/assets/images/delete.png'
import noDriverImg from '@/assets/images/noDriver.png'
import driverImg from '@/assets/images/driver.gif'
import download2Img from '@/assets/images/download_driver2.png'
import testDriverLoadImg from '@/assets/images/test_driver_loading.gif'
export default {
    name:"SiteUserEdit",
    data(){
        return{
            showClose:true,
            selectBtnIndex:1,
            updateSiteUser:{
                id:"",
                userId:"",
                siteId:"",
                userAccount:"",
                newUserAccount:"",
                firstName:"",
                newFirstName:"",
                surname:"",
                newSurname:"",
                userRole:"",
                newUserRole:"",
                userRoleOptions:[
                    {
                       id:"admin", 
                       name:"admin", 
                    },
                    {
                       id:"employee", 
                       name:"employee", 
                    },
                    {
                       id:"user", 
                       name:"user", 
                    },
                    {
                       id:"others", 
                       name:"others", 
                    }
                ],
                apartmentId:"",
                newApartmentId:"",
                apartmentOptions:[],
                houseNo:"",
                newHouseNo:"",
                userStatus:"",
                licenseRemaining:"",
                licenseStatus:"",
                serverDuration:2,
                serverDurationUnit:"M",
                remainingTxt:"",
                bindingBtnState:0,
                unbindingBtnState:0,
                saveBtn:true,
                updateBtnState:0,
                
            },
            serverActiva:{
                showWaitForm:false,
                activationListForm: false,
                selectActivationForm: false,
                confirmActivationForm: false,
                waitmentImg,
                successImg,
                failImg,
                siteUsersListData:[], //获取用户数据，当前选中的用户
                licenseId:"",
                licenseList:[],
                licenseImg,
                selectLicense: {},
                confirmActivationBtn: false,
            },
            binding:{
                bindUserFrom:false,
                bindForm: true,
                showWaitForm: false,
                bindSuccessForm: false,
                bindBtnState:0,
                invitationEmail:"",
                firstName:"",
                surname:"",
                apartmentId:"",
                apartmentOptions:[],
                houseNo:"",
                userRole:"",
                userRoleOptions:[
                    {
                       id:"admin", 
                       name:"admin", 
                    },
                    {
                       id:"employee", 
                       name:"employee", 
                    },
                    {
                       id:"user", 
                       name:"user", 
                    },
                    {
                       id:"others", 
                       name:"others", 
                    }
                ],
                waitmentImg,
                siteName:"",
                apartmentName:"",
                invitationCode:"",
                invitationQrCode:"",
            },
            unbinding:{
                unbindUserFrom:false,
                unbindBtnState:0
            },
            userDevices:{
                deviceList:[],
                currentPage:1,
                totalDevices:0
            },
            sip:{
                mode:1,
                entrancePackId:"", //sip sever entrancePackId
                entrancePackId_p2p:"", ////sip p2p entrancePackId
                entranceConfigShow:false,
                entranceConfigLoding:"",
                entranceConfigId:"",
                entranceConfigIdOptions:[],
                entranceConfigIdDis:false,
                entranceDeviceId:"",
                entranceDeviceIdOptions:[],
                serverList:[],
                p2pList:[],
                account1:"",
                account2:"",
                account3:"",
                account4:"",
                ip1:"",
                ip2:"",
                ip3:"",
                ip4:"",
                serverState:"",
                p2pState:"",
            },
            card:{
                hasNoCard:false,
                cardList:[],
                editImg,
                deleteImg,
                showAddCardBtn:0,
                // totalCards:0,
                // currentPage: 1,
            },
            addCard:{
                addCardFrom:false,
                showClose:true,
                title:"",
                addCardType:"",
                payload:"",
                locks:"",
                locksOptions:[
                    {
                        id:"1",
                        name:"Lock 1"
                    },
                    {
                        id:"2",
                        name:"Lock 2"
                    },
                ],
                countLimitDis:false,
                countLimit:"",
                Unlinmited:false,
                devList:[],
                startTime:"",
                endTime:"",
                isFovever:false,
                fovever:false,
                addBtnState:0,
                readCardDevice:null, //读卡
                intervalId:null,
                inputCardFrom:false,
                manualInputType:"1",
                manualFullCardNum:"",
                manualFacility:"",
                manualCardNum:"",
                readCardDriverFrom:false, //驱动正常弹框
                readCardNoDriverFrom:false,//驱动异常弹框
                readCardDownloadDriverFrom:false,//下载驱动弹框
                testDriverFrom:false,//测试驱动弹框
                driverImg,
                noDriverImg,
                download2Img,
                testDriverLoadImg,
                driverList:[],
            },
            editCard:{
                editCardFrom:false,
                showClose:true,
                entranceKeyId:"",
                title:"",
                payload:"",
                locks:"",
                locksOptions:[
                    {
                        id:"1",
                        name:"Lock 1"
                    },
                    {
                        id:"2",
                        name:"Lock 2"
                    },
                ],
                countLimitDis:false,
                countLimit:1,
                Unlinmited:false,
                devList:[],
                entranceDeviceList:[],
                startTime:"",
                endTime:"",
                isFovever:false,
                fovever:false,
                userId:"",
                facilityCode:"",
                cardNumber:"",
                editBtnState:0,
            },
            delCard:{
                delCardFrom:false,
                showClose:true,
                entranceKeyId:"",
                delBtnState:0,
            },
        }
    },
    mounted(){
        // this.$store.commit("CHANGEMODEL","company");
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/siteusers',
                name:this.$t('SiteUsers')
            },
            {
                id:nanoid(),
                path:'/main/siteusers',
                name:this.$t('UserDetail')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
        //获取Block列表，渲染下拉列表框
        this.getBlocksListForSelect();
        this.updateSiteUser.id = this.$route.params.id;
        this.updateSiteUser.userId = this.$route.params.userId;
        this.detailSiteUser({userHouseId:this.updateSiteUser.id});
        // console.log("updateSiteUser",this.$route.params);
    },
    methods:{
        // ...mapMutations(["OPENINEDITPAGE"]),
        //获取Block列表，渲染下拉列表框
        getBlocksListForSelect(){
            let blocksInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
            }
            sitesUsersApi.getSiteBlocksListForSelect(blocksInfoObj).then(res => {
                if(res.code === 0){
                    this.updateSiteUser.apartmentOptions = [];
                    this.binding.apartmentOptions = [];
                    this.updateSiteUser.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    this.binding.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    for(let i=0;i< res.data.length;i++){
                        this.updateSiteUser.apartmentOptions.push(res.data[i]);
                        this.binding.apartmentOptions.push(res.data[i]);
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        changeEditType(type){
            this.selectBtnIndex = type;
            if(type === 1){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/siteusers',
                        name:this.$t('SiteUsers')
                    },
                    {
                        id:nanoid(),
                        path:'/main/siteusers',
                        name:this.$t('UserDetail')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);
            }else if(type === 2){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/siteusers',
                        name:this.$t('SiteUsers')
                    },
                    {
                        id:nanoid(),
                        path:'/main/siteusers',
                        name:this.$t('Panels')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);
                let serachDevicesObj = {
                    "userHouseId":this.updateSiteUser.id,
                    "pageNum":1
                };
                this.getSiteUserDevicesList(serachDevicesObj);
            }else if(type === 3){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/siteusers',
                        name:this.$t('SiteUsers')
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('SipSettings')
                    }
                ];
                // this.sip.showAddSipBtn = 1;
                this.sip.mode = 1;
                this.$generateBreadcrumb(siteMangeBread);
               //获取有权限的设备
                this.getSiteUserSipDevicesList();
            }else if(type === 4){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/siteusers',
                        name:this.$t('SiteUsers')
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:"Card Settings"
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);
                //获取有权限的设备
                this.getSiteUserCardDevicesList();
                //获取用户Card列表
                this.getSiteUserCardList();
            }
        },
        //用户名渲染函数
        showUserName(surname,firstName){
            if(surname == null){
                surname = "";
            }
            if(firstName == null){
                firstName = "";
            }
            let userName = surname + " " + firstName;
            return userName.trim();
        },
        //获取用户住宅详情
        detailSiteUser(item){
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
                "userHouseId":item.userHouseId,
                "siteId":this.selectSite.id,
            };
             // 刷新时，apartmentid丢失，这里做个规避
            if(this.updateSiteUser.id === undefined){
                return false
            }
            sitesUsersApi.detailSiteUser(rgnObj).then(res => {
                if(res.code === 0){
                    this.updateSiteUser.userAccount = res.data.userAccount;
                    this.updateSiteUser.newUserAccount = res.data.userAccount;
                    this.updateSiteUser.firstName = res.data.firstName;
                    this.updateSiteUser.newFirstName = res.data.firstName;
                    this.updateSiteUser.surname = res.data.surname;
                    this.updateSiteUser.newSurname = res.data.surname;
                    this.updateSiteUser.userRole = res.data.userRole;
                    this.updateSiteUser.newUserRole = res.data.userRole;
                    this.updateSiteUser.apartmentId = res.data.apartmentId;
                    this.updateSiteUser.newApartmentId = res.data.apartmentId;
                    this.updateSiteUser.houseNo = res.data.houseNo;
                    this.updateSiteUser.newHouseNo = res.data.houseNo;
                    this.updateSiteUser.userStatus = res.data.userStatus;
                    this.updateSiteUser.licenseStatus = res.data.licenseStatus;
                    this.updateSiteUser.licenseRemaining = res.data.licenseRemaining;
                    this.updateSiteUser.remainingTxt = this.updateSiteUser.licenseRemaining === 0 ? 0 : this.updateSiteUser.licenseRemaining + " " + this.$t('Days');
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //user status 渲染函数
        getUserStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('NotResponding');
                    break;
                case 1:
                    getStr = this.$t('Accepted');
                    break
                case 2:
                    getStr = this.$t('Refuse');
                    break
                case 3:
                    getStr = this.$t('NotBound');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        //licenseStatus 渲染函数
        getServerStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('NotEnabled');
                    break;
                case 1:
                    getStr = this.$t('InUse');
                    break
                case 2:
                    getStr = this.$t('Cancel').replace(' ','');
                    break
                case 9:
                    getStr = this.$t('End');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
       //server status状态样式渲染函数
       getServerStatusClass(status){
            let getClass = "";
            switch(status){
                case 0:
                    getClass = "statusRed";
                    break;
                case 1:
                    getClass = "statusGreen";
                    break
                case 2:
                    getClass = "statusOrange";
                    break
                case 3:
                    getClass = "statusOrange";
                    break
                case 9:
                    getClass = "";
                    break
                default:
                    getClass = "";
            }
            return getClass;
        },
        //更新site user信息
        saveUpdateSiteUser(){
            // if((this.updateSiteUser.userAccount).trim() === ""){
            //     this.$message.error("The User Account cannot be empty");
            //     return false
            // }
            if(this.updateSiteUser.firstName == null){
                this.updateSiteUser.firstName = "";
            }
            if(this.updateSiteUser.surname == null){
                this.updateSiteUser.surname = "";
            }
            if(this.updateSiteUser.houseNo == null){
                this.updateSiteUser.houseNo = "";
            }
            if((this.updateSiteUser.firstName).trim() === ""){
                this.$message.info(this.$t('FirstNameEmptys'));
                return false
            }
            let checkResultFirstName = this.$regularCheckLimit(this.updateSiteUser.firstName,1,20,'FirstNameLimit');
            if(!checkResultFirstName){
                return false
            }
            if((this.updateSiteUser.surname).trim() === ""){
                this.$message.info(this.$t('SurnameNameEmpty'));
                return false
            }
            let checkResultSurname = this.$regularCheckLimit(this.updateSiteUser.surname,1,20,'SurnameLimit');
            if(!checkResultSurname){
                return false
            }
            if((this.updateSiteUser.houseNo).trim() === ""){
                this.$message.info(this.$t('HouseNumberEmpty'));
                return false
            }
            let houseNumberRegex = /^[a-zA-Z0-9]+$/;
            if(!houseNumberRegex.test(this.updateSiteUser.houseNo)){
                this.$message.info(this.$t('LimitHouseNumber'));
                return false
            }
            let checkResulNumberReg = /^\d+$/;
            if(checkResulNumberReg.test(this.updateSiteUser.houseNo)){
                if(Number(this.updateSiteUser.houseNo) <= 0){
                    this.$message.info(this.$t('LimitHouseNumber'));
                    return false
                }
            }
            let checkResultHouseNumber = this.$regularCheckLimit(this.updateSiteUser.houseNo,1,20,'HouseNumberLimit');
            if(!checkResultHouseNumber){
                return false
            }
            this.updateSiteUser.updateBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let updateSiteUserObj = {
                "id":this.updateSiteUser.id,
                "siteId":this.selectSite.id,
                "firstName":this.updateSiteUser.firstName,
                "surname":this.updateSiteUser.surname,
                "userRole":this.updateSiteUser.userRole,
                "apartmentId":this.updateSiteUser.apartmentId,
                "houseNo":this.updateSiteUser.houseNo,
            }
            sitesUsersApi.updateSiteUser(rgnObj,updateSiteUserObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.updateSiteUser.updateBtnState = 0;
                    this.updateSiteUser.saveBtn = true;
                    this.updateSiteUser.newUserAccount = this.updateSiteUser.userAccount;
                    this.updateSiteUser.newFirstName = this.updateSiteUser.firstName;
                    this.updateSiteUser.newSurname = this.updateSiteUser.surname;
                    this.updateSiteUser.newUserRole = this.updateSiteUser.userRole;
                    this.updateSiteUser.newApartmentId = this.updateSiteUser.apartmentId;
                    this.updateSiteUser.newHouseNo = this.updateSiteUser.houseNo;
                    this.detailSiteUser({userHouseId:this.updateSiteUser.id});
                }else{
                    this.updateSiteUser.updateBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                this.updateSiteUser.updateBtnState = 0;
                console.log(err)
            })
        },
        //点击augment执行函数
        activateServiceFrom(){
            this.serverActiva.siteUsersListData = [];
            this.serverActiva.siteUsersListData.push(
                {
                    id:this.updateSiteUser.id,
                    surname:this.updateSiteUser.surname,
                    firstName:this.updateSiteUser.firstName,
                    licenseStatus:this.updateSiteUser.licenseStatus,
                    licenseRemaining:this.updateSiteUser.licenseRemaining,
                }
            )
            this.serverActiva.activationListForm = true;
            this.serverActiva.showWaitForm = false;
            this.serverActiva.selectActivationForm = true;
            this.serverActiva.confirmActivationForm = false;
            this.getUsersLicenseList();
        },
        //可用License列表获取
        getUsersLicenseList(){
            let licenseObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "companyId":this.selectCompany.id,
            }
            sitesUsersApi.getSiteUsersLicenseList(licenseObj).then(res => {
                if(res.code === 0){
                    this.serverActiva.licenseList = [];
                    if(res.data === null || res.data.length === 0){
                        this.serverActiva.licenseList = [];
                    }else{
                        for(let i=0;i<res.data.length;i++){
                            this.serverActiva.licenseList.push(res.data[i]);
                        }
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
         //选择需要activa的用户列表状态渲染
        getActivationServerStatusClass(obj){
            let getClass = "";
            if(obj.columnIndex === 1){
                    let status = obj.row.licenseStatus;
                    switch(status){
                        case 0:
                            getClass = "statusRed";
                            break;
                        case 1:
                            getClass = "statusGreen";
                            break
                        case 2:
                            getClass = "statusOrange";
                            break
                        case 9:
                            getClass = "";
                            break
                        default:
                            getClass = "";
                    }
                }
                return getClass;
        },
        
        //日期字符串转换函数
        stringDate(val,str) {
            if(val>1){
                if (str == "Y" || str == "y") {
                    return this.$t('Years')
                } else if (str == "M" || str == "m") {
                    return this.$t('Months')
                } else if (str == "D" || str == "d") {
                    return this.$t('Days')
                }
            }else{
                if (str == "Y" || str == "y") {
                    return this.$t('Year')
                } else if (str == "M" || str == "m") {
                    return this.$t('Month')
                } else if (str == "D" || str == "d") {
                    return this.$t('Day')
                }
            }
        },
        getConfirmActivationServerStatusClass(obj){
            let getClass = "";
            if(obj.columnIndex === 1){
                let status = obj.row.licenseStatus;
                switch(status){
                    case 0:
                        getClass = "statusRed";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusOrange";
                        break
                    case 9:
                        getClass = "";
                        break
                    default:
                        getClass = "";
                }
            }
            return getClass;
        },
        //关闭Service Activation弹框
        closeActivateServiceFrom(){
            this.serverActiva.activationListForm = false;
        },
        //点击Activation执行方法
        confirmactivateService(){
            if(this.serverActiva.licenseId === ""){
                this.$message.info(this.$t('SelectLicense'));
                return false
            }           
            this.serverActiva.activationListForm = true;
            this.serverActiva.showWaitForm = true;
            this.serverActiva.selectActivationForm = false;
            this.serverActiva.confirmActivationForm = false;
            this.serverActiva.selectLicense = commonFun.getTargetObjInfo(this.serverActiva.licenseList,this.serverActiva.licenseId);
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let activateUserObj = {
                "siteLicenseId": this.serverActiva.licenseId,
            };
            activateUserObj.userHouseIdList = [];
            activateUserObj.userHouseIdList.push(this.updateSiteUser.id);
            sitesUsersApi.activationSiteUserLicense(rgnObj,activateUserObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('ActivationSuccessful'));
                    this.serverActiva.activationListForm = true;
                    this.serverActiva.showWaitForm = false;
                    this.serverActiva.selectActivationForm = false;
                    this.serverActiva.confirmActivationForm = true;
                    //确定按钮禁用
                    this.serverActiva.confirmActivationBtn = true;
                    //更新用户信息
                    this.detailSiteUser({userHouseId:this.updateSiteUser.id});
                    //更新License相关信息
                    this.getUsersLicenseList()
                    setTimeout(() => {
                        this.serverActiva.siteUsersListData = [];
                        this.serverActiva.siteUsersListData.push(
                            {
                                id:this.updateSiteUser.id,
                                surname:this.updateSiteUser.surname,
                                firstName:this.updateSiteUser.firstName,
                                licenseStatus:this.updateSiteUser.licenseStatus,
                                licenseRemaining:this.updateSiteUser.licenseRemaining,
                            }
                        )
                        //更新License剩余数量
                        this.serverActiva.selectLicense = commonFun.getTargetObjInfo(this.serverActiva.licenseList,this.serverActiva.licenseId);
                    }, 100);
                }else{
                    this.$handerrcode(res.code);
                    this.serverActiva.activationListForm = true;
                    this.serverActiva.showWaitForm = false;
                    this.serverActiva.selectActivationForm = false;
                    this.serverActiva.confirmActivationForm = true;
                    //确定按钮取消禁用
                    this.serverActiva.confirmActivationBtn = false;
                }
            }).catch((err) => {
                console.log(err);
                this.serverActiva.activationListForm = true;
                this.serverActiva.showWaitForm = false;
                this.serverActiva.selectActivationForm = false;
                this.serverActiva.confirmActivationForm = true;
                //确定按钮取消禁用
                this.serverActiva.confirmActivationBtn = false;
            })

            setTimeout(() => {
                this.serverActiva.activationListForm = true;
                    this.serverActiva.showWaitForm = false;
                    this.serverActiva.selectActivationForm = false;
                    this.serverActiva.confirmActivationForm = true;
                    //确定按钮禁用
                    this.serverActiva.confirmActivationBtn = true;
            }, 1000);
        },

        //点击绑定按钮执行函数
        bindingUserFrom(){
            this.binding.invitationEmail = "";
            this.binding.firstName = "";
            this.binding.surname = "";
            this.binding.apartmentId = this.updateSiteUser.apartmentId;
            this.binding.houseNo = this.updateSiteUser.houseNo;
            this.binding.userRole = this.updateSiteUser.userRole;
            this.binding.bindBtnState = 0;
            this.binding.bindUserFrom = true;
            this.binding.bindForm = true;
            this.binding.showWaitForm = false;
            this.binding.bindSuccessForm = false;
            //this.getUsersLicenseList();
        },

        //绑定弹框点击取消
        // cancalSiteUserFrom(){
        //     this.binding.bindUserFrom = false;
        // },

        //绑定按钮确认函数
        bindingNewSiteUser(){
            if((this.binding.invitationEmail).trim() === ""){
                this.$message.info(this.$t('UserEmailEmpty'));
                return false
            }
            let reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
            if(!reg.test(this.binding.invitationEmail)){
                this.$message.info(this.$t('EmailFormatLimit'));
                return false
            }
            if((this.binding.firstName).trim() === ""){
                this.$message.info(this.$t('FirstNameEmptys'));
                return false
            }
            let checkResultFirstName = this.$regularCheckLimit(this.binding.firstName,1,20,'FirstNameLimit');
            if(!checkResultFirstName){
                return false
            }
            if((this.binding.surname).trim() === ""){
                this.$message.info(this.$t('SurnameNameEmpty'));
                return false
            }
            let checkResultSurname = this.$regularCheckLimit(this.binding.surname,1,20,'SurnameLimit');
            if(!checkResultSurname){
                return false
            }
            if((this.binding.houseNo).trim() === ""){
                this.$message.info(this.$t('HouseNumberEmpty'));
                return false
            }
            let houseNumberRegex = /^[a-zA-Z0-9]+$/;
            if(!houseNumberRegex.test(this.binding.houseNo)){
                this.$message.info(this.$t('LimitHouseNumber'));
                return false
            }
            let checkResulNumberReg = /^\d+$/;
            if(checkResulNumberReg.test(this.binding.houseNo)){
                if(Number(this.binding.houseNo) <= 0){
                    this.$message.info(this.$t('LimitHouseNumber'));
                    return false
                }
            }
            let checkResultHouseNumber = this.$regularCheckLimit(this.binding.houseNo,1,20,'HouseNumberLimit');
            if(!checkResultHouseNumber){
                return false
            }
            if((this.binding.userRole).trim() === ""){
                this.$message.info(this.$t('UserRoleEmpty'));
                return false
            }
            // if((this.binding.activationType).trim() === ""){
            //     this.$message.error("The Activation method be empty");
            //     return false
            // }
            if((this.binding.apartmentId).trim() === ""){
                this.$message.info(this.$t('SiteOrBlocksEmpty'));
                return false
            }
            this.binding.bindUserFrom = true;
            this.binding.bindForm = false;
            this.binding.showWaitForm = true;
            this.binding.bindSuccessForm = false;
            this.binding.bindBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let bindUserObj = {
                "userHouseId":this.updateSiteUser.id,
                "siteId":this.selectSite.id,
                "apartmentId":this.binding.apartmentId,
                "invitationEmail":this.binding.invitationEmail,
                "firstName": this.binding.firstName,
                "surname": this.binding.surname,
                "houseNo": this.binding.houseNo,
                "userRole": this.binding.userRole,
                "language": this.language
            }
            // console.log("bindUserObj",bindUserObj);
            // return false
            sitesUsersApi.bindingSiteUser(rgnObj,bindUserObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('BindingSuccessful'));
                    this.binding.bindBtnState = 0;
                    this.binding.siteName = this.selectSite.siteName;
                    this.binding.apartmentName = res.data.apartmentName;
                    this.binding.houseNo = res.data.houseNo;
                    this.binding.invitationCode = res.data.invitationCode;
                    this.binding.invitationQrCode = res.data.invitationQrcode;
                    this.$refs.bindQRcodeForApp.innerHTML = '';
                    //调用生成二维码函数
                    this.creatQrCode();
                    this.binding.bindUserFrom = true;
                    this.binding.bindForm = false;
                    this.binding.showWaitForm = false;
                    this.binding.bindSuccessForm = true;
                }else{
                    this.binding.bindBtnState = 0;
                    this.$handerrcode(res.code);
                    this.binding.bindUserFrom = true;
                    this.binding.bindForm = true;
                    this.binding.showWaitForm = false;
                    this.binding.bindSuccessForm = false;
                }
            }).catch((err) => {
                this.binding.bindBtnState = 0;
                console.log(err);
                this.binding.bindUserFrom = true;
                this.binding.bindForm = true;
                this.binding.showWaitForm = false;
                this.binding.bindSuccessForm = false;
            })
           
            // this.binding.bindUserFrom = true;
            // this.binding.bindForm = false;
            // this.binding.showWaitForm = true;
            // this.binding.bindSuccessForm = false;

            // setTimeout(() => {
            //     this.binding.bindUserFrom = true;
            //     this.binding.bindForm = false;
            //     this.binding.showWaitForm = false;
            //     this.binding.bindSuccessForm = true;
            // }, 1000);
        },
        //生成app加入二维码
        creatQrCode() {
            new QRCode(this.$refs.bindQRcodeForApp, {
                text: this.binding.invitationQrCode, // 需要转换为二维码的内容
                width: 185,
                height: 185,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },

        //绑定成功点击确认按钮
        bindingSucceSiteUser(){
            this.binding.bindUserFrom = false;
            this.binding.bindForm = false;
            this.binding.showWaitForm = false;
            this.binding.bindSuccessForm = false;
            this.detailSiteUser({userHouseId:this.updateSiteUser.id});
        },
        //点击解绑执行函数
        unbindingSiteUserFrom(){
            this.unbinding.unbindUserFrom = true;
        },
        //点击解绑确认按钮执行方法 
        unbindingSiteUser(){
            this.unbinding.unbindBtnState = 1;
            let unbindUserObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let unbindingSiteUserObj = {}
            unbindingSiteUserObj.siteId = this.selectSite.id;
            unbindingSiteUserObj.userHouseId = this.updateSiteUser.id;
            // console.log("unbindingSiteUserObj",unbindingSiteUserObj);
            // console.log("unbindUserObj",unbindUserObj);
            // return false
            sitesUsersApi.unbindingSiteUsers(unbindUserObj,unbindingSiteUserObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UnbindSuccessful'));
                    this.unbinding.unbindBtnState = 0;
                    this.unbinding.unbindUserFrom = false;
                    this.detailSiteUser({userHouseId:this.updateSiteUser.id});
                }else{
                    this.$handerrcode(res.code);
                    this.unbinding.unbindBtnState = 0;
                }
            }).catch((err) => {
                this.unbinding.unbindBtnState = 0;
                console.log(err)
            })
            
        },

         //查询住宅有权限设备列表
        getSiteUserDevicesList(serachSiteDevicesObj){
            let devicesInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "userHouseId":serachSiteDevicesObj.userHouseId,
                "pageSize":10,
                "pageNum":serachSiteDevicesObj.pageNum,
            }
            sitesUsersApi.getSiteUserDevicesList(devicesInfoObj).then(res => {
                if(res.code === 0){
                    this.userDevices.deviceList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.userDevices.deviceList = [];
                    }else{
                        for(let i=0;i<res.data.list.length;i++){
                            this.userDevices.deviceList.push(res.data.list[i]);
                        }
                    }
                    this.userDevices.currentPage = res.data.pageNum;
                    this.userDevices.totalDevices = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //用户权限翻译函数
        userPermissionStr(permissionArr){
            let getStr = "";
            if(permissionArr === null){
                return getStr = "--";
            }
            for(let i=0;i<permissionArr.length;i++){
                if(permissionArr[i] === "1"){
                    if((i+1) === permissionArr.length){
                        getStr+= this.$t('Preview')
                    }else{
                        getStr+= this.$t('PreviewEnd')
                    }
                    
                }
                if(permissionArr[i] === "2"){
                    if((i+1) === permissionArr.length){
                        getStr+= this.$t('Unlock')
                    }else{
                        getStr+= this.$t('UnlockEnd')
                    }
                    
                }
                if(permissionArr[i] === "3"){
                    if((i+1) === permissionArr.length){
                        getStr+= this.$t('Call')
                    }else{
                        getStr+= this.$t('CallEnd')
                    }
                    
                }
            }
            
            return getStr;
        },
        
        //devices 状态改变执行函数
        stateChanged(item){
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const updateDevAccessObj = {}
            updateDevAccessObj.siteId = this.selectSite.id;
            updateDevAccessObj.userHouseId = this.updateSiteUser.id;
            updateDevAccessObj.entranceDeviceId = item.id;
            updateDevAccessObj.accessStatus = item.accessStatus;
            sitesUsersApi.updateDevicesAccess(rgnObj,updateDevAccessObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    let serachDevicesObj = {
                        "userHouseId":this.updateSiteUser.id,
                        "pageNum":1
                    };
                    this.getSiteUserDevicesList(serachDevicesObj);
                }else{
                    this.$handerrcode(res.code);
                }
                
            }).catch((err) => {
                console.log('err', err);
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            let serachDevicesObj = {
                "userHouseId":this.updateSiteUser.id,
                "pageNum":val
            };
            this.getSiteUserDevicesList(serachDevicesObj);
        },
        /**
         * sip setting
         */
        //user sip type切换执行函数
        changeSipType(val){
            if(val === 1){
                //获取user sip相关配置 type 为sip_server
                this.getUserSipConfig(1,'sip_server');
            }else{
                //获取user sip相关配置 type 为sip_p2p
                this.getUserSipConfig(1,'sip_p2p');
            }
        },
        //获取panels列表（支持）
        //查询住宅有权限设备列表
        getSiteUserSipDevicesList(){
            let devicesInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "userHouseId":this.updateSiteUser.id,
                "pageSize":-1,
                "pageNum":1,
            }
            sitesUsersApi.getSiteUserDevicesList(devicesInfoObj).then(res => {
                if(res.code === 0){
                    this.sip.entranceDeviceIdOptions = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.sip.entranceDeviceIdOptions = [];
                    }else{
                        for(let i=0;i<res.data.list.length;i++){
                            if(res.data.list[i].accessStatus === 1){
                                this.sip.entranceDeviceIdOptions.push(res.data.list[i]);
                            }
                        }
                    }
                    //获取user sip相关配置
                    this.getUserSipConfig(1,'sip_server');
                }else{
                    this.sip.entranceDeviceIdOptions = [];
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                this.sip.entranceDeviceIdOptions = [];
                console.log(err);
            })
        },
        //获取user sip相关配置
        getUserSipConfig(pageNum,type){
            let userSipObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "userHouseId":this.updateSiteUser.id,
                "type":type,
                "pageSize":-1,
                "pageNum":pageNum,
            }
            this.sip.entranceConfigShow = false;
            sitesUsersApi.getSiteUserSipConfigList(userSipObj).then(res => {
                if(res.code === 0){
                    if(res.data === null || res.data.list.length === 0){
                        this.sip.entranceDeviceId = '';
                        this.sip.entranceConfigId = '';
                        this.sip.entranceConfigIdDis = true;
                        if(type === "sip_server"){
                            this.sip.entrancePackId = "";
                            this.sip.account1 = "";
                            this.sip.account2 = "";
                            this.sip.account3 = "";
                            this.sip.account4 = "";
                            //获取第一个设备下的sip配置,并渲染Server Config下拉列表框
                            if(this.sip.entranceDeviceIdOptions.length > 0){
                                this.getEntranceConfig(this.sip.entranceDeviceIdOptions[0].id);
                            }else{
                                this.sip.entranceConfigShow = true;
                            }
                        }else{
                            this.sip.entrancePackId_p2p = "";
                            this.sip.ip1 = "";
                            this.sip.ip2 = "";
                            this.sip.ip3 = "";
                            this.sip.ip4 = "";
                        }
                    }else{
                        //user sip配置
                        let UserSipObj = res.data.list;
                        this.sip.serverList = [];
                        this.sip.p2pList = [];
                        UserSipObj.map(item=>{
                            if(item.entranceDeviceId === "0"){
                                this.sip.p2pList.push(item);
                            }else{
                                this.sip.serverList.push(item);
                            }
                        })
                        //用户配置的账号信息
                        let targetServerList = [];
                        //用户配置的IP信息
                        let targetP2PList = [];
                        if(type === "sip_server"){
                            this.sip.account1 = "";
                            this.sip.account2 = "";
                            this.sip.account3 = "";
                            this.sip.account4 = "";
                            if(this.sip.serverList.length > 0){
                                //获取第一个设备下entrancePackId
                                this.sip.entrancePackId = this.sip.serverList[0].entrancePackId;
                                this.sip.entranceDeviceId = this.sip.serverList[0].entranceDeviceId;
                                this.sip.entranceConfigId = JSON.parse(this.sip.serverList[0].payload).entranceConfigId;
                                //获取第一个设备下的sip配置,并渲染Server Config下拉列表框
                                this.getEntranceConfig(this.sip.entranceDeviceId,this.sip.entranceConfigId);
                                targetServerList = JSON.parse(this.sip.serverList[0].payload).targetList;
                            }
                            //渲染server model
                            targetServerList.map((item,index)=>{
                                if(index==0){
                                    this.sip.account1 = item;
                                }else if(index==1){
                                    this.sip.account2 = item;
                                }else if(index==2){
                                    this.sip.account3 = item;
                                }else if(index==3){
                                    this.sip.account4 = item;
                                }
                            })
                        }else{
                            this.sip.ip1 = "";
                            this.sip.ip2 = "";
                            this.sip.ip3 = "";
                            this.sip.ip4 = "";
                            if(this.sip.p2pList.length > 0){
                                targetP2PList = JSON.parse(this.sip.p2pList[0].payload).targetList;
                                this.sip.entrancePackId_p2p = this.sip.p2pList[0].entrancePackId;
                            }
                            //渲染p2p model
                            targetP2PList.map((item,index)=>{
                                if(index==0){
                                    this.sip.ip1 = item;
                                }else if(index==1){
                                    this.sip.ip2 = item;
                                }else if(index==2){
                                    this.sip.ip3 = item;
                                }else if(index==3){
                                    this.sip.ip4 = item;
                                }
                            })
                        }
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //panels改变执行函数
        selUserSipServerDev(val){
            this.sip.entranceConfigIdDis = false;
            //获取所选设备对应的
            this.sip.entrancePackId = "";
            this.sip.serverList.map(item=>{
                if(item.entranceDeviceId === val){
                    this.sip.entrancePackId = item.entrancePackId;
                }
            })
            this.sip.entranceConfigShow = false;
            if(!this.sip.entrancePackId){
                this.sip.account1 = "";
                this.sip.account2 = "";
                this.sip.account3 = "";
                this.sip.account4 = "";
                //根据entranceDeviceId来渲染Server Config，若没有找到对应的entrancePackId，则只渲染Server Config
                this.getEntranceConfig(val);
            }else{
                //查询单个用户出入口sip设置
                let devicesInfoObj = {
                    "rgn":this.selectCompany.companyRegion,
                    "siteId":this.selectSite.id,
                    "entrancePackId":this.sip.entrancePackId,
                }
                sitesUsersApi.getSitePanelsSipEntrancePack(devicesInfoObj).then(res => {
                    if(res.code === 0){
                        if(res.data === null){
                            this.sip.account1 = "";
                            this.sip.account2 = "";
                            this.sip.account3 = "";
                            this.sip.account4 = "";
                        }else{
                            this.sip.account1 = "";
                            this.sip.account2 = "";
                            this.sip.account3 = "";
                            this.sip.account4 = "";
                             //根据entranceDeviceId来渲染Server Config，找到对应的entrancePackId，获取Server Config（entranceConfigId）并渲染Server Config列表，选中获取Server Config
                            this.getEntranceConfig(val,JSON.parse(res.data.payload).entranceConfigId);
                            let targetServerList = [];
                            targetServerList = JSON.parse(res.data.payload).targetList;
                            targetServerList.map((item,index)=>{
                            if(index==0){
                                this.sip.account1 = item;
                            }else if(index==1){
                                this.sip.account2 = item;
                            }else if(index==2){
                                this.sip.account3 = item;
                            }else if(index==3){
                                this.sip.account4 = item;
                            }
                        })
                        }
                    }else{
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
        //server config改变执行函数
        // selUserSipServerConfig(val){
        //     console.log("ServerConfig",val)
        // },
        //获取设备sip配置(Server Config)
        getEntranceConfig(entranceDeviceId,hasVal){
            let panelsSipObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "entranceDeviceId":entranceDeviceId,
                "type":'sip',
                "pageSize":-1,
                "pageNum":1,
            }
            sitesUsersApi.getSitePanelsSipConfigList(panelsSipObj).then(res => {
                if(res.code === 0){
                    this.sip.entranceConfigIdOptions = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.sip.entranceConfigId = '';
                        this.sip.entranceConfigIdOptions = [];
                        this.sip.entranceConfigShow = true;
                    }else{
                        if(hasVal){
                            this.sip.entranceConfigId = hasVal;
                        }else{
                            this.sip.entranceConfigId = '';
                        }
                        this.sip.entranceConfigIdOptions = res.data.list;
                        this.sip.entranceConfigShow = true;
                    }
                }else{
                    this.$handerrcode(res.code);
                    this.sip.entranceConfigId = '';
                    this.sip.entranceConfigIdOptions = [];
                    this.sip.entranceConfigShow = true;
                }
            }).catch((err) => {
                console.log(err)
                this.sip.entranceConfigId = '';
                this.sip.entranceConfigIdOptions = [];
                this.sip.entranceConfigShow = true;
            })
        },
        //保存&更新user sip settings配置
         saveUserSipSetting(){
            let typeModel = '';
            if(this.sip.mode === 1){
                typeModel = 'sip_server';
                if((this.sip.entranceDeviceId).trim() === ""){
                    this.$message.info(this.$t('PleaseSelectPanels'));
                    return false
                }
            }else{
                typeModel = 'sip_p2p';
                this.sip.entranceDeviceId = "0";
            }
            //获取用户输入的账号或者IP
            let targetListArr = [];
            if(this.sip.mode === 1){
               if(this.sip.account1 != ""){
                targetListArr.push(this.sip.account1)
               }
               if(this.sip.account2 != ""){
                targetListArr.push(this.sip.account2)
               }
               if(this.sip.account3 != ""){
                targetListArr.push(this.sip.account3)
               }
               if(this.sip.account4 != ""){
                targetListArr.push(this.sip.account4)
               }
            }else{
                if(this.sip.ip1 != ""){
                    targetListArr.push(this.sip.ip1)
                }
                if(this.sip.ip2 != ""){
                    targetListArr.push(this.sip.ip2)
                }
                if(this.sip.ip3 != ""){
                    targetListArr.push(this.sip.ip3)
                }
                if(this.sip.ip4 != ""){
                    targetListArr.push(this.sip.ip4)
                }
            }
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addUserSipObj = {
                "type": typeModel,
                "userHouseId":this.updateSiteUser.id,
                "entranceDeviceId": this.sip.entranceDeviceId, 
                "title": "user sip",
            };
            let entranceConfigId = "";
            if(this.sip.mode === 1){
                if(this.sip.entranceConfigId === ""){
                    this.$message.info(this.$t('ServerConfigEmpty'));
                    return false
                }
                entranceConfigId = this.sip.entranceConfigId;
            }else{
                entranceConfigId = "";
            }
            addUserSipObj.payload = JSON.stringify({
                "sipMode":this.sip.mode,
                "entranceConfigId":entranceConfigId,
                "targetList":targetListArr
            })
            if(this.sip.mode === 1){
                this.sip.serverState = 1;
            }else{
                this.sip.p2pState = 1;
            }
            if(this.sip.mode === 1){
                if(!this.sip.entrancePackId){
                    sitesUsersApi.addSiteUserSipConfig(rgnObj,addUserSipObj).then(res => {
                        if(res.code === 0){
                            this.$message.success(this.$t('AddSuccessful'));
                            this.sip.serverState = 0;
                            //获取uesr sip 配置列表
                            this.getUserSipConfig(1,'sip_server');
                        }else{
                            this.sip.serverState = 0;
                            this.$handerrcode(res.code);
                        }
                    }).catch((err) => {
                        console.log(err);
                        this.sip.serverState = 0;
                    })
                }else{
                    addUserSipObj.entrancePackId = this.sip.entrancePackId;
                    sitesUsersApi.updateSiteUserSipConfig(rgnObj,addUserSipObj).then(res => {
                        if(res.code === 0){
                            this.$message.success(this.$t('UpdateSuccessfully'));
                            this.sip.serverState = 0;
                            //获取uesr sip 配置列表
                            this.getUserSipConfig(1,'sip_server');
                        }else{
                            this.sip.serverState = 0;
                            this.$handerrcode(res.code);
                        }
                    }).catch((err) => {
                        console.log(err);
                        this.sip.serverState = 0;
                    })
                }
            }else{
                if(!this.sip.entrancePackId_p2p){
                    sitesUsersApi.addSiteUserSipConfig(rgnObj,addUserSipObj).then(res => {
                        if(res.code === 0){
                            this.$message.success(this.$t('AddSuccessful'));
                            this.sip.p2pState = 0;
                             //获取uesr sip 配置列表
                            this.getUserSipConfig(1,'sip_p2p');
                        }else{
                            this.sip.p2pState = 0;
                            this.$handerrcode(res.code);
                        }
                    }).catch((err) => {
                        console.log(err);
                        this.sip.p2pState = 0;
                    })
                }else{
                    addUserSipObj.entrancePackId = this.sip.entrancePackId_p2p;
                    sitesUsersApi.updateSiteUserSipConfig(rgnObj,addUserSipObj).then(res => {
                        if(res.code === 0){
                            this.$message.success(this.$t('UpdateSuccessfully'));
                            this.sip.p2pState = 0;
                            //获取uesr sip 配置列表
                            this.getUserSipConfig(1,'sip_p2p');
                        }else{
                            this.sip.p2pState = 0;
                            this.$handerrcode(res.code);
                        }
                    }).catch((err) => {
                        console.log(err);
                        this.sip.p2pState = 0;
                    })
                }
            }
            
            
        },

        /**
         * Card Settings
         */

        //查询住宅有权限设备列表
        getSiteUserCardDevicesList(){
            let devicesInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "userHouseId":this.updateSiteUser.id,
                "pageSize":-1,
                "pageNum":1,
            }
            sitesUsersApi.getSiteUserDevicesList(devicesInfoObj).then(res => {
                if(res.code === 0){
                    this.addCard.devList = [];
                    this.editCard.devList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.addCard.devList = [];
                        this.editCard.devList = [];
                    }else{
                        res.data.list.map(item => {
                            if(item.accessStatus === 1){
                                item.isChecked = false;
                                this.addCard.devList.push(item);
                                this.editCard.devList.push(item);
                            }
                            
                        })
                    }
                }else{
                    this.addCard.devList = [];
                    this.editCard.devList = [];
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                this.addCard.devList = [];
                this.editCard.devList = [];
                console.log(err);
            })
        },
        //查询用户Card列表
        getSiteUserCardList(){
            let cardInfoObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let cardPostObj = {
                "userHouseId":this.updateSiteUser.id,
                "userId":this.updateSiteUser.userId,
                "type":4,
            }
            sitesUsersApi.getSiteUserCardConfigList(cardInfoObj,cardPostObj).then(res => {
                if(res.code === 0){
                    this.card.cardList = [];
                    if(res.data === null || res.data.length === 0){
                        this.card.cardList = [];
                        this.card.hasNoCard = true;
                    }else{
                        this.card.hasNoCard = false;
                        for(let i=0;i<res.data.length;i++){
                            this.card.cardList.push(res.data[i]);
                        }
                    }
                }else{
                    this.card.cardList = [];
                    this.card.hasNoCard = true;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                this.card.cardList = [];
                this.card.hasNoCard = true;
                console.log(err);
            })
        },
        //解密卡号
        decryptCardNumber(cardVal){
            //将tdk+userHouseId使用SHA256生成AES加密KEY
            let payloadKey = CryptoJS.SHA256("tdk"+this.updateSiteUser.id);
            //把获取到的16进制的字符串转为base64
            let base64Str = this.hexStringToBase64(cardVal);
            //解密之后得到16进制的卡号
            let cardNumHex = decryptCBC(base64Str,payloadKey,CryptoJS.enc.Hex.parse(this.byteToHex(new Uint8Array(16))));
            //再把16进制的卡号转成10进制
            let cardNumDecimal = this.hexToDecimal(cardNumHex);
            let finCardNum;
            if(cardNumDecimal.toString().length < 8){
                finCardNum = this.padStringToLength(cardNumDecimal.toString(),8);
            }else{
                finCardNum = cardNumDecimal;
            }
            return finCardNum
        },
        //处理次数
        handCountLimit(count,usedCount){
            let countLimitStr = "";
            if(count === 0){
                countLimitStr = this.$t('Unlinmited');
            }else{
                countLimitStr = usedCount + "/" + count;
            }
            return countLimitStr
        },
        //处理有效期
        validityTime(start,end){
            let validityStr = "";
            if(start === 0 && end === 0){
                validityStr = this.$t('Forever');
            }else{
                validityStr = this.utctolocal(start) + " ~ " + this.utctolocal(end);
            }
            return validityStr
        },
         //panels 名称显示
         showPanelsName(panelList){
            let panelStr = "";
            if(this.addCard.devList.length != 0){
                this.addCard.devList.map(item =>{
                    panelList.map(item2 =>{
                        if(item.id === item2){
                            panelStr += item.deviceName +  "\u2003"
                        }
                    })
                })
            }
            return panelStr;
        },
         //处理设备应用状态函数
         handPanelApplyStatus(statu){
            let statuStr = "";
           switch (statu) {
            case 0:
                statuStr = this.$t('NotApplied');
                break;
            case 1:
                statuStr = this.$t('ApplicationComplete');
                break;
            case 2:
                statuStr = this.$t('DataValidationFailed');
                break;
            case 3:
                statuStr = this.$t('TypesNotSupported');
                break;
            case 4:
                statuStr = this.$t('DeviceLimitedInput');
                break;
            default:
                statuStr = "";
                break;
           }
            return statuStr
        },
        //添加Card
        addCardSetting(){
            this.addCard.addCardFrom = true;
            this.addCard.title = "";
            this.addCard.payload = "";
            this.addCard.locks = "";
            this.addCard.countLimit = "";
            this.addCard.countLimitDis = false;
            this.addCard.Unlinmited = false;
            this.addCard.startTime = "";
            this.addCard.endTime = "";
            this.addCard.isFovever = false;
            this.addCard.fovever = false;
            this.addCard.devList.map(item =>{
                item.isChecked = false;
            })
        },
        //添加Card 不限次数开关改变函数
        addCradUnlinmitChage(item){
            if(item){
                this.addCard.countLimit = "";
                this.addCard.countLimitDis = true;
            }else{
                this.addCard.countLimitDis = false;
            }
        },
        //添加Card 永久有效开关改变函数
        addCradForeverChage(item){
            if(item){
                this.addCard.startTime = "";
                this.addCard.endTime = "";
                this.addCard.isFovever = true;
            }else{
                this.addCard.isFovever = false;
            }
        },
        // //Card 分页操作
        // handleSizeChangeCard(val) {
        // // console.log(`每页 ${val} 条`);
        // },
        // //Card 分页操作
        // handleCurrentChangeCard(val) {
        //     // this.getEntranceGuardConfig(val);
        // },

        //新增Card Number

        //base64转为16进制字符串
        base64ToHex(base64String) {
            // 解码Base64字符串
            const byteArray = atob(base64String)
                .split('')
                .map(char => char.charCodeAt(0));
            
            // 转换为16进制字符串
            return byteArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        },
        //字节数组转为16进制字符串
        byteToHex(arr) {
            let hexStr = "";
            for (let i = 0; i < arr.length; i++) {
                let hex = arr[i].toString(16);
                if (hex.length === 1) {
                hex = "0" + hex;
                }
                hexStr += hex;
            }
            return hexStr;
        },
        //16进制转base64
        hexStringToBase64(hexString) {
            // 将16进制字符串转换为Uint8Array
            const byteArray = new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
            
            // 使用btoa将二进制数据转换为Base64字符串
            const base64String = btoa(String.fromCharCode(...byteArray));
            
            return base64String;
        },
        //HmacSHA1签名函数
        Hmacstr(sigstr, key) {
            let result = CryptoJS.HmacSHA1(sigstr, key);
            let sig = result.toString(CryptoJS.enc.Hex);
            return sig;
        },
        //16进制转2进制
        hexToBinary(hexString) {
            // 创建一个映射表，用于将16进制字符映射到2进制字符串
            const hexToBinMap = {
                '0': '0000', '1': '0001', '2': '0010', '3': '0011',
                '4': '0100', '5': '0101', '6': '0110', '7': '0111',
                '8': '1000', '9': '1001', 'A': '1010', 'B': '1011',
                'C': '1100', 'D': '1101', 'E': '1110', 'F': '1111'
            };

            // 使用映射表将16进制字符串转换为2进制字符串
            let binaryString = hexString.split('').map(hexChar =>
                hexToBinMap[hexChar.toUpperCase()]
            ).join('');

            return binaryString;
        },
        //10进制转为16进制
        decimalToHex(decimal) {
            return decimal.toString(16).toUpperCase();
        },
        //16进制转为10进制
        hexToDecimal(hexString) {
            return parseInt(hexString, 16);
        },
        //二进制转10进制
        binaryToDecimal(binaryString) {
            return parseInt(binaryString, 2);
        },
        //字符串前面补0，直到满足长度要求
        padStringToLength(str, desiredLength) {
            return str.padStart(desiredLength, '0');
        },
        //二进制取前三个字节
        getFirstThreeBytes(binaryString) {
            // 确保二进制字符串长度至少有24位
            if (binaryString.length < 24) {
                console.log('The binary string is not long enough to contain three bytes');
            }
            // 截取前24位
            return binaryString.slice(0, 24);
        },
        //卡号输入方式改变执行函数
        changeInputType(item){
            if(item === "1"){
                this.addCard.readCardDriverFrom = false;
                this.addCard.readCardNoDriverFrom = false;
                this.addCard.readCardDownloadDriverFrom = false;
                this.addCard.testDriverFrom = false;
                this.addCard.inputCardFrom = true;
                this.addCard.manualFullCardNum = ""
                this.addCard.manualFacility = ""
                this.addCard.manualCardNum = ""
                this.addCard.manualInputType = "1"
            }else{
                this.addCard.inputCardFrom = false;
                this.initReadCard();
            }
        },
        //手动输入卡号 输入卡号方式改变执行函数
        // changeManualInputType(item){
        //     console.log("ManualInputType",item);
        // },
        //关闭手动输入卡号弹框执行函数
        closeManualInputFrom(){
            this.addCard.addCardType = "";
        },
        //手动输入卡号点击确定执行函数
        manualInputCardNumber(){
            if(this.addCard.manualInputType === "1"){
                if(this.addCard.manualFullCardNum === ""){
                    this.$message.info(this.$t('CardNumberEmpty'));
                    return false
                }
                if(!(/^-?\d+$/.test(this.addCard.manualFullCardNum)) || Number(this.addCard.manualFullCardNum) > 9999999999){
                    this.$message.info(this.$t('FullCardNumLimit'));
                    return false
                }
                let cardNumHex = this.decimalToHex(Number(this.addCard.manualFullCardNum));
                if(Number(this.addCard.manualFullCardNum) <= 16777215){
                    if(cardNumHex.length < 6){
                        cardNumHex = this.padStringToLength(cardNumHex,6);
                    } 
                }else if(Number(this.addCard.manualFullCardNum) <= 4294967295){
                    if(cardNumHex.length <= 8){
                        cardNumHex = this.padStringToLength(cardNumHex,8);
                        cardNumHex = cardNumHex.slice(0,6);
                    }
                }else{
                    if(cardNumHex.length <= 10){
                        cardNumHex = this.padStringToLength(cardNumHex,10);
                        cardNumHex = cardNumHex.slice(0,6);
                    }else{
                        this.$message.info(this.$t('CardNumberWrong'));
                        return false
                    }
                }
                //十六进制转10进制
                let cardNumberDecimal = this.hexToDecimal(cardNumHex); 
                if(cardNumberDecimal.toString().length < 8){
                    this.addCard.payload = this.padStringToLength(cardNumberDecimal.toString(),8);
                }else{
                    this.addCard.payload = cardNumberDecimal;
                }
                this.addCard.inputCardFrom = false;
            }else{
                if(this.addCard.manualFacility === "" || this.addCard.manualCardNum === ""){
                    this.$message.info(this.$t('FacilityCodeCardNumberEmpty'));
                    return false
                }
                if(!(/^-?\d+$/.test(this.addCard.manualFacility)) || Number(this.addCard.manualFacility) > 255){
                    this.$message.info(this.$t('FacilityCodeEmpty'));
                    return false
                }
                if(!(/^-?\d+$/.test(this.addCard.manualCardNum)) || Number(this.addCard.manualCardNum) > 65535){
                    this.$message.info(this.$t('FacilityCardNumberEmpty'));
                    return false
                }
                
                let calculationCardNum = Number(this.addCard.manualCardNum) + Number(this.addCard.manualFacility << 16);

                let cardNumHex = this.decimalToHex(Number(calculationCardNum));

                if(Number(calculationCardNum) <= 16777215){
                    if(cardNumHex.length < 6){
                        cardNumHex = this.padStringToLength(cardNumHex,6);
                    } 
                }else if(Number(calculationCardNum) <= 4294967295){
                    if(cardNumHex.length <= 8){
                        cardNumHex = this.padStringToLength(cardNumHex,8);
                        cardNumHex = cardNumHex.slice(0,6);
                    }
                }else{
                    if(cardNumHex.length <= 10){
                        cardNumHex = this.padStringToLength(cardNumHex,10);
                        cardNumHex = cardNumHex.slice(0,6);
                    }else{
                        this.$message.info(this.$t('CardNumberWrong'));
                        return false
                    }
                }

                let cardNumberDecimal = this.hexToDecimal(cardNumHex); 
                if(cardNumberDecimal.toString().length < 8){
                    this.addCard.payload = this.padStringToLength(cardNumberDecimal.toString(),8);
                }else{
                    this.addCard.payload = cardNumberDecimal;
                }
                this.addCard.inputCardFrom = false;
            }
        },
        //关闭自动读卡弹框执行函数
        closeReadCardFrom(){
            this.addCard.addCardType = "";
        },
        //驱动异常 点击测试驱动执行函数
        testDriverBtn(){
            this.initReadCard(true);
        },
        //测试驱动 点击再次测试
        restartTestDriver(){
            this.initReadCard(true);
        },
        //点击下载驱动执行函数
        downloadDriver(){
            this.addCard.readCardDownloadDriverFrom = true;
            this.addCard.driverList = driverList;
        },
        //初始化读卡
        async initReadCard(isTestDriver){
            if(isTestDriver){
                this.addCard.testDriverFrom = true;
            }
            this.closeCard();
            const result = await openWs("ws://127.0.0.1:50150", 5000);
            if (result) {
                this.addCard.readCardDevice = await dc_init("100", "115200"); //打开设备
                if (!this.addCard.readCardDevice  || this.addCard.readCardDevice[0] < 0) {
                    console.log("Failed device connection");
                    if(isTestDriver){
                        this.addCard.testDriverFrom = false;
                    }
                    this.addCard.readCardNoDriverFrom = true;
                    this.addCard.readCardDevice = null;
                    return;
                }
                this.addCard.testDriverFrom = false;
                this.addCard.readCardNoDriverFrom = false;
                this.addCard.readCardDriverFrom = true;
                this.startRread();
            } else {
                console.log("The driver was not detected");
                if(isTestDriver){
                    this.addCard.testDriverFrom = false;
                }
                this.addCard.readCardNoDriverFrom = true;
            }
        },
        //开始读卡，添加蜂鸣器
        async startRread(){
            if (!this.addCard.readCardDevice) {
                console.log("Failed device connection");
                this.addCard.readCardNoDriverFrom = true;
                return;
            }
            this.addCard.readCardNoDriverFrom = false;
            await dc_beep(this.addCard.readCardDevice, "10") //蜂鸣器
            if (!this.addCard.intervalId) {
                this.addCard.intervalId = setInterval(()=> {
                    this.readCard();
                }, 1000);
            }
        },
        //开始读卡
        async readCard(){
            let reset = await dc_reset(this.addCard.readCardDevice,"10"); //复位射频
            if (!reset || reset[0] < 0) {
                console.log("Failed device connection");
                this.addCard.readCardNoDriverFrom = true;
                this.closeCard();
                return;
            }
            this.addCard.readCardNoDriverFrom = false;
            //let configCard = await dc_config_card(this.addCard.readCardDevice, "65"); //配置非接触卡类型
            let result = await dc_card_n(this.addCard.readCardDevice, "0"); //寻卡请求、防卡冲突、选卡操作
            if (result[0] === 0) {
                await dc_beep(this.addCard.readCardDevice, "100");
                //读卡机读出来的卡号为16进制，取前三个字节 转为2进制
                let cardNumberHex =  result[2];
                //十六进制转10进制
                let cardNumberDecimal = this.hexToDecimal(cardNumberHex);
                if(Number(cardNumberDecimal) <= 16777215){
                    if(cardNumberHex.length < 6){
                        cardNumberHex = this.padStringToLength(cardNumberHex,6);
                    } 
                }else if(Number(cardNumberDecimal) <= 4294967295){
                    if(cardNumberHex.length <= 8){
                        cardNumberHex = this.padStringToLength(cardNumberHex,8);
                        cardNumberHex = cardNumberHex.slice(0,6);
                    }
                }else{
                    if(cardNumberHex.length <= 10){
                        cardNumberHex = this.padStringToLength(cardNumberHex,10);
                        cardNumberHex = cardNumberHex.slice(0,6);
                    }else{
                        this.$message.info(this.$t('CardNumberWrong'));
                        return false
                    }
                }
                // let cardNumberBinary = this.hexToBinary(cardNumberHex);
                // let cardNumber24 = this.getFirstThreeBytes(cardNumberBinary);
                // //二进制转10进制
                // let cardNumberDecimal = this.binaryToDecimal(cardNumber24);
                if(this.hexToDecimal(cardNumberHex).toString().length < 8){
                    this.addCard.payload = this.padStringToLength(this.hexToDecimal(cardNumberHex).toString(),8);
                }else{
                    this.addCard.payload = this.hexToDecimal(cardNumberHex);
                }
                // console.log("2进制",this.hexToBinary(this.addCard.payload));
                // console.log("10进制转换为16进制",this.addCard.payload,this.decimalToHex(Number(this.addCard.payload)))
                // 清除定时器
                this.clearRreadTask();
                this.addCard.readCardDriverFrom = false;
            }
        },
        //关闭ws连接，清除定时器
        async closeCard() {
            await closeWs();
            this.clearRreadTask();
        },
        clearRreadTask() {
            if (this.addCard.intervalId) {
                clearInterval(this.addCard.intervalId);
                this.addCard.intervalId = null;
            }
        },
        //添加card配置点击确定执行函数
        addCardNumber(){
            let checkCardName = this.$regularCheckLimit(this.addCard.title,1,64,'NameCannotEmpty');
            if(!checkCardName){
                return false
            }
            if(this.addCard.payload === ""){
                this.$message.info(this.$t('CardNumberEmpty'));
                return false
            }
            if(this.addCard.locks.length == 0){
                this.$message.info(this.$t('UnlockOptionEmpty'));
                return false
            }
            let finCountLimit = 0;
            if(this.addCard.Unlinmited){
                finCountLimit = 0;
            }else{
                if(this.addCard.countLimit === ""){
                    this.$message.info(this.$t('LimitedEmpty'));
                    return false
                }else{
                    if(Number(this.addCard.countLimit) > 100){
                        this.$message.info(this.$t('LimitUnlockNum'));
                        return false
                    }
                }
                finCountLimit = this.addCard.countLimit;
            }
            let finStartTime = 0;
            let finEndTime = 0;
            if(this.addCard.fovever){
                finStartTime = 0;
                finEndTime = 0;
            }else{
                if(this.addCard.startTime == null){
                    this.addCard.startTime = "";
                }
                if(this.addCard.endTime == null){
                    this.addCard.endTime = "";
                }
                if(this.addCard.startTime === ""){
                    this.$message.info(this.$t('StartTimeEmptye'));
                    return false
                }
                if(this.addCard.endTime === ""){
                    this.$message.info(this.$t('EndTimeEmptye'));
                    return false
                }
                if(this.addCard.startTime != "" && this.addCard.endTime != ""){
                    if(this.addCard.startTime >= this.addCard.endTime){
                        this.$message.info(this.$t('StartTimeLaterEndTime'));
                        return false
                    }
                }
                finStartTime = this.addCard.startTime;
                finEndTime = this.addCard.endTime;

            }
            let finDevArr = [];
            this.addCard.devList.map(item =>{
                if(item.isChecked){
                    finDevArr.push(item.id)
                }
            })
            if(finDevArr.length === 0){
                this.$message.info(this.$t('PleaseSelectPanels'));
                return false
                
            }
            //将tdk+userHouseId使用SHA256生成AES加密KEY
            let payloadKey = CryptoJS.SHA256("tdk"+this.updateSiteUser.id);
            // todo... 先转16进制 在加密 this.decimalToHex(Number(this.addCard.payload));
            let finCardNum = this.decimalToHex(Number(this.addCard.payload));
            if(finCardNum.toString().length < 6){
                finCardNum = this.padStringToLength(finCardNum,6)
            }
            let encryStr = encryptCBC(finCardNum,payloadKey,CryptoJS.enc.Hex.parse(this.byteToHex(new Uint8Array(16))));
            let nowTimeTamp = new Date().getTime();
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let addUserCardObj = {
                "type": 4,
                "userHouseId":this.updateSiteUser.id,
                // "userId":this.updateSiteUser.userId,
                "checkKeyId": nowTimeTamp, 
                "title": this.addCard.title,
                "payload": this.base64ToHex(encryStr),
                "payloadSha": CryptoJS.SHA256(finCardNum),
                "cardType": 24,
                "locks": this.addCard.locks.join(","),
                "countLimit": finCountLimit,
                "startTime": finStartTime,
                "endTime": finEndTime,
                "entranceDeviceIds": finDevArr,
                "timestamp": nowTimeTamp,
                "createTime": nowTimeTamp,
            };
            const sortedData = Object.keys(addUserCardObj)
                    .sort()
                    .map(key => `${key}=${addUserCardObj[key]}`)
                    .join("&");
            // 创建 formData 对象
            const formData = new FormData();
            formData.append('userHouseId', this.updateSiteUser.id);
            formData.append('userId', this.updateSiteUser.userId);
            formData.append('type', 4);
            formData.append('checkKeyId', nowTimeTamp);
            formData.append('title', this.addCard.title);
            formData.append('payload', this.base64ToHex(encryStr));
            formData.append('payloadSha', CryptoJS.SHA256(finCardNum));
            formData.append('cardType', 24);
            formData.append('locks', this.addCard.locks.join(","));
            formData.append('countLimit', finCountLimit);
            formData.append('startTime', finStartTime);
            formData.append('endTime', finEndTime);
            formData.append('entranceDeviceIds', finDevArr);
            formData.append('timestamp', nowTimeTamp);
            formData.append('createTime', nowTimeTamp);
            formData.append('sign', this.Hmacstr(sortedData,this.updateSiteUser.id));
            this.addCard.addBtnState = 1;
            sitesUsersApi.addSiteUserCardConfig(rgnObj,formData).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('AddSuccessful'));
                    this.addCard.addBtnState = 0;
                    //获取uesr Card 配置列表
                    this.getSiteUserCardList();
                    this.addCard.addCardFrom = false;
                }else{
                    this.addCard.addBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.addCard.addBtnState = 0;
            })
        },
        //编辑Card弹窗
        editCardSetting(item){
            this.editCard.editCardFrom = true;
            this.editCard.facilityCode = "";
            this.editCard.cardNumber = "";
            let cardInfoObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let cardPostObj = {
                "userHouseId":this.updateSiteUser.id,
                "entranceKeyId":item.entranceKeyId,
                "userId":item.userId,
            }
            this.editCard.devList.map(item=>{
                this.editCard.entranceDeviceList.map(item2=>{
                    if(item.id === item2.entranceDeviceId){
                        item.isChecked = true
                        item.applyStatus = item2.applyStatus
                    }
                })
            })
            sitesUsersApi.getSiteUserCardDetail(cardInfoObj,cardPostObj).then(res => {
                if(res.code === 0){
                   this.editCard.title = res.data.title;
                   //todo... 要转为10进制
                   //将tdk+userHouseId使用SHA256生成AES加密KEY
                   let payloadKey = CryptoJS.SHA256("tdk"+this.updateSiteUser.id);
                   //把获取到的16进制的字符串转为base64
                   let base64Str = this.hexStringToBase64(res.data.payload);
                   //解密之后得到16进制的卡号
                   let cardNumHex = decryptCBC(base64Str,payloadKey,CryptoJS.enc.Hex.parse(this.byteToHex(new Uint8Array(16))));
                   //再把16进制的卡号转成10进制
                   let cardNumDecimal = this.hexToDecimal(cardNumHex);
                   let finCardNum;
                   if(cardNumDecimal.toString().length < 8){
                      finCardNum = this.padStringToLength(cardNumDecimal.toString(),8);
                    }else{
                      finCardNum = cardNumDecimal;
                    }
                    this.editCard.payload = finCardNum;
                    //计算Facility Code Card Number
                    //取卡号后六位进行计算
                    let cardNumLastSix = cardNumHex.slice(-6);
                    if(cardNumLastSix.length === 6){
                        this.editCard.facilityCode = this.hexToDecimal(cardNumHex.slice(0,2));
                        this.editCard.cardNumber = this.hexToDecimal(cardNumHex.slice(-4));
                    }else if(cardNumLastSix.length < 6){
                        if(cardNumLastSix.length <= 4){
                            this.editCard.cardNumber = this.hexToDecimal(cardNumLastSix);
                            this.editCard.facilityCode = 0;
                        }else{
                            this.editCard.facilityCode = this.hexToDecimal(cardNumHex.slice(0,cardNumLastSix.length - 4));
                            this.editCard.cardNumber = this.hexToDecimal(cardNumHex.slice(-4));
                        }
                    }
                   this.editCard.userId = res.data.userId;
                   this.editCard.entranceKeyId = res.data.entranceKeyId;
                   this.editCard.locks = (res.data.locks).split(",");
                   if(res.data.countLimit === 0){
                       this.editCard.countLimit = "";
                       this.editCard.countLimitDis = true;
                       this.editCard.Unlinmited = true;
                   }else{
                       this.editCard.countLimitDis = false;
                       this.editCard.Unlinmited = false;
                       this.editCard.countLimit = res.data.countLimit;
                   }
                   if(res.data.startTime === 0 && res.data.endTime === 0){
                       this.editCard.startTime = "";
                       this.editCard.endTime = "";
                       this.editCard.isFovever = true;
                       this.editCard.fovever = true;
                   }else{
                       this.editCard.startTime = res.data.startTime;
                       this.editCard.endTime = res.data.endTime;
                       this.editCard.isFovever = false;
                       this.editCard.fovever = false;
                   }
                   this.editCard.entranceDeviceList = res.data.entranceDeviceList;
                   this.editCard.devList.map(item=>{
                        item.isChecked = false;
                        if (item.hasOwnProperty('applyStatus')) {
                                delete item.applyStatus
                            }
                    })
                    this.editCard.devList.map(item=>{
                        this.editCard.entranceDeviceList.map(item2=>{
                            if(item.id === item2.entranceDeviceId){
                                item.isChecked = true;
                                item.applyStatus = item2.applyStatus;
                            }
                        })
                    })
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
            })
            
        },
        //编辑Card 不限次数开关改变函数
        editCradUnlinmitChage(item){
            if(item){
                this.editCard.countLimit = "";
                this.editCard.countLimitDis = true;
            }else{
                this.editCard.countLimitDis = false;
            }
        },
        //编辑Card 永久有效开关改变函数
        editCradForeverChage(item){
            if(item){
                this.editCard.startTime = "";
                this.editCard.endTime = "";
                this.editCard.isFovever = true;
            }else{
                this.editCard.isFovever = false;
            }
        },
        //编辑Card确认
        editCardNumber(){
            let checkCardName = this.$regularCheckLimit(this.editCard.title,1,64,'NameCannotEmpty');
            if(!checkCardName){
                return false
            }
            if((this.editCard.payload.toString()).trim() === ""){
                this.$message.info(this.$t('CardNumberEmpty'));
                return false
            }
            if(this.editCard.locks.length == 0){
                this.$message.info(this.$t('UnlockOptionEmpty'));
                return false
            }
            let finCountLimit = 0;
            if(this.editCard.Unlinmited){
                finCountLimit = 0;
            }else{
                if(this.editCard.countLimit === ""){
                    this.$message.info(this.$t('LimitedEmpty'));
                    return false
                }else{
                    if(Number(this.editCard.countLimit) > 100){
                        this.$message.info(this.$t('LimitUnlockNum'));
                        return false
                    }
                }
                finCountLimit = this.editCard.countLimit;
            }
            let finStartTime = 0;
            let finEndTime = 0;
            if(this.editCard.fovever){
                finStartTime = 0;
                finEndTime = 0;
            }else{
                if(this.editCard.startTime == null){
                    this.editCard.startTime = "";
                }
                if(this.editCard.endTime == null){
                    this.editCard.endTime = "";
                }
                if(this.editCard.startTime === ""){
                    this.$message.info(this.$t('StartTimeEmptye'));
                    return false
                }
                if(this.editCard.endTime === ""){
                    this.$message.info(this.$t('EndTimeEmptye'));
                    return false
                }
                if(this.editCard.startTime != "" && this.editCard.endTime != ""){
                    if(this.editCard.startTime >= this.editCard.endTime){
                        this.$message.info(this.$t('StartTimeLaterEndTime'));
                        return false
                    }
                }
                finStartTime = this.editCard.startTime;
                finEndTime = this.editCard.endTime;

            }
            let finDevArr = [];
            this.editCard.devList.map(item =>{
                if(item.isChecked){
                    finDevArr.push(item.id)
                }
            })
            if(finDevArr.length === 0){
                this.$message.info(this.$t('PleaseSelectPanels'));
                return false
                
            }
            //将tdk+userHouseId使用SHA256生成AES加密KEY
            let payloadKey = CryptoJS.SHA256("tdk"+this.updateSiteUser.id);
            // 先转16进制 在加密 
            let finCardNum = this.decimalToHex(Number(this.editCard.payload));
            let encryStr = encryptCBC(finCardNum,payloadKey,CryptoJS.enc.Hex.parse(this.byteToHex(new Uint8Array(16))));
            let nowTimeTamp = new Date().getTime();
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let editUserCardObj = {
                "type": 4,
                "userHouseId":this.updateSiteUser.id,
                "entranceKeyId":this.editCard.entranceKeyId,
                // "checkKeyId": new Date().getTime(), 
                "title": this.editCard.title,
                "payload": this.base64ToHex(encryStr),
                "cardType": 24,
                "locks": this.editCard.locks.join(","),
                "countLimit": finCountLimit,
                "startTime": finStartTime,
                "endTime": finEndTime,
                "entranceDeviceIds": finDevArr,
                "timestamp": nowTimeTamp,
            };
            const sortedData = Object.keys(editUserCardObj)
                    .sort()
                    .map(key => `${key}=${editUserCardObj[key]}`)
                    .join("&");
            // 创建 formData 对象
            const formData = new FormData();
            formData.append('userHouseId', this.updateSiteUser.id);
            formData.append('userId', this.updateSiteUser.userId);
            formData.append('entranceKeyId', this.editCard.entranceKeyId);
            formData.append('type', 4);
            formData.append('checkKeyId', nowTimeTamp);
            formData.append('title', this.editCard.title);
            formData.append('payload', this.base64ToHex(encryStr));
            formData.append('cardType', 24);
            formData.append('locks', this.editCard.locks.join(","));
            formData.append('countLimit', finCountLimit);
            formData.append('startTime', finStartTime);
            formData.append('endTime', finEndTime);
            formData.append('entranceDeviceIds', finDevArr);
            formData.append('timestamp', nowTimeTamp);
            formData.append('sign', this.Hmacstr(sortedData,this.updateSiteUser.id));
            this.editCard.editBtnState = 1;
            sitesUsersApi.updateSiteUserCardConfig(rgnObj,formData).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.editCard.editBtnState = 0;
                    //获取uesr Card 配置列表
                    this.getSiteUserCardList();
                    this.editCard.editCardFrom = false;
                }else{
                    this.editCard.editBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.editCard.editBtnState = 0;
            })
        },
        //删除Card弹窗
        openDelCardFrom(item){
            this.delCard.delCardFrom = true;
            this.delCard.entranceKeyId = item.entranceKeyId;
        },
        //删除Card 确认
        delCardSetting(){
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delSiteUserCardObj = {}
            delSiteUserCardObj.entranceKeyId = [];
            delSiteUserCardObj.entranceKeyId.push(this.delCard.entranceKeyId);
            delSiteUserCardObj.userHouseId = this.updateSiteUser.id;
            delSiteUserCardObj.userId = this.updateSiteUser.userId;
            this.delCard.delBtnState = 1;
            sitesUsersApi.delSiteUserCard(rgnObj,delSiteUserCardObj).then(res => {
                if(res.code === 0){
                    this.delCard.delBtnState = 0;
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.delCard.delCardFrom = false;
                    //获取uesr Card 配置列表
                    this.getSiteUserCardList();
                }else{
                    this.delCard.delBtnState = 0;
                    this.$handerrcode(res.code);
                }
                
            }).catch((err) => {
                this.delCard.delBtnState = 0;
                console.log('err', err);
            })
            
        }
    },
    computed:{
        ...mapState(["selectCompany","selectSite","language"]),
    },
    watch:{
        'updateSiteUser.userAccount'(){
            if( this.updateSiteUser.userAccount != this.updateSiteUser.newUserAccount || 
                this.updateSiteUser.firstName != this.updateSiteUser.newFirstName ||
                this.updateSiteUser.surname != this.updateSiteUser.newSurname ||
                this.updateSiteUser.userRole != this.updateSiteUser.newUserRole ||
                this.updateSiteUser.apartmentId != this.updateSiteUser.newApartmentId ||
                this.updateSiteUser.houseNo != this.updateSiteUser.newHouseNo
            ){
                this.updateSiteUser.saveBtn = false;
            }else{
                this.updateSiteUser.saveBtn = true;
            }
        },
        'updateSiteUser.firstName'(){
            if( this.updateSiteUser.userAccount != this.updateSiteUser.newUserAccount || 
                this.updateSiteUser.firstName != this.updateSiteUser.newFirstName ||
                this.updateSiteUser.surname != this.updateSiteUser.newSurname ||
                this.updateSiteUser.userRole != this.updateSiteUser.newUserRole ||
                this.updateSiteUser.apartmentId != this.updateSiteUser.newApartmentId ||
                this.updateSiteUser.houseNo != this.updateSiteUser.newHouseNo
            ){
                this.updateSiteUser.saveBtn = false;
            }else{
                this.updateSiteUser.saveBtn = true;
            }
        },
        'updateSiteUser.surname'(){
            if( this.updateSiteUser.userAccount != this.updateSiteUser.newUserAccount || 
                this.updateSiteUser.firstName != this.updateSiteUser.newFirstName ||
                this.updateSiteUser.surname != this.updateSiteUser.newSurname ||
                this.updateSiteUser.userRole != this.updateSiteUser.newUserRole ||
                this.updateSiteUser.apartmentId != this.updateSiteUser.newApartmentId ||
                this.updateSiteUser.houseNo != this.updateSiteUser.newHouseNo
            ){
                this.updateSiteUser.saveBtn = false;
            }else{
                this.updateSiteUser.saveBtn = true;
            }
        },
        'updateSiteUser.userRole'(){
            if( this.updateSiteUser.userAccount != this.updateSiteUser.newUserAccount || 
                this.updateSiteUser.firstName != this.updateSiteUser.newFirstName ||
                this.updateSiteUser.surname != this.updateSiteUser.newSurname ||
                this.updateSiteUser.userRole != this.updateSiteUser.newUserRole ||
                this.updateSiteUser.apartmentId != this.updateSiteUser.newApartmentId ||
                this.updateSiteUser.houseNo != this.updateSiteUser.newHouseNo
            ){
                this.updateSiteUser.saveBtn = false;
            }else{
                this.updateSiteUser.saveBtn = true;
            }
        },
        'updateSiteUser.apartmentId'(){
            if( this.updateSiteUser.userAccount != this.updateSiteUser.newUserAccount || 
                this.updateSiteUser.firstName != this.updateSiteUser.newFirstName ||
                this.updateSiteUser.surname != this.updateSiteUser.newSurname ||
                this.updateSiteUser.userRole != this.updateSiteUser.newUserRole ||
                this.updateSiteUser.apartmentId != this.updateSiteUser.newApartmentId ||
                this.updateSiteUser.houseNo != this.updateSiteUser.newHouseNo
            ){
                this.updateSiteUser.saveBtn = false;
            }else{
                this.updateSiteUser.saveBtn = true;
            }
        },
        'updateSiteUser.houseNo'(){
            if( this.updateSiteUser.userAccount != this.updateSiteUser.newUserAccount || 
                this.updateSiteUser.firstName != this.updateSiteUser.newFirstName ||
                this.updateSiteUser.surname != this.updateSiteUser.newSurname ||
                this.updateSiteUser.userRole != this.updateSiteUser.newUserRole ||
                this.updateSiteUser.apartmentId != this.updateSiteUser.newApartmentId ||
                this.updateSiteUser.houseNo != this.updateSiteUser.newHouseNo
            ){
                this.updateSiteUser.saveBtn = false;
            }else{
                this.updateSiteUser.saveBtn = true;
            }
        },
       
    }
}
</script>

<style scoped>
.siteUserEditBox{
   box-sizing: border-box;
   padding: 15px 30px;
}
.controlBtnBox{
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 600px) {
    .controlBtnBox{
       justify-content: center;
    }
}
.el-button {
    height: 30px;
    width: 140px;
    padding: 6px 20px;
    font-size: 15px;
    background: #ccc;
    color: #000;
}
.el-button:hover{
    background: #6498b5;
    color: #fff;
}
.el-button:active {
    background: #6498b5;
    color: #fff;
}
.el-button:focus {
    background: #6498b5;
    color: #fff;
  }

.selectBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
}
/* Blocks */
.BlocksContent{
    margin-top: 20px;
}
::v-deep .el-input {
    font-size: 16px;
    margin-top: 10px;
}
.userItem{
    display: flex;
    align-items: baseline;
    margin-top: 1vw;
}
.userItem label{
   min-width: 150px;
}
@media screen and (max-width: 600px) {
    .userItem{
       justify-content: center;
    }
}
.editInput{
    width: 15vw;
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .editInput{
        width: 170px;
    }
}
::v-deep .editInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #333;
    background: #fff;
}
.userItem label{
    width: 135px;
    text-align: left;
}
.userItem input{
    width: 300px;
}
::v-deep .editInput .el-input__inner:hover {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #333;
    background: #fff;
}
::v-deep .el-input .el-input--suffix .is-focus{
    border: 1px solid #333 !important;
    border-radius: 4px !important;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
    border: 1px solid #333 !important;
    border-radius: 4px !important;
}
::v-deep .el-select .el-input .el-select__caret {
    color: #333;
    font-weight: bold;
}
::v-deep .el-input .el-input--suffix{
    border: 1px solid #333 !important;
    border-radius: 4px;
}
::v-deep .el-select .el-input__inner:focus {
    border: 1px solid #333 !important;
    border-radius: 4px;
}

.editBlock{
    width: 12vw;
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .editBlock{
        width: 170px;
    }
}
::v-deep .editBlock.el-select .el-input .el-select__caret {
    color: #c0c4cc !important;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
    background: #fff;
}
.btnBox{
    position: fixed;
    bottom: 38px;
    display: flex;
    width: 96%;
    justify-content: center;
}
.savetBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
    margin-left: 20px;
    width: 220px;
    min-width: 160px;
    height: 36px;
    
}

.siteUserContent{
    margin-top: 20px;
}


.el-divider--vertical{
    width: 4px;
    margin: 0 !important;
    background-color: #6498b5;
}


.userBigBox{
    display:flex;
    flex-direction: column;
    width: 50vw;
}
.userBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.userBoxLeft{
    /* width: 30%; */
    /* width: 30vw; */
    min-width: 350px;
}
@media screen and (max-width: 600px) {
    .userBoxLeft{
        width: 100%;
    }
}
.userBoxLeftTex{
    text-align: left;
    margin-top: 1vw;
}
.userBoxLeftTex span{
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
}
.userBoxRight{
    /* width: 30%; */
    /* width: 30vw; */
    /* margin-left: 5vw; */
    min-width: 350px
}
@media screen and (max-width: 600px) {
    .userBoxRight{
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }
}
.userRightItem{
    display: flex;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .userRightItem{
        display: flex;
        margin-top: 20px;
        justify-content: center;
    }
}
.userLicenseBox{
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 0 1vw;
    height: 60px;
    min-height: 45px;
    background: #EDEFFB;
    margin-top: 2vw;
    min-width: 350px;
}
.userLicenseLeft{
    font-weight: bold;
    color: #333333;
}
.augmentBtn{
    border: 1px solid #6498b5;
    height: 40px;
    color: #6498b5;
    background: #EDEFFB;
    font-size: 16px;
    font-weight: 600;
}

::v-deep .statusGreen{
    color: green;
}
::v-deep .statusOrange{
    color: orange;
}
::v-deep .statusRed{
    color: red;
}
::v-deep .statusBlue{
    color: blue;
}
/* 等待框样式 */
.waitmentBox{
    display: flex;
    justify-content: center;
}
.waitmentBox img{
    margin-top: 5vw;
    width: 100px;
}
.waitText{
    margin-top: 4vw;
    margin-bottom: 8vw;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}
/* service activation */
.confirmActivationBtn{
    display: flex;
    justify-content: center;
    margin-top: 2vw;
}

/* service activation 弹框样式 */

.activeTableBox{
    margin-top: 1vw;
    /* height: 16vw; */
    overflow-y: auto;
    max-height: 16vw;
    margin-bottom: 1vw;
}
::v-deep .el-table-column--selection .cell {
    padding-left: 10px;
}

::v-deep .el-table{
    min-height: 510px !important;
} 
 
.activeTableBox .el-table{
    min-height: 300px !important;
} 

.activeResult .el-table{
    min-height: 200px !important;
} 
.licenseBox{
    background: #EFEFEF;
    margin-top: 1vw;
    padding: 1vw 1vw;
    height: 105px;
    overflow-y: auto;
    border-radius: 10px;
}
.selectLicenseBox{
    display: flex;
    margin-top: 1vw;
    flex-wrap: wrap;
    margin-bottom: 2vw;
}
.selectLicenseBox div{
    /* width: 300px; */
    margin-right: 1vw;
    margin-top: 10px;
}
::v-deep .el-radio__label {
    font-weight: bold;
}
::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #606266;
}
.activationBtn{
    display: flex;
    justify-content: center;
}
.activationBtn button{
    background: #6498b5 !important;
    color: #fff !important;
}
/* 确认激活弹窗 */
.confirmActivaTex{
    display: flex;
    align-items: center;
}
.confirmActivaTex div{
    margin-right: 1vw;
    font-weight: bold;
    font-size: 18px;
}

.confirmActivaTex div span{
    margin-left: 3vw;
}
.confirmActivaNumTex{
    margin-top: 1vw;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1vw;
}
.confirmActivationBtn{
    display: flex;
    justify-content: center;
    margin-top: 2vw;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
/* 绑定 */
.addsiteUersBox{
    display: flex;
}
.addSiteUserLeft{
    width: 50%;
    padding-right: 1vw;
}
.NameBox{
    display: flex;
    justify-content: space-between;
}

.addSiteUserRight{
    width: 50%;
    font-size: 18px !important;
    margin-left: 2vw;
}
.addSiteUserRight p {
    font-size: 16px;
    color: #CFCFCF;
}
.addSiteUserRight p span{
    color: #6498b5;
}
::v-deep .addSiteUserRight .el-input__inner {
    height: 40px;
    line-height: 40px;
    width: 100%;
    border: none;
    background: #EFEFEF;
}


::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 1200px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        min-width: 300px;
        overflow: auto;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
    color: #333;
}
::v-deep .addSiteUserLeft .el-input__inner {
    height: 40px;
    line-height: 40px;
    width: 100%;
    border:none;
    background: #EFEFEF;
}
.addSiteUserLeft p {
    font-size: 16px;
    color: #CFCFCF;
}
.addSiteUserLeft p span{
    color: #6498b5;
}

::v-deep .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 1px solid #707070;
}

 
::v-deep .el-checkbox__inner::after {
    border: 3px solid #fff;
    border-left: 0;
    border-top: 0;
    left: 5px;
    top: 2px;
}

.el-checkbox__label {
    font-size: 16px;
}
 
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
        transform: rotate(40deg) scaleY(1.3);
 }

 ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #606266;
}
.addSiteUserLeft .el-select{
    width: 100%;
}
.addSiteUserRight .el-select{
    width: 100%;
}

.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 4px;
}
.el-form-item__label {
    color: #333;
}
::v-deep .el-dialog__footer {
    padding: 10px 20px 35px;
    text-align: center !important;
}
.bindingBtn  button{
    background: #6498b5 !important;
    color: #fff !important;
}
/* 等待框样式 */
.waitmentBox{
    display: flex;
    justify-content: center;
}
.waitmentBox img{
    margin-top: 5vw;
    width: 100px;
}
.waitText{
    margin-top: 4vw;
    margin-bottom: 8vw;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}

/* 添加user成功框样式 */
.addSuccessLeft{
    width: 50%;
    padding-right: 1vw;
}

.addSuccessRight{
    width: 50%;
    font-size: 18px !important;
    margin-left: 2vw;
    padding-top: 2vw;
    text-align: center;
}
.addSuccessRight div{
    font-size: 18px;
    color: #333;
    font-weight: bold;
}
.addSuccessQRBox{
    margin-top: 2vw;
    display: flex;
    justify-content: center;
}
.addSuccessQRCode{
    width: 185px;
    height: 185px;
}
.addSuccessItem{
    margin-top: 2vw;
}
.addSuccessItem div{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}
.addSuccessItem p{
    font-size: 16px;
    margin-top: 5px;
    padding-right: 2vw;
    word-break: keep-all;
    line-height: 1.5;
}
.addSuccessDiv{
    display: flex;
}
.addSuccessBtn{
    display: flex;
    justify-content: center;
    margin-top: 4vw;
}
.addSuccessBtn button{
    background: #6498b5 !important;
    color: #fff !important;
}
/* 解绑用户 */
::v-deep .unbindingUserDiv .el-dialog{
    width: 650px;
}
.unbindingUserBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 50px;
}

.unbindingUserText{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2vw;
}
.unbindingUserBox ul li{
    font-size: 16px;
    word-break: keep-all;
    line-height: 1.5;
    margin-bottom: 1vw;
}

.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}
.unbindingBtn button{
    background: #6498b5 !important;
    color: #fff !important;
}
/* devices */
.devicesEditDiv{
    margin-top: 1vw;

}
.pageBox {
    /* position: fixed;
    bottom: 2vw; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed;
        bottom: 10px; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}

::v-deep .pageBox .el-input{
    margin-top: 0px !important;
}

/* sip css */
.sipModeDiv{
    display:flex;
    justify-content:space-between;
    width:20%;
    align-items: baseline;
}
.sipModeServerDiv{
    display:flex;
    justify-content:space-between;
    margin-left: -20px;
}
.sipModeConfig{
    margin-top:20px;
    width:20%;
    padding: 30px 30px 60px;
    background-color: #F4F4F4;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #F4F4F4;
}
.sipModeConfig p{   
    text-align: left;
    font-weight: bold;
    margin-left: -20px;
}
.sipModeConfig h3{
    font-size:16px;
    color:#6498b5;
    text-align:left;
    margin-top: 20px;
}
.sipItem{
    display: flex;
    align-items: baseline;
    margin-top: 10px;
    justify-content: space-around;
    font-size: 14px;
}
.sipItem div{
   min-width: 120px;
}
.sipItem .el-input{
   height: 30px !important;
   font-size: 14px !important;
}
::v-deep.sipItem .editInput .el-input__inner{
    border: none !important;
}

::v-deep .el-tag.el-tag--info{
    margin-top: 10px !important;
}
.LimitContent{
    border-top:1px solid #ddd;
    /* border-radius: 8px; */
    padding-bottom: 15px;
}
.LimitBox{
    display: flex;
    justify-content: space-between;
    height: 45px;
}
::v-deep .LimitBox label{
    margin-top: 10px !important;
}
.FoveverBox{
    display: flex;
    justify-content: space-between;
    height: 45px;
}
.addCard{
    margin-top: 20px;
}
.addCard button{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    border-color: #dcdfe6;
    color: #606266;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    height: 40px;
}
.addCard .el-button--primary {
    margin-left: 60px !important;
    color: #fff;
    background-color: #6498b5;
    border-color: #6498b5;
}
::v-deep .serachSel .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input.is-focus .el-input__inner{
    border: none !important;
}
::v-deep .el-select .el-input__inner:focus {
    border: none !important;;
}

::v-deep .el-radio__label{
    font-weight: 300;
}
.cardText{
    width:48%;
    text-align: right;
    padding-right: 20px; 
    margin-top: 20px;color: #333;
}
::v-deep .el-form-item__label{
    text-align: left;
}
.manualInputCardBbox{
    text-align: center;
    margin-top: 30px;
}
::v-deep .manualInputCardBbox .el-input__inner{
    background: #EFEFEF;
}

::v-deep .manualInputCardBbox .el-input__inner{
    border: none;
}
::v-deep .manualInputCardBbox .el-input.is-focus .el-input__inner{
    border: none !important;
}
::v-deep .manualInputCardBbox .el-input__inner:focus {
    border: none !important;
}
.downloadDriver{
    padding-left: 30px;
    font-size: 0;
    margin-top: 10px;
}
.downloadDriver img{
    vertical-align: middle;
    padding-right: 10px;
    cursor: pointer;
}
.downloadDriver a{
    text-decoration: none;
    font-size: 16px;
    color: #6498b5;
    vertical-align: middle;
}
</style>