<!-- <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>paysuccess</title>
    <style>
        .paySuccessImg {
            display: flex;
            justify-content: center;
            padding-top: 5vw;
        }
        
        .paySuccessTex {
            display: flex;
            justify-content: center;
            padding-top: 2vw;
            color: #6380F5;
            font-size: 18px;
        }
        
        .paySuccessbtnTex {
            display: flex;
            justify-content: center;
            padding-top: 3vw;
        }
        
        #paySuccessbtn {
            width: 30%;
            height: 45px;
            line-height: 45px;
            border-radius: 15px;
            font-size: 18px;
            margin-top: 5vw;
            background: #6380F5;
            /* 标准语法 */
            color: #fff;
            outline: none;
            border: 1px solid #6380F5;
        }
    </style>
</head>

<body>
    <div class="paySuccessImg">
        <img src="../../assets/images/success.png" alt="">
    </div>
    <div class="paySuccessTex">
        <div>pay successfully!</div>
    </div>
    <div class="paySuccessbtnTex">
        <button id="paySuccessbtn" onclick="startServer()">start</button>
    </div>
</body>
<script>
    // (function() {
    //     startServer
    //     document.getElementById("paySuccessbtn")
    // })()
    function startServer() {
        window.close();
    }
</script>

</html> -->

<template>
    <div>
        <div class="paySuccessImg">
        <img :src="successImg" alt="">
        </div>
        <div class="paySuccessTex">
            <div>{{$t('PaySuccess')}}!</div>
        </div>
        <div class="paySuccessbtnTex">
            <button id="paySuccessbtn" @click="startServer">{{$t('Confirm')}}</button>
        </div>
    </div>
  
</template>

<script>
import successImg from '@/assets/images/success.png'
export default {
    name:"PaySuccess",
    data(){
        return{
            successImg
        }
    },
    methods:{
        startServer(){
            window.close();
        }
    }
}
</script>

<style>
.paySuccessImg {
            display: flex;
            justify-content: center;
            padding-top: 5vw;
        }
        
        .paySuccessTex {
            display: flex;
            justify-content: center;
            padding-top: 2vw;
            color: #6498b5;
            font-size: 18px;
        }
        
        .paySuccessbtnTex {
            display: flex;
            justify-content: center;
            padding-top: 3vw;
        }
        
        #paySuccessbtn {
            width: 30%;
            height: 45px;
            line-height: 45px;
            border-radius: 15px;
            font-size: 18px;
            margin-top: 5vw;
            background: #6498b5;
            /* 标准语法 */
            color: #fff;
            outline: none;
            border: 1px solid #6498b5;
        }
</style>