//导入封装好的新的axios
import request from '@/request/index'
export function getLogin(data) {
    return request({
        url: '/auth/login',
        method: 'post',
        data,
    })
}

//查询公司列表
export function getCompanyList(data) {
    return request({
        url: '/company/list',
        method: 'get',
        data,
    })
}

//获取APP下载链接
export function getAppDownloadUrl(data) {
    return request({
        url: '/auth/register',
        method: 'post',
        data,
    })
}
//获取验证码
export function getRegisterCode(data) {
    return request({
        url: '/auth/getRegisterCode',
        method: 'post',
        data,
    })
}

//注册
export function registerAccount(data) {
    return request({
        url: '/auth/register',
        method: 'post',
        data,
    })
}

//修改密码获取验证码
export function getResetPasswordCode(data) {
    return request({
        url: '/auth/getResetPasswordCode',
        method: 'post',
        data,
    })
}


//修改密码
export function resetPassword(params,data) {
    return request({
        url: '/auth/resetPassword' + '/' + params.rgn,
        method: 'post',
        data,
    })
}


export default { getLogin, getCompanyList, getAppDownloadUrl, getRegisterCode, registerAccount, getResetPasswordCode, resetPassword }