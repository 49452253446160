<template>
    <div>
        <el-empty description="Event Record is under development..."></el-empty>
    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import { mapState } from 'vuex';
export default {
    name:"EventRecords",
    data(){
        return{
           
        }
    },
    mounted(){
       
    },
    methods:{
        
    },
    computed:{
        ...mapState(["selectCompany","companyList"])
    },
    watch:{
      
    }
}
</script>

<style scoped>
</style>