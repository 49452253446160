import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        "language": "en",
        "userInfo": {
            "userName": "admin"
        },
        // "hasCompany": true, //是否有公司
        "companyList": [], //公司列表
        "siteList": [], //site列表
        "selectModel": "company", //model:company,site
        "selectCompany": {},
        "selectSite": {},
        "breadcrumbList": [], //面包屑导航数据
        "addCompany": false, //新建company(下拉列表点击新建)
        "isEditPage": { //详情，编辑网页显示隐藏
            "isCompanyAdminEdit": false,
            "isPanelsEdit": false,
            "isSiteUserEdit": false,
        }
    },
    actions: {
        changeModel(context, model) {
            context.commit('CHANGEMODEL', model);
        },
        changeCompany(context, companyList) {
            context.commit('GETCOMPANYLIST', companyList);
        },
    },
    mutations: {
        //语言
        CHANGELANGUAGE(state, lang) {
            state.language = lang;
        },
        //用户信息
        GETUSERINFO(state, useInfoObj) {
            state.userInfo.userName = useInfoObj.userName;
        },
        //模式 company,site
        CHANGEMODEL(state, model) {
            state.selectModel = model;
        },
        //面包屑导航
        GENERATEBREADLIST(state, breadList) {
            state.breadcrumbList = breadList;
        },
        //获取公司
        GETCOMPANYLIST(state, companyList) {
            //注意要使用vue能检测到的7个方法来操作数组对象：push(),pop(),shift(),unshift(),splice(),sort(),reverse()
            state.companyList.splice(0, (state.companyList).length);
            for (let i = 0; i < companyList.length; i++) {
                state.companyList.push(companyList[i]);
            }
            //state.companyList = companyList;
        },
        //是否有公司
        // GETHASCOMPANY(state, isCompany) {
        //     state.hasCompany = isCompany;
        // },
        //选择公司
        GETSELECTCOMPANY(state, selCompany) {
            //Vue.set(state, "selectCompany", selCompany);
            Vue.set(state.selectCompany, "companyName", selCompany["companyName"]);
            Vue.set(state.selectCompany, "companyRegion", selCompany["companyRegion"]);
            Vue.set(state.selectCompany, "role", selCompany["role"]);
            Vue.set(state.selectCompany, "createBy", selCompany["createBy"]);
            Vue.set(state.selectCompany, "createTime", selCompany["createTime"]);
            Vue.set(state.selectCompany, "dataRegion", selCompany["dataRegion"]);
            Vue.set(state.selectCompany, "id", selCompany["id"]);
            Vue.set(state.selectCompany, "updateBy", selCompany["updateBy"]);
            Vue.set(state.selectCompany, "updateTime", selCompany["updateTime"]);
            Vue.set(state.selectCompany, "version", selCompany["version"]);
        },
        //获取公司下site
        GETSITELIST(state, siteList) {
            //注意要使用vue能检测到的7个方法来操作数组对象：push(),pop(),shift(),unshift(),splice(),sort(),reverse()
            state.siteList.splice(0, (state.siteList).length);
            for (let i = 0; i < siteList.length; i++) {
                state.siteList.push(siteList[i]);
            }
        },
        //选择的site
        GETSELECTSITE(state, selSite) {
            Vue.set(state.selectSite, "createTime", selSite["createTime"]);
            Vue.set(state.selectSite, "id", selSite["id"]);
            Vue.set(state.selectSite, "role", selSite["role"]);
            Vue.set(state.selectSite, "siteAddress", selSite["siteAddress"]);
            Vue.set(state.selectSite, "siteName", selSite["siteName"]);
            Vue.set(state.selectSite, "type", selSite["type"]);
            // Vue.set(state, "selectSite", selSite);
            //state.selectSite = selSite;
        },
        //新建公司
        CREATECOMPANY(state, isCreate) {
            state.addCompany = isCreate;
        },
        //是否以编辑页打开
        OPENINEDITPAGE(state, editObj) {
            Vue.set(state.isEditPage, editObj.name, editObj.value);
        },
    },
    plugins: [createPersistedState()],
})