<template>
    <div class="sitePanelLogBox">
        <div class="controlBtnBox">
            <el-button :class="{selectBtn:selectBtnIndex===1}" @click="changeLogType(1)">{{$t('CallLog')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===2}" @click="changeLogType(2)">{{$t('AccessLog')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===3}" @click="changeLogType(3)">{{$t('TamperLog')}}</el-button>
        </div>
        <div v-if="selectBtnIndex === 1">
            <CallLog></CallLog>
        </div>
        <div v-if="selectBtnIndex === 2">
            <AccessLog></AccessLog>
        </div>
        <div v-if="selectBtnIndex === 3">
            <TamperLog></TamperLog>
        </div>
    </div>
    
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import {nanoid} from 'nanoid'
import CallLog from './calllog/index.vue'
import AccessLog from './accesslog/index.vue'
import TamperLog from './tamperlog/index.vue'

export default {
    name:"PanelLog",
    data(){
        return{
            selectBtnIndex:1,
          
        }
    },
    components:{ CallLog, AccessLog, TamperLog},
    mounted(){
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/panellog',
                name:this.$t('PanelLog')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
       
    },
    methods:{
         //点击功能切换按钮
         changeLogType(type){
            this.selectBtnIndex = type;
        },
       
    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    watch: {
      
    }
}
</script>

<style scoped>
.sitePanelLogBox{
   box-sizing: border-box;
   padding: 15px 30px;
}
@media screen and (max-width: 600px) {
    .sitePanelLogBox{
        padding: 20px 0;
    }
}
.controlBtnBox{
    display: flex;
}
.el-button {
    height: 30px;
    /* width: 140px; */
    padding: 6px 20px;
    font-size: 15px;
    background: #ccc;
    color: #000;
}
.el-button:hover{
    background: #6498b5;
    color: #fff;
}
.el-button:active {
    background: #6498b5;
    color: #fff;
}
.el-button:focus {
    background: #6498b5;
    color: #fff;
  }

.selectBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
}
.el-button+.el-button{
    margin-left: 4px;
}
</style>