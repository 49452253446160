//导入封装好的新的axios
import request from '@/request/index'
//查询开锁日志
export function getAccessLogList(params) {
    return request({
        url: '/' + params.rgn +'/site/events/page',
        method: 'get',
        params,
    })
}

//导出
export function getAccessLogExport(params) {
    return request({
        url: '/' + params.rgn +'/site/events/getExportUrl',
        method: 'get',
        params,
    })
}

export default { getAccessLogList, getAccessLogExport }