//导入封装好的新的axios
import request from '@/request/index'

//查询公司下site
export function getCompanySiteList(params) {
    return request({
        url: '/' + params.rgn + '/company/site/page',
        method: 'get',
        params: {
            companyId: params.companyId,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
        },
    })
}
//查询公司下site
export function getCompanySiteListNoPage(params) {
    return request({
        url: '/' + params.rgn + '/company/site/page',
        method: 'get',
        params: {
            companyId: params.companyId,
        },
    })
}
//添加site
export function addNewSite(params, data) {
    return request({
        url: '/' + params.rgn + '/company/site/add',
        method: 'post',
        data,
    })
}

//编辑site
export function editCompanySite(params, data) {
    return request({
        url: '/' + params.rgn + '/site/update',
        method: 'post',
        data,
    })
}

//删除site
export function deleteCompanySite(params, data) {
    return request({
        url: '/' + params.rgn + '/company/site/delete',
        method: 'post',
        data,
    })
}


export default { addNewSite, getCompanySiteList, getCompanySiteListNoPage, editCompanySite, deleteCompanySite }