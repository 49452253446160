//导入封装好的新的axios
import request from '@/request/index'

//查询普通用户相关信息
export function getUserApartmentInfo(params) {
    return request({
        url: '/' + params.rgn + '/site/getUserSiteInfo',
        method: 'get',
        params: {
            companyId: params.companyId,
            siteId: params.siteId,
            oemGroupId: params.oemGroupId,
        },
    })
}

export default { getUserApartmentInfo }