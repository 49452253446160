//导入封装好的新的axios
import request from '@/request/index'
//查询呼叫日志列表
export function getTamperLogList(params) {
    return request({
        url: '/' + params.rgn +'/site/events/page',
        method: 'get',
        params,
    })
}

//导出
export function getTamperLogExport(params) {
    return request({
        url: '/' + params.rgn +'/site/events/getExportUrl',
        method: 'get',
        params,
    })
}


export default { getTamperLogList, getTamperLogExport}