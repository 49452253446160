<template>
    <div>
        <div class="siteUersBox" v-show="!isEditPage.isSiteUserEdit">
            <!--新增User弹框-->
            <el-dialog :title="$t('AddSiteUser')" 
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="add.addUserFrom" 
                center 
                :show-close="showClose" 
            >
                <!-- 等待页面显示 -->
                <div v-show="add.showWaitForm">
                    <div class="waitmentBox">
                        <img :src="add.waitmentImg" alt="">
                    </div>
                    <div class="waitText">{{$t('AddingUser')}}</div>
                </div>
                <!-- 新增site user弹框 -->
                <div class="addsiteUersBox" v-show="add.addForm">
                    <div class="addSiteUserLeft">
                        <el-form :model="add">
                            <el-form-item :label="$t('UserEmail') + ' *' ">
                                <el-input v-model="add.invitationEmail"></el-input>
                                <!-- <p>if you can't fnd serial number please click here <span>>></span></p> -->
                            </el-form-item>
                            <div class="NameBox">
                                <div style="width:48%">
                                    <el-form-item :label="$t('FirstName')">
                                    <el-input v-model="add.firstName"></el-input>
                                    </el-form-item>
                                </div>
                                <div style="width:48%">
                                    <el-form-item :label="$t('Surname')">
                                    <el-input v-model="add.surname"></el-input>
                                </el-form-item>
                                </div>
                            </div>
                            <el-form-item :label="$t('Sites') + $t('Or') + $t('Blocks') + ' *' ">
                                <el-select v-model="add.apartmentId" :placeholder="$t('Sites') + '/' + $t('Blocks')">
                                    <el-option
                                    v-for="item in add.apartmentOptions"
                                    :key="item.id"
                                    :label="item.apartmentName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('HouseNumber') + ' *'">
                                <el-input v-model="add.houseNo"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="addSiteUserRight">
                        <el-form :model="add">
                            <el-form-item :label="$t('UserRole') + ' *'">
                                <el-select v-model="add.userRole" :placeholder="$t('UserRole')">
                                    <el-option
                                    v-for="item in add.userRoleOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('ActivationMethod') + ' *'" v-if="false">
                                <el-select v-model="add.activationType" disabled>
                                    <el-option
                                    v-for="item in add.activationTypeOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                                <!-- <el-input v-model="add.activationType"></el-input> -->
                                <p>{{ $t('ChooseActivationMethod') }} <span>>></span></p>
                            </el-form-item>
                        </el-form>
                        <div>{{ $t('AvailableActivationCodes') }}</div>
                        <div class="licenseBox">
                            <el-checkbox-group v-model="add.checkedLicense" @change="selectLicense">
                                <div class="licenseItem" v-for="item in add.licenseList" :key="item.id">
                                    <div class="licenseItemLeft"><el-checkbox :label="item.id" size="medium">
                                        {{item.serverDuration > 1 ? item.serverDuration + " " + stringDate(item.serverDuration,item.serverDurationUnit) : "1 "+ stringDate(item.serverDuration,item.serverDurationUnit)}}
                                    </el-checkbox></div>
                                    <div class="licenseItemRight">{{$t('RemainingQuantit')}} {{ item.quantity }}</div>
                                </div>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer" v-show="add.addForm">
                    <el-button @click="cancalSiteUserFrom">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="add.addBtnState === 1" @click="addNewSiteUser">{{$t('Confirm')}}</el-button>
                </div>
                <!-- 新增成功后弹框 -->
                <div class="addSuccessBox" v-show="add.addSuccessForm">
                    <div class="addSuccessDiv">
                        <div class="addSuccessLeft">
                            <div class="addSuccessItem">
                                <div>{{$t('AddUserSuccess')}}</div>
                                <p>{{$t('UserHasJoined')}} {{ add.siteName + "  " + add.apartmentName + "  " + add.houseNo}}</p>
                            </div>
                            <div class="addSuccessItem">
                                <div>{{$t('UserServiceActivated')}}</div>
                                <p>{{$t('ActivationCode')}} :  {{ add.invitationCode }}</p>
                            </div>
                            <div class="addSuccessItem">
                                <div> {{$t('UserConfrmation')}} </div>
                                <p>{{$t('ConfirmationEmail')}}</p>
                            </div>
                        </div>
                        <div class="addSuccessRight">
                            <div>{{$t('ScanQRCode')}}</div>
                            <div class="addSuccessQRBox">
                                <div class="addSuccessQRCode" ref="QRcodeForApp">

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div slot="footer" class="dialog-footer addSuccessBtn">
                        <el-button @click="cancalSucceSiteUserFrom">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" :loading="add.addBtnState === 1" @click="addSucceSiteUserBtn">{{$t('Confirm')}}</el-button>
                    </div>
                </div>
            </el-dialog>
            <!--新增Service Activation弹框-->
            <el-dialog :title="$t('ServiceActivation')" 
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="serverActiva.activationListForm" 
                center 
                :show-close="showClose"
            >
                <!-- 等待页面显示 -->
                <div v-show="serverActiva.showWaitForm">
                    <div class="waitmentBox">
                        <img :src="add.waitmentImg" alt="">
                    </div>
                    <div class="waitText">{{$t('UserServiceActivation')}}</div>
                </div>
                <!-- Activation 选择页面 -->
                <div  v-show="serverActiva.selectActivationForm">
                    <div class="addActiveUersBox">
                        <div class="serachActiveBox">
                            <div class="serachLeft">
                                <el-input v-model="serverActiva.username" class="serachInput" :placeholder="$t('UserName')"></el-input>
                                <el-select v-model="serverActiva.licenseStatus" class="serachSel" :placeholder="$t('ServiceStatus')">
                                    <el-option
                                    v-for="item in serverActiva.licenseStatusOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-input v-model="serverActiva.licenseRemaining" class="serachInput" :placeholder="$t('ServicesRemaining')"></el-input>
                                
                            </div>
                            <div class="serachRight">
                                <el-button type="primary" class="serachBtn"  @click="serachActiveSiteUsers">{{$t('Serach')}}</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="activeTableBox">
                        <template>
                            <el-table
                            :data="serverActiva.siteUsersListData"
                            :cell-class-name="getActivationServerStatusClass"
                            @selection-change="handleSelectDate"
                            style="width: 100%; border-right: 3px solid #6498b5;"
                            :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                            >
                            <el-table-column
                                label="#"
                                type="index"
                                width="50">
                            </el-table-column>
                            <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column
                                prop="surname"
                                :label="$t('UserName')"
                                width="150"
                                >
                                <template slot-scope="scope">
                                    {{ $handFieldNull(showUserName(scope.row.surname,scope.row.firstName))}}
                                </template>
                            </el-table-column>
                            
                            <el-table-column
                                prop="licenseStatus"
                                :label="$t('ServiceStatus')"
                                width="150"
                                >
                                <template slot-scope="scope">
                                    {{ getServerStatusStr(scope.row.licenseStatus)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="licenseRemaining"
                                :label="$t('ServicesRemaining')"
                                width="200"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.licenseRemaining === null ? "--" : (scope.row.licenseRemaining === 0 ? 0 : scope.row.licenseRemaining + " " + $t('Days'))}}
                                </template>
                            </el-table-column>

                            <el-table-column>

                            </el-table-column>
                        
                            </el-table>
                        </template>
                    </div>
                    <div class="selectNumBox">
                        <div>{{$t('Total')}} : {{ serverActiva.siteUsersListData.length }}</div>
                        <div>{{$t('Select')}} : {{ serverActiva.selectSiteUsersListData.length }}</div>
                    </div>
                    <div class="selectLicenseBox">
                        <div v-for="item in serverActiva.licenseList" :key="item.id">
                            <el-radio v-model="serverActiva.licenseId" :label="item.id">
                                <!-- {{item.serverDuration > 1 ? item.serverDuration + " " + stringDate(item.serverDuration,item.serverDurationUnit) : "One "+ stringDate(item.serverDuration,item.serverDurationUnit)}}  -->
                                {{item.licenseName}} 
                            </el-radio> 
                            <span>{{$t('RemainingQuantit')}} {{ item.quantity }}</span>
                        </div>
                    </div>
                    <div slot="footer" class="dialog-footer activationBtn">
                        <el-button @click="closeActivateServiceFrom">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" @click="confirmactivateService">{{$t('Activation')}}</el-button>
                    </div>
                </div>

                <!-- 确认激活用户弹窗 -->
                <div v-show="serverActiva.confirmActivationForm">
                    <div class="confirmActivaTex">
                        <div>
                            <img :src="serverActiva.licenseImg" alt="">
                        </div>
                        <div> 
                            <!-- {{serverActiva.selectLicense.serverDuration > 1 ? serverActiva.selectLicense.serverDuration + " " 
                            + stringDate(serverActiva.selectLicense.serverDuration,serverActiva.selectLicense.serverDurationUnit) : "One " + 
                            stringDate(serverActiva.selectLicense.serverDuration,serverActiva.selectLicense.serverDurationUnit)}}  -->
                            {{serverActiva.selectLicense.licenseName}}
                            <span>{{$t('RemainingQuantit')}} {{ serverActiva.selectLicense.quantity }}</span>
                        </div>
                    </div>
                    <div class="confirmActivaNumTex">
                        {{ serverActiva.selectSiteUsersListData.length }} {{$t('ConsumedActivate')}}
                    </div>
                    <div class="confirmActivationTableBox">
                        <template>
                            <el-table
                            :data="serverActiva.selectSiteUsersListData"
                            :cell-class-name="getConfirmActivationServerStatusClass"
                            style="width: 100%; border-right: 3px solid #6498b5;"
                            :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                            >
                            <el-table-column
                                label="#"
                                type="index"
                                width="50">
                            </el-table-column>
                            <el-table-column
                                prop="surname"
                                :label="$t('UserName')"
                                width="150"
                                > 
                                <template slot-scope="scope">
                                    {{ $handFieldNull(showUserName(scope.row.surname,scope.row.firstName))}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="licenseStatus"
                                :label="$t('ServiceStatus')"
                                width="150"
                                >
                                <template slot-scope="scope">
                                    {{ getServerStatusStr(scope.row.licenseStatus)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="licenseRemaining"
                                :label="$t('ServicesRemaining')"
                                width="200"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.licenseRemaining === null ? "--" : (scope.row.licenseRemaining === 0 ? 0 : scope.row.licenseRemaining + " " + $t('Days'))}}
                                </template>
                            </el-table-column>

                            <el-table-column>

                            </el-table-column>
                        
                            </el-table>
                        </template>
                    </div>
                    <div slot="footer" class="dialog-footer confirmActivationBtn">
                        <el-button @click="closeConfirmActivateFrom">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" :disabled="serverActiva.confirmActivationBtn" @click="confirmSelectActivate">{{$t('Confirm')}}</el-button>
                    </div>
                   
                </div>
                
            </el-dialog>
            <!--绑定User弹框-->
            <el-dialog :title="$t('BindingUser')" 
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="binding.bindUserFrom" 
                center 
                :show-close="showClose"
             >
                <!-- 等待页面显示 -->
                <div v-show="binding.showWaitForm">
                    <div class="waitmentBox">
                        <img :src="binding.waitmentImg" alt="">
                    </div>
                    <div class="waitText">{{$t('BindingUserWait')}}</div>
                </div>
                <!-- 绑定site user弹框 -->
                <div class="addsiteUersBox" v-show="binding.bindForm">
                    <div class="addSiteUserLeft">
                        <el-form :model="binding">
                            <el-form-item :label="$t('UserEmail') + ' *' ">
                                <el-input v-model="binding.invitationEmail"></el-input>
                                <!-- <p>if you can't fnd serial number please click here <span>>></span></p> -->
                            </el-form-item>
                            <div class="NameBox">
                                <div style="width:48%">
                                    <el-form-item :label="$t('FirstName')">
                                    <el-input v-model="binding.firstName"></el-input>
                                    </el-form-item>
                                </div>
                                <div style="width:48%">
                                    <el-form-item :label="$t('Surname')">
                                    <el-input v-model="binding.surname"></el-input>
                                </el-form-item>
                                </div>
                            </div>
                            <el-form-item :label="$t('Sites') + $t('Or') + $t('Blocks') + ' *' ">
                                <el-select v-model="binding.apartmentId" :placeholder="$t('Sites') + '/' + $t('Blocks')">
                                    <el-option
                                    v-for="item in binding.apartmentOptions"
                                    :key="item.id"
                                    :label="item.apartmentName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('HouseNumber') + ' *'">
                                <el-input v-model="binding.houseNo"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="addSiteUserRight">
                        <el-form :model="binding">
                            <el-form-item :label="$t('UserRole') + ' *' ">
                                <el-select v-model="binding.userRole" :placeholder="$t('UserRole')">
                                    <el-option
                                    v-for="item in binding.userRoleOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        
                    </div>
                </div>
                <div slot="footer" class="dialog-footer" v-show="binding.bindForm">
                    <el-button @click="binding.bindUserFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="binding.bindBtnState === 1" @click="bindingNewSiteUser">{{$t('Confirm')}}</el-button>
                </div>
                <!-- 绑定成功后弹框 -->
                <div class="addSuccessBox" v-show="binding.bindSuccessForm">
                    <div class="addSuccessDiv">
                        <div class="addSuccessLeft">
                            <div class="addSuccessItem">
                                <div>{{$t('AddUserSuccess')}}</div>
                                <p>{{$t('UserHasJoined')}} {{ binding.siteName + "  " + binding.apartmentName + "  " + binding.houseNo}}</p>
                            </div>
                            <div class="addSuccessItem">
                                <div>{{$t('UserServiceActivated')}}</div>
                                <p>{{$t('ActivationCode')}} :   {{ binding.invitationCode }}</p>
                            </div>
                            <div class="addSuccessItem">
                                <div>{{$t('UserConfrmation')}}</div>
                                <p>{{$t('ConfirmationEmail')}}</p>
                            </div>
                        </div>
                        <div class="addSuccessRight">
                            <div>{{$t('ScanQRCode')}}</div>
                            <div class="addSuccessQRBox">
                                <div class="addSuccessQRCode" ref="bindQRcodeForApp">

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div slot="footer" class="dialog-footer addSuccessBtn">
                        <el-button @click="bindingSucceSiteUser">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" :loading="binding.bindBtnState === 1" @click="bindingSucceSiteUser">{{$t('Confirm')}}</el-button>
                    </div>
                </div>
            </el-dialog>
            <!--解绑User弹框-->
            <el-dialog :title="$t('UnbindingUser')" class="unbindingUserDiv" 
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                :visible.sync="unbinding.unbindUserFrom" 
                center 
                :show-close="showClose"
             >
                <div class="unbindingUserBox">
                    <!-- <div class="unbindingUserText">Are you sure to untie the user and house?</div>
                    <ul>
                        <li>After unbinding, the user will lose the permission to useall doorway equipment in the park.</li>
                        <li>The link and QR code that invited the user will be invalid.</li>
                    </ul> -->
                    <p class="deleteDesc">{{$t('UnbindingUserDesc')}}</p>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="unbinding.unbindUserFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="unbinding.unbindBtnState === 1" @click="unbindingSiteUser">{{$t('Confirm')}}</el-button>
                </div>
               
            </el-dialog>
            <!--删除SiteUser弹框-->
            <el-dialog
                :title="$t('Delete')"
                :visible.sync="del.delSiteUserFrom"
                width="30%"
                :show-close="showClose">
                <p class="deleteDesc">{{$t('DeleteUser')}}</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="del.delSiteUserFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="del.delBtnState === 1" @click="delSiteFromBtn">{{$t('Confirm')}}</el-button>
                </span>
            </el-dialog>
            <!--查看头像弹框-->
            <el-dialog
                :title="$t('Profile')"
                :visible.sync="showAvatar.avatarBigFrom"
                :close-on-click-modal="false" 
                :close-on-press-escape="false"
                width="40%"
                :show-close="showClose">
                <div>
                    <img :src="showAvatar.avatarUrl" width="96%" height="96%" style="margin: 1% 2%;" alt="">
                </div>
            </el-dialog>
            <!--头像审核状态弹框-->
            <el-dialog
                :title="avatarCheck.avatarCheckStatus === 1 ? $t('EnableProfile') : $t('DisableProfile')"
                :visible.sync="avatarCheck.avatarCheckFrom"
                width="30%"
                :show-close="showClose">
                <p class="deleteDesc">{{avatarCheck.avatarCheckStatus === 1 ? $t('WhetherEnableProfile') : $t('WhetherDisableProfile')}}</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="avatarCheck.avatarCheckFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="avatarCheck.avatarCheckBtnState === 1" @click="avatarCheckSiteFromBtn">{{$t('Confirm')}}</el-button>
                </span>
            </el-dialog>
            <div class="serachBox">
                <div class="serachLeft">
                    <el-input v-model="serach.username" class="serachInput" :placeholder="$t('UserName')"></el-input>
                    <el-select v-model="serach.apartmentId" class="serachSel" :placeholder="$t('Sites') + '/' + $t('Blocks')" clearable>
                        <el-option
                        v-for="item in serach.apartmentOptions"
                        :key="item.id"
                        :label="item.apartmentName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="serach.licenseStatus" class="serachSel" :placeholder="$t('ServiceStatus')" clearable>
                        <el-option
                        v-for="item in serach.licenseStatusOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="serachBtn"  @click="serachSiteUsers">{{$t('Serach')}}</el-button>
                </div>
                <div class="serachRight">
                    <el-button type="primary" class="serachBtn" @click="activateServiceFrom">{{$t('ServiceActivation')}}</el-button>
                    <el-button type="primary" class="serachBtn" @click="addNewUserFrom">{{$t('AddUsers')}}</el-button>
                </div>
            </div>
            <el-divider></el-divider>
            <div>
                <div class="noPanels" v-show="hasSiteUser">{{$t('NoUser')}}</div>
                <template>
                    <el-table
                    v-show="!hasSiteUser"
                    :data="siteUsersListData"
                    :cell-class-name="getServerStatusClass"
                    @cell-click="handlePanelsCell"
                    @row-dblclick="handleSiteUserRow"
                    style="width: 100%;"
                    :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                    >
                    <el-table-column
                        prop="surname"
                        :label="$t('UserName')"
                        show-overflow-tooltip
                        >
                        <template slot-scope="scope">
                            {{ $handFieldNull(showUserName(scope.row.surname,scope.row.firstName))}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="apartmentName"
                        :label="$t('Blocks') + '/'+ $t('Sites')"
                        >
                        <template slot-scope="scope">
                            {{scope.row.apartmentId === "0" ? $t('Sites') : scope.row.apartmentName}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="houseNo"
                        :label="$t('HouseNumber')"
                        show-overflow-tooltip
                        >
                        <template slot-scope="scope">
                            {{ $handFieldNull(scope.row.houseNo)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="userAccount"
                        :label="$t('Account')"
                        show-overflow-tooltip
                        width="200"
                        >
                        <template slot-scope="scope">
                            {{ $handFieldNull(scope.row.userAccount)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="userRole"
                        :label="$t('UserRole')"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="avatarUrl"
                        :label="$t('Profile')"
                        width="120"
                        >
                        <template slot-scope="scope">
                            <div v-show="!scope.row.avatarUrl"> -- </div>
                            <el-image v-show="scope.row.avatarUrl" :src="scope.row.avatarUrl" @click="showBigAvatar(scope.row)" style="width: 60px; height:25px;">
                                <div slot="placeholder" class="image-slot" style="width: 100px; height:25px;">
                                    {{ $t("Loading") }}<span class="dot">...</span>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="userStatus"
                        :label="$t('UserStatus')"
                        >
                        <template slot-scope="scope">
                            {{ getUserStatusStr(scope.row.userStatus)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="licenseStatus"
                        :label="$t('ServiceStatus')"
                        >
                        <template slot-scope="scope">
                            {{ getServerStatusStr(scope.row.licenseStatus)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="licenseRemaining"
                        :label="$t('ServicesRemaining')"
                        width="200"
                        >
                        <template slot-scope="scope">
                            {{scope.row.licenseRemaining === null ? "--" : (scope.row.licenseRemaining === 0 ? 0 : scope.row.licenseRemaining + " " + $t('Days'))}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        :label="$t('CreateDate')"
                        width="160"
                        >
                        <template slot-scope="scope">
                            {{ utctolocal(scope.row.createTime)}}
                        </template>
                    </el-table-column>
                    <el-table-column width="260"  label="..." align="center">
                        <template slot-scope="scope">
                            <el-image :src="editImg"  @click="editlSiteUser(scope.row)" style="width:24px;" fit="fill"></el-image>
                            <el-image :src="deleteImg" @click="openDelSiteUserFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                            <el-image :src="bindingImg" v-show="scope.row.userStatus === 3"  @click="bindingUserFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                            <!-- <el-image :src="disBindingImg" v-show="scope.row.userStatus === 0 || scope.row.userStatus === 1 || scope.row.userStatus === 2" style="width:24px;margin-left:1vw" fit="fill"></el-image> -->
                            <el-image :src="unbindingImg" v-show="(scope.row.userStatus === 0 || scope.row.userStatus === 1 || scope.row.userStatus === 2)" @click="unbindingSiteUserFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                            <!-- <el-image :src="disUnbindingImg" v-show="scope.row.userStatus === 3" style="width:24px;margin-left:1vw" fit="fill"></el-image> -->
                            <template v-if="scope.row.avatarUrl">
                                <el-image :src="forbiddenatatar" v-show="scope.row.avatarCheck != 0" @click="openAvatarCheckFrom(scope.row,0)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                                <!-- <el-image :src="forbiddenatatar_d" v-show="scope.row.avatarCheck != 1" style="width:24px;margin-left:1vw" fit="fill"></el-image> -->
                                <el-image :src="enableavatar" v-show="scope.row.avatarCheck === 0" @click="openAvatarCheckFrom(scope.row,1)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                                <!-- <el-image :src="enableavatar_d" v-show="scope.row.avatarCheck === 1" style="width:24px;margin-left:1vw" fit="fill"></el-image> -->  
                            </template>
                        </template>
                    </el-table-column>

                    <el-table-column>

                    </el-table-column>
                
                    </el-table>
                </template>
                <div class="pageBox">
                    <el-pagination
                        v-show="!hasSiteUser"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="prev, pager, next, jumper"
                        :total="totalUserSite"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <router-view v-show="isEditPage.isSiteUserEdit"></router-view>
    </div>
    
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import {nanoid} from 'nanoid'
import QRCode from 'qrcodejs2'
import { sha256 } from 'js-sha256'
import sitesUsersApi from '@/api/siteusers/index.js'
import commonFun from '@/common/index.js'
import editImg from '@/assets/images/edit.png'
import deleteImg from '@/assets/images/delete.png'
import disDeleteImg from '@/assets/images/delete_d.png'
import bindingImg from '@/assets/images/binding.png'
import disBindingImg from '@/assets/images/binding_d.png'
import unbindingImg from '@/assets/images/unbinding.png'
import disUnbindingImg from '@/assets/images/unbinding_d.png'
import licenseImg from '@/assets/images/license.png'
import waitmentImg from '@/assets/images/waitment.png'
import enableavatar from '@/assets/images/enableavatar.png'
import enableavatar_d from '@/assets/images/enableavatar_d.png'
import forbiddenatatar from '@/assets/images/forbiddenatatar.png'
import forbiddenatatar_d from '@/assets/images/forbiddenatatar_d.png'
export default {
    name:"SiteUsers",
    data(){
        return{
            showClose:true,
            hasSiteUser:false,
            totalUserSite:0,
            currentPage: 1,
            siteUsersListData:[],
            editImg,
            deleteImg,
            disDeleteImg,
            bindingImg,
            disBindingImg,
            unbindingImg,
            disUnbindingImg,
            enableavatar,
            enableavatar_d,
            forbiddenatatar,
            forbiddenatatar_d,
            serach:{
                username:"",
                apartmentId:"",
                apartmentOptions:[],
                licenseStatus:"",
                licenseStatusOptions:[
                    {
                        "id": nanoid(),
                        "value": 0,
                        "name":this.$t('NotEnabled')
                    },
                    {
                        "id": nanoid(),
                        "value": 1,
                        "name":this.$t('InUse')
                    },
                    {
                        "id": nanoid(),
                        "value": 2,
                        "name":this.$t('Cancel').replace(' ','')
                    },
                    {
                        "id": nanoid(),
                        "value": 9,
                        "name":this.$t('End')
                    },
                ],
            },
            add:{
                addUserFrom:false,
                addBtnState:0,
                invitationEmail:"",
                firstName:"",
                surname:"",
                apartmentId:"",
                apartmentOptions:[],
                houseNo:"",
                userRole:"",
                userRoleOptions:[
                    {
                       id:"admin", 
                       name:"admin", 
                    },
                    {
                       id:"employee", 
                       name:"employee", 
                    },
                    {
                       id:"user", 
                       name:"user", 
                    },
                    {
                       id:"others", 
                       name:"others", 
                    }
                ],
                activationType:"activationType02",
                activationTypeOptions:[
                    {
                        id:"activationType01",
                        name:this.$t('ActivationTypeOne')
                    },
                    {
                        id:"activationType02",
                        name:this.$t('ActivationTypeTwo')
                    },
                    {
                        id:"activationType03",
                        name:this.$t('ActivationTypeTwo')
                    },
                ],
                checkedLicense:[],
                licenseList:[],
                addForm: true,
                showWaitForm: false,
                addSuccessForm: false,
                waitmentImg,
                siteName:"",
                apartmentName:"",
                invitationCode:"",
                invitationQrCode:"",

            },
            serverActiva:{
                showWaitForm:false,
                activationListForm: false,
                selectActivationForm: false,
                confirmActivationForm: false,
                username:"",
                apartmentId:"",
                apartmentOptions:[],
                licenseRemaining:"",
                siteUsersListData:[], //获取用户数据，除用户角色为owner
                selectSiteUsersListData:[], //获取选中的用户数据
                licenseId:"",
                licenseList:[],
                licenseImg,
                selectLicense: {},
                licenseStatus:"",
                licenseStatusOptions:[
                {
                        "id": nanoid(),
                        "value": 0,
                        "name":this.$t('NotEnabled')
                    },
                    {
                        "id": nanoid(),
                        "value": 1,
                        "name":this.$t('InUse')
                    },
                    {
                        "id": nanoid(),
                        "value": 2,
                        "name":this.$t('Cancel').replace(' ','')
                    },
                    {
                        "id": nanoid(),
                        "value": 9,
                        "name":this.$t('End')
                    },
                ],
                confirmActivationBtn: false,
            },
            binding:{
                bindUserFrom:false,
                bindForm: true,
                showWaitForm: false,
                bindSuccessForm: false,
                bindBtnState:0,
                id:"",
                invitationEmail:"",
                firstName:"",
                surname:"",
                apartmentId:"",
                apartmentOptions:[],
                houseNo:"",
                userRole:"",
                userRoleOptions:[
                    {
                       id:"admin", 
                       name:"admin", 
                    },
                    {
                       id:"employee", 
                       name:"employee", 
                    },
                    {
                       id:"user", 
                       name:"user", 
                    },
                    {
                       id:"others", 
                       name:"others", 
                    }
                ],
                waitmentImg,
                siteName:"",
                apartmentName:"",
                invitationCode:"",
                invitationQrCode:"",

            },
            unbinding:{
                id:"",
                unbindUserFrom:false,
                unbindBtnState:0
            },
            del:{
                delSiteUserFrom:false,
                delBtnState:0,
                userHouseId:""
            },
            showAvatar:{
                avatarBigFrom:false,
                avatarUrl:""
            },
            avatarCheck:{
                avatarCheckFrom:false,
                avatarCheckBtnState:0,
                avatarCheckStatus:"",
                userHouseId:""
            },
        }
    },
    mounted(){
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/siteusers',
                name:this.$t('SiteUsers')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
        // this.OPENINEDITPAGE({name:"isPanelsEdit",value:false});
       //获取blocks下拉列表框
       this.getBlocksListForSelect();
       this.serachSiteUsers();
       //根据路由携带信息判断是否打开新增panels弹窗
       if(this.$route.params.createUser){
            this.addNewUserFrom();
       }else{
            this.cancalSiteUserFrom();
       }

    },
    methods:{
        ...mapMutations(["OPENINEDITPAGE"]),
        //双击表格行执行函数
        handleSiteUserRow(row, column, event){
            // console.log("row",row);
            this.OPENINEDITPAGE({name:"isSiteUserEdit",value:true}),
            this.$router.push(
                {
                    name:"siteusersedit",
                    params:row,
                }
            );
        },
        //单击设备列执行函数
        handlePanelsCell(row, column, cell, event){
            if(column.property === "deviceId"){
                this.OPENINEDITPAGE({name:"isSiteUserEdit",value:true}),
                this.$router.push(
                    {
                        name:"panelsedit",
                        params:row,
                    }
                );
            }
        },
        //用户名渲染函数
        showUserName(surname,firstName){
            if(surname == null){
                surname = "";
            }
            if(firstName == null){
                firstName = "";
            }
            let userName = surname + " " + firstName;
            return userName.trim();
        },
        //获取Block列表，渲染下拉列表框
        getBlocksListForSelect(){
            let blocksInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
            }
            sitesUsersApi.getSiteBlocksListForSelect(blocksInfoObj).then(res => {
                if(res.code === 0){
                    this.serach.apartmentOptions = [];
                    this.add.apartmentOptions = [];
                    //前面添加site，固定
                    this.serach.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    this.add.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    this.serverActiva.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    this.binding.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    for(let i=0;i< res.data.length;i++){
                        this.serach.apartmentOptions.push(res.data[i]);
                        this.add.apartmentOptions.push(res.data[i]);
                        this.serverActiva.apartmentOptions.push(res.data[i]);
                        this.binding.apartmentOptions.push(res.data[i]);
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //site service状态渲染函数
        getServerStatusClass(obj){
            let getClass = "";
            if(obj.columnIndex === 7){
                let status = obj.row.licenseStatus;
                switch(status){
                    case 0:
                        getClass = "statusRed";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusOrange";
                        break
                    case 9:
                        getClass = "";
                        break
                    default:
                        getClass = "";
                }
            }
            if(obj.columnIndex === 6){
                let status = obj.row.userStatus;
                switch(status){
                    case 0:
                        getClass = "";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusRed";
                        break
                    case 3:
                        getClass = "statusOrange";
                        break
                    default:
                        getClass = "";
                }
            }
            return getClass;
        },
        //选择需要activa的用户列表状态渲染
        getActivationServerStatusClass(obj){
            let getClass = "";
            if(obj.columnIndex === 3){
                let status = obj.row.licenseStatus;
                switch(status){
                    case 0:
                        getClass = "statusRed";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusOrange";
                        break
                    case 9:
                        getClass = "";
                        break
                    default:
                        getClass = "";
                }
            }
            return getClass;
        },
        getConfirmActivationServerStatusClass(obj){
            let getClass = "";
            if(obj.columnIndex === 2){
                let status = obj.row.licenseStatus;
                switch(status){
                    case 0:
                        getClass = "statusRed";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusOrange";
                        break
                    case 9:
                        getClass = "";
                        break
                    default:
                        getClass = "";
                }
            }
            return getClass;
        },
        
        getUserStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('NotResponding');
                    break;
                case 1:
                    getStr = this.$t('Accepted');
                    break
                case 2:
                    getStr = this.$t('Refuse');
                    break
                case 3:
                    getStr = this.$t('NotBound');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        getServerStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('NotEnabled');
                    break;
                case 1:
                    getStr = this.$t('InUse');
                    break
                case 2:
                    getStr = this.$t('Cancel').replace(' ','');
                    break
                case 9:
                    getStr = this.$t('End');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        //site user列表获取
        getSiteUsersList(serachSiteUserObj){
            let userInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "apartmentId":serachSiteUserObj.apartmentId,
                "username":serachSiteUserObj.username,
                "licenseStatus":serachSiteUserObj.licenseStatus,
                "pageSize":10,
                "pageNum":serachSiteUserObj.pageNum,
            }

            if(serachSiteUserObj.username != undefined){
                if((serachSiteUserObj.username).trim() != ""){
                let checkResultSurname = this.$regularCheckLimit(serachSiteUserObj.username,1,30,'UserNameLimit');
                if(!checkResultSurname){
                    return false
                }
            }
            }
            
            sitesUsersApi.getSiteUsersList(userInfoObj).then(res => {
                if(res.code === 0){
                    this.siteUsersListData = [];
                    // this.serverActiva.siteUsersListData = [];
                    // this.serverActiva.siteUsersListData = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.hasSiteUser = true;
                    }else{
                        this.hasSiteUser = false;
                        for(let i=0;i<res.data.list.length;i++){
                            this.siteUsersListData.push(res.data.list[i]);
                            // this.serverActiva.siteUsersListData.push(res.data.list[i]);
                        }
                        // this.siteUsersListData = res.data.list;
                    }
                    this.currentPage = res.data.pageNum;
                    this.totalUserSite = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //搜索Site Usres
        serachSiteUsers(){
            let serachSiteUserObj = {
                "apartmentId":this.serach.apartmentId,
                "username":this.serach.username,
                "licenseStatus":this.serach.licenseStatus,
                "pageNum":1
            }
            this.getSiteUsersList(serachSiteUserObj);
        },
       
         //日期字符串转换函数
        stringDate(val,str) {
            if(val>1){
                if (str == "Y" || str == "y") {
                    return this.$t('Years')
                } else if (str == "M" || str == "m") {
                    return this.$t('Months')
                } else if (str == "D" || str == "d") {
                    return this.$t('Days')
                }
            }else{
                if (str == "Y" || str == "y") {
                    return this.$t('Year')
                } else if (str == "M" || str == "m") {
                    return this.$t('Month')
                } else if (str == "D" || str == "d") {
                    return this.$t('Day')
                }
            }
        },
        //可用License列表获取
        getUsersLicenseList(){
            let licenseObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "companyId":this.selectCompany.id,
            }
            sitesUsersApi.getSiteUsersLicenseList(licenseObj).then(res => {
                if(res.code === 0){
                    this.add.licenseList = [];
                    this.serverActiva.licenseList = [];
                    if(res.data === null || res.data.length === 0){
                        this.add.licenseList = [];
                        this.serverActiva.licenseList = [];
                    }else{
                        for(let i=0;i<res.data.length;i++){
                            this.add.licenseList.push(res.data[i]);
                            this.serverActiva.licenseList.push(res.data[i]);
                        }
                    }
                    // console.log("this.add.licenseList",this.add.licenseList)
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //选择License
        selectLicense(){
            if(this.add.checkedLicense.length > 1){
                this.add.checkedLicense.splice(0,1)
            }
        },
        //处理房间号输入问题
        // handleHouseNo(){
        //     this.add.houseNo = this.add.houseNo.replace(/\D|^0/g, '');
        // },
        //添加新的user弹框
        addNewUserFrom(){
            this.add.addBtnState = 0;
            this.add.addUserFrom = true;
            this.add.showWaitForm = false;
            this.add.addForm = true;
            this.add.addSuccessForm = false;
            this.add.invitationEmail = "";
            this.add.firstName = "";
            this.add.surname = "";
            this.add.apartmentId = "";
            this.add.houseNo = "";
            this.add.userRole = "";
            this.add.checkedLicense = [];
            this.getUsersLicenseList();
        },
        //取消新增User弹框
        cancalSiteUserFrom(){
            this.add.addBtnState = 0;
            this.add.addUserFrom = false;
        },
        //添加Site User点击确认按钮执行方法
        addNewSiteUser(){
            if((this.add.invitationEmail).trim() === ""){
                this.$message.info(this.$t('UserEmailEmpty'));
                return false
            }
            let reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
            if(!reg.test(this.add.invitationEmail)){
                this.$message.info(this.$t('EmailFormatLimit'));
                return false
            }
            if((this.add.firstName).trim() === ""){
                this.$message.info(this.$t('FirstNameEmpty'));
                return false
            }
            let checkResultFirstName = this.$regularCheckLimit(this.add.firstName,1,20,'FirstNameLimit');
            if(!checkResultFirstName){
                return false
            }
            if((this.add.surname).trim() === ""){
                this.$message.info(this.$t('SurnameNameEmpty'));
                return false
            }
            let checkResultSurname = this.$regularCheckLimit(this.add.surname,1,20,'SurnameLimit');
            if(!checkResultSurname){
                return false
            }
            if((this.add.houseNo).trim() === ""){
                this.$message.info(this.$t('HouseNumberEmpty'));
                return false
            }
            let houseNumberRegex = /^[a-zA-Z0-9]+$/;
            if(!houseNumberRegex.test(this.add.houseNo)){
                this.$message.info(this.$t('LimitHouseNumber'));
                return false
            }
            let checkResulNumberReg = /^\d+$/;
            if(checkResulNumberReg.test(this.add.houseNo)){
                if(Number(this.add.houseNo) <= 0){
                    this.$message.info(this.$t('LimitHouseNumber'));
                    return false
                }
            }
            let checkResultHouseNumber = this.$regularCheckLimit(this.add.houseNo,1,20,'HouseNumberLimit');
            if(!checkResultHouseNumber){
                return false
            }
            if((this.add.userRole).trim() === ""){
                this.$message.info(this.$t('UserRoleEmpty'));
                return false
            }
            // if((this.add.activationType).trim() === ""){
            //     this.$message.info(this.$t('ActivationMethodEmpty'));
            //     return false
            // }
            if((this.add.apartmentId).trim() === ""){
                this.$message.info(this.$t('SiteOrBlocksEmpty'));
                return false
            }
            this.add.addBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addSiteUserObj = {
                "siteId":this.selectSite.id,
                "apartmentId":this.add.apartmentId,
                "invitationEmail":this.add.invitationEmail,
                "firstName": this.add.firstName,
                "surname": this.add.surname,
                "houseNo": this.add.houseNo,
                "userRole": this.add.userRole,
                "siteLicenseId": this.add.checkedLicense[0],
                "language": this.language,
            }
            this.add.showWaitForm = true;
            this.add.addForm = false;
            sitesUsersApi.addSiteUser(rgnObj,addSiteUserObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('CreatedSuccessfully'));
                    this.add.siteName = this.selectSite.siteName;
                    this.add.apartmentName = res.data.apartmentName;
                    this.add.houseNo = res.data.houseNo;
                    this.add.invitationCode = res.data.invitationCode;
                    this.add.invitationQrCode = res.data.invitationQrcode;
                    this.$refs.QRcodeForApp.innerHTML = '';
                    //调用生成二维码函数
                    this.creatQrCode(this.$refs.QRcodeForApp,this.add.invitationQrCode);
                    this.add.showWaitForm = false;
                    this.add.addForm = false;
                    this.add.addSuccessForm = true;
                    this.add.addBtnState = 0;
                    //获取用户列表
                    let serachSiteUserObj = {
                        "apartmentId":"",
                        "username":"",
                        "licenseStatus":"",
                        "pageNum":1
                    }
                    this.getSiteUsersList(serachSiteUserObj);
                }else{
                    this.add.showWaitForm = false;
                    this.add.addForm = true;
                    this.add.addSuccessForm = false;
                    this.add.addBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.add.showWaitForm = false;
                this.add.addForm = true;
                this.add.addSuccessForm = false;
                this.add.addBtnState = 0;
            })
           
            //this.add.addUserFrom = false;
        },
        //生成app加入二维码
        creatQrCode(id,content) {
            new QRCode(id, {
                text: content, // 需要转换为二维码的内容
                width: 185,
                height: 185,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        //添加成功后点击确认按钮执行方法
        addSucceSiteUserBtn(){
            this.add.addUserFrom = false;
            this.add.showWaitForm = false;
            this.add.addForm = false;
            this.add.addSuccessForm = false;
            this.serachSiteUsers();
        },
        //添加成功后点击取消按钮执行方法
        cancalSucceSiteUserFrom(){
            this.add.addUserFrom = false;
            this.add.showWaitForm = false;
            this.add.addForm = false;
            this.add.addSuccessForm = false;
            this.serachSiteUsers();
        },
        //点击编辑图标
        editlSiteUser(row){
            this.OPENINEDITPAGE({name:"isSiteUserEdit",value:true}),
            // this.isPanelsEdit = true;
            this.$router.push(
                {
                    name:"siteusersedit",
                    params:row,
                }
            );
        },

        //点击Service Activation执行函数
        activateServiceFrom(){
            this.serverActiva.activationListForm = true;
            this.serverActiva.showWaitForm = false;
            this.serverActiva.selectActivationForm = true;
            this.serverActiva.confirmActivationForm = false;
            this.getUsersLicenseList();
            this.serverActiva.username = "";
            this.serverActiva.licenseStatus = "";
            this.serverActiva.licenseRemaining = "";
            this.serverActiva.licenseId = "";
            this.serverActiva.siteUsersListData = [];
            this.serverActiva.selectSiteUsersListData = [];
        },
        //关闭Service Activation弹框
        closeActivateServiceFrom(){
            this.serverActiva.activationListForm = false;
        },
        //site user列表获取(owner除外)
        getActivateSiteUsersList(serachSiteUserObj){
            let userInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "licenseRemaining":serachSiteUserObj.licenseRemaining,
                "username":serachSiteUserObj.username,
                "licenseStatus":serachSiteUserObj.licenseStatus,
            }
            sitesUsersApi.getSiteUsersWithoutOwnerList(userInfoObj).then(res => {
                if(res.code === 0){
                    this.serverActiva.siteUsersListData = [];
                    if(res.data === null || res.data.length === 0){
                        // this.hasSiteUser = true;
                        this.$message.info(this.$t('NoData'));
                    }else{
                        for(let i=0;i<res.data.length;i++){
                            this.serverActiva.siteUsersListData.push(res.data[i]);
                        }
                        // if(isActive){
                        //     // this.serverActiva.selectSiteUsersListData = [];
                        //     for(let i=0;i<res.data.length;i++){
                        //         this.serverActiva.selectSiteUsersListData.push(res.data[i]);
                        //     }
                        //     console.log("this.serverActiva.selectSiteUsersListData222222",this.serverActiva.selectSiteUsersListData);
                        //     this.$forceUpdate();
                        // }else{
                        //     for(let i=0;i<res.data.length;i++){
                        //         this.serverActiva.siteUsersListData.push(res.data[i]);
                        //     }
                        // }
                        
                       
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        
        //Active弹窗点击搜索执行函数
        serachActiveSiteUsers(){
            if((this.serverActiva.username).trim() === "" && (this.serverActiva.licenseStatus).toString().trim() === "" && (this.serverActiva.licenseRemaining).trim() === ""){
                this.$message.info(this.$t('SelectAtLeastOne'));
                return false
            }
            if((this.serverActiva.username).trim() != ""){
                let checkResultUsername = this.$regularCheckLimit(this.serverActiva.username,1,30,'UserNameLimit');
                if(!checkResultUsername){
                    return false
                }
            }
            if((this.serverActiva.licenseRemaining).trim() != ""){
                let reg = /^(0|[1-9][0-9]{0,7})$/;
                if(!reg.test(this.serverActiva.licenseRemaining)){
                    this.$message.info(this.$t('ServicesRemainingLimit'));
                    return false
                }
            }
           
            let serachActiveSiteUserObj = {
                "licenseStatus":this.serverActiva.licenseStatus,
                "username":this.serverActiva.username,
                "licenseRemaining":this.serverActiva.licenseRemaining,
            }
            this.getActivateSiteUsersList(serachActiveSiteUserObj);
        },
        //Service Activation数据表格选择改变执行函数
        handleSelectDate(selValArr){
            // console.log("selValArr",selValArr);
            this.serverActiva.selectSiteUsersListData = [];
            for(let i=0;i<selValArr.length;i++){
                this.serverActiva.selectSiteUsersListData.push(selValArr[i]);
            }
        },
        //点击Activation执行方法
        confirmactivateService(){
            if(this.serverActiva.selectSiteUsersListData.length === 0){
                this.$message.info(this.$t('SelectAtLeastActivate'));
                return false
            }
            if(this.serverActiva.licenseId === ""){
                this.$message.info(this.$t('SelectLicense'));
                return false
            }
            this.serverActiva.selectLicense = commonFun.getTargetObjInfo(this.serverActiva.licenseList,this.serverActiva.licenseId);
            if(this.serverActiva.selectSiteUsersListData.length > this.serverActiva.selectLicense.quantity){
                this.$message.info(this.$t('InsufficientLicense'));
                return false
            }
            this.serverActiva.activationListForm = true;
            this.serverActiva.showWaitForm = false;
            this.serverActiva.selectActivationForm = false;
            this.serverActiva.confirmActivationForm = true;
            // console.log("aaaa",commonFun.getTargetObjInfo(this.serverActiva.licenseList,this.serverActiva.licenseId));
            // Vue.set(this.serverActiva.selectLicense, "companyName", selCompany["companyName"]);
            
           },
        //关闭确认激活许可证弹窗
        closeConfirmActivateFrom(){
            this.serverActiva.activationListForm = false;
            this.serverActiva.showWaitForm = false;
            this.serverActiva.selectActivationForm = false;
            this.serverActiva.confirmActivationForm = false;
        },
        //点击确认激活许可证按钮 activationSiteUserLicense
        confirmSelectActivate(){
            // if(this.serverActiva.selectSiteUsersListData.length > this.serverActiva.selectLicense.quantity){
            //     this.$message.info("Insufficient License quantity");
            //     return false
            // }
            this.serverActiva.activationListForm = true;
            this.serverActiva.showWaitForm = true;
            this.serverActiva.selectActivationForm = false;
            this.serverActiva.confirmActivationForm = false;
            this.serverActiva.confirmActivationBtn = true;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let activateUserArr = [];
            for(let i=0;i<this.serverActiva.selectSiteUsersListData.length;i++){
                activateUserArr.push(this.serverActiva.selectSiteUsersListData[i].id);
            }
            let activateUserObj = {
                "siteLicenseId": this.serverActiva.licenseId,
                "userHouseIdList": activateUserArr,
            }
            // console.log("this.serverActiva.selectLicense",this.serverActiva.selectLicense)
            // console.log("activateUserObj",activateUserObj);
            // return false
            sitesUsersApi.activationSiteUserLicense(rgnObj,activateUserObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('ActivationSuccessful'));
                    this.serverActiva.activationListForm = false;
                    this.serverActiva.showWaitForm = false;
                    this.serverActiva.selectActivationForm = false;
                    this.serverActiva.confirmActivationForm = false;
                    //确定按钮禁用
                    this.serverActiva.confirmActivationBtn = false;
                    //刷新用户列表
                    this.serachSiteUsers();
                   
                }else{
                    this.$handerrcode(res.code);
                    this.serverActiva.activationListForm = true;
                    this.serverActiva.showWaitForm = false;
                    this.serverActiva.selectActivationForm = false;
                    this.serverActiva.confirmActivationForm = true;
                    //确定按钮取消禁用
                    this.serverActiva.confirmActivationBtn = false;
                }
            }).catch((err) => {
                console.log(err);
                this.serverActiva.activationListForm = true;
                this.serverActiva.showWaitForm = false;
                this.serverActiva.selectActivationForm = false;
                this.serverActiva.confirmActivationForm = true;
                //确定按钮取消禁用
                this.serverActiva.confirmActivationBtn = false;
            })
        },
        //添加新的user弹框
        //点击绑定图标执行函数
        bindingUserFrom(item){
            this.binding.id = item.id;
            this.binding.invitationEmail = "";
            this.binding.firstName = "";
            this.binding.surname = "";
            this.binding.apartmentId = item.apartmentId;
            this.binding.houseNo = item.houseNo;
            this.binding.userRole = item.userRole;
            this.binding.bindBtnState = 0;
            this.binding.bindUserFrom = true;
            this.binding.bindForm = true;
            this.binding.showWaitForm = false;
            this.binding.bindSuccessForm = false;
            //this.getUsersLicenseList();
        },
        //绑定按钮确认函数
        bindingNewSiteUser(){
            if((this.binding.invitationEmail).trim() === ""){
                this.$message.info(this.$t('UserEmailEmpty'));
                return false
            }
            let reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
            if(!reg.test(this.binding.invitationEmail)){
                this.$message.info(this.$t('EmailFormatLimit'));
                return false
            }
            if((this.binding.firstName).trim() === ""){
                this.$message.info(this.$t('FirstNameEmptys'));
                return false
            }
            let checkResultFirstName = this.$regularCheckLimit(this.binding.firstName,1,20,'FirstNameLimit');
            if(!checkResultFirstName){
                return false
            }
            if((this.binding.surname).trim() === ""){
                this.$message.info(this.$t('SurnameNameEmpty'));
                return false
            }
            let checkResultSurname = this.$regularCheckLimit(this.binding.surname,1,20,'SurnameLimit');
            if(!checkResultSurname){
                return false
            }
            if((this.binding.houseNo).trim() === ""){
                this.$message.info(this.$t('HouseNumberEmpty'));
                return false
            }
            let houseNumberRegex = /^[a-zA-Z0-9]+$/;
            if(!houseNumberRegex.test(this.binding.houseNo)){
                this.$message.info(this.$t('LimitHouseNumber'));
                return false
            }
            let checkResulNumberReg = /^\d+$/;
            if(checkResulNumberReg.test(this.binding.houseNo)){
                if(Number(this.binding.houseNo) <= 0){
                    this.$message.info(this.$t('LimitHouseNumber'));
                    return false
                }
            }
            let checkResultHouseNumber = this.$regularCheckLimit(this.binding.houseNo,1,20,'HouseNumberLimit');
            if(!checkResultHouseNumber){
                return false
            }
            if((this.binding.userRole).trim() === ""){
                this.$message.info(this.$t('UserRoleEmpty'));
                return false
            }
            if((this.binding.apartmentId).trim() === ""){
                this.$message.info(this.$t('SiteOrBlocksEmpty'));
                return false
            }
           
            this.binding.bindBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let bindUserObj = {
                "userHouseId":this.binding.id,
                "siteId":this.selectSite.id,
                "apartmentId":this.binding.apartmentId,
                "invitationEmail":this.binding.invitationEmail,
                "firstName": this.binding.firstName,
                "surname": this.binding.surname,
                "houseNo": this.binding.houseNo,
                "userRole": this.binding.userRole,
                "language": this.language,
            }
            // console.log("bindUserObj",bindUserObj);
            // return false
            sitesUsersApi.bindingSiteUser(rgnObj,bindUserObj).then(res => {
                if(res.code === 0){
                    this.binding.bindBtnState = 0;
                    this.$message.success(this.$t('BindingSuccessful'));
                    //获取Site user列表
                    // let serachSiteUserObj = {
                    //     "apartmentId":this.serach.apartmentId,
                    //     "username":this.serach.username,
                    //     "licenseStatus":this.serach.licenseStatus,
                    //     "pageNum":1
                    // };
                    // this.getSiteUsersList(serachSiteUserObj);
                    this.binding.siteName = this.selectSite.siteName;
                    this.binding.apartmentName = res.data.apartmentName;
                    this.binding.houseNo = res.data.houseNo;
                    this.binding.invitationCode = res.data.invitationCode;
                    this.binding.invitationQrCode = res.data.invitationQrcode;
                    this.$refs.bindQRcodeForApp.innerHTML = '';
                    //调用生成二维码函数
                    this.creatQrCode(this.$refs.bindQRcodeForApp,this.binding.invitationQrCode);
                    this.binding.bindUserFrom = true;
                    this.binding.bindForm = false;
                    this.binding.showWaitForm = false;
                    this.binding.bindSuccessForm = true;
                }else{
                    this.binding.bindBtnState = 0;
                    this.$handerrcode(res.code);
                    this.binding.bindUserFrom = true;
                    this.binding.bindForm = true;
                    this.binding.showWaitForm = false;
                    this.binding.bindSuccessForm = false;
                }
            }).catch((err) => {
                console.log(err);
                this.binding.bindBtnState = 0;
                this.binding.bindUserFrom = true;
                this.binding.bindForm = true;
                this.binding.showWaitForm = false;
                this.binding.bindSuccessForm = false;
            })
        },
        //绑定成功点击确认按钮
        bindingSucceSiteUser(){
            this.binding.bindUserFrom = false;
            this.binding.bindForm = false;
            this.binding.showWaitForm = false;
            this.binding.bindSuccessForm = false;
            //获取site user列表
            let serachSiteUserObj = {
                "apartmentId":"",
                "username":"",
                "licenseStatus":"",
                "pageNum":1
            }
            this.getSiteUsersList(serachSiteUserObj);
        },
        //点击解绑执行函数
        unbindingSiteUserFrom(item){
            this.unbinding.unbindUserFrom = true;
            this.unbinding.id = item.id;
        },
        //点击解绑确认按钮执行方法 
        unbindingSiteUser(){
            this.unbinding.unbindBtnState = 1;
            let unbindUserObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let unbindingSiteUserObj = {}
            unbindingSiteUserObj.siteId = this.selectSite.id;
            unbindingSiteUserObj.userHouseId = this.unbinding.id;
            // console.log("unbindingSiteUserObj",unbindingSiteUserObj);
            // return false
            sitesUsersApi.unbindingSiteUsers(unbindUserObj,unbindingSiteUserObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UnbindSuccessful'));
                    this.unbinding.unbindBtnState = 0;
                    this.unbinding.unbindUserFrom = false;
                    //获取site user列表
                    let serachSiteUserObj = {
                        "apartmentId":this.serach.apartmentId,
                        "username":this.serach.username,
                        "licenseStatus":this.serach.licenseStatus,
                        "pageNum":1
                    };
                    this.getSiteUsersList(serachSiteUserObj);
                }else{
                    this.unbinding.unbindBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                this.unbinding.unbindBtnState = 0;
                console.log(err)
            })
            
        },
        //打开删除弹窗
        openDelSiteUserFrom(item){
            this.del.delSiteUserFrom = true;
            this.del.userHouseId = item.id;
            return false
        },
         //点击确认删除框确认按钮执行函数
         delSiteFromBtn(){
            this.del.delBtnState = 1;
            this.delSiteUser({id:this.del.userHouseId});
        },
        //删除
        delSiteUser(item){
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delSiteUserObj = {}
            delSiteUserObj.siteId = this.selectSite.id;
            delSiteUserObj.userHouseId = item.id;
            sitesUsersApi.delSiteUser(rgnObj,delSiteUserObj).then(res => {
                if(res.code === 0){
                    this.del.delBtnState = 0;
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.del.delSiteUserFrom = false;
                    //获取site user列表
                    let serachSiteUserObj = {
                        "apartmentId":this.serach.apartmentId,
                        "username":this.serach.username,
                        "licenseStatus":this.serach.licenseStatus,
                        "pageNum":1
                    }
                    this.getSiteUsersList(serachSiteUserObj);
                }else{
                    this.del.delBtnState = 0;
                    this.$handerrcode(res.code);
                }
                
            }).catch((err) => {
                this.del.delBtnState = 0;
                console.log('err', err);
            })
        },
        //查看头像
        showBigAvatar(item){
            this.showAvatar.avatarBigFrom = true;
            this.showAvatar.avatarUrl = item.avatarUrl;
        },
        //打开头像审核弹窗
        openAvatarCheckFrom(item,status){
            this.avatarCheck.avatarCheckFrom = true;
            this.avatarCheck.userHouseId = item.id;
            this.avatarCheck.avatarCheckStatus = status;
            return false
        },
        //点击头像审核弹窗确认按钮执行函数
        avatarCheckSiteFromBtn(){
            this.avatarCheck.avatarCheckBtnState = 1;
            this.avatarCheckSiteUser({id:this.avatarCheck.userHouseId,avatarCheck:this.avatarCheck.avatarCheckStatus});
        },
        //头像审核
        avatarCheckSiteUser(item){
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const avatarCheckObj = {}
            avatarCheckObj.siteId = this.selectSite.id;
            avatarCheckObj.userHouseId = item.id;
            avatarCheckObj.avatarCheck = item.avatarCheck;
            sitesUsersApi.avatarCheck(rgnObj,avatarCheckObj).then(res => {
                if(res.code === 0){
                    this.avatarCheck.avatarCheckBtnState = 0;
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.avatarCheck.avatarCheckFrom = false;
                    //获取site user列表
                    let serachSiteUserObj = {
                        "apartmentId":this.serach.apartmentId,
                        "username":this.serach.username,
                        "licenseStatus":this.serach.licenseStatus,
                        "pageNum":1
                    }
                    this.getSiteUsersList(serachSiteUserObj);
                }else{
                    this.del.delBtnState = 0;
                    this.$handerrcode(res.code);
                }
                
            }).catch((err) => {
                this.del.delBtnState = 0;
                console.log('err', err);
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            let serachSiteUserObj = {
                "apartmentId":this.serach.apartmentId,
                "houseNo":this.serach.houseNo,
                "licenseStatus":this.serach.licenseStatus,
                "pageNum":val
            };
            this.getSiteUsersList(serachSiteUserObj);
        }
    },
    computed:{
        ...mapState(["selectCompany","selectSite","isEditPage","language"])
    },
    watch: {
         // 监听路由信息对象中的path属性
         "$route.path"(path) {
            // console.log("to,from",path);
            if(path === "/main/siteusers"){
                this.OPENINEDITPAGE({name:"isSiteUserEdit",value:false});
                //this.isPanelsEdit = false; // 当path发生变化的时候执行关联操作调用计算函数
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/siteusers',
                        name:this.$t('SiteUsers')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);
                 //获取panels列表
                let serachSiteUserObj = {
                    "apartmentId":this.serach.apartmentId,
                    "houseNo":this.serach.houseNo,
                    "deviceStatus":this.serach.deviceStatus,
                    "pageNum":1
                };
                this.getSiteUsersList(serachSiteUserObj);
            }else{
                //this.$route.path = "/main/blocks"
                // this.$router.push({name:"blocks"});
            }
        },
    }
}
</script>

<style scoped>
.siteUersBox{
    padding: 10px 30px;
}

@media screen and (max-width: 600px) {
    .siteUersBox{
    padding: 10px 0;
}
}
.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .serachBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding: 30px 10px; */
    }
}
/* 搜索栏样式 */
.serachInput{
    width: 12vw;
    min-width: 170px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff;
    border-radius: 4px;

}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}
::v-deep .serachInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: none;
}
.serachSel{
    width: 12vw;
    min-width: 170px;
    margin-right: 24px;
    border: 1px solid #979797;
    background: #fff;
    border-radius: 4px;
}
@media screen and (max-width: 600px) {
    .serachSel{
        width: 170px;
        margin-top: 10px;
        margin-right: 24px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}

::v-deep .serachSel .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input__inner:focus {
    border: none;
}
.serachItem{
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .serachBtn{
        margin-top: 10px;
        width: 170px;
    }
}
.serachLeft{
    margin-top: 0.5vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.serachRight{
    margin-top: 0.5vw;
}
@media screen and (max-width: 600px) {
    .serachLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5px;
    }
}
@media screen and (max-width: 600px) {
    .serachRight{
        margin-top: 10px;
        width: 170px;
    }
}
.el-divider--horizontal{
    height: 2px;
}
/* 数据为空提示页样式 */
.noPanels{
    color: #ACACAC;
    margin-top: 10vw;
}
/* 表格样式 */
::v-deep .el-table .el-table__cell{
    padding: 8px 0;
}
::v-deep .el-table{
    min-height: 510px !important;
}
.pageBox {
    /* position: fixed; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}

::v-deep .statusGreen{
    color: green;
}
::v-deep .statusOrange{
    color: orange;
}
::v-deep .statusRed{
    color: red;
}
::v-deep .statusBlue{
    color: blue;
}
/* 新增site user弹框样式 */
.addsiteUersBox{
    display: flex;
}
.addSiteUserLeft{
    width: 50%;
    padding-right: 1vw;
}
.NameBox{
    display: flex;
    justify-content: space-between;
}

.addSiteUserRight{
    width: 50%;
    font-size: 18px !important;
    margin-left: 2vw;
}
.addSiteUserRight p {
    font-size: 16px;
    color: #CFCFCF;
}
.addSiteUserRight p span{
    color: #6498b5;
}
::v-deep .addSiteUserRight .el-input__inner {
    height: 40px;
    line-height: 40px;
    width: 100%;
    border: none;
    background: #EFEFEF;
}


::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 1080px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        min-width: 300px;
        overflow: auto;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
    color: #333;
}
::v-deep .addSiteUserLeft .el-input__inner {
    height: 40px;
    line-height: 40px;
    width: 100%;
    border:none;
    background: #EFEFEF;
}
.addSiteUserLeft p {
    font-size: 16px;
    color: #CFCFCF;
}
.addSiteUserLeft p span{
    color: #6498b5;
}
.licenseBox{
    background: #EFEFEF;
    margin-top: 1vw;
    padding: 1vw 1vw;
    height: 105px;
    overflow-y: auto;
    border-radius: 10px;
}
.licenseItem{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.licenseItemLeft{
    width: 35%;
}
.licenseItemLeft .el-checkbox{
    margin-right: 6px;
}
.licenseItemRight{
    width: 65%;
}
::v-deep .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 1px solid #707070;
}

 
::v-deep .el-checkbox__inner::after {
    border: 3px solid #fff;
    border-left: 0;
    border-top: 0;
    left: 5px;
    top: 2px;
}

.el-checkbox__label {
    font-size: 16px;
}
 
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
        transform: rotate(40deg) scaleY(1.3);
 }

 ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #606266;
}
.addSiteUserLeft .el-select{
    width: 100%;
}
.addSiteUserRight .el-select{
    width: 100%;
}

.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 12px;
}
.el-form-item__label {
    color: #333;
}
::v-deep .el-dialog__footer {
    padding: 10px 20px 35px;
    text-align: center !important;
}
/* 等待框样式 */
.waitmentBox{
    display: flex;
    justify-content: center;
}
.waitmentBox img{
    margin-top: 5vw;
    width: 100px;
}
.waitText{
    margin-top: 4vw;
    margin-bottom: 8vw;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}

/* 添加user成功框样式 */
.addSuccessLeft{
    width: 50%;
    padding-right: 1vw;
}

.addSuccessRight{
    width: 50%;
    font-size: 18px !important;
    margin-left: 2vw;
    padding-top: 2vw;
    text-align: center;
}
.addSuccessRight div{
    font-size: 18px;
    color: #333;
    font-weight: bold;
}
.addSuccessQRBox{
    margin-top: 2vw;
    display: flex;
    justify-content: center;
}
.addSuccessQRCode{
    width: 185px;
    height: 185px;
}
.addSuccessItem{
    margin-top: 2vw;
}
.addSuccessItem div{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}
.addSuccessItem p{
    font-size: 16px;
    margin-top: 5px;
    padding-right: 2vw;
    word-break: keep-all;
    line-height: 1.5;
}
.addSuccessDiv{
    display: flex;
}
.addSuccessBtn{
    display: flex;
    justify-content: center;
    margin-top: 4vw;
}
/* service activation 弹框样式 */
.serachActiveBox{
    display: flex;
    justify-content: space-between;
}
.activeTableBox{
    margin-top: 1vw;
    /* height: 16vw; */
    overflow-y: auto;
    max-height: 16vw;
    margin-bottom: 1vw;
}
.activeTableBox .el-table{
    min-height: 300px !important;
} 
.confirmActivationTableBox .el-table{
    min-height: 300px !important;
} 
::v-deep .el-table-column--selection .cell {
    padding-left: 10px;
}
.selectNumBox{
    display: flex;
}
.selectNumBox div{
    margin-right: 5vw;
}
.selectLicenseBox{
    display: flex;
    margin-top: 1vw;
    flex-wrap: wrap;
    margin-bottom: 2vw;
}
.selectLicenseBox div{
    /* width: 300px; */
    margin-right: 1vw;
    margin-top: 10px;
}
::v-deep .el-radio__label {
    font-weight: bold;
}
::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #606266;
}
.activationBtn{
    display: flex;
    justify-content: center;
}
/* 确认激活弹窗 */
.confirmActivaTex{
    display: flex;
    align-items: center;
}
.confirmActivaTex div{
    margin-right: 1vw;
    font-weight: bold;
    font-size: 18px;
}

.confirmActivaTex div span{
    margin-left: 3vw;
}
.confirmActivaNumTex{
    margin-top: 1vw;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1vw;
}
.confirmActivationBtn{
    display: flex;
    justify-content: center;
    margin-top: 2vw;
}
/* 解绑用户 */
::v-deep .unbindingUserDiv .el-dialog{
    width: 650px;
}
.unbindingUserBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 50px;
}

.unbindingUserText{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2vw;
}
.unbindingUserBox ul li{
    font-size: 16px;
    word-break: keep-all;
    line-height: 1.5;
    margin-bottom: 1vw;
}

.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}
</style>