//导入封装好的新的axios
import request from '@/request/index'

//查询公司下site
export function getCompanySiteListNoPage(params) {
    return request({
        url: '/' + params.rgn + '/company/site/page',
        method: 'get',
        params: {
            companyId: params.companyId,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
        },
    })
}

//查询公司管理员列表
export function getCompanyAdminList(params) {
    return request({
        url: '/' + params.rgn + '/company/admin/page',
        method: 'get',
        params: {
            companyId: params.companyId,
            firstDataId: params.firstDataId,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
        }
    })
}

export default { getCompanySiteListNoPage, getCompanyAdminList }