<template>
    <div class="siteAccessLogBox">
        <div class="serachBox">
            <div class="serachLeft">
                <el-input v-model="serach.deviceName" class="serachInput" :placeholder="$t('Panel')"></el-input>
                <el-input v-model="serach.houseNo" class="serachInput" :placeholder="$t('ApartmentNumber')"></el-input>
                <!-- <el-select v-model="serach.houseNo" class="serachSel" placeholder="Apartment" clearable>
                    <el-option
                    v-for="item in serach.houseNoOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select> -->
                <el-select v-model="serach.unlockType" class="serachSel" :placeholder="$t('UnlockType')" clearable>
                    <el-option
                    v-for="item in serach.unlockTypeOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
                <div class="dataBox">
                    <div>
                        <el-date-picker
                        :placeholder="$t('StartTime')"
                        v-model="serach.startTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="dateDivider">_</div>
                <div class="dataBox">
                    <div>
                        <el-date-picker
                        :placeholder="$t('EndTime')"
                        v-model="serach.endTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <el-button type="primary" class="serachBtn" :loading="serach.serachBtnState === 1" @click="serachAccessLog">{{$t('Serach')}}</el-button>
            </div>
            <div class="serachRight">
                <el-button type="primary" class="serachBtn" @click="exportAccessLog">{{$t('Export')}}</el-button>
            </div>
        </div>
        <el-divider></el-divider>
        <div>
            <div class="noAccessLog" v-show="hasNoAccessLog">{{$t('NoData')}}</div>
            <template>
                <el-table
                v-show="!hasNoAccessLog"
                :data="accessLogList"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    prop="deviceName"
                    :label="$t('Panel')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="apartmentName"
                    :label="$t('Blocks') + '/'+ $t('Sites')"
                    width="150px"
                    >
                    <template slot-scope="scope">
                        {{scope.row.apartmentId === "0" ? $t('Sites') : scope.row.apartmentName}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="houseNo"
                    :label="$t('ApartmentNumber')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="userName"
                    :label="$t('UserName')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="unlockType"
                    :label="$t('UnlockType')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ getUnlockTypeStr(scope.row.unlockType)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    :label="$t('Status')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ getAccessStatusStr(scope.row.status)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="alarmTime"
                    :label="$t('Time')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ scope.row.alarmTime}}
                    </template>
                </el-table-column>
                
                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox">
                <el-pagination
                    v-show="!hasNoAccessLog"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="totalAccessLogs"
                >
                </el-pagination>
            </div>
        </div>
    </div>
    
    
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import sitesAccessLogApi from '@/api/sitepanellog/accesslog/index.js'
export default {
    name:"AccessLog",
    data(){
        return{
            serach:{
                deviceName:"",
                startTime:"",
                endTime:"",
                houseNo:'',
                unlockType:'',
                unlockTypeOptions:[
                    // {
                    //     id:0,
                    //     name:this.$t('Default')
                    // },
                    {
                        id:1,
                        name:this.$t('QRCodeUnlock')
                    },
                    {
                        id:2,
                        name:this.$t('PasswordUnlock')
                    },
                    // {
                    //     id:3,
                    //     name:this.$t('IndoorMachineUnlock')
                    // },
                    // {
                    //     id:4,
                    //     name:this.$t('AppUnlock')
                    // },
                    {
                        id:5,
                        name:this.$t('RfCard')
                    },
                    // {
                    //     id:6,
                    //     name:this.$t('ExitButton')
                    // },
                    {
                        id:7,
                        name:this.$t('DtmfUnlock')
                    },
                    // {
                    //     id:8,
                    //     name:this.$t('GoogleUnlock')
                    // },
                    // {
                    //     id:9,
                    //     name:this.$t('AlexaUnlock')
                    // },
                    {
                        id:10,
                        name:this.$t('FaceUnlock')
                    },
                ],
                serachBtnState:0,
            },
            hasNoAccessLog:false,
            totalAccessLogs:0,
            currentPage: 1,
            accessLogList:[],
          
        }
    },
    mounted(){
        this.getAccessLogList(1);
    },
    methods:{
        // ...mapMutations(["OPENINEDITPAGE"]),
        serachAccessLog(){
            this.getAccessLogList(1);
        },
        getAccessLogList(pageNum){
            if(this.serach.startTime == null){
                this.serach.startTime = "";
            }
            if(this.serach.endTime == null){
                this.serach.endTime = "";
            }

            if(this.serach.startTime != "" && this.serach.endTime === ""){
                this.$message.info(this.$t('EndTimeEmptye'));
                return false
            }
            if(this.serach.startTime === "" && this.serach.endTime != ""){
                this.$message.info(this.$t('StartTimeEmptye'));
                return false
            }
            if(this.serach.startTime != "" && this.serach.endTime != ""){
                if(this.serach.startTime >= this.serach.endTime){
                    this.$message.info(this.$t('StartTimeLaterEndTime'));
                    return false
                }
            }
            let accessLogObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "deviceName":this.serach.deviceName,
                "houseNo":this.serach.houseNo,
                "unlockType":this.serach.unlockType,
                "startTime": this.serach.startTime,
                "endTime": this.serach.endTime,
                "eventType": "63,71",
                "pageSize":10,
                "pageNum":pageNum,
            }
            sitesAccessLogApi.getAccessLogList(accessLogObj).then(res=>{
                if(res.code === 0){
                    this.accessLogList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.hasNoAccessLog = true;
                    }else{
                        this.hasNoAccessLog = false;
                        this.accessLogList = res.data.list;
                    }
                    this.currentPage = res.data.pageNum;
                    this.totalAccessLogs = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                console.log(err);
            })
        },
         //开锁状态
         getAccessStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('Success');
                    break;
                case 1:
                    getStr = this.$t('Failed');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
         //开锁类型
         getUnlockTypeStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('Default');
                    break;
                case 1:
                    getStr = this.$t('QRCodeUnlock');
                    break
                case 2:
                    getStr = this.$t('PasswordUnlock');
                    break
                case 3:
                    getStr = this.$t('IndoorMachineUnlock');
                    break
                case 4:
                    getStr = this.$t('AppUnlock');
                    break
                case 5:
                    getStr = this.$t('RfCard');
                    break
                case 6:
                    getStr = this.$t('ExitButton');
                    break
                case 7:
                    getStr = this.$t('DtmfUnlock');
                    break
                case 8:
                    getStr = this.$t('GoogleUnlock');
                    break
                case 9:
                    getStr = this.$t('AlexaUnlock');
                    break
                case 10:
                    getStr = this.$t('FaceUnlock');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        //导出
        exportAccessLog(){
            if(this.accessLogList.length === 0){
                this.$message.info(this.$t('NoExportData'));
                return false
            }
            let accessLogExportObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "deviceName":this.serach.deviceName,
                "houseNo":this.serach.houseNo,
                "unlockType":this.serach.unlockType,
                "startTime": this.serach.startTime,
                "endTime": this.serach.endTime,
                "eventType": "63,71",
            }
            sitesAccessLogApi.getAccessLogExport(accessLogExportObj).then(res=>{
                if(res.code === 0){
                   const hostUrl = sessionStorage.getItem('URL');
                   let path = res.data;
                   window.open((hostUrl + path),"_self");
                   this.$message.success(this.$t('ExportSuccess'));
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.getAccessLogList(val);
        }
    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    watch: {
      
    }
}
</script>

<style scoped>
.siteAccessLogBox{
   box-sizing: border-box;
   padding: 15px 30px;
}

.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .serachBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 10px;
    }
}

.serachLeft{
    margin-top: 0.5vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
    .serachLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }
    .serachLeft div{
        margin-top: 10px;
    }
}

.serachRight{
    margin-top: 0.5vw;
}

@media screen and (max-width: 600px) {
    .serachRight{
        margin-top: 10px;
        width: 170px;
    }
}

::v-deep .serachInput .el-input__inner {
    height: 42px!important;
    line-height: 42px!important;
    border: 1px solid #979797;
    background: #fff;
}
::v-deep .el-pagination .el-input__inner {
    background: #fff;
    border: 1px solid #dcdfe6;
}
::v-deep .el-date-editor .el-input__inner {
    height: 42px!important;
    line-height: 42px!important;
    border: 1px solid #979797;
    background: #fff;
}
.el-select .el-input.is-focus .el-input__inner {
    border: none;
}

.serachBtn{
    margin-left: 24px;
}
@media screen and (max-width: 600px) {
    .serachBtn{
        margin-left: 24px;
    }
}
.serachInput{
    width: 12vw;
    min-width: 170px;
    margin-right: 10px;
}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
    }
}
.dataBox{
    display: flex;
    align-items: center;
}
/* .dataBox div{
    margin-right: 10px;
} */
.dateDivider{
    /* margin: auto;
    padding-right: 13px; */
    line-height: 24px;
    padding: 0 6px;
}

.serachSel{
    width: 12vw;
    min-width: 170px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff;
    border-radius: 4px;
}
@media screen and (max-width: 600px) {
    .serachSel{
        width: 170px;
        margin-top: 10px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}

::v-deep .serachSel .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input__inner:focus {
    border: none;
}

/* ::v-deep .el-input__inner::placeholder {
  color:  #31E5F5;
} */
/* 谷歌 */
/* ::v-deep .el-input__inner::-webkit-input-placeholder {
  color:  #31E5F5;
} */
/* 火狐 */
/* ::v-deep .el-input__inner:-moz-placeholder {
  color:  #31E5F5;
} */
/*ie*/
/* ::v-deep .el-input__inner:-ms-input-placeholder {
  color:  #31E5F5;
} */

::v-deep .el-table{
    min-height: 510px !important;
}

.pageBox {
    /* position: fixed;
    bottom: 2vw; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed;
        bottom: 10px; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}

.noAccessLog{
    color: #ACACAC;
    margin-top: 10vw;
}

::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}
</style>