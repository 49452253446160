//导入封装好的新的axios
import request from '@/request/index'

//查询assess log列表
export function getOperationLogList(params) {
    return request({
        url: '/' + params.rgn + '/adminlog/page',
        method: 'get',
        params: {
            userName: params.userName,
            startTime: params.startTime,
            endTime: params.endTime,
            pageSize: params.pageSize,
            siteId: params.siteId,
            pageNum: params.pageNum,
        },
    })
}
export default { getOperationLogList }