//导入封装好的新的axios
import request from '@/request/index'

//获取Blocks列表，渲染site/Block下拉列表框
export function getSiteBlocksListForSelect(params) {
    return request({
        url: '/' + params.rgn + '/site/apartment/list',
        method: 'get',
        params: {
            siteId: params.siteId,
        },
    })
}

//查询panels
export function getSitePanelsList(params) {
    return request({
        url: '/' + params.rgn + '/site/device/page',
        method: 'get',
        params: {
            siteId: params.siteId,
            apartmentId: params.apartmentId,
            deviceName: params.deviceName,
            deviceStatus: params.deviceStatus,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
        },
    })
}
//添加panels
export function addSitePanels(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/add',
        method: 'post',
        data,
    })
}

//panels详情
export function detailSitePanels(params) {
    return request({
        url: '/' + params.rgn + '/site/device/detail',
        method: 'get',
        params: {
            entranceDeviceId: params.entranceDeviceId
        },
    })
}

//更新panels（基本信息）
export function updateSitePanels(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/update',
        method: 'post',
        data,
    })
}

//更新panels（权限信息）
export function updateSitePanelsPermission(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/updatePermission',
        method: 'post',
        data,
    })
}

//删除panels
export function delSitePanels(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/delete',
        method: 'post',
        data,
    })
}

//更新外部校验码
export function updatePanelsSecurityCode(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/updateSecurityCode',
        method: 'post',
        data,
    })
}

//查询panels 配置
export function getSitePanelsConfigList(params) {
    return request({
        url: '/' + params.rgn + '/site/device/findEntranceConfigPage',
        method: 'get',
        params: {
            siteId: params.siteId,
            entranceDeviceId: params.entranceDeviceId,
            type: params.type,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
        },
    })
}

//添加 panels  配置
export function addSitePanelsConfig(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/addEntranceConfig',
        method: 'post',
        data,
    })
}

//更新 panels  配置
export function updateSitePanelsConfig(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/updateEntranceConfig',
        method: 'post',
        data,
    })
}

//删除panels sip 配置
export function delSitePanelsSipConfig(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/deleteEntranceConfig',
        method: 'post',
        data,
    })
}

//查询panels Guard 配置
export function getSitePanelsGuardConfigList(params) {
    return request({
        url: '/' + params.rgn + '/site/device/findEntranceGuardPage',
        method: 'get',
        params: {
            siteId: params.siteId,
            entranceDeviceId: params.entranceDeviceId,
            type: params.type,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
        },
    })
}

//获取指定设备下 Blocks , user列表，渲染site/Block下拉列表框,Address下拉列表框
export function getSiteBlocksUserListForSelect(params) {
    return request({
        url: '/' + params.rgn + '/site/device/findEntranceGuardUser',
        method: 'get',
        params: {
            entranceDeviceId: params.entranceDeviceId,
        },
    })
}

//添加 panels Guard
export function addSitePanelsGuard(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/addEntranceGuard',
        method: 'post',
        data,
    })
}

//编辑 panels Guard
export function updateSitePanelsGuard(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/updateEntranceGuard',
        method: 'post',
        data,
    })
}
//删除panels Guard 配置
export function delSitePanelsGuardConfig(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/deleteEntranceGuard',
        method: 'post',
        data,
    })
}
//绑定panels
export function bindSitePanels(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/binding',
        method: 'post',
        data,
    })
}
//解绑panels
export function unbindSitePanels(params, data) {
    return request({
        url: '/' + params.rgn + '/site/device/unbinding',
        method: 'post',
        data,
    })
}
export default { getSiteBlocksListForSelect, getSitePanelsList, addSitePanels, detailSitePanels, updateSitePanels, updateSitePanelsPermission, delSitePanels, updatePanelsSecurityCode, getSitePanelsConfigList, addSitePanelsConfig, updateSitePanelsConfig, delSitePanelsSipConfig, getSitePanelsGuardConfigList, getSiteBlocksUserListForSelect, addSitePanelsGuard, updateSitePanelsGuard, delSitePanelsGuardConfig, bindSitePanels, unbindSitePanels }