<template>
    <div class="siteNoticeBox">
        <!--查看图片弹框-->
        <el-dialog
            :title="imgTitle"
            :visible.sync="showBigImgFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="60%"
            :show-close="showClose">
            <div>
                <img :src="bigImgUrl" width="96%" height="96%" style="margin: 1% 2%;" alt="">
            </div>
        </el-dialog>
        <div class="serachBox">
            <div class="serachLeft">
                <el-input v-model="serach.deviceName" class="serachInput" :placeholder="$t('Panel')"></el-input>
                <el-input v-model="serach.houseNo" class="serachInput" :placeholder="$t('ApartmentNumber')"></el-input>
                <!-- <el-select v-model="serach.houseNo" class="serachSel" placeholder="Apartment" clearable>
                    <el-option
                    v-for="item in serach.houseNoOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select> -->
                <div class="dataBox">
                    <div>
                        <el-date-picker
                        :placeholder="$t('StartTime')"
                        v-model="serach.startTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="dateDivider">_</div>
                <div class="dataBox">
                    <div>
                        <el-date-picker
                        :placeholder="$t('EndTime')"
                        v-model="serach.endTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <el-button type="primary" class="serachBtn" :loading="serach.serachBtnState === 1" @click="serachCallLog">{{$t('Serach')}}</el-button>
            </div>
            <div class="serachRight">
                <el-button type="primary" class="serachBtn" :loading="serach.exportBtnState === 1" @click="exportCallLog">{{$t('Export')}}</el-button>
            </div>
        </div>
        <el-divider></el-divider>
        <div>
            <div class="noCallLog" v-show="hasNoCallLog">{{$t('NoData')}}</div>
            <template>
                <el-table
                v-show="!hasNoCallLog"
                :data="callLogList"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    prop="deviceName"
                    :label="$t('Panel')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="apartmentName"
                    :label="$t('Blocks') + '/'+ $t('Sites')"
                    width="150px"
                    >
                    <template slot-scope="scope">
                        {{scope.row.apartmentId === "0" ? $t('Sites') : scope.row.apartmentName}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="houseNo"
                    :label="$t('ApartmentNumber')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="userName"
                    :label="$t('UserName')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="status"
                    :label="$t('Status')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ getCallStatusStr(scope.row.status)}}
                    </template>
                    </el-table-column>
                <el-table-column
                    prop="alarmTime"
                    :label="$t('Time')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ scope.row.alarmTime}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="imageUrl"
                    :label="$t('Image')"
                    >
                    <template slot-scope="scope">
                    <!-- <img :src="scope.row.imageUrl" @click="showBigImg(scope.row)"  min-width="40" height="25"/> -->
                    <!-- <el-image :src="scope.row.imageUrl" @click="showBigImg(scope.row)"  min-width="40" height="25">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image> -->
                        <el-image v-if="scope.row.imageUrl != null" :src="scope.row.imageUrl" @click="showBigImg(scope.row)" style="width: 60px; height:25px;">
                            <div slot="placeholder" class="image-slot" style="width: 100px; height:25px;">
                                {{ $t("Loading") }}<span class="dot">...</span>
                            </div>
                        </el-image>
                        <el-image v-else></el-image>
                    </template>
                </el-table-column>
                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox">
                <el-pagination
                    v-show="!hasNoCallLog"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="totalCallLogs"
                >
                </el-pagination>
            </div>
        </div>
    </div>
    
    
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import {nanoid} from 'nanoid'
import sitesCallLogApi from '@/api/sitepanellog/calllog/index.js'
export default {
    name:"CallLog",
    data(){
        return{
            serach:{
                deviceName:"",
                houseNo:'',
                startTime:"",
                endTime:"",
                serachBtnState:0,
                exportBtnState:0,
            },
            hasNoCallLog:false,
            showBigImgFrom:false,
            showClose:true,
            totalCallLogs:0,
            currentPage: 1,
            callLogList:[],
            imgTitle:"",
            bigImgUrl:"",
          
        }
    },
    mounted(){
        this.getCallLogList(1);
    },
    methods:{
        // ...mapMutations(["OPENINEDITPAGE"]),
        
        serachCallLog(){
            this.getCallLogList(1);
        },
        getCallLogList(pageNum){

            if(this.serach.startTime == null){
                this.serach.startTime = "";
            }
            if(this.serach.endTime == null){
                this.serach.endTime = "";
            }

            if(this.serach.startTime != "" && this.serach.endTime === ""){
                this.$message.info(this.$t('EndTimeEmptye'));
                return false
            }
            if(this.serach.startTime === "" && this.serach.endTime != ""){
                this.$message.info(this.$t('StartTimeEmptye'));
                return false
            }
            if(this.serach.startTime != "" && this.serach.endTime != ""){
                if(this.serach.startTime >= this.serach.endTime){
                    this.$message.info(this.$t('StartTimeLaterEndTime'));
                    return false
                }
            }
            let callLogObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "deviceName":this.serach.deviceName,
                "houseNo":this.serach.houseNo,
                "startTime": this.serach.startTime,
                "endTime": this.serach.endTime,
                "eventType": "19",
                "pageSize":10,
                "pageNum":pageNum,
            }
            this.serach.serachBtnState = 1;
            sitesCallLogApi.getCallLogList(callLogObj).then(res=>{
                if(res.code === 0){
                    this.serach.serachBtnState = 0;
                    this.callLogList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.hasNoCallLog = true;
                    }else{
                        this.hasNoCallLog = false;
                        this.callLogList = res.data.list;
                    }
                    this.currentPage = res.data.pageNum;
                    this.totalCallLogs = res.data.total;
                }else{
                    this.serach.serachBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                this.serach.serachBtnState = 0;
                console.log(err);
            })
        },
        //呼叫状态
        getCallStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('CallHasBeenAnswered');
                    break;
                case 1:
                    getStr = this.$t('CallNotAnswered');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        //导出
        exportCallLog(){
            if(this.callLogList.length === 0){
                this.$message.info(this.$t('NoExportData'));
                return false
            }
            this.serach.exportBtnState = 1;
            //return false
            let callLogExportObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "deviceName":this.serach.deviceName,
                "houseNo":this.serach.houseNo,
                "startTime": this.serach.startTime,
                "endTime": this.serach.endTime,
                "eventType": "19",
            }
            sitesCallLogApi.getCallLogExport(callLogExportObj).then(res=>{
                this.serach.exportBtnState = 0;
                if(res.code === 0){
                    let path = res.data;
                    const hostUrl = sessionStorage.getItem('URL');
                    window.open((hostUrl + path),"_self");
                    this.$message.success(this.$t('ExportSuccess'));
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                this.serach.exportBtnState = 0;
                console.log(err);
            })

        },
        //showBigImg(item,a){
        showBigImg(item){
            //console.log("item",item,a);
            // if(a){
            //     console.log("图片加载失败")
            // }
            this.showBigImgFrom = true;
            this.imgTitle = this.utctolocal(item.alarmTime);
            this.bigImgUrl = item.imageUrl;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.getCallLogList(val);
        }
    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    watch: {
      
    }
}
</script>

<style scoped>
.siteNoticeBox{
   box-sizing: border-box;
   padding: 15px 30px;
}

.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .serachBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 10px;
    }
}

.serachLeft{
    margin-top: 0.5vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
    .serachLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }
    .serachLeft div{
        margin-top: 10px;
    }
}

.serachRight{
    margin-top: 0.5vw;
}

@media screen and (max-width: 600px) {
    .serachRight{
        margin-top: 10px;
        width: 170px;
    }
}

::v-deep .serachInput .el-input__inner {
    height: 42px!important;
    line-height: 42px!important;
    border: 1px solid #979797;
    background: #fff;
}
::v-deep .el-pagination .el-input__inner {
    background: #fff;
    border: 1px solid #dcdfe6;
}
::v-deep .el-date-editor .el-input__inner {
    height: 42px!important;
    line-height: 42px!important;
    border: 1px solid #979797;
    background: #fff;
}
.el-select .el-input.is-focus .el-input__inner {
    border: none;
}

.serachBtn{
    margin-left: 24px;
}
@media screen and (max-width: 600px) {
    .serachBtn{
        margin-left: 24px;
    }
}
.serachInput{
    width: 12vw;
    min-width: 170px;
    margin-right: 10px;
}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
    }
}
.dataBox{
    display: flex;
    align-items: center;
}
/* .dataBox div{
    margin-right: 10px;
} */
.dateDivider{
    /* margin: auto;
    padding-right: 20px; */
    line-height: 24px;
    padding: 0 6px;
}

.serachSel{
    width: 12vw;
    min-width: 170px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff;
    border-radius: 4px;
}
@media screen and (max-width: 600px) {
    .serachSel{
        width: 170px;
        margin-top: 10px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}

::v-deep .serachSel .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input__inner:focus {
    border: none;
}

/* ::v-deep .el-input__inner::placeholder {
  color:  #31E5F5;
} */
/* 谷歌 */
/* ::v-deep .el-input__inner::-webkit-input-placeholder {
  color:  #31E5F5;
} */
/* 火狐 */
/* ::v-deep .el-input__inner:-moz-placeholder {
  color:  #31E5F5;
} */
/*ie*/
/* ::v-deep .el-input__inner:-ms-input-placeholder {
  color:  #31E5F5;
} */
::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 1080px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        min-width: 300px;
        overflow: auto;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}

::v-deep .el-table{
    min-height: 510px !important;
}

.pageBox {
    /* position: fixed;
    bottom: 2vw; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed;
        bottom: 10px; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}

.noCallLog{
    color: #ACACAC;
    margin-top: 10vw;
}

::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}
</style>