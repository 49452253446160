<template>
    <div class="siteManageBox">
         <!--新增Site弹框-->
         <el-dialog :title="$t('Add')"
         :close-on-click-modal="false" 
         :close-on-press-escape="false" 
         :visible.sync="addSiteFrom" center 
         :show-close="showClose">
            <el-form :model="addSite">
                <el-form-item :label="$t('SiteName')">
                    <el-input v-model="addSite.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('SiteAddress')">
                    <el-input v-model="addSite.address"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancalAddSite">{{$t('Cancel')}}</el-button>
                <el-button type="primary" :loading="btnState === 1" @click="addNewSite">{{$t('Confirm')}}</el-button>
            </div>
        </el-dialog>
         <!--编辑Site弹框-->
         <el-dialog :title="$t('Edit')"
         :close-on-click-modal="false" 
         :close-on-press-escape="false" 
         :visible.sync="editSite.editSiteFrom" center 
         :show-close="showClose">
            <el-form :model="editSite">
                <el-form-item :label="$t('SiteName')">
                    <el-input v-model="editSite.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('SiteAddress')">
                    <el-input v-model="editSite.address"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editSite.editSiteFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" :loading="editSite.editBtnState === 1" :disabled="editSite.saveBtn" @click="editSiteComfirm">{{$t('Confirm')}}</el-button>
            </div>
        </el-dialog>
         <!--删除Site弹框-->
         <el-dialog
            :title="$t('Delete')"
            :visible.sync="delSite.delSiteFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="30%"
            :show-close="showClose">
            <p class="deleteDesc">{{$t('DeleteSiteTitle')}}</p>
            <el-form>
                <el-form-item :label="$t('SiteName')">
                    <el-input v-model="delSite.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="delSite.delSiteFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" :loading="delSite.delSiteBtn === 1" @click="deliteFrom">{{$t('Confirm')}}</el-button>
            </div>
        </el-dialog>
        <div class="noPermission" v-show="noPermission">{{$t('NoData')}}</div>
        <div style="min-height: 628px;">
            <div class="siteItem" v-show="!noPermission">
                <div class="sitebox" v-show="showCreateSite" @click="showAddSiteFrom">
                    <!-- <div class="siteImgBox"> -->
                        <el-image
                            class="siteImg"
                            :src="srcAdd"
                            fit="contain">
                        </el-image>
                    <!-- </div> -->
                    <div class="siteTitle">
                        <h5>{{$t('CreateSites')}}</h5>
                    </div>
                </div>
                <div class="sitebox" v-for="(item,index) in siteArr" 
                :key="item.id" 
                @click="manageSite(item,index)"
                :class="{
                        siteboxbg1:(index%6 ===1),
                        siteboxbg2:(index%6 ===2),
                        siteboxbg3:(index%6 ===3),
                        siteboxbg4:(index%6 ===4),
                        siteboxbg5:(index%6 ===5),
                        siteboxbg6:(index%6 ===0),
                    }">
                    <!-- <div class="siteImgBox"> -->
                        <el-image
                            class="siteImg"
                            :src="srcSite"
                            fit="contain">
                        </el-image>
                    <!-- </div> -->
                    <div class="SiteName">{{item.siteName }} <img class="editImg" v-show="selectCompany.role ==='admin' || selectCompany.role ==='site_admin'" @click.stop="handEditImg(item)" :src="editImg" alt=""></div>
                    <div class="SiteAddress">
                        <h5>{{item.siteAddress}}</h5>
                    </div>
                    <div class="delDiv" v-show="selectCompany.role ==='admin'">
                        <img class="delImg" @click.stop="handDeleteImg(item)" :src="deleteImg" alt="" />
                    </div>
                    
                </div>
                <!-- <div class="sitebox">
                    <div class="siteImgBox">
                        <el-image
                            style="width: 64px;margin-top:70px;"
                            :src="srcSite"
                            fit="contain">
                        </el-image>
                        <div class="SiteName">Site 1</div>
                    </div>
                    <h5 class="siteTitle">Create New Sites</h5>
                </div> -->
            </div>
        </div>
        
        <div class="pageBox" v-show="!noPermission">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="9"
            layout="prev, pager, next, jumper"
            :total="totalSite">
            </el-pagination>
        </div>
       
    </div>
  
</template>

<script>
import editImg from '@/assets/images/edit_d.png'
import deleteImg from '@/assets/images/delete_d.png'
import sitesManageApi from '@/api/sitesmanage/index.js'
import {nanoid} from 'nanoid'
import { mapState, mapMutations } from 'vuex';
import add from '@/assets/images/add.png'
import site from '@/assets/images/site.png'
export default {
    name:"SiteManage",
    mounted() {
        if(this.$store.state.selectCompany.role != "admin"){
            let serachComObj = {
                    "pageNum":1
                };
            this.getCompanySiteList(serachComObj);
            this.showCreateSite = false;
            let siteMangeBread = [
                {
                    id:nanoid(),
                    // path:'/main/companydashboard',
                    name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                },
                {
                    id:nanoid(),
                    path:'/main/sitesmanage',
                    name:this.$t('Sites')
                }
            ];
            this.$generateBreadcrumb(siteMangeBread);
            
        }else{
            this.showCreateSite = true;
            let siteMangeBread = [
                {
                    id:nanoid(),
                    path:'/main/companydashboard',
                    name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                },
                {
                    id:nanoid(),
                    path:'/main/sitesmanage',
                    name:this.$t('Sites')
                }
            ];
            this.$generateBreadcrumb(siteMangeBread);
        }
        let serachComObj = {
                "pageNum":1
            };
        this.getCompanySiteList(serachComObj);
        this.getCompanySiteListNoPage();
        // console.log("this.$route",this.$route);
        //判断是否为仪表盘打开新增窗口
        if(this.$route.params.createSite){
            this.addSiteFrom = true;
        }else{
            this.addSiteFrom = false;
        }
    },
    data(){
        return{
            currentPage: 1,
            totalSite:0,
            srcAdd:add,
            srcSite:site,
            addSiteFrom:false,
            showClose:true,
            showCreateSite:this.$store.state.selectCompany.role === 'admin',
            noPermission: false,
            btnState: 0,
            addSite:{
                name:"",
                address:""
            },
            editSite:{
                editSiteFrom:false,
                id:"",
                name:"",
                newName:"",
                address:"",
                newAddress:"",
                saveBtn:true,
                editBtnState:0
            },
            delSite:{
                delSiteFrom:false,
                id:"",
                name:"",
                delSiteBtn:0
            },
            siteArr:[],
            siteNoPageArr:[],
            editImg,
            deleteImg,
        }
    },
    methods:{
        ...mapMutations(["GETSITELIST","GETSELECTSITE"]),
        //获取公司下site列表
        getCompanySiteList(serachComObj){
            let conpanyInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "pageSize":9,
                "pageNum":serachComObj.pageNum
            }
            this.siteArr = [];
            this.noPermission = false;
            sitesManageApi.getCompanySiteList(conpanyInfoObj).then(res => {
                if(res.code === 0){
                    if(res.data.length === 0){
                        this.siteArr = [];
                        if(!this.showCreateSite){
                            this.noPermission = true;
                        }
                    }else{
                        this.noPermission = false;
                        //this.siteArr = res.data.list;
                        this.siteArr = [];
                        res.data.list.map(item => {
                            item.type = "site";
                            this.siteArr.push(item)
                        })
                        //site列表存到vuex中
                        this.GETSITELIST(this.siteArr);
                        //默认选择第一个
                        this.GETSELECTSITE(this.siteArr[0])
                        this.totalSite = res.data.total;
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
         //获取公司下site列表不分页 渲染site选择框
         getCompanySiteListNoPage(){
            let conpanyInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
            }
            sitesManageApi.getCompanySiteListNoPage(conpanyInfoObj).then(res => {
                if(res.code === 0){
                    if(res.data.length === 0){
                        this.siteNoPageArr = [];
                        
                    }else{
                        
                        this.siteNoPageArr = [];
                        res.data.list.map(item => {
                            item.type = "site";
                            this.siteNoPageArr.push(item)
                        })
                        //site列表存到vuex中
                        this.GETSITELIST(this.siteNoPageArr);
                        //默认选择第一个
                        this.GETSELECTSITE(this.siteNoPageArr[0])
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            let serachComObj = {
                "pageNum":val
            };
            this.getCompanySiteList(serachComObj);
        },
        //点击添加site执行
        showAddSiteFrom(){
            this.addSiteFrom = true;
            this.addSite.name = '';
            this.addSite.address = '';
        },
        //关闭新建site窗口
        cancalAddSite(){
            this.addSiteFrom = false;
        },
        //确定新增site执行方法
        addNewSite(){
            //this.addSiteFrom = false;
            if((this.addSite.name).trim() === ""){
                this.$message.info(this.$t('SiteNameEmpty'));
                return false
            }
            let reg = /^(.{1,30})$/;
            if(!reg.test(this.addSite.name)){
                this.$message.info(this.$t('SiteNameLimit'));
                return false
            }
            // return false
            if((this.addSite.address).trim() === ""){
                this.$message.info(this.$t('SiteAddressEmpty'));
                return false
            }
            this.btnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addSiteObj = {
                "companyId":this.selectCompany.id,
                "siteName":this.addSite.name,
                "siteAddress": this.addSite.address
            }
            sitesManageApi.addNewSite(rgnObj,addSiteObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('CreatedSuccessfully'));
                    this.btnState = 0;
                    this.addSiteFrom = false;
                    let serachComObj = {
                            "pageNum":1
                        };
                    this.getCompanySiteList(serachComObj);
                }else{
                    this.$handerrcode(res.code);
                    this.btnState = 0;
                    this.addSiteFrom = false;
                }
            }).catch((err) => {
                console.log(err);
                this.btnState = 0;
                this.addSiteFrom = false;
            })
            
        },
        //点击site执行
        manageSite(item,index){
            // console.log("item",item);
            if(item.role === null || item.role === ""){
                this.$message.info(this.$t('NoOperationPermission'));
                return false
            }else if(item.role === "user"){
                this.$store.commit("CHANGEMODEL","site");
                this.GETSELECTSITE(item);
                this.$router.push({name:"profile"});
            }else{
                this.$store.commit("CHANGEMODEL","site");
                this.GETSELECTSITE(item);
                this.$router.push({name:"sitedashboard"});
            }
           
            //this.$store.commit("GETSELECTSITE",item);
           
        },
        //点击编辑图标执行函数
        handEditImg(item){
            this.editSite.editSiteFrom = true;
            this.editSite.id = item.id;
            this.editSite.name = item.siteName;
            this.editSite.newName = item.siteName;
            this.editSite.address = item.siteAddress;
            this.editSite.newAddress = item.siteAddress;
        },
        //编辑site确定执行函数
        editSiteComfirm(){
            if((this.editSite.name).trim() === ""){
                this.$message.info(this.$t('SiteNameEmpty'));
                return false
            }
            let reg = /^(.{1,30})$/;
            if(!reg.test(this.editSite.name)){
                this.$message.info(this.$t('SiteNameLimit'));
                return false
            }
            if((this.editSite.address).trim() === ""){
                this.$message.info(this.$t('SiteAddressEmpty'));
                return false
            }
            this.editSite.editBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let editSiteObj = {
                "companyId":this.selectCompany.id,
                "siteId":this.editSite.id,
                "siteName":this.editSite.name,
                "siteAddress":this.editSite.address,
            }
            sitesManageApi.editCompanySite(rgnObj,editSiteObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.editSite.editBtnState = 0;
                    this.editSite.editSiteFrom = false;
                    let serachComObj = {
                            "pageNum":1
                        };
                    this.getCompanySiteList(serachComObj);
                }else{
                    this.editSite.editBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.editSite.editBtnState = 0;
                this.editSite.editSiteFrom = false;
            })
        },
         //点击删除图标执行函数
         handDeleteImg(item){
            // console.log("item",item);
            this.delSite.delSiteFrom = true;
            this.delSite.id = item.id;
            this.delSite.name = "";
        },
        //删除点击确定执行函数
        deliteFrom(){
            if((this.delSite.name).trim() === ""){
                this.$message.info(this.$t('SiteNameEmpty'));
                return false
            }
            let checkResultSiteName = this.$regularCheckLimit(this.delSite.name,1,30,'SiteNameLimit');
            if(!checkResultSiteName){
                return false
            }
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let delSiteObj = {
                "companyId":this.selectCompany.id,
                "siteId":this.delSite.id,
                "siteName":this.delSite.name,
            };
            // console.log("delSiteObj",delSiteObj);
            // return false
            this.delSite.delSiteBtn = 1;
            sitesManageApi.deleteCompanySite(rgnObj,delSiteObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.delSite.delSiteFrom = false
                    this.delSite.delSiteBtn = 0;
                    //获取site列表
                    let serachComObj = {
                            "pageNum":1
                        };
                    this.getCompanySiteList(serachComObj);
                }else{
                    this.$handerrcode(res.code);
                    this.delSite.delSiteBtn = 0;
                }
                
            }).catch((err) => {
                console.log('err', err);
                this.delSite.delSiteBtn = 0;
            })
        },
       
    },
    computed:{
        ...mapState(["selectCompany","companyList"])
    },
    watch:{
         // 监听路由信息对象中的path属性
        // "$route.path"() {
        //     this.generateBreadcrumb(); // 当path发生变化的时候执行关联操作调用计算函数
        // },
        //监听所选公司id变化，解决多个用户对多个公司角色相同时，路由不变数据不刷新问题
        "selectCompany.id"(){
            let serachComObj = {
                "pageNum":1
            };
            this.getCompanySiteList(serachComObj);
        },
        "selectCompany.role"(){
            if(this.$store.state.selectCompany.role != "admin"){
                let serachComObj = {
                        "pageNum":1
                    };
                this.getCompanySiteList(serachComObj);
                this.showCreateSite = false;
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        // path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitesmanage',
                        name:this.$t('Sites')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);
            }else{
                this.showCreateSite = true;
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitesmanage',
                        name:this.$t('Sites')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);
            }
        },
        'editSite.name'(){
            if(this.editSite.name != this.editSite.newName || this.editSite.address != this.editSite.newAddress){
                this.editSite.saveBtn = false;
            }else{
                this.editSite.saveBtn = true;
            }
            
        },
        'editSite.address'(){
            if(this.editSite.name != this.editSite.newName || this.editSite.address != this.editSite.newAddress){
                this.editSite.saveBtn = false;
            }else{
                this.editSite.saveBtn = true;
            }
            
        },
    },

}
</script>

<style scoped>
.siteManageBox{
    /* width: 80%; */
    /* height: 100%; */
    /* border: 1px solid royalblue; */
    box-sizing: border-box;
    /* margin-left: 10%; */
    padding: 20px 30px;
}
.noPermission{
    color: #ACACAC;
    margin-top: 10vw;
}
.siteItem{
    /* width: 98vw;
    max-width: 1200px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-flow: wrap;
    /* margin-left: 32px; */
}
@media screen and (max-width: 700px) {
    .siteItem{
        margin-left: 10px;
        justify-content: center;
    }
}
.sitebox{
    background-color: #6498b5;
    width: 12vw;
    height: 12vw;
    min-width: 150px;
    min-height: 150px;
    margin: 1vw;
    /* padding-bottom: 10px; */
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.siteboxbg1{
    background-color: #FEE3E9;
}
.siteboxbg2{
    background-color: #E4E9FE;
}
.siteboxbg3{
    background-color: #FEEED6;
}
.siteboxbg4{
    background-color: #EAEAEA;
}
.siteboxbg5{
    background-color: #FFE8E0;
}
.siteboxbg6{
    background-color: #F5F5F5;
}
.siteImgBox {
    width: 13vw;
    /* max-width: 180px; */
    min-width: 150px;
    height: 8vw;
    /* max-height: 231px; */
    min-height: 100px;
    /* background: #ddd; */
    /* border-radius: 4px; */
}
.siteImg{
    width: 3.5vw;
    margin:5px;
    min-width: 50px;
}
@media screen and (max-width: 700px) {
    .siteImg{
        width: 3.5vw;
        /* margin-top:10px; */
        min-width: 50px;
    }
}
.siteTitle {
    /* width: 28vw; */
    /* max-width: 180px;
    min-width: 100px; */
    text-align: center;
    margin-top: 40px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    color: #fff;
    font-size: 16px;
}
@media screen and (max-width: 700px) {
    .siteTitle{
        margin-top:10px;
        min-width: 100px;
    }
}
.SiteName{
    color: #85898D;
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
}
@media screen and (max-width: 700px) {
    .SiteName{
        margin-top:10px;
        min-width: 100px;
    }
}
.editImg{
    width: 16px;
    vertical-align: text-bottom;
    margin-left: 5px;
}
.SiteAddress{
    color: #85898D;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
    height: 2vw;
}
@media screen and (max-width: 700px) {
    .SiteAddress{
        margin-top:10px;
        min-width: 100px;
    }
}

.delDiv{
    margin-top: 15px;
    margin-bottom: 5px;
    border-top: 2px solid #fff;
    width: 100%;
}
@media screen and (max-width: 700px) {
    .delDiv{
        margin-top:10px;
        min-width: 100px;
    }
}
.delImg{
    width: 24px;
    margin-top: 10px;
}
/* .siteText {
    width: 28vw;
    max-width: 180px;
    min-width: 100px;
    text-align: center;
    font-size: 14px;
    color: #999999;
    padding-bottom: 6px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
} */

::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 635px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        width: 300px;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
}
.el-form-item {
    margin-top: 20px;
}
::v-deep .el-input {
    font-size: 16px;
    margin-top: 10px;
}
::v-deep .el-input__inner {
    height: 50px;
    line-height: 50px;
    width: 100%;
    border:none;
    background: #EFEFEF;
}
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 60px;
}
::v-deep .el-dialog__footer {
    padding: 10px 20px 35px;
    text-align: center !important;
}

::v-deep .el-pagination__jump {
    margin-top: -10px !important;
}
.pageBox{
    /* position: fixed; */
    margin-top: 20px;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}
.dialog-footer{
    text-align: center;
}
::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}
</style>