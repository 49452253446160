<template>
    <div class="login">
         <!--跳转登录界面提示弹框-->
         <el-dialog :title="$t('Prompt')" class="goLoginDiv" 
            :close-on-click-modal="false" 
            :close-on-press-escape="false" 
            :visible.sync="goLogin.showLoginFrom" 
            center 
            :show-close="goLogin.showClose"
            >
            <div class="goLoginBox">
                
                <p class="goLoginDesc">{{ goLogin.goLoginType === 1 ? ($t('RegisteredSuccess')+", "+$t('AutoJumpLogin').replace('0',goLogin.closeTime)) : ($t('PasswordChangedSuccess')+", "+$t('AutoJumpLogin').replace('0',goLogin.closeTime))}}</p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="goLogin.btnState === 1" @click="goLoginNow">{{$t('Confirm')}}</el-button>
            </div>
            
        </el-dialog>
        <!-- <div class="loginDiv"> -->
        <div class="loginBox">
            <div class="LoginSign">
                <div class="loginBtnDiv" :class="{colorSel:selMode === '1'}" @click="changeSelModel('1')">{{$t('Login')}}</div>
                <el-divider direction="vertical"></el-divider>
                <div class="signBtnDiv" :class="{colorSel:selMode === '2'}" @click="changeSelModel('2')">{{$t('SignUp')}}</div>
            </div>
            <div style="width: 500px;" v-show="selMode === '1'">
                <div class="titleBox">{{ $t('Welcome') }}</div>
                <el-form class="loginFromBox" :model="ruleLogin"  ref="ruleLogin">
                    <!-- <el-form-item :label="$t('Language')" class="loginItem">
                        <el-select v-model="lang" style="width:150px;float: left;" @change="selectLanguage" placeholder="请选择">
                            <el-option
                            v-for="item in langOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        
                    </el-form-item> -->
                    <el-form-item>
                        <el-select v-model="lang" class="loginInp" @change="selectLanguage" :placeholder="$t('PleaseSelect')">
                            <el-option
                            v-for="item in langOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        
                    </el-form-item>
                    <el-form-item prop="account">
                        <el-input v-model="ruleLogin.account" :placeholder="$t('Email')" class="loginInp" @keyup.enter.native="submitLogin('ruleLogin')"></el-input>
                    </el-form-item>
                    <el-form-item prop="passWord">
                        <el-input :type="ruleLogin.passwordFlage" v-model="ruleLogin.passWord" :placeholder="$t('PassWord')" class="loginInp" @keyup.enter.native="submitLogin('ruleLogin')">
                            <i slot="suffix" class="el-input__icon" @click="changeLoginPasswordShow">
                                <img style="width: 20px;margin-top: 10px; margin-right: 10px;" :src="ruleLogin.passwordImg" alt="">
                            </i>
                        </el-input>
                    </el-form-item>
                    <div class="registerbox">
                        <!-- <a @click="goRegister">{{$t('NoAccount')}} &nbsp;></a>  -->
                        <a @click="changeSelModel('3')">{{$t('ForgotPasswrod')}}</a>
                    </div>
                    <div class="loginBtnBox">
                        <el-button type="primary" class="loginBtn" :loading="loginState === 1" @click="submitLogin('ruleLogin')" round>{{ $t('Login') }}</el-button>
                    </div>
                    <!-- <div class="registerbox">
                        <a @click="goRegister">{{$t('NoAccount')}} &nbsp;></a>
                    </div> -->
                </el-form>                    
            </div>
            <div class="registerContentBox" v-show="selMode === '2'">
                <!-- <div  class="registerTex">
                    <p>{{$t('ScanQRCodeDownloadAPP')}}</p>
                </div>
                <div class="registerQRCode" ref="QRcodeForApp">

                </div> -->
                <div class="titleBox">{{ $t('SignUp') }}</div>
                <el-form class="loginFromBox" :model="register" :rules="rules" ref="registerRuleForm">
                    
                    <el-form-item prop="email">
                        <el-input v-model="register.email" :placeholder="$t('Email')" class="loginInp"></el-input>
                    </el-form-item>
                    <el-form-item prop="passWord">
                        <el-input :type="register.passwordFlage" v-model="register.passWord" :placeholder="$t('PassWord')" class="loginInp">
                            <i slot="suffix" class="el-input__icon" @click="changePasswordShow">
                                <img style="width: 20px;margin-top: 10px; margin-right: 10px;" :src="register.passwordImg" alt="">
                            </i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="confirmpassWord">
                        <el-input :type="register.confirmPasswordFlage" v-model="register.confirmpassWord" :placeholder="$t('ConfirmPassword')" class="loginInp">
                            <i slot="suffix" class="el-input__icon" @click="changeConfirmPasswordShow">
                                <img style="width: 20px;margin-top: 10px; margin-right: 10px;" :src="register.confirmPasswordImg" alt="">
                            </i>
                        </el-input>
                    </el-form-item>
                    <el-form-item class="loginInp" prop="verificationCode">
                        <div style="display: flex;">
                            <div>
                                <el-input v-model="register.verificationCode" :placeholder="$t('VerificationCode')" class="verificationInp" ></el-input>
                            </div>
                            <div>
                                <el-button class="verificationBtn" @click="sendVerification('registerRuleForm')" v-if="register.sendVerificationCode">{{$t('SendVerificationCode')}}</el-button>
                                <el-button class="verificationTimeBtn" v-if="!register.sendVerificationCode" disabled>{{$t('ResendSeconds').replace('0',register.time)}}</el-button>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>  
                <p class="passwordRules">{{ $t('PasswordFormatLimit') }}</p>    
                <div class="registerBtnBox">
                    <el-button type="primary" class="loginBtn" :loading="register.registerState === 1" @click="registerAccount('registerRuleForm')" round>{{ $t('SignUp') }}</el-button>
                </div>
            </div>
            <div class="registerContentBox" v-show="selMode === '3'">
                <div class="titleBox">{{$t('ChangePassword')}}</div>
                <!-- <div class="registerQRCode" ref="QRcodeForApp">

                </div> -->
                <div v-show="rePassword.step === 1">
                    <el-form class="loginFromBox" style="top: 200px;" :model="rePassword" :rules="rules" ref="rePasswordRuleForm">
                        <el-form-item prop="email">
                            <el-input v-model="rePassword.email" :placeholder="$t('Email')" class="loginInp"></el-input>
                        </el-form-item>
                    </el-form>   
                    <div class="registerBtnBox" style="margin-top: 300px;">
                        <el-button type="primary" class="loginBtn" :loading="rePassword.registerState === 1" @click="rePasswordStep('rePasswordRuleForm')" round>{{$t('NextStep')}}</el-button>
                    </div>
                </div>

                <div v-show="rePassword.step === 2" style="display: flex; flex-direction: column; align-items: center;">
                    <el-form class="loginFromBox" style="top: 130px;" :model="rePassword" :rules="rules" ref="rePasswordConfirmRuleForm">
                        <el-form-item class="loginInp" prop="verificationCode">
                            <div style="display: flex;">
                                <div>
                                    <el-input v-model="rePassword.verificationCode" :placeholder="$t('VerificationCode')" class="verificationInp" ></el-input>
                                </div>
                                <div>
                                    <el-button class="verificationBtn" @click="sendVerification_rePassword" v-if="rePassword.sendVerificationCode">{{$t('SendVerificationCode')}}</el-button>
                                    <el-button class="verificationTimeBtn" v-if="!rePassword.sendVerificationCode" disabled>{{$t('ResendSeconds').replace('0',rePassword.time)}}</el-button>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item prop="passWord">
                            <el-input :type="rePassword.passwordFlage" v-model="rePassword.passWord" :placeholder="$t('PassWord')" class="loginInp">
                                <i slot="suffix" class="el-input__icon" @click="changePasswordShow_rePassword">
                                    <img style="width: 20px;margin-top: 10px; margin-right: 10px;" :src="rePassword.passwordImg" alt="">
                                </i>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="confirmpassWord_re">
                            <el-input :type="rePassword.confirmPasswordFlage" v-model="rePassword.confirmpassWord_re" :placeholder="$t('ConfirmPassword')" class="loginInp">
                                <i slot="suffix" class="el-input__icon" @click="changeConfirmPasswordShow_rePassword">
                                    <img style="width: 20px;margin-top: 10px; margin-right: 10px;" :src="rePassword.confirmPasswordImg" alt="">
                                </i>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <p class="passwordRules" style="margin-top: 170px">{{ $t('PasswordFormatLimit') }}</p>
                    <div class="registerBtnBox" style="margin-top: 50px;">
                        <el-button type="primary" class="loginBtn" :loading="rePassword.registerState === 1" @click="rePasswordConfirm('rePasswordConfirmRuleForm')" round>{{$t('Confirm')}}</el-button>
                    </div>
                </div>
                
            </div>
            <!-- <div class="termsBox" v-show="selMode === '1'">
                <el-checkbox v-model="ruleLogin.terms">
                    {{$t('ReadAgree')}} <a href="https://www.dropbox.com/scl/fi/7b1yf40ntvtj13uq1yj9i/AES-Opyn-Terms-and-Conditions.pdf?rlkey=y8orjpdmyebdgcfwbm02gw46r&dl=0" target="_blank">{{$t('TermsCondition')}}</a> {{$t('And')}} <a href="https://www.dropbox.com/s/yhvb987a2034gj1/AES%20-%20PRIVACY%20POLICY.docx?dl=0" target="_blank">{{ $t('PrivacyPolicy') }}</a>
                </el-checkbox>
            </div> -->
            <div class="termsBox" style="top:30px" v-show="selMode === '2'">
                <el-checkbox v-model="register.terms">
                    {{$t('ReadAgree')}} <a href="https://www.dropbox.com/scl/fi/7b1yf40ntvtj13uq1yj9i/AES-Opyn-Terms-and-Conditions.pdf?rlkey=y8orjpdmyebdgcfwbm02gw46r&dl=0" target="_blank">{{$t('TermsCondition')}}</a> {{$t('And')}} <a href="https://www.dropbox.com/s/yhvb987a2034gj1/AES%20-%20PRIVACY%20POLICY.docx?dl=0" target="_blank">{{ $t('PrivacyPolicy') }}</a>
                </el-checkbox>
            </div>
           
        </div>
        <!-- </div> -->       
        
    </div>
</template>
<script>
import Cookies from 'js-cookie' // 封装了获取，设置，删除cookie的方法
import { sha256 } from 'js-sha256'
import { mapState, mapMutations } from 'vuex';
import QRCode from 'qrcodejs2'
// import { Base64 } from 'js-base64'
import LoginApi from '@/api/login/index.js'
import showPassword from '@/assets/images/password_show.png'
import hidePassword from '@/assets/images/password_hide.png'

export default{
    name: 'Login',
    data(){
        let checkVerificationCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('EnterVerificationCode')));
            } else {
                callback();
            }
        };
         //邮箱号验证
         const checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
            if (!value) {
                return callback(new Error(this.$t('EmailEmpty')));
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback();
                } else {
                    callback(new Error(this.$t('EnterCorrectEmailFormat')));
                }
            }, 100);
        };
        //密码校验
        const checkPassword = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$t('PasswordEmpty')));
            }
            if (value.length < 8) {
                return callback(this.$t('PasswordLess'));

            }
            if (value.length > 16) {
                return callback(this.$t('PasswordLarger'));
            }
            setTimeout(() => {
                if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[._~!@#$^&*()\-_=+[\]{}|;:'",<.>/?])[A-Za-z0-9._~!@#$^&*()\-_=+[\]{}|;:'",<.>/?]{8,16}$/g.test(value)) {
                    callback()
                } else {
                    callback(new Error(this.$t('PasswordNotRules')))
                }
            }, 100);
           
        };
        //校验确认密码
        const checkConfirmPassword = (rule, value, callback) => {
            if (value === '') {
            callback(new Error(this.$t('EntePasswordAgain')));
            } else if (value !== this.register.passWord) {
            callback(new Error(this.$t('PasswordNotMatch')));
            } else {
            callback();
            }
            
        };
         //校验确认密码(修改密码)
         const checkConfirmPassword_rePassword = (rule, value, callback) => {
            if (value === '') {
            callback(new Error(this.$t('EntePasswordAgain')));
            } else if (value !== this.rePassword.passWord) {
            callback(new Error(this.$t('PasswordNotMatch')));
            } else {
            callback();
            }
            
        };
        return{
            selMode:"1",
            lang:Cookies.get('language') || 'en',
            langOptions:[
                {
                    value: 'zh',
                    label: '简体中文'
                }, 
                {
                    value: 'en',
                    label: 'English'
                }
            ],
            ruleLogin:{
                //密码小眼睛切换状态
                passwordFlage:"password",
                showPassword,
                hidePassword,
                passwordImg:hidePassword,
                account:"",
                passWord:"",
                // terms:false,
            },
            loginState:0,
            invitationCode:"",
            register:{
                //密码小眼睛切换状态
                passwordFlage:"password",
                confirmPasswordFlage:"password",
                showPassword,
                hidePassword,
                passwordImg:hidePassword,
                confirmPasswordImg:hidePassword,
                email:"",
                passWord:"",
                confirmpassWord:"",
                verificationCode:"",
                timer:null,
                time:60,
                sendVerificationCode:true,
                registerState:0,
                terms:false,
            },
            rePassword:{
                //密码小眼睛切换状态
                passwordFlage:"password",
                confirmPasswordFlage:"password",
                showPassword,
                hidePassword,
                passwordImg:hidePassword,
                confirmPasswordImg:hidePassword,
                email:"",
                passWord:"",
                confirmpassWord_re:"",
                verificationCode:"",
                timer:null,
                time:60,
                sendVerificationCode:true,
                registerState:0,
                step:1,
                rgn:"",
            },
            goLogin:{
                showLoginFrom:false,
                showClose:false,
                goLoginType:1, //1:注册成跳转，2：修改密码成功跳转
                timer:null,
                closeTime:0,
                btnState:0,
            },
            rules:{
                email: [
                    { required: true, validator: checkEmail, trigger: 'blur' }
                ],
                passWord: [
                    { required: true, validator: checkPassword, trigger: 'blur' }
                ],
                confirmpassWord: [
                    { required: true, validator: checkConfirmPassword, trigger: 'blur' }
                ],
                confirmpassWord_re: [
                    { required: true, validator: checkConfirmPassword_rePassword, trigger: 'blur' }
                ], 
                verificationCode: [
                    { required: true, validator: checkVerificationCode, trigger: 'blur' }
                ],                
            },

        }
    },
    mounted(){
        // console.log("sha256",sha256)
        if(Cookies.get("token")){
            this.$router.push({name:"main"});
       }
    },
    computed:{
        ...mapState(["companyList"])
    },
    methods:{
        ...mapMutations(["GETUSERINFO","GETCOMPANYLIST","GETSELECTCOMPANY","CHANGEMODEL"]),
        selectLanguage(){
            this.$i18n.locale = this.lang;
            this.$store.commit("CHANGELANGUAGE",this.lang);
            Cookies.set('language', this.lang);
        },
        //校验用户命名密码是否为空
        submitLogin(loginRules) {
            this.$refs[loginRules].validate((valid) => {
            if (valid) {
                this. getLogin();
            } else {
                return false;
            }
            });
        },
        //登录,注册忘记密码切换
        changeSelModel(index){
            this.selMode = index;
            if(index === "1"){
                this.ruleLogin.account = "";
                this.ruleLogin.passWord = "";
                this.ruleLogin.passwordImg = this.ruleLogin.hidePassword;
                this.ruleLogin.passwordFlage = "password";
                // this.ruleLogin.terms = false;
                //清除注册定时器
                this.register.time = 0;
                if(this.register.timer != null){
                    clearInterval(this.register.timer);
                }
                this.register.sendVerificationCode = true;
                //清除忘记密码定时器
                this.rePassword.time = 0;
                this.rePassword.sendVerificationCode = true;
                if(this.rePassword.timer != null){
                    clearInterval(this.rePassword.timer);
                }
                this.rePassword.timer = null
            }else if(index === "2"){
                // this.getAppDownLoadingUrl();
                this.register.email = "";
                this.register.passWord = "";
                this.register.confirmpassWord = "";
                this.register.verificationCode = "";
                this.register.passwordImg = this.register.hidePassword;
                this.register.passwordFlage = "password";
                this.register.confirmPasswordImg = this.register.hidePassword;
                this.register.confirmPasswordFlage = "password";
                this.$refs.registerRuleForm.resetFields();
                this.register.terms = false;
                //清除忘记密码定时器
                this.rePassword.time = 0;
                this.rePassword.sendVerificationCode = true;
                if(this.rePassword.timer != null){
                    clearInterval(this.rePassword.timer);
                }
                this.rePassword.timer = null;
            }else if(index === "3"){
                this.rePassword.step = 1;
                this.$refs.rePasswordRuleForm.resetFields();
                this.$refs.rePasswordConfirmRuleForm.resetFields();
                //清除注册定时器
                this.register.time = 0;
                if(this.register.timer != null){
                    clearInterval(this.register.timer);
                }
                this.register.timer = null;
            }
        },
        //点击登录执行函数
        getLogin(){
            if((this.ruleLogin.account).trim() === ""){
                this.$message.info(this.$t('EmailEmpty'));
                return false
            }
            if((this.ruleLogin.passWord).trim() === ""){
                this.$message.info(this.$t('PasswordEmpty'));
                return false
            }
            // if(!this.ruleLogin.terms){
            //     this.$message.info(this.$t('CheckReadAgree'));
            //     return false
            // }
            this.loginState = 1;
            let loginObj = {
                "oemGroupId": webConfig.oemGroupId,
                "username": (this.ruleLogin.account).trim(),
                "password": sha256(this.ruleLogin.passWord),
                "type": 1
            }
            LoginApi.getLogin(loginObj).then(res => {
                if(res.code === 0){
                    this.loginState = 0;
                    //重置为company模式
                    this.CHANGEMODEL("company");
                    //更新用户信息
                    this.GETUSERINFO({"userName":this.ruleLogin.account});
                    LoginApi.getCompanyList().then(res => {
                        if(res.code === 0){
                            // console.log("res.data.length",res.data.length)
                            if(res.data.length === 0){
                                this.GETCOMPANYLIST([
                                    {
                                        "id": "createCompany",
                                        "companyName": "+ " + this.$t('CreateCompany')
                                    }
                                ])
                                this.$store.commit("GETSELECTCOMPANY",this.companyList[0]);
                            }else{
                                let getCompanyArr = res.data;
                                getCompanyArr.push( {
                                    "id": "createCompany",
                                    "companyName": "+ " + this.$t('CreateCompany')
                                });
                                //默认选中第一个公司
                                this.GETSELECTCOMPANY(getCompanyArr[0]);
                                //更新公司列表
                                this.GETCOMPANYLIST(getCompanyArr);
                            }
                            this.$router.push({name:"main"});
                        }else{
                            this.$handerrcode(res.code);
                        }
                        
                    }).catch((err) => {

                    })
                }else{
                    this.loginState = 0;
                    this.$handerrcode(res.code);
                }
                
            }).catch((err) => {
                this.loginState = 0;
                console.log('err', err);
                return false
            })
        },
        //点击注册执行函数（目前弃用）
        goRegister(){
            this.$router.push({name:"register"});
        },
       
        //获取APP下载链接
        getAppDownLoadingUrl(){
            let appUrlfoObj = {
                "oemGroupId": webConfig.oemGroupId,
            }
            LoginApi.getAppDownloadUrl(appUrlfoObj).then(res => {
                if(res.code === 0){
                    this.invitationCode = res.data.invitationCode;
                    this.$refs.QRcodeForApp.innerHTML = '';
                    //调用生成二维码函数
                    this.creatQrCode(this.$refs.QRcodeForApp,this.invitationCode);
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //获取app下载二维码
        creatQrCode(id,content) {
            new QRCode(id, {
                text: content, // 需要转换为二维码的内容
                width: 200,
                height: 200,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        //登录时切换密码显示隐藏
        changeLoginPasswordShow(){
            if(this.ruleLogin.passwordImg === this.ruleLogin.showPassword){
                this.ruleLogin.passwordImg = this.ruleLogin.hidePassword;
                this.ruleLogin.passwordFlage = "password";
            }else{
                this.ruleLogin.passwordImg = this.ruleLogin.showPassword;
                this.ruleLogin.passwordFlage = "text";
            }
        },

        /**
         * 用户注册（邮箱）
        */
       //注册时切换密码显示隐藏   
        changePasswordShow(){
            if(this.register.passwordImg === this.register.showPassword){
                this.register.passwordImg = this.register.hidePassword;
                this.register.passwordFlage = "password";
            }else{
                this.register.passwordImg = this.register.showPassword;
                this.register.passwordFlage = "text";
            }
        },
        //注册时切换确认密码显示隐藏     
        changeConfirmPasswordShow(){
            if(this.register.confirmPasswordImg === this.register.showPassword){
                this.register.confirmPasswordImg = this.register.hidePassword;
                this.register.confirmPasswordFlage = "password";
            }else{
                this.register.confirmPasswordImg = this.register.showPassword;
                this.register.confirmPasswordFlage = "text";
            }
        },
        //点击发送验证码
        sendVerification(registerRuleForm){
            this.$refs[registerRuleForm].validateField("email",(err) => {
                if (!err) {
                    // const num = 60;
                    if (!this.register.timer) {
                        this.register.time = 60;
                        this.register.sendVerificationCode = false;
                        this.register.timer = setInterval(() => 
                        {
                            if (this.register.time > 0) {
                                this.register.time--;
                            } else {
                                this.register.sendVerificationCode = true;
                                clearInterval(this.register.timer);
                                this.register.timer = null;
                            }
                        }, 1000);
                    };
                    let verificationObj = {
                        "username":(this.register.email).trim(),
                        "oemGroupId": webConfig.oemGroupId,
                        "type": 1,
                        "accountType": "auth-email"
                    }
                    LoginApi.getRegisterCode(verificationObj).then(res => {
                        if(res.code === 0){
                            this.$message.info(this.$t('VerificationCodeSentSuccess'));
                        }else{
                            this.$handerrcode(res.code);
                            this.register.time = 0;
                            if(this.register.timer != null){
                                clearInterval(this.register.timer);
                            }
                            this.register.timer = null
                            this.register.sendVerificationCode = true;
                            // this.$message.info("Failed to send the verification code");
                        }
                    }).catch((err) => {
                        console.log(err);
                        // this.$message.info("Failed to send the verification code");
                    })
                }
            });
        },
        //点击注册执行函数
        registerAccount(registerRuleForm){
            if(!this.register.terms){
                this.$message.info(this.$t('CheckReadAgree'));
                return false
            }
            this.$refs[registerRuleForm].validate((valid) => {
                if (valid) {
                    let registerObj = {
                        "username":(this.register.email).trim(),
                        "password": sha256(this.register.passWord),
                        "oemGroupId": webConfig.oemGroupId,
                        "authCode": this.register.verificationCode,
                        "type": 1,
                        "accountType": "auth-email"
                    }
                    LoginApi.registerAccount(registerObj).then(res => {
                        if(res.code === 0){
                            //this.$message.info(this.$t('RegisteredSuccess'));
                            this.goLogin.showLoginFrom = true;
                            this.goLogin.goLoginType = 1;
                            this.timinGoLoginNow(3);
                        }else{
                            this.$handerrcode(res.code);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                } else {
                    return false;
                }
            });
        },

        /**
         * 找回密码
         * **/

         //修改密码，密码点击显示或隐藏密码
         changePasswordShow_rePassword(){
            if(this.rePassword.passwordImg === this.rePassword.showPassword){
                this.rePassword.passwordImg = this.rePassword.hidePassword;
                this.rePassword.passwordFlage = "password";
            }else{
                this.rePassword.passwordImg = this.rePassword.showPassword;
                this.rePassword.passwordFlage = "text";
            }
        },
         //修改密码，确认密码点击显示或隐藏密码
        changeConfirmPasswordShow_rePassword(){
            if(this.rePassword.confirmPasswordImg === this.rePassword.showPassword){
                this.rePassword.confirmPasswordImg = this.rePassword.hidePassword;
                this.rePassword.confirmPasswordFlage = "password";
            }else{
                this.rePassword.confirmPasswordImg = this.rePassword.showPassword;
                this.rePassword.confirmPasswordFlage = "text";
            }
        },
        //点击发送验证码
        sendVerification_rePassword(){
            if (!this.rePassword.timer) {
                this.rePassword.time = 60;
                this.rePassword.sendVerificationCode = false;
                this.rePassword.timer = setInterval(() => 
                {
                    if (this.rePassword.time > 0) {
                        this.rePassword.time--;
                    } else {
                        this.rePassword.sendVerificationCode = true;
                        clearInterval(this.rePassword.timer);
                        this.rePassword.timer = null;
                    }
                }, 1000);
            };
            let verificationObj = {
                "username":(this.rePassword.email).trim(),
                "oemGroupId": webConfig.oemGroupId,
                "type": 1,
                "accountType": "auth-email"
            }
            LoginApi.getResetPasswordCode(verificationObj).then(res => {
                if(res.code === 0){
                    this.$message.info(this.$t('VerificationCodeSentSuccess'));
                    this.rePassword.rgn = res.data;
                }else{
                    this.$handerrcode(res.code);
                    this.rePassword.time = 0;
                    this.rePassword.sendVerificationCode = true;
                    if(this.rePassword.timer != null){
                        clearInterval(this.rePassword.timer);
                    }
                    this.rePassword.timer = null;
                }
            }).catch((err) => {
                console.log(err);
            })
    
        },
        //修改密码点击下一步执行函数(获取修改密码验证码)
        rePasswordStep(rePasswordRuleForm){
            this.$refs[rePasswordRuleForm].validateField("email",(err) => {
                if (!err) {
                    // const num = 60;
                    this.rePassword.step = 2;
                    if (!this.rePassword.timer) {
                        this.rePassword.time = 60;
                        this.rePassword.sendVerificationCode = false;
                        this.rePassword.timer = setInterval(() => 
                        {
                            if (this.rePassword.time > 0) {
                                this.rePassword.time--;
                            } else {
                                this.rePassword.sendVerificationCode = true;
                                clearInterval(this.rePassword.timer);
                                this.rePassword.timer = null;
                            }
                        }, 1000);
                    };
                    let verificationObj = {
                        "username":(this.rePassword.email).trim(),
                        "oemGroupId": webConfig.oemGroupId,
                        "type": 1,
                        "accountType": "auth-email"
                    }
                    LoginApi.getResetPasswordCode(verificationObj).then(res => {
                        if(res.code === 0){
                            this.$message.info(this.$t('VerificationCodeSentSuccess'));
                            this.rePassword.rgn = res.data;
                        }else{
                            this.$handerrcode(res.code);
                            this.rePassword.time = 0;
                            this.rePassword.sendVerificationCode = true;
                            if(this.rePassword.timer != null){
                                clearInterval(this.rePassword.timer);
                            }
                            this.rePassword.timer = null;
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                } else {
                    return false;
                }
            });
        },
       
        //修改密码点击确认
        rePasswordConfirm(rePasswordConfirmRuleForm){
            this.$refs[rePasswordConfirmRuleForm].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    let rgnObj = {
                        rgn:this.rePassword.rgn
                    };
                    let rePasswordObj = {
                        "username":(this.rePassword.email).trim(),
                        "oemGroupId": webConfig.oemGroupId,
                        "authCode": this.rePassword.verificationCode,
                        "password": sha256(this.rePassword.passWord),
                        "confirmPassword": sha256(this.rePassword.confirmpassWord_re),
                        "type": 1,
                        "accountType": "auth-email"
                    }
                    LoginApi.resetPassword(rgnObj,rePasswordObj).then(res => {
                        if(res.code === 0){
                            this.goLogin.showLoginFrom = true;
                            this.goLogin.goLoginType = 2;
                            this.timinGoLoginNow(3);
                        }else{
                            this.$handerrcode(res.code);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                } else {
                    return false;
                }
            });
        },

        //立即跳到登录执行函数
        goLoginNow(){
            this.goLogin.showLoginFrom = false;
            this.changeSelModel('1');
            if(this.goLogin.timer != null){
                clearInterval(this.goLogin.timer);
            }
            this.goLogin.timer = null;      
        },
        //定时跳转到登录界面
        timinGoLoginNow(time){
            this.goLogin.closeTime = time;
            this.goLogin.timer = setInterval(() => 
            {
                if (this.goLogin.closeTime > 0) {
                    this.goLogin.closeTime--;
                } else {
                    this.goLogin.showLoginFrom = false;
                    this.changeSelModel('1');
                    if(this.goLogin.timer != null){
                        clearInterval(this.goLogin.timer);
                    }
                    this.goLogin.timer = null;
                }
            }, 1000);
        },
    }
}
</script>
<style scoped>
.login{
    background-color: #fff;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    min-width: 900px;
    background-image: url("../../assets/images/login.png");
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
    overflow: auto;
}
.loginBox{
    margin: auto;
    width: 1000px;
    height: 580px;
    /* min-width: 300px; */
    /* background-color: #fff; */
    /* border: 1px solid #fff; */
    border-radius: 48px;
    box-shadow: 1px 4px 6px #ddd;
    padding: 50px 30px;
    background-image: url("../../assets/images/login_box.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* min-width: 800px;
    overflow: auto; */
}
/* @media screen and (max-width: 600px) {
    .loginBox {
        width: 96vw;
        padding: 20px 10px;   
    }
} */
.LoginSign{
    display: flex;
    position: relative;
    top: 0px;
    left: 10px;
}
.loginBtnDiv{
    line-height: 16px;
}
.loginBtnDiv:hover{
    cursor: pointer;
}

.signBtnDiv{
    line-height: 16px;
}
.signBtnDiv:hover{
    cursor: pointer;
}
.el-divider {
    background-color: #6498b5;
    position: relative;
}
.colorSel{
    color:#6498b5;
}
.titleBox{
    font-size: 45px;
    font-weight: bold;
    position: relative;
    top: 100px;
    color: #333333;
    height: 50px;
    text-align: center;
}
.loginFromBox{
    position: relative;
    top: 120px;
}
.loginItem{
    margin-top: 15px;
}
.loginInp{
    width:300px;
    min-width: 150px;
}
.loginBtnBox{
    margin-top: 65px;
}
.loginBtn{
   width:260px;
   min-width: 260px;
   border-radius: 12px;
   
}
@media screen and (max-width: 600px) {
    .loginBtn {
        min-width: 260px;
    }
}
.registerbox{
    /* margin-top: 5px; */
    float: right;
    margin-right: 100px;
}
.registerbox a{
    text-decoration: none;
    font-size: 14px;
    color:#6498b5;
    cursor:pointer;
}
.termsBox{
    text-align: left;
    position: relative;
    top: 200px;
    left: 40px;
    color: #C1C1C1;
}
.el-checkbox {
    color: #C1C1C1;
}
::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #C1C1C1;
}
::v-deep .el-checkbox__inner {
    border-radius: 7px;
}
.termsBox a{
    color:#6498b5;
    text-decoration:underline;
}
.registerContentBox{
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.registerTex{
    display: flex;
    position: relative;
    top: 100px;
    margin-bottom: 79px;
    height: 40px;
}
.registerTex p{
    width: 300px;
    line-height: 1.5;
    text-align: center;
    font-size: 14px;
    color: #595959;
}
.registerQRCode{
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    position: relative;
    top: 70px;
}
.verificationInp {
    padding: 0px 0px !important;
    padding-right: 6px;
}

.verificationBtn{
    margin-left: 4px;
    width: 150px;
    padding: 12px 2px;
    color: #6498b5;
    border: 1px solid #6498b5;
}
@media screen and (max-width: 600px) {
    .verificationBtn { 
        padding: 10px 12px
    }
}
.verificationTimeBtn{
    width: 160px;
    margin-left: 4px;
    padding: 10px 5px;
    height: 40px;
    color: #6498b5;
    border: 1px solid #6498b5;
}
.passwordRules{
    margin-top: 120px;
    width: 380px;
    font-size: 13px;
    color: #9B9B9B;
    margin-left: 77px;
    text-align: left;
    line-height: 1.5;
}
.registerBtnBox{
    margin-top: 25px;
}
.el-button.is-round{
    border-radius: 10px;
}

/* 跳转到登录界面提示框 */
::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 1080px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        min-width: 300px;
        overflow: auto;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .goLoginDiv .el-dialog{
    width: 650px;
}
.goLoginBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 50px;
}

.goLoginBoxText{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2vw;
}
.goLoginBox ul li{
    font-size: 16px;
    word-break: keep-all;
    line-height: 1.5;
    margin-bottom: 1vw;
}

.goLoginDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: normal;
}

::v-deep .el-input__inner{
    padding: 0px 13px !important;
}

</style>