let ws

export async function openWs(url, timeMs) {
    if (typeof (ws) !== 'undefined') {
        return false
    }

    try {
        ws = new WebSocket(url)
    } catch (error) {
        return false
    }

    let onopen = new Promise((resolve) => {
        ws.onopen = function (evt) {
            return resolve(true)
        }
    })

    let onclose = new Promise((resolve) => {
        ws.onclose = function (evt) {
            return resolve(false)
        }
    })

    let onmessage = new Promise((resolve) => {
        ws.onmessage = function (evt) {
            return resolve(false)
        }
    })

    let onerror = new Promise((resolve) => {
        ws.onerror = function (evt) {
            return resolve(false)
        }
    })

    let timeout = new Promise((resolve) => {
        setTimeout(function () {
            return resolve(false)
        }, timeMs)
    })

    await Promise.race([onopen, onclose, onmessage, onerror, timeout])

    ws.onopen = undefined
    ws.onclose = undefined
    ws.onmessage = undefined
    ws.onerror = undefined

    if (ws.readyState !== WebSocket.OPEN) {
        ws.close()
        ws = undefined
        return false
    }

    return true
}

export async function closeWs() {
    if (typeof (ws) === 'undefined') {
        return
    }

    ws.close()
    ws = undefined
}

//打开设备
export async function dc_init(port, baud) {
    let result = new Array()
    let inStr, outStr
    let obj

    inStr = '{"func":"dc_init","in":["' + port.toString() + '","' + baud.toString() + '"]}'
    outStr = await callDcrf32(inStr, 10000)
    if (typeof (outStr) === 'undefined') {
        return
    }

    try {
        obj = JSON.parse(outStr)
    } catch (error) {
        return
    }

    result[0] = parseInt(obj.result)

    return result
}

//蜂鸣器
export async function dc_beep(icdev, _Msec) {
    let result = new Array()
    let inStr, outStr
    let obj

    inStr = '{"func":"dc_beep","in":["' + icdev.toString() + '","' + _Msec.toString() + '"]}'
    outStr = await callDcrf32(inStr, _Msec * 10 + 10000)
    if (typeof (outStr) === 'undefined') {
        return
    }

    try {
        obj = JSON.parse(outStr)
    } catch (error) {
        return
    }

    result[0] = parseInt(obj.result)

    return result
}

//复位射频
export async function dc_reset(icdev, _Msec) {
    let result = new Array()
    let inStr, outStr
    let obj

    inStr = '{"func":"dc_reset","in":["' + icdev.toString() + '","' + _Msec.toString() + '"]}'
    outStr = await callDcrf32(inStr, _Msec * 10 + 10000)
    if (typeof (outStr) === 'undefined') {
        return
    }

    try {
        obj = JSON.parse(outStr)
    } catch (error) {
        return
    }

    result[0] = parseInt(obj.result)

    return result
}

//配置非接触卡类型  
export async function dc_config_card(icdev, cardtype) {
    let result = new Array()
    let inStr, outStr
    let obj

    inStr = '{"func":"dc_config_card","in":["' + icdev.toString() + '","' + cardtype.toString() + '"]}'
    outStr = await callDcrf32(inStr, 10000)
    if (typeof (outStr) === 'undefined') {
        return
    }

    try {
        obj = JSON.parse(outStr)
    } catch (error) {
        return
    }

    result[0] = parseInt(obj.result)

    return result
}

//寻卡请求、防卡冲突、选卡操作
export async function dc_card_n(icdev, _Mode) {
    let result = new Array()
    let inStr, outStr
    let obj

    inStr = '{"func":"dc_card_n","in":["' + icdev.toString() + '","' + _Mode.toString() + '"]}'
    outStr = await callDcrf32(inStr, 10000)
    if (typeof (outStr) === 'undefined') {
        return
    }

    try {
        obj = JSON.parse(outStr)
    } catch (error) {
        return
    }

    result[0] = parseInt(obj.result)
    if (result[0] === 0) {
        result[1] = parseInt(obj.out[0])
        result[2] = obj.out[1]
    }

    return result
}

async function callDcrf32(data, timeMs) {
    let result

    result = await WriteMessage(data)
    if (!result) {
        return
    }

    return await ReadMessage(timeMs)
}

async function WriteMessage(msg) {
    if (typeof (ws) === 'undefined') {
      return false
    }
  
    try {
      ws.send(msg)
    } catch (error) {
      return false
    }
  
    return true
  }
  async function ReadMessage(timeMs) {
    if (typeof (ws) === 'undefined') {
      return
    }
  
    let onopen = new Promise((resolve) => {
      ws.onopen = function (evt) {
        return resolve(undefined)
      }
    })
  
    let onclose = new Promise((resolve) => {
      ws.onclose = function (evt) {
        return resolve(undefined)
      }
    })
  
    let onmessage = new Promise((resolve) => {
      ws.onmessage = function (evt) {
        return resolve(evt.data)
      }
    })
  
    let onerror = new Promise((resolve) => {
      ws.onerror = function (evt) {
        return resolve(undefined)
      }
    })
  
    let timeout = new Promise((resolve) => {
      setTimeout(function () {
        return resolve(undefined)
      }, timeMs)
    })
  
    let result = await Promise.race([onopen, onclose, onmessage, onerror, timeout])
  
    ws.onopen = undefined
    ws.onclose = undefined
    ws.onmessage = undefined
    ws.onerror = undefined
  
    return result
  }