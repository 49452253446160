import Vue from "vue"
import VueRouter from "vue-router"

import login from '@/pages/login/index.vue'
import main from '@/pages/main/index.vue'

//company相关
import companydashboard from '@/pages/company/companydashboard'
import sitesmanage from '@/pages/company/sitesmanage'
import companyadmin from '@/pages/company/companyadmin'
import companyadminedit from '@/pages/company/companyadmin/edit'
import purchaserecord from '@/pages/company/purchaserecord'

//site相关
import sitedashboard from '@/pages/site/sitedashboard'
import blocks from '@/pages/site/blocks'
import blocksedit from '@/pages/site/blocks/edit'
import panels from '@/pages/site/panels'
import panelsedit from '@/pages/site/panels/edit'
import siteusers from '@/pages/site/siteusers'
import siteusersedit from '@/pages/site/siteusers/edit/index.vue'
import sitelicenses from '@/pages/site/sitelicenses'
import eventrecords from '@/pages/site/eventrecords'
import panellog from '@/pages/site/panellog'
import operationlog from '@/pages/site/operationlog'
import payments from '@/pages/site/payments'
import profile from '@/pages/site/profile'
import paysuccess from '@/pages/paystatus/paysuccess'
import payfail from '@/pages/paystatus/payfail'
import notice from '@/pages/site/notice'

import store from '@/store/index.js' //引入store.js

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/paysuccess',
        name: 'paysuccess',
        component: paysuccess
    },
    {
        path: '/payfail',
        name: 'payfail',
        component: payfail
    },
    {
        path: '/main',
        name: 'main',
        component: main,
        meta: {
            breadcrumb: true,
            title: 'Company'
        },
        children: [
            //Company相关
            {
                path: 'companydashboard',
                name: 'companydashboard',
                component: companydashboard,
            },
            {
                path: 'sitesmanage',
                name: 'sitesmanage',
                component: sitesmanage,
            },
            {
                path: 'companyadmin',
                name: 'companyadmin',
                component: companyadmin,
                children: [{
                    path: 'companyadminedit',
                    name: 'companyadminedit',
                    component: companyadminedit,
                    meta: {
                        isChildren: true,
                        usePath: "/main/companyadmin"
                    }
                }, ]
            },
            {
                path: 'purchaserecord',
                name: 'purchaserecord',
                component: purchaserecord,
            },

            // Sites相关
            {
                path: 'sitedashboard',
                name: 'sitedashboard',
                component: sitedashboard
            },
            {
                path: 'blocks',
                name: 'blocks',
                component: blocks,
                children: [{
                    path: 'blocksedit',
                    name: 'blocksedit',
                    component: blocksedit,
                    meta: {
                        isChildren: true,
                        usePath: "/main/blocks"
                    }
                }, ]
            },
            {
                path: 'panels',
                name: 'panels',
                component: panels,
                children: [{
                    path: 'panelsedit',
                    name: 'panelsedit',
                    component: panelsedit,
                    meta: {
                        isChildren: true,
                        usePath: "/main/panels"
                    }
                }, ]
            },
            {
                path: 'siteusers',
                name: 'siteusers',
                component: siteusers,
                children: [{
                    path: 'siteusersedit',
                    name: 'siteusersedit',
                    component: siteusersedit,
                    meta: {
                        isChildren: true,
                        usePath: "/main/siteusers"
                    }
                }, ]
            },
            {
                path: 'eventrecords',
                name: 'eventrecords',
                component: eventrecords,

            },
            {
                path: 'operationlog',
                name: 'operationlog',
                component: operationlog,

            },
            {
                path: 'panellog',
                name: 'panellog',
                component: panellog,

            },
            {
                path: 'sitelicenses',
                name: 'sitelicenses',
                component: sitelicenses,

            },
            {
                path: 'payments',
                name: 'payments',
                component: payments,
            },
            {
                path: 'profile',
                name: 'profile',
                component: profile,

            },
            {
                path: 'notice',
                name: 'notice',
                component: notice,
            },
        ]

    },
    //重定向:在顶级目录中不存在的数据自动指向首页
    {
        path: '*',
        redirect: '/'
    }

]

//重写push方法
const originPush = VueRouter.prototype.push; // 把官方的push方法暂存到originPush这个变量里面
VueRouter.prototype.push = function push(location) { // 参数是调用push方法传进来的参数
    return originPush.call(this, location).catch(() => {});
};
//或者重写replace方法
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originReplace.call(this, location).catch(() => {})
}


const router = new VueRouter({
    //mode: 'hash',
    mode: 'history',
    routes: routes
})

//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
    // console.log('前置路由守卫', to, from);
    // console.log('前置路由守卫', store.state);
    //重置panels编辑显示状态
    if (to.path === "/main/panels") {
        store.commit("OPENINEDITPAGE", { name: "isPanelsEdit", value: false });
    } else {
        store.commit("OPENINEDITPAGE", { name: "isPanelsEdit", value: true });
    }
    if (to.path === "/main/siteusers") {
        store.commit("OPENINEDITPAGE", { name: "isSiteUserEdit", value: false });
    }
    if (to.path === "/main/companyadmin") {
        store.commit("OPENINEDITPAGE", { name: "isCompanyAdminEdit", value: false });
    }
    //公司相关路由列表数组
    let companyRouteArr = ["/main/companydashboard","/main/sitesmanage","/main/companyadmin","/main/purchaserecord"];
    if(companyRouteArr.indexOf(to.path) > -1){
        store.commit("CHANGEMODEL", "company");
    }
    //园区相关路由列表数组 修改点击浏览器前进或后退时，园区到公司模块 菜单栏显示异常问题
    let sitesRouteArr = ["/main/sitedashboard","/main/blocks","/main/panels","/main/siteusers","/main/operationlog","/main/panellog","/main/sitelicenses","/main/payments","/main/profile","/main/notice"];
    if(sitesRouteArr.indexOf(to.path) > -1){
        store.commit("CHANGEMODEL", "site");
    }
    if(to.path === "/main"){
        router.replace("/main/sitesmanage")
    }
    next()

})

export default router