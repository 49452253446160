//导入封装好的新的axios
import request from '@/request/index'

//查询blocks
export function getSiteBlocksList(params) {
    return request({
        url: '/' + params.rgn + '/site/apartment/page',
        method: 'get',
        params: {
            siteId: params.siteId,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
            apartmentName: params.apartmentName,
        },
    })
}

//查询panels列表
export function getSitePanelsList(params) {
    return request({
        url: '/' + params.rgn + '/site/getSiteDeviceInfo',
        method: 'get',
        params: {
            siteId: params.siteId,
        },
    })
}

//查询离线panels列表
export function getSiteOfflinePanelsList(params) {
    return request({
        url: '/' + params.rgn + '/site/getSiteDeviceStatus',
        method: 'get',
        params: {
            siteId: params.siteId,
        },
    })
}

//查询Site Users
export function getSiteUsersList(params) {
    return request({
        url: '/' + params.rgn + '/site/getSiteUserInfo',
        method: 'get',
        params: {
            siteId: params.siteId,
        },
    })
}

//查询可用licenses列表
export function getSiteLicensesList(params) {
    return request({
        url: '/' + params.rgn + '/site/license/manage/list',
        method: 'get',
        params: {
            companyId: params.companyId,
            siteId: params.siteId,
        },
    })
}

//

export default { getSiteBlocksList, getSitePanelsList, getSiteOfflinePanelsList, getSiteUsersList, getSiteLicensesList }