//导入封装好的新的axios
import request from '@/request/index'

//查询site licenses
export function getPurchaseLicensesList(params) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/goods/sale',
        method: 'get',
        params: {
            oemGroupId: params.oemGroupId,
            currencyCode: params.currencyCode,
            siteId:params.siteId,
        },
    })
}
//查询可用支付平台
export function getPaymentPlatformList(params) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/findPayPlatform',
        method: 'get',
        params: {
            merchantId: params.oemGroupId,
            currencyCode: params.currencyCode,
        },
    })
}
//licenses单价和币种相关查询
export function getLicensesInfo(params) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/goods',
        method: 'get',
        params: {
            goodsId: params.goodsId,
        },
    })
}

//licenses预下单
export function licensesPreOrder(params, data) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/order/pre',
        method: 'post',
        data,
    })
}

//licenses下单
export function licensesOrder(params, data) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/order',
        method: 'post',
        data,
    })
}

//License商品订单支付
export function licensesOrderPay(params, data) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/order/pay',
        method: 'post',
        data,
    })
}

//License订单状态查询(手动)
export function licensesPayReflush(params, data) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/pay/result/reflush',
        method: 'post',
        data,
    })
}

//License订单状态查询通过订单id
export function getLicensesPayState(params) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/paymentStatus',
        method: 'get',
        params: {
            "orderNo": params.orderNo,
        },
    })
}
//用户License购买记录查询
export function getLicensesPurchaseRecord(params) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/order',
        method: 'get',
        params: {
            siteId: params.siteId,
            orderNo: params.orderNo,
            yearMonth: params.yearMonth,
            companyId: params.companyId,
            orderStatus: params.orderStatus,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
        },
    })
}

export default { getPurchaseLicensesList, getPaymentPlatformList, getLicensesInfo, licensesPreOrder, licensesOrder, licensesOrderPay, licensesPayReflush, getLicensesPayState, getLicensesPurchaseRecord }