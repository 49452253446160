<template>
    <div>
        <div class="panelsBox" v-show="!isEditPage.isPanelsEdit">
            <!--新增panels弹框-->
            <el-dialog :title="$t('CreatePanels')"
             :visible.sync="add.addPanelsFrom"
             :close-on-click-modal="false" 
             :close-on-press-escape="false"
             center 
             :show-close="showClose">
                <div class="addPanelsBox">
                    <div class="addPanelsLeft">
                        <el-form :model="add">
                            <el-form-item :label="$t('PanelsSerialNumber')">
                                <el-input v-model="add.deviceId"></el-input>
                                <p @click="serialNumberHelp">{{$t('NotFndSerial')}} <span>>></span></p>
                            </el-form-item>
                            <el-form-item :label="$t('PanelsSecurityCode')">
                                <el-input v-model="add.securityCode"></el-input>
                                <p @click="serialCodeHelp">{{$t('PanelsSerialCode')}} <span>>></span></p>
                            </el-form-item>
                            <el-form-item :label="$t('PanelsName') + ' *' ">
                                <el-input v-model="add.deviceName"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('Sites') + ' ' + $t('Or') + ' ' + $t('Blocks') + ' *' ">
                                <!-- <el-input v-model="add.apartmentId"></el-input> -->
                                <el-select v-model="add.apartmentId" :placeholder="$t('Sites') + '/' + $t('Blocks')" clearable>
                                    <el-option
                                    v-for="item in add.apartmentOptions"
                                    :key="item.id"
                                    :label="item.apartmentName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="addPanelsRight">
                        <p>{{$t('RolePermission')}}</p>
                        <div class="permissionDiv">
                            <div class="permissionItem" v-for="(item,key) in add.permissionList" :key="key">
                                <div class="permissionLeft" v-if="key === 'user'">{{$t('SiteUser')}}</div>
                                <div class="permissionLeft" v-else-if="key === 'employee'">{{$t('SiteEmployee')}}</div>
                                <div class="permissionLeft" v-else-if="key === 'others'">{{$t('SiteOther')}}</div>
                                <div class="permissionRight" >
                                    <img :src="add.preview" v-if="item.indexOf('1')>-1" @click="removePermission(item,key,'1')" alt="">
                                    <img :src="add.unpreview" v-else @click="selectPermission(item,key,'1')" alt="">
                                    <img :src="add.lock" v-if="item.indexOf('2')>-1" @click="removePermission(item,key,'2')" alt="">
                                    <img :src="add.unlock" v-else @click="selectPermission(item,key,'2')" alt="">
                                    <img :src="add.call" v-if="item.indexOf('3')>-1" @click="removePermission(item,key,'3')" alt="">
                                    <img :src="add.uncall" v-else @click="selectPermission(item,key,'3')" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancalPanels">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="add.addBtnState === 1" @click="addNewPanels">{{$t('Confirm')}}</el-button>
                </div>
            </el-dialog>
            <!--删除Panels弹框-->
            <el-dialog
                :title="$t('Delete')"
                :visible.sync="del.delPanelsFrom"
                :close-on-click-modal="false" 
                :close-on-press-escape="false"
                width="30%"
                :show-close="showClose">
                <p class="deleteDesc">{{$t('DeletePanel')}}</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="del.delPanelsFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="del.delBtnState === 1" @click="delPanelsFromBtn">{{$t('Confirm')}}</el-button>
                </span>
            </el-dialog>
            <!--绑定panels弹框-->
            <el-dialog :title="$t('BindingPanels')"
             class="bindPanelsDialog"
             :visible.sync="binding.bindPanelsFrom"
             :close-on-click-modal="false" 
             :close-on-press-escape="false"
             center 
             :show-close="showClose">
                <div class="bindPanelsBox">
                    <div class="bindPanelsDiv">
                        <el-form>
                            <el-form-item :label="$t('PanelsSerialNumber')">
                                <el-input v-model="binding.deviceId"></el-input>
                                <p @click="serialNumberHelp">{{$t('NotFndSerial')}} <span>>></span></p>
                            </el-form-item>
                            <el-form-item :label="$t('PanelsSecurityCode')">
                                <el-input v-model="binding.securityCode"></el-input>
                                <p @click="serialCodeHelp">{{$t('PanelsSerialCode')}} <span>>></span></p>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                
                <div slot="footer" class="dialog-footer">
                    <el-button @click="binding.bindPanelsFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="binding.bindBtnState === 1" @click="bindNewPanels">{{$t('Confirm')}}</el-button>
                </div>
            </el-dialog>
             <!--解绑Panels弹框-->
             <el-dialog
                :title="$t('UnbindingPanels')"
                :visible.sync="unbinding.unbindPanelsFrom"
                :close-on-click-modal="false" 
                :close-on-press-escape="false"
                width="30%"
                :show-close="showClose">
                <p class="deleteDesc">{{$t('ConfirmUnbindPanel')}}</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="unbinding.unbindPanelsFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="unbinding.unbindBtnState === 1" @click="unbindPanelsFromBtn">{{$t('Confirm')}}</el-button>
                </span>
            </el-dialog>
            <div class="serachBox">
                <div class="serachLeft">
                    <el-input v-model="serach.deviceName" class="serachInput" :placeholder="$t('PanelsName')"></el-input>
                    <el-select v-model="serach.apartmentId" class="serachSel" :placeholder="$t('Sites') +'/'+ $t('Blocks')" clearable>
                        <el-option
                        v-for="item in serach.apartmentOptions"
                        :key="item.id"
                        :label="item.apartmentName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="serach.deviceStatus" class="serachSel" :placeholder="$t('Status')" clearable>
                        <el-option
                        v-for="item in serach.deviceOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="serachBtn"  @click="serachPanels">{{$t('Serach')}}</el-button>
                </div>
                <div class="serachRight">
                    <el-button type="primary" class="serachBtn" @click="addNewPanelsFrom">{{$t('CreatePanels')}}</el-button>
                </div>
            </div>
            <el-divider></el-divider>
            <div>
                <div class="noPanels" v-show="hasNoPanels">{{$t('NoPanel')}}</div>
                <template>
                    <el-table
                    v-show="!hasNoPanels"
                    :data="panelsListData"
                    :cell-class-name="getPanelsStatusClass"
                    @cell-click="handlePanelsCell"
                    @row-dblclick="handlePanelsRow"
                    style="width: 100%;"
                    :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                    >
                    <el-table-column
                        prop="deviceId"
                        :label="$t('PanelsId')"
                        width="200"
                        >
                        <template slot-scope="scope">
                            {{scope.row.deviceId === "" ? "--" : scope.row.deviceId}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="deviceName"
                        :label="$t('PanelsName')"
                        width="200"
                        >
                    </el-table-column>
                    <!-- <el-table-column
                        prop="apartmentName"
                        :label="$t('Blocks')"
                        width="200"
                        >
                        <template slot-scope="scope">
                            {{ handApartmentName(scope.row.apartmentName)}}
                        </template>
                    </el-table-column> -->
                    <el-table-column
                        prop="apartmentName"
                        :label="$t('Blocks') + '/'+ $t('Sites')"
                        width="150px"
                        >
                        <template slot-scope="scope">
                            {{scope.row.apartmentId === "0" ? $t('Sites') : scope.row.apartmentName}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="deviceStatus"
                        :label="$t('PanelsStatus')"
                        width="200"
                        >
                        <template slot-scope="scope">
                            {{ getPanelsStatusStr(scope.row.deviceStatus)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        :label="$t('CreateDate')"
                        width="200"
                        >
                        <template slot-scope="scope">
                            {{ utctolocal(scope.row.createTime)}}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column  label="操作" fixed="right" width="180px" align="center"> -->
                    <el-table-column width="200"  label="..." align="center">
                        <template slot-scope="scope">
                            <!-- <el-dropdown>
                                <span class="el-dropdown-link" style="font-weight: bold;">
                                    ...
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="detailPanels(scope.row)">详情</el-dropdown-item>
                                    <el-dropdown-item @click.native="openDelPanelsFrom(scope.row)">删除</el-dropdown-item>
                                </el-dropdown-menu>  
                            </el-dropdown> -->
                            <el-image :src="editImg"  @click="editlPanels(scope.row)" style="width:24px;" fit="fill"></el-image>
                            <el-image :src="deleteImg"  @click="openDelPanelsFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                            <template v-if="scope.row.deviceStatus === 2">
                                <el-image :src="bindingImg"  @click="openBindPanelsFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                                <!-- <el-image :src="unbindingImg_d" style="width:24px;margin-left:1vw" fit="fill"></el-image> -->
                            </template>
                            <template v-else>
                                <!-- <el-image :src="bindingImg_d"  style="width:24px;margin-left:1vw" fit="fill"></el-image> -->
                                <el-image :src="unbindingImg"  @click="openUnbindPanelsFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                            </template>
                            
                            
                        </template>
                    </el-table-column>

                    <el-table-column>

                    </el-table-column>
                
                    </el-table>
                </template>
                <div class="pageBox">
                    <el-pagination
                        v-show="!hasNoPanels"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="prev, pager, next, jumper"
                        :total="totalPanels"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <router-view v-show="isEditPage.isPanelsEdit"></router-view>
    </div>
    
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import {nanoid} from 'nanoid'
import { sha256 } from 'js-sha256'
import sitesPanelsApi from '@/api/panels/index.js'
import commonFun from '@/common/index.js'
import editImg from '@/assets/images/edit.png'
import deleteImg from '@/assets/images/delete.png'
import lock1 from '@/assets/images/lock1.png'
import lock2 from '@/assets/images/lock2.png'
import call1 from '@/assets/images/call1.png'
import call2 from '@/assets/images/call2.png'
import preview1 from '@/assets/images/preview1.png'
import preview2 from '@/assets/images/preview2.png'
import bindingImg from '@/assets/images/binding.png'
import bindingImg_d from '@/assets/images/binding_d.png'
import unbindingImg from '@/assets/images/unbinding.png'
import unbindingImg_d from '@/assets/images/unbinding_d.png'
export default {
    name:"Panels",
    data(){
        return{
            // isPanelsEdit:false,
            showClose:true,
            hasNoPanels:false,
            totalPanels:0,
            currentPage: 1,
            serach:{
                deviceName:"",
                apartmentId:"",
                apartmentOptions:[],
                deviceStatus:"",
                deviceOptions:[
                    {
                        "id": nanoid(),
                        "value": 0,
                        "name":this.$t('Invalid')
                    },
                    {
                        "id": nanoid(),
                        "value": 1,
                        "name":this.$t('Ready')
                    },
                    {
                        "id": nanoid(),
                        "value": 2,
                        "name":this.$t('NeedConfigration')
                    },
                    {
                        "id": nanoid(),
                        "value": 3,
                        "name":this.$t('NeedVerification')
                    },
                ],
            },
            add:{
                addPanelsFrom:false,
                addBtnState:0,
                deviceId:"",
                securityCode:"",
                deviceName:"",
                apartmentId:"",
                apartmentOptions:[],
                lock:lock1,
                unlock:lock2,
                call:call1,
                uncall:call2,
                preview:preview1,
                unpreview:preview2,
                permissionList:{
                    user:['1','2','3'],
                    employee:['1','2','3'],
                    others:['1','2','3'],
                }
            },
            update:{
                // updatePanelsFrom:false,
                updateBtnState:0,
                deviceId:"",
                securityCode:"",
                deviceName:"",
                apartmentId:"",
                apartmentOptions:[],
                lock:lock1,
                unlock:lock2,
                call:call1,
                uncall:call2,
                preview:preview1,
                unpreview:preview2,
                permissionList:{
                    user:[],
                    employee:[],
                    others:[],
                }
            },
            del:{
                delPanelsFrom:false,
                delBtnState:0,
                entranceDeviceId:""
            },
            panelsListData:[],
            editImg,
            deleteImg,
            bindingImg,
            bindingImg_d,
            unbindingImg,
            unbindingImg_d,
            binding:{
                bindPanelsFrom:false,
                entranceDeviceId:"",
                deviceId:"",
                securityCode:"",
                bindBtnState:0,
            },
            unbinding:{
                unbindPanelsFrom:false,
                unbindBtnState:0,
                entranceDeviceId:"",
                deviceId:"",
            },
        }
    },
    mounted(){
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/panels',
                name:this.$t('Panels')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
        // this.OPENINEDITPAGE({name:"isPanelsEdit",value:false});
       //获取blocks下拉列表框
       this.getBlocksListForSelect();
       //获取panels列表
       let serachPanelsObj = {
            "apartmentId":this.serach.apartmentId,
            "deviceName":this.serach.deviceName,
            "deviceStatus":this.serach.deviceStatus,
            "pageNum":1
        };
        this.getPanelsList(serachPanelsObj);
        //根据路由携带信息判断是否打开新增panels弹窗
        if(this.$route.params.createPanels){
            this.add.addPanelsFrom = true;
        }else{
            this.add.addPanelsFrom = false;
        }
    },
    methods:{
        ...mapMutations(["OPENINEDITPAGE"]),
        //点击表格行执行函数
        handlePanelsRow(row, column, event){
            this.OPENINEDITPAGE({name:"isPanelsEdit",value:true}),
            // this.isPanelsEdit = true;

            this.$router.push(
                {
                    name:"panelsedit",
                    params:row,
                }
            );
        },
        //单击设备列执行函数
        handlePanelsCell(row, column, cell, event){
            if(column.property === "deviceId"){
                this.OPENINEDITPAGE({name:"isPanelsEdit",value:true}),
                this.$router.push(
                    {
                        name:"panelsedit",
                        params:row,
                    }
                );
            }
        },
        //获取Block列表，渲染下拉列表框
        getBlocksListForSelect(){
            let blocksInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
            }
            sitesPanelsApi.getSiteBlocksListForSelect(blocksInfoObj).then(res => {
                if(res.code === 0){
                    this.serach.apartmentOptions = [];
                    this.add.apartmentOptions = [];
                    //前面添加site，固定
                    this.serach.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    this.add.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    for(let i=0;i< res.data.length;i++){
                        this.serach.apartmentOptions.push(res.data[i]);
                        this.add.apartmentOptions.push(res.data[i]);
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //panel状态渲染函数
        getPanelsStatusClass(obj){
            let getClass = "";
            if(obj.columnIndex === 3){
                // console.log("obj.row.deviceStatus",obj.row.deviceStatus)
                let status = obj.row.deviceStatus;
                switch(status){
                    case 0:
                        getClass = "statusBlue";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusOrange";
                        break
                    case 3:
                        getClass = "statusRed";
                        break
                    default:
                        getClass = "";
                }
            }
            return getClass;
        },
        getPanelsStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('Offine');
                    break;
                case 1:
                    getStr = this.$t('Ready');
                    break
                case 2:
                    getStr = this.$t('NeedConfigration');
                    break
                case 3:
                    getStr = this.$t('NeedVerification');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        //处理apartmentName为空时显示问题
        handApartmentName(name){
            let finalName = "";
            if(name === null || name.trim() === ""){
                finalName = "--"
            }else{
                finalName = name;
            }
            return finalName
        },
        //Panels列表获取
        getPanelsList(serachPanelsObj){
            let panelsInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "apartmentId":serachPanelsObj.apartmentId,
                "deviceName":serachPanelsObj.deviceName,
                "deviceStatus":serachPanelsObj.deviceStatus,
                "pageSize":10,
                "pageNum":serachPanelsObj.pageNum,
                "apartmentName":serachPanelsObj.apartmentName || ""
            }
            if((serachPanelsObj.deviceName).trim() != ""){
                let checkResultDeviceName = this.$regularCheckLimit(serachPanelsObj.deviceName,1,30,'DeviceNameLimit');
                if(!checkResultDeviceName){
                    return false
                }
            }
            sitesPanelsApi.getSitePanelsList(panelsInfoObj).then(res => {
                if(res.code === 0){
                    this.panelsListData = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.hasNoPanels = true;
                    }else{
                        this.hasNoPanels = false;
                        this.panelsListData = res.data.list;
                    }
                    this.currentPage = res.data.pageNum;
                    this.totalPanels = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //搜索pannel
        serachPanels(){
            let serachPanelsObj = {
                "apartmentId":this.serach.apartmentId,
                "deviceName":this.serach.deviceName,
                "deviceStatus":this.serach.deviceStatus,
                "pageNum":1
            }
            this.getPanelsList(serachPanelsObj);
        },
        //处理权限
        selectPermission(item,key,val){
            this.add.permissionList[key].push(val);
        },
        removePermission(item,key,val){
            commonFun.removeByValue(this.add.permissionList[key],val)
            //this.removeByValue(this.add.permissionList[key],val);
        },
        //fnd serial number
        serialNumberHelp(){
            window.open("https://opyn.bitrix24.site/uid_help/");
        },
        //fnd security code 
        serialCodeHelp(){
            window.open("https://opyn.bitrix24.site/code_help/");
        },
        //添加新的panels弹框
        addNewPanelsFrom(){
            this.add.addBtnState = 0;
            this.add.addPanelsFrom = true;
            this.add.deviceId = "";
            this.add.securityCode = "";
            this.add.deviceName = "";
            this.add.apartmentId = "";
        },
        //取消新增panels弹框
        cancalPanels(){
            this.add.addBtnState = 0;
            this.add.addPanelsFrom = false;
        },
        //添加Panels点击确认按钮执行方法
        addNewPanels(){
            if((this.add.deviceId).trim() === ""){
                this.$message.info(this.$t('PanelsSerialNumberEmpty'));
                return false
            }
            let checkResultDeviceId = this.$regularCheckLimit(this.add.deviceId,1,20,'DeviceSerialLimit');
            if(!checkResultDeviceId){
                return false
            }
            if((this.add.securityCode).trim() === ""){
                this.$message.info(this.$t('PanelsSecurityNumberEmpty'));
                return false
            }
            let checkResultSecurityCode = this.$regularCheckLimit(this.add.securityCode,1,64,'DeviceSecurityCodeLimit');
            if(!checkResultSecurityCode){
                return false
            }
            if((this.add.deviceName).trim() === ""){
                this.$message.info(this.$t('PanelsNameEmpty'));
                return false
            }
            let checkResultDeviceName = this.$regularCheckLimit(this.add.deviceName,1,30,'DeviceNameLimit');
            if(!checkResultDeviceName){
                return false
            }
            if((this.add.apartmentId).trim() === ""){
                this.$message.info(this.$t('SiteOrBlocksEmpty'));
                return false
            }
            this.add.addBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addPanelsObj = {
                "siteId":this.selectSite.id,
                "apartmentId":this.add.apartmentId,
                "deviceId":this.add.deviceId,
                // "securityCode": this.add.securityCode,
                "securityCode": sha256(this.add.securityCode),
                "deviceName": this.add.deviceName,
                "permissionMap": this.add.permissionList
            }
            // console.log("addPanelsObj",addPanelsObj);
            // return false
            sitesPanelsApi.addSitePanels(rgnObj,addPanelsObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('AddSuccessful'));
                    this.add.addBtnState = 0;
                    this.add.addPanelsFrom = false;
                    //获取panels列表
                    let serachPanelsObj = {
                            "apartmentId":this.serach.apartmentId,
                            "deviceName":this.serach.deviceName,
                            "deviceStatus":this.serach.deviceStatus,
                            "pageNum":1
                        };
                    this.getPanelsList(serachPanelsObj);
                }else{
                    this.add.addBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.add.addBtnState = 0;
            })
           
        },
        //点击编辑图标
        editlPanels(row){
            this.OPENINEDITPAGE({name:"isPanelsEdit",value:true}),
            // this.isPanelsEdit = true;
            this.$router.push(
                {
                    name:"panelsedit",
                    params:row,
                }
            );
        },
         //打开删除弹窗
         openDelPanelsFrom(item){
            this.del.delPanelsFrom = true;
            this.del.entranceDeviceId = item.id;
            return false
        },
         //点击确认删除框确认按钮执行函数
         delPanelsFromBtn(){
            this.delPanels({id:this.del.entranceDeviceId});
        },
        //删除
        delPanels(item){
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delPanelsObj = {}
            delPanelsObj.entranceDeviceId = item.id;
            this.del.delBtnState = 1;
            sitesPanelsApi.delSitePanels(rgnObj,delPanelsObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.del.delPanelsFrom = false
                    this.del.delBtnState = 0;
                    //获取panels列表
                    let serachPanelsObj = {
                            "apartmentId":this.serach.apartmentId,
                            "deviceName":this.serach.deviceName,
                            "deviceStatus":this.serach.deviceStatus,
                            "pageNum":1
                        };
                    this.getPanelsList(serachPanelsObj);
                }else{
                    this.$handerrcode(res.code);
                    this.del.delBtnState = 0;
                }
                
            }).catch((err) => {
                console.log('err', err);
                this.del.delBtnState = 0;
            })
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            let serachPanelsObj = {
                "apartmentId":this.serach.apartmentId,
                "deviceName":this.serach.deviceName,
                "deviceStatus":this.serach.deviceStatus,
                "pageNum":val
            };
            this.getPanelsList(serachPanelsObj);
        },
        //设备绑定弹窗
        openBindPanelsFrom(item){
            this.binding.bindPanelsFrom = true;
            this.binding.deviceId = "";
            this.binding.securityCode = "";
            this.binding.entranceDeviceId = item.id;
        },
        //设备绑定确认
        bindNewPanels(){
            let checkResultDeviceId = this.$regularCheckLimit(this.binding.deviceId,1,20,'DeviceSerialLimit');
            if(!checkResultDeviceId){
                return false
            }
           
            let checkResultSecurityCode = this.$regularCheckLimit(this.binding.securityCode,1,64,'DeviceSecurityCodeLimit');
            if(!checkResultSecurityCode){
                return false
            }
           
            this.binding.bindBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let bindPanelsObj = {
                "siteId":this.selectSite.id,
                "entranceDeviceId":this.binding.entranceDeviceId,
                "deviceId":this.binding.deviceId,
                "securityCode": sha256(this.binding.securityCode),
            }
            // console.log("bindPanelsObj",bindPanelsObj);
            // return false
            sitesPanelsApi.bindSitePanels(rgnObj,bindPanelsObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('BindingSuccessful'));
                    this.binding.bindBtnState = 0;
                    this.binding.bindPanelsFrom = false;
                    //获取panels列表
                    let serachPanelsObj = {
                            "apartmentId":this.serach.apartmentId,
                            "deviceName":this.serach.deviceName,
                            "deviceStatus":this.serach.deviceStatus,
                            "pageNum":1
                        };
                    this.getPanelsList(serachPanelsObj);
                }else{
                    this.binding.bindBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.binding.bindBtnState = 0;
            })
        },
        //设备解绑
        openUnbindPanelsFrom(item){
            this.unbinding.unbindPanelsFrom = true;
            this.unbinding.entranceDeviceId = item.id;
            this.unbinding.deviceId = item.deviceId;
        },
        //设备解绑点击确认执行
        unbindPanelsFromBtn(){
            this.unbinding.unbindBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let unbindPanelsObj = {
                // "siteId":this.selectSite.id,
                "entranceDeviceId":this.unbinding.entranceDeviceId,
                "deviceId":this.unbinding.deviceId,
            }
            // console.log("unbindPanelsObj",unbindPanelsObj);
            // return false
            sitesPanelsApi.unbindSitePanels(rgnObj,unbindPanelsObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UnbindSuccessful'));
                    this.unbinding.unbindBtnState = 0;
                    this.unbinding.unbindPanelsFrom = false;
                    //获取panels列表
                    let serachPanelsObj = {
                            "apartmentId":this.serach.apartmentId,
                            "deviceName":this.serach.deviceName,
                            "deviceStatus":this.serach.deviceStatus,
                            "pageNum":1
                        };
                    this.getPanelsList(serachPanelsObj);
                }else{
                    this.unbinding.unbindBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.unbinding.unbindBtnState = 0;
            })
        },
    },
    computed:{
        ...mapState(["selectCompany","selectSite","isEditPage"])
    },
    watch: {
         // 监听路由信息对象中的path属性
         "$route.path"(path) {
            if(path === "/main/panels"){
                this.OPENINEDITPAGE({name:"isPanelsEdit",value:false});
                //this.isPanelsEdit = false; // 当path发生变化的时候执行关联操作调用计算函数
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('Panels')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);
                 //获取panels列表
                let serachPanelsObj = {
                    "apartmentId":this.serach.apartmentId,
                    "deviceName":this.serach.deviceName,
                    "deviceStatus":this.serach.deviceStatus,
                    "pageNum":1
                };
                this.getPanelsList(serachPanelsObj);
            }else{
                //this.$route.path = "/main/blocks"
                // this.$router.push({name:"blocks"});
            }
        }
    }
}
</script>

<style scoped>
.panelsBox{
    padding: 10px 30px;
}

@media screen and (max-width: 600px) {
    .panelsBox{
    padding: 10px 0;
}
}
.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .serachBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding: 30px 10px; */
    }
}
.serachInput{
    width: 12vw;
    min-width: 170px;
    margin-right: 24px;
    border: 1px solid #979797;
    background: #fff;
    border-radius: 4px;

}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}
::v-deep .serachInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: none;
}
.serachSel{
    width: 12vw;
    min-width: 170px;
    margin-right: 24px;
    border: 1px solid #979797;
    background: #fff;
    border-radius: 4px;
}
@media screen and (max-width: 600px) {
    .serachSel{
        width: 170px;
        margin-top: 10px;
        margin-right: 24px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}

::v-deep .serachSel .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input__inner:focus {
    border: none;
}
.serachItem{
    min-width: 170px;
}
/* .serachBtn{
    margin-left: 24px;
} */
@media screen and (max-width: 600px) {
    .serachBtn{
        /* margin-left: 24px; */
        margin-top: 10px;
        width: 170px;
    }
}
.serachLeft{
    margin-top: 0.5vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.serachRight{
    margin-top: 0.5vw;
}
@media screen and (max-width: 600px) {
    .serachLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5px;
    }
}
@media screen and (max-width: 600px) {
    .serachRight{
        margin-top: 10px;
        width: 170px;
    }
}
.el-divider--horizontal{
    height: 2px;
}

.noPanels{
    color: #ACACAC;
    margin-top: 10vw;
}
::v-deep .el-table .el-table__cell{
    padding: 8px 0;
}

::v-deep .el-table{
    min-height: 510px !important;
}
.pageBox {
    /* position: fixed;
    bottom: 2vw; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed;
        bottom: 10px; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}

::v-deep .statusGreen{
    color: green;
}
::v-deep .statusOrange{
    color: orange;
}
::v-deep .statusRed{
    color: red;
}
::v-deep .statusBlue{
    color: blue;
}
.addPanelsBox{
    display: flex;
}
.addPanelsLeft{
    width: 50%;
    padding-right: 1vw;
}
.addPanelsRight{
    width: 50%;
    font-size: 18px !important;
    padding: 12px 30px;
}
.addPanelsRight p{
    color: #333;
}
.permissionDiv{
    margin-top: 1vw;
    border-radius: 8px;
    padding: 0.5vw 1vw 2vw 1vw;
    background: #EFEFEF;

}
.permissionItem{
    display: flex;
    padding-top: 1vw;
}
.permissionLeft{
    width: 35%;
    color: #333;
    font-size: 20px;
}
.permissionRight{
    width: 65%;
    display: flex;
    justify-content: space-evenly;
}

.permissionRight img:hover{
    cursor: pointer;
}

::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 1080px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        min-width: 300px;
        overflow: auto;
    }
}
.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
    color: #333;
}
::v-deep .addPanelsLeft .el-input__inner {
    height: 40px;
    line-height: 40px;
    width: 100%;
    border:none;
    background: #EFEFEF;
}
.addPanelsLeft p {
    font-size: 16px;
    color: #CFCFCF;
    cursor: pointer;
}
.addPanelsLeft p span{
    color: #6498b5;
}

.addPanelsLeft .el-select{
    width: 100%;
}
/* .el-form-item {
    margin-top: 20px;
} */
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 60px;
}
.el-form-item__label {
    color: #333;
}
::v-deep .el-dialog__footer {
    padding: 10px 20px 35px;
    text-align: center !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}

.bindPanelsBox{
    display: flex;
    justify-content: center;
}
::v-deep .bindPanelsDialog .el-dialog {
    border-radius: 15px !important;
    width: 620px ;
}
.bindPanelsDiv{
    width: 100%;
    padding-right: 1vw;
}
::v-deep .bindPanelsDiv .el-input__inner {
    height: 40px;
    line-height: 40px;
    width: 100%;
    border:none;
    background: #EFEFEF;
}
.bindPanelsDiv p {
    font-size: 16px;
    color: #CFCFCF;
    cursor: pointer;
}
.bindPanelsDiv p span{
    color: #6498b5;
}

.bindPanelsDiv .el-select{
    width: 100%;
}
</style>