import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router';
import store from './store/index.js' //引入store.js

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css'
import 'default-passive-events'
import i18n from './langs/index.js'
import App from './App.vue'

import { formatDate } from '@/mixins/index'
import commonFun from '@/common/index.js'

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.mixin(formatDate);
Vue.prototype.$generateBreadcrumb = commonFun.generateBreadcrumb;
Vue.prototype.$handerrcode = commonFun.handErrCode;
Vue.prototype.$handFieldNull = commonFun.handFieldNull;
Vue.prototype.$regularCheckLimit = commonFun.regularCheckLimit;
Vue.config.productionTip = false


new Vue({
    render: h => h(App),
    router,
    store,
    i18n,
}).$mount('#app')