<template>
    <div class="topLogn">
        <!--编辑公司弹框-->
        <el-dialog :title="$t('CompanyList')" 
        :visible.sync="editCompany.editComFrom" 
        :close-on-click-modal="false" 
        :close-on-press-escape="false" 
        center
        append-to-body
        :show-close="editCompany.showClose">
            <template>
                <el-table
                :data="editCompany.companyList"
                style="width: 100%; border-right: 3px solid #6498b5;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    label="#"
                    type="index"
                    width="50">
                </el-table-column>
                
                <el-table-column
                    prop="companyName"
                    :label="$t('CompanyNameOnly')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column width="200"  label="..." align="center">
                    <template slot-scope="scope">
                        <el-image :src="editImg"  @click="editCompanyImg(scope.row)" style="width:24px;" fit="fill"></el-image>
                        <el-image :src="deleteImg" @click="delCompanyImg(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>                            
                    </template>
                </el-table-column>
                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editCompany.editComFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary"  @click="editCompany.editComFrom = false">{{$t('Confirm')}}</el-button>
            </div>
            <el-dialog :title="$t('Rename')" 
            :visible.sync="editCompany.renameComFrom" 
            :close-on-click-modal="false" 
            :close-on-press-escape="false" 
            center 
            append-to-body
            :show-close="editCompany.showClose"
            class="renameBox"
            >
                <el-form>
                    <el-form-item :label="$t('InputNewCompanyName')">
                        <el-input v-model="editCompany.reCompanyName" class="reNameInput"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editCompany.renameComFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="editCompany.renamebtnState === 1" @click="renameCompany">{{$t('Confirm')}}</el-button>
                </div>
            </el-dialog>
            <el-dialog :title="$t('Delete')" 
            :visible.sync="editCompany.delComFrom" 
            :close-on-click-modal="false" 
            :close-on-press-escape="false" 
            center 
            append-to-body
            :show-close="editCompany.showClose"
            class="renameBox"
            >
                <p class="deleteDesc">{{$t('SureDeleteCompany')}}</p>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editCompany.delComFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="editCompany.delbtnState === 1" @click="deleteCompany">{{$t('Confirm')}}</el-button>
                </div>
            </el-dialog>
            
        </el-dialog>
        <div class="breadcrumbDiv">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item 
                :to="item.path" 
                v-for="item in breadcrumbList" 
                :key="item.id"
                >
                    {{ item.name }}
                </el-breadcrumb-item>
                <!-- <el-breadcrumb-item :to="{ path: '/' }">Site</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
        <div class="logoImgDiv">
            <el-image
            class="logoImg"
                :src="url"
                fit="contain">
            </el-image>
        </div>
        <div class="userInfoDiv">
            <div v-if="selectModel === 'company'">
                <img :src="editImgCompany" @click="editCompanyInfo" style="vertical-align: middle;margin-right: 8px;" alt="">
                <el-select v-model="companyValue" @change="getCompanySelect" :disabled="$store.state.companyList.length === 1" :placeholder="$t('PleaseSelect')">
                    <el-option
                    v-for="item in companyOptions"
                    :key="item.id"
                    :label="item.companyName"
                    :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div v-if="selectModel === 'site'">
                <el-select v-model="siteValue" @change="getSiteSelect" :placeholder="$t('PleaseSelect')">
                    <el-option
                    v-for="item in siteOptions"
                    v-show="item.role != null"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div style="width: 32px;height: 32px;margin-left: 30px;line-height: 0px;">
                <el-dropdown @command="handleCommand">
                    <el-image
                        style="width: 32px;height: 32px;"
                        :src="url_avatar"
                        fit="contain">
                    </el-image>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>{{userInfo.userName}}</el-dropdown-item>
                        <el-dropdown-item command="logout">{{$t('Logout')}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        
    </div>
</template>
  
<script>
import Cookies from 'js-cookie'
import {nanoid} from 'nanoid'
import toplogoApi from '@/api/toplogo/index.js'
import qv_logo from '@/assets/images/qv_logo.png'
import avatar from '@/assets/images/avatar.png'
import editImgCompany from '@/assets/images/edit_company.png'
import editImg from '@/assets/images/edit.png'
import deleteImg from '@/assets/images/delete.png'
import {mapState, mapMutations} from "vuex"
import commonFun from '@/common/index.js'
export default {
    name:"TopLogo",
    data(){
        return{
            url:qv_logo,
            url_avatar:avatar,
            editImgCompany,
            editImg,
            deleteImg,
            companyValue: this.$store.state.selectCompany.id || "",
            //companyValue: this.$store.state.companyList[0].id || "",
            companyOptions: this.$store.state.companyList || [],
            siteValue: this.$store.state.selectSite.id || "",
            siteOptions:this.$store.state.siteList || [],
            editCompany:{
                editComFrom:false,
                showClose:true,
                companyList:[],
                renameComFrom:false,
                companyRgn:"",
                companyId:"",
                reCompanyName:"",
                renamebtnState:0,
                delComFrom:false,
                delbtnState:0,
            }
         }
    },
    mounted(){
        // this.companyValue = this.$store.state.selectCompany.id;
        // this.companyOptions = this.$store.state.companyList;
        this.getCompanyList();
        this.generateBreadcrumb();
    },
    methods:{
        ...mapMutations(["GENERATEBREADLIST","CREATECOMPANY","GETSELECTCOMPANY","GETSELECTSITE","GETCOMPANYLIST"]),
        //获取公司列表
        getCompanyList(){
            toplogoApi.getCompanyList().then(res => {
                if(res.code === 0){
                    if(res.data.length === 0){
                        this.GETCOMPANYLIST([
                            {
                                "id": "createCompany",
                                "companyName": "+ " + this.$t('CreateCompany')
                            }
                        ])
                        this.editCompany.companyList = [];
                        this.editCompany.editComFrom = false;
                        this.GETSELECTCOMPANY(this.companyList[0]);
                        this.companyValue = this.selectCompany.id;
                    }else{
                        let getCompanyArr = [];
                        // getCompanyArr = res.data;
                        res.data.map(item => {
                            getCompanyArr.push(item)
                        })
                        getCompanyArr.push( {
                            "id": "createCompany",
                            "companyName": "+ " + this.$t('CreateCompany')
                        });
                        let selectCompanyIsDel = true;
                        if(this.selectCompany){
                            //选中选择当前选中公司
                            getCompanyArr.map((item,index) => {
                                if(item.id === this.selectCompany.id){
                                    // this.GETSELECTCOMPANY(getCompanyArr[index]);
                                    selectCompanyIsDel = false
                                }
                            })
                            if(selectCompanyIsDel){
                                //公司选择框默认选中第一个公司
                                this.companyValue = getCompanyArr[0].id;
                                //默认选中第一个公司
                                this.GETSELECTCOMPANY(getCompanyArr[0]);
                            }else{
                                //公司选择当前选中的公司
                                this.companyValue = this.selectCompany.id;
                                getCompanyArr.map((item,index) => {
                                if(item.id === this.selectCompany.id){
                                    this.GETSELECTCOMPANY(getCompanyArr[index]);
                                }
                            })
                            } 
                        }else{
                            //公司选择框默认选中第一个公司
                            this.companyValue = getCompanyArr[0].id;
                            //默认选中第一个公司
                            this.GETSELECTCOMPANY(getCompanyArr[0]);
                        }

                        //公司选择框默认选中第一个公司
                        //this.companyValue = getCompanyArr[0].id;
                        //默认选中第一个公司
                        //this.GETSELECTCOMPANY(getCompanyArr[0]);
                        //更新公司列表
                        this.GETCOMPANYLIST(getCompanyArr);
                        this.editCompany.companyList = [];
                        res.data.map(item => {
                            this.editCompany.companyList.push(item);
                        })
                    }
                    this.generateBreadcrumb();
                }else{
                    this.$handerrcode(res.code);
                }
                
            }).catch((err) => {

            })
        },
        //公司选择框改变执行函数
        getCompanySelect(){
            if(this.companyValue === "createCompany"){
                this.CREATECOMPANY(true);
                //this.GETSELECTCOMPANY(commonFun.getCompayInfo(this.companyList,'createCompany'))
                //this.companyValue = "-";
                this.companyValue = this.$store.state.selectCompany.id;
                // this.$router.go(0)
            }else{
                // console.log("this.companyValue",this.companyValue);
                this.GETSELECTCOMPANY(commonFun.getCompayInfo(this.$store.state.companyList,this.companyValue));
                
                if(this.$store.state.selectCompany.role === "admin"){
                    //改变公司后，直接切换到公司首页
                    this.$router.push({name:"companydashboard"});
                }else{
                    this.$router.push({name:"sitesmanage"});
                }
               
            }
            this.generateBreadcrumb();
           
           
        },
        //site选择框改变执行函数
        getSiteSelect(){
            // this.$store.commit("GETSELECTSITE",this.siteValue);
            this.GETSELECTSITE(commonFun.getSiteInfo(this.$store.state.siteList,this.siteValue));
            this.generateBreadcrumb();
            // console.log("selectSite",this.selectSite);
            if(this.selectSite.role === 'admin' || this.selectSite.role === 'site_admin'){
                //改变Site后，直接切换到Site首页
                this.$router.push({name:"sitedashboard"});
            }else{
                this.$router.push({name:"profile"});
            }
           
        },
        handleCommand(command) {
            if(command == "logout"){
                Cookies.remove('token');
                this.$router.push({name:"login"});
            }else{
                console.log("there is no match");
            }
            // console.log("command",command);
        },
        generateBreadcrumb() {
            // 初始化面包屑导航数据
            const breadcrumbList = [];
            if(this.selectModel === "company"){
                if(this.selectCompany.role === "admin"){
                    breadcrumbList.push(
                        {
                            id:nanoid(),
                            path:'/main/companydashboard',
                            name:this.selectCompany.id === "createCompany"? "":this.selectCompany.companyName,
                        },
                        
                    );
                }else{
                    breadcrumbList.push(
                        {
                            id:nanoid(),
                            name:this.selectCompany.id === "createCompany"? "":this.selectCompany.companyName,
                        },
                        
                    );
                }
                
            }else if(this.selectModel === "site"){
                breadcrumbList.push(
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany.id === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitesmanage',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:'Site Dashboard'
                    }
                );
            }
            
             // 保存面包屑导航数据
             //this.$store.commit("GENERATEBREADLIST",breadcrumbList);
             this.GENERATEBREADLIST(breadcrumbList);
        },
        //编辑公司执行函数
        editCompanyInfo(){
            this.editCompany.editComFrom = true;
            this.getCompanyList();
        },
        //点击公司编辑图标执行函数
        editCompanyImg(item){
            this.editCompany.renameComFrom = true;
            this.editCompany.companyRgn = item.companyRegion;
            this.editCompany.companyId = item.id;
            this.editCompany.reCompanyName = "";

        },
        //修改公司名称点击确定
        renameCompany(){
            let checkResultCompanyName = this.$regularCheckLimit(this.editCompany.reCompanyName.trim(),1,30,'CompanyNameLimit');
            if(!checkResultCompanyName){
                return false
            }
            let rgnObj = {
                "rgn":this.editCompany.companyRgn,
            }
            let editCompanyObj = {
                "id":this.editCompany.companyId,
                "companyName":this.editCompany.reCompanyName
            }
            this.editCompany.renamebtnState = 1;
            toplogoApi.updateCompanyInfo(rgnObj,editCompanyObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.getCompanyList();
                    this.editCompany.renamebtnState = 0;
                    this.editCompany.renameComFrom = false;
                    // this.$router.push({name:"main"});
                }else{
                    this.$handerrcode(res.code);
                    this.editCompany.renamebtnState = 0;
                }
            }).catch((err) => {
                console.log(err);
                this.editCompany.renamebtnState = 0;
            })
        },
        //点击公司删除图标执行函数
        delCompanyImg(item){
            this.editCompany.delComFrom = true;
            this.editCompany.companyRgn = item.companyRegion;
            this.editCompany.companyId = item.id;
        },
        //删除公司点击确定
        deleteCompany(){
            let rgnObj = {
                "rgn":this.editCompany.companyRgn,
            }
            let delCompanyObj = {
                "id":this.editCompany.companyId,
            }
            this.editCompany.delbtnState = 1;
            toplogoApi.deleteCompany(rgnObj,delCompanyObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.editCompany.delbtnState = 0;
                    this.editCompany.delComFrom = false;
                    this.getCompanyList();
                    // this.$router.push({name:"main"});
                }else{
                    this.$handerrcode(res.code);
                    this.editCompany.delbtnState = 0;
                }
            }).catch((err) => {
                console.log(err);
                this.editCompany.delbtnState = 0;
            })
        }
    },
    computed:{
        ...mapState(["userInfo","selectModel","selectCompany","breadcrumbList","selectSite","companyList"]),
    },
    watch: {
    // 监听路由信息对象中的path属性
    "$route.path"() {
      this.generateBreadcrumb(); // 当path发生变化的时候执行关联操作调用计算函数
    },
    //检测当前选中的公司，来及时更新面包屑导航和公司下来列表框选中的值
    // selectCompany(){
    //     this.generateBreadcrumb();
    //     this.companyValue = this.$store.state.selectCompany.id
    // },
    "selectCompany.id"(){
        //获取公司下site列表
        this.getCompanyList();
    },
    "selectSite.id"(){
        this.siteValue = this.$store.state.selectSite.id
    }
  },
   

}
</script>
  
<style scoped>
  .topLogn{
    /* background-color:#6380F5; */
    box-sizing: border-box; 
    height: 60px;
    width: 98%;
    margin-left: 1%;
    /* line-height: 60px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 600px) {
    .topLogn{
        /* background-color:#6380F5; */
        box-sizing: border-box; 
        height: 120px;
        width: 100%;
        margin-left: 0%;
        /* line-height: 60px; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}
.breadcrumbDiv{
    /* width: 15vw; */
    width: 33.33%;
    min-width: 260px;
    font-size: 2vw;
}
@media screen and (max-width: 600px) {
    .breadcrumbDiv{
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }
}
.el-breadcrumb ::v-deep .el-breadcrumb__inner {
    color: #fff !important;
}
::v-deep .el-breadcrumb__separator{
    color: #fff !important;
}
.logoImgDiv{
    width: 33.33%;
}
@media screen and (max-width: 600px) {
    .logoImgDiv{
        height: 30px;
        width: 100%;
    }
}
.logoImg{
    width: 101px;
    min-width: 101px;
    /* margin-top: 45px; */
}
@media screen and (max-width: 600px) {
    .logoImg{
        width: 101px;
        min-width: 101px;
        margin-top: 5px;
    }
}
.userInfoDiv{
    display: flex; 
    align-items: center;
    width: 33.33%;
    justify-content: flex-end;
}
::v-deep .el-input__inner{
    border-radius: 20px !important;
    height: 30px;
    line-height: 30px;
}
::v-deep .el-input__icon{
    line-height: 30px;
}

::v-deep .el-dialog {
    border-radius: 15px !important;
    /* width: 635px; */
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 60px;
}
.el-dialog__footer {
    padding: 10px 20px 35px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
::v-deep.renameBox .el-dialog {
    width: 30% !important;
}
::v-deep .el-form-item__label {
    margin-top: 16px !important;
    font-size: 16px;
}
::v-deep.reNameInput .el-input__inner {
    height: 50px;
    line-height: 50px;
    width: 100%;
    border:none;
    border-radius: 4px !important;
    background: #EFEFEF;
}
.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
    padding: 60px 0;
    text-align: center;
}
</style>