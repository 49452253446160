<template>
    <div>
        <!--新增公司弹框-->
        <el-dialog :title="$t('CreateCompany')" 
        :visible.sync="createComFrom" 
        :close-on-click-modal="false" 
        :close-on-press-escape="false" 
        @close="cancalCompamy"
        center 
        :show-close="showClose">
            <!-- <el-form :model="add" :rules="rules" ref="CompanyNameValidate"> -->
            <el-form :model="add"  ref="CompanyNameValidate">
                <el-form-item :label="$t('CompanyName')">
                    <el-input v-model="add.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancalCompamy">{{$t('Cancel')}}</el-button>
                <!-- <el-button type="primary" @click="submitCreatCompany('CompanyNameValidate')">确 定</el-button> -->
                <el-button type="primary" :loading="btnState === 1" @click="createNewCompany">{{$t('Confirm')}}</el-button>
            </div>
        </el-dialog>
        <div class="creatComBox">
            <el-button type="primary" class="createBtn" v-show="!addCompany" @click="createComFrom = true;add.name = ''">
                <span class="companyPlus"><i class="el-icon-plus"></i></span>
                <span>{{$t('CreateCompany')}}</span>
            </el-button>
        </div>
    </div>
</template>
  
<script>
import { mapState, mapMutations } from 'vuex';
import creatCompanyApi from '@/api/createcompany/index.js'
export default {
    name:"CreateCompany",
    data(){
        // let checkCompanyName = (rule, value, callback) => {
        //     if (value === '') {
        //         callback(new Error('公司名不能为空'));
        //     }else{
        //         setTimeout(() => {
        //             if (value.length>=1 && value.length<=30) {
        //                 callback();
        //             } else {
        //                 callback(new Error("公司名长度在 1 到 30 个字符"));
        //             }
        //         }, 100);
        //     }
        // }
        return{
            createComFrom: false,
            showClose:true,
            btnState:0,//确实按钮状态
            add: {
                name: '',
            },
            // rules:{
            //     name: [
            //         { required: true, validator: checkCompanyName, trigger: 'blur' }
            //     ]
            // },
        }
    },
    mounted(){
        if(this.addCompany){
            this.createComFrom = true;
        }
    },
    methods:{
        ...mapMutations(["GETSELECTCOMPANY","GETCOMPANYLIST","GETSELECTCOMPANY"]),
        // submitCreatCompany(formName){
        //     this.$refs[formName].validate((valid) => {
        //     if (valid) {
        //         this.createNewCompany();
        //         this.createComFrom = false;
        //         this.$store.commit("CREATECOMPANY",false);
        //     } else {
        //         // console.log('error submit!!');
        //         return false;
        //     }
        //     });
           
        // },
        //新增公司方法
        createNewCompany(){
            let companyNameObj = {
                "companyName":this.add.name
            };
            if((this.add.name).trim() === ""){
                this.$message.info(this.$t('CompanyNameEmpty'));
                return false
            }
            let reg = /^.{1,30}$/;
            if(!reg.test(this.add.name)){
                this.$message.info(this.$t('CompanyNameLimit'));
                return false
            }
            // return false
            this.btnState = 1;
            creatCompanyApi.addNewCompany(companyNameObj).then( res=>{
                if(res.code===0){
                    this.btnState = 0;
                    this.createComFrom = false;
                    this.$message.success(this.$t('CreatedSuccessfully'));
                    //获取公司列表
                    creatCompanyApi.getCompanyList().then(res => {
                        if(res.code === 0){
                            if(res.data.length === 0){
                                this.GETCOMPANYLIST([
                                    {
                                        "id": "createCompany",
                                        "companyName": "+ " + this.$t('CreateCompany')
                                    }
                                ])
                                this.GETSELECTCOMPANY(this.companyList[0]);
                            }else{
                                let getCompanyArr = res.data;
                                getCompanyArr.push( {
                                    "id": "createCompany",
                                    "companyName": "+ " + this.$t('CreateCompany')
                                });
                                //默认选中第一个公司
                                this.GETSELECTCOMPANY(getCompanyArr[0]);
                                //更新公司列表
                                this.GETCOMPANYLIST(getCompanyArr);
                            }
                            this.$router.push({name:"companydashboard"});
                        }else{
                            this.$handerrcode(res.code);
                        }
                        
                    }).catch((err) => {

                    })
                }else{
                    this.btnState = 0;
                    this.$handerrcode(res.code);
                }
                
            }
            ).catch((err) => {
                this.btnState = 0;
                console.log(err);
            })
            // this.btnState = 0;
            // this.createComFrom = false;
            this.$store.commit("CREATECOMPANY",false);
        },
        cancalCompamy(){
            this.createComFrom = false;
            this.$store.commit("CREATECOMPANY",false);
        }
    },
    computed:{
        ...mapState(["addCompany","companyList","selectCompany"])
    },
    
}
</script>
  
<style scoped>
.creatComBox{
    height: calc(100vh - 150px);
}
@media screen and (max-width: 600px) {
    .creatComBox {
        height: calc(100vh - 210px);
    }
}
 .createBtn{
    font-size: 18px;
    margin-top: 3.5vw;
    height: 3vw;
    min-height: 50px;
    width: 15vw;
    min-width: 260px;
    border-radius: 9px;
 }
 @media screen and (max-width: 600px) {
    .createBtn{
        margin-top: 10vw;
        height: 50px;
        width: 260px;
        border-radius: 9px;
    }
}
.companyPlus{
    font-size: 24px;
    margin-top: -3px;
    float: left;
    margin-left: 10px;
}
::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 635px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        width: 300px;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
}
.el-form-item {
    margin-top: 20px;
}
::v-deep .el-input {
    font-size: 16px;
    margin-top: 10px;
}
::v-deep .el-input__inner {
    height: 50px;
    line-height: 50px;
    width: 100%;
    border:none;
    background: #E9F6FF;
}
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 60px;
}
.el-dialog__footer {
    padding: 10px 20px 35px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
</style>