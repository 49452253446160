export default {
    "Language": "Language",
    "UserName": "User Name",
    "Email": "Email",
    "PassWord": "Password",
    "Login": "Login",
    "SignUp": "Sign Up",
    "Welcome": "Welcome",
    "ForgotPasswrod": "Forgot Password?",
    "NoAccount": "Don't have an account yet. Register",
    "ServerError": "system error",
    "ReadAgree": "I have read and agree to",
    "And": "and",
    "ScanQRCodeDownloadAPP": "Please Scan The Following QR Code For Downloading App And Creating Accounts",
    "EmailEmpty": "Email cannot be empty",
    "PasswordEmpty": "The password cannot be empty",
    "PleaseSelect": "Please Select",
    "Logout": "Logout",
    "CreateCompany": "Create Company",
    "CompanyName": "Company Name *",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "CompanyNameEmpty": "The company name cannot be empty",
    "CreatedSuccessfully": "Created successfully",
    "CompanyDashboard": "Company Dashboard",
    "Sites": "Site",
    "CompanyAdmin": "Company Admin",
    "PurchaseRecord": "Purchase Record",
    "MoreDetail": "More Detail",
    "CreateSite": "New Site",
    "Admin": "Admins",
    "AdminAccess": "Manage Users Who Have Administrator Access To This Company",
    "Licenses": "Licenses",
    "PaymentInvioces": "View Your Payment History And Manage Company Invioces",
    "SiteName": "Site Name *",
    "SiteAddress": "Address *",
    "Add": "Add",
    "Edit": "Edit",
    "Delete": "Delete",
    "DeleteSiteTitle": "Are you sure you want to delete the site?",
    "NoData": "No Data",
    "SiteNameEmpty": "The site name cannot be empty",
    "SiteAddressEmpty": "The site address cannot be empty",
    "UpdateSuccessfully": "Update successfully",
    "DeleteSuccessfully": "Delete Successfully",
    "AddAdmin": "Add Admin",
    "AddCompanyAdmin": "Add Company Admin",
    "RegisteredAccount": "Please Enter The Registered Account",
    "EmailAccount": "Email Account *",
    "AdminCompanyRole": "User Role Company Admin:",
    "ManagesSitesAdmins": "Manages All Sites And Site Admins.",
    "AccessBilling": "Makes Payments And Has Access To Billing Info.",
    "Serach": "Search",
    "UserInfo": "User Info",
    "UserAccount": "User Account:",
    "Nickname": "Nickname:",
    "FirstName": "First Name *",
    "Surname": "Surname",
    "SureDelete": "Are you sure to delete?",
    "NoRecord": "No Record",
    "AdminName": "Admin Name",
    "Account": "Account",
    "Role": "Role",
    "CreateDate": "Creation Date",
    "EmailEmpty": "The email account cannot be empty",
    "EmailIncorrect": "The email account does not exist or is incorrect",
    "FirstNameEmpty": "The first name cannot be empty!",
    "SurnameNameEmpty": "The surname cannot be empty!",
    "Save": "Save",
    "StartTime": "Start Time",
    "EndTime": "End Time",
    "NoPurchaseRecord": "There is no Purchase Record!",
    "LicenseName": "License Name",
    "Quantity": "Quantity",
    "Amount": "Amount",
    "StartTimeEmptye": "The start time cannot be empty",
    "EndTimeEmptye": "The end time cannot be empty",
    "StartTimeLaterEndTime": "The start time cannot be later than the end time",
    "SiteDashboard": "Site Dashboard",
    "Blocks": "Block",
    "Panels": "Panels",
    "SiteUsers": "Site Users",
    "EventRecords": "Event Records",
    "AssessLog": "Access Log",
    "SiteLicenses": "Site Licenses",
    "Payments": "Payments",
    "MyProfile": "My Profile",
    "AddBlocks": "Add Blocks",
    "BlocksOnSite": "Blocks On Site",
    "SeeAllPanels": "See All Panels",
    "AddPanels": "Add Panels",
    "AddedDecices": "Added Devices",
    "Normal": "Normal",
    "NeedConfigration": "Need Configuration",
    "CheckFail": "Error",
    "Offine": "Offine",
    "SeeAllUsers": "See All Users",
    "Users": "Users",
    "AddUsers": "Add Users",
    "AddedUser": "Added User",
    "LicensesNormal": "Active Licences",
    "LicensesExpired": "Expired Licences",
    "NotOpened": "Not Opened",
    "NotAccepted": "Not Accepted",
    "BuyLiences": "Buy Licenses",
    "RemainingQuantit": "Remaining Quantity:",
    "BuyMore": "Buy More",
    "BlocksName": "Blocks Name *",
    "BlocksNumber": "Blocks Number *",
    "DeleteBlock": "After the building is deleted, all users under the building will be unbound, and these users will not be able to use the entrance machine service of the park; All door equipment under the building will be unbound; The deletion operation cannot be restored.",
    "CreateBlock": "Create New Blocks",
    "NoBlock": "No Existing Blocks,Please Prioritize Adding Blocks!",
    "PanelsQuantity": "Panel Quantity",
    "UsersQuantity": "User Quantity",
    "CreateSites": "Create New Sites",
    "BlocksNameEmpty": "The blocks name cannot be empty",
    "BlocksNumberEmpty": "The blocks number cannot be empty",
    "BlockDetail": "Block Detail",
    "DeletePanel": "Are you sure to remove the device from the campus? After deletion, it will automatically disappear from the relevant user account.",
    "NoPanel": "No Information,Please Prioritize Adding Devices!",
    "PanelsId": "Panel ID",
    "PanelsName": "Panel Name",
    "PanelsStatus": "Panel Status",
    "DeleteUser": "After the data is deleted, the user (and the shared) will be unbound, and the user will not be able to use the entrance service of the park; The deletion operation cannot be restored.",
    "NoUser": "No user information, please add user.",
    "HouseNumber": "House Number",
    "UserRole": "User Role",
    "UserStatus": "User Status",
    "ServiceStatus": "Licence Status",
    "ServicesRemaining": "Remaining Duration",
    "BlocksDetail": "Blocks Detail",
    "Invalid": "Invalid",
    "Ready": "Ready",
    "NeedVerification": "Need Verification",
    "NotResponding": "Not Responding",
    "Accepted": "Accepted",
    "Refuse": "Refuse",
    "NotBound": "Not Bound",
    "NotEnabled": "Not Enabled",
    "InUse": "In Use",
    "End": "End",
    "Days": "Days",
    "CreatePanels": "Create New Panel",
    "PanelsSerialNumber": "Panel Serial Number *",
    "NotFndSerial": "if you can't fnd serial number please click here",
    "PanelsSecurityCode": "Panel Security Code *",
    "PanelsSerialCode": "if you can't fnd security code please click here",
    "Or": "Or",
    "RolePermission": "Role permission control",
    "SiteUser": "Site User",
    "SiteEmployee": "Site Employee",
    "SiteOther": "Site Other",
    "Status": "Status",
    "PanelsSerialNumberEmpty": "The Panel serial number cannot be empty",
    "PanelsSecurityNumberEmpty": "The Panels serial security Code cannot be empty",
    "PanelsNameEmpty": "The Panel name cannot be empty",
    "SiteOrBlocksEmpty": "The Site Or Blocks cannot be empty",
    "AddSuccessful": "Add successful",
    "RevalidateDevice": "Revalidate Device",
    "PanelsDetail": "Panels Detail",
    "AccessControl": "Access Control",
    "DevicesMode": "Devices Mode",
    "SerialNumber": "Serial Number",
    "FirmwareVersion": "Firmware Version",
    "Revalidate": "Revalidate",
    "UpdateFailure": "Update Failure",
    "AddSiteUser": "Add Site User",
    "AddingUser": "Adding user,please wait a moment...",
    "UserEmail": "User email",
    "ActivationMethod": "Activation method",
    "ChooseActivationMethod": "choose how to activate the service for the user.",
    "AvailableActivationCodes": "Available activation codes",
    "AddUserSuccess": "Add User Success!",
    "UserHasJoined": "User Has Joined",
    "UserServiceActivated": "User's service is activated!",
    "ActivationCode": "Activation code",
    "UserConfrmation": "User confrmation",
    "ConfirmationEmail": "A confirmation email has been sent to the user, pleasenotify the user to complete the follow-up operationaccording to the prompt of the email content.",
    "ScanQRCode": "Or Please Scan The QR Code With The APP To Join!",
    "ServiceActivation": "Service Activation",
    "Total": "Total",
    "Select": "Select",
    "Activation": "Activation",
    "UserServiceActivation": "User service activation,please wait a moment...",
    "ConsumedActivate": "sheets will be consumed to activate the following users",
    "BindingUser": "Binding User",
    "BindingUserWait": "Binding user,please wait a moment...",
    "UnbindingUser": "Unbinding User",
    "UnbindingUserDesc": "After unbinding, the user will not be able to use the entrance machine service of the site;",
    "ActivationTypeOne": "Users buy and open by themselves",
    "ActivationTypeTwo": "Assign activation code",
    "ActivationTypeThree": "Fill in the activation code",
    "Years": "years",
    "Year": "year",
    "Months": "months",
    "Month": "month",
    "Day": "Day",
    "UserEmailEmpty": "The User email cannot be empty",
    "HouseNumberEmpty": "The House Number cannot be empty",
    "UserRoleEmpty": "The User role cannot be empty",
    "ActivationMethodEmpty": "The Activation method be empty",
    "SelectAtLeastOne": "Please select at least one criterion to search",
    "SelectAtLeastActivate": "Please select at least one user to activate",
    "SelectLicense": "Please select a license",
    "InsufficientLicense": "Insufficient License quantity",
    "ActivationSuccessful": "Successful Activation",
    "BindingSuccessful": "Binding Successful",
    "UnbindSuccessful": "Unbind Successfully",
    "UserDetail": "User Detail",
    "Devices": "Devices",
    "UserSetting": "User Setting",
    "Augment": "Augment",
    "DeviceName": "Device Name",
    "DeviceId": "Device ID",
    "AccessRights": "Access Rights",
    "AssessStatus": "Access Status",
    "SiteDevices": "Site Devices",
    "Preview": "Preview",
    "PreviewEnd": "Preview、",
    "Unlock": "Unlock",
    "UnlockEnd": "Unlock、",
    "Call": "Call",
    "CallEnd": "Call、",
    "NoAssessLog": "No access log is recorded!",
    "OperationModule": "Operation module",
    "OperationContent": "Operation Content",
    "Company": "Company",
    "UpdateSite": "Update Site",
    "DeleteSite": "Delete Site",
    "UpdateBlock": "Update Block",
    "DeleteBlock": "Delete Block",
    "BindingPanels": "Binding Panels",
    "UpdatePanels": "Update Panels",
    "UnbindingPanels": "Unbinding Panels",
    "UpdateUser": "Update User",
    "DeleteUserLog": "Delete User",
    "PurchasingLicence": "Purchasing Licence",
    "UpdateCompanyAdmin": "Update Company Admin",
    "DeleteCompanyAdmin": "Delete Company Admin",
    "UserLogin": "User Login",
    "RocessingPayment": "Rocessing payment data,please wait...",
    "PaySuccess": "Pay Success",
    "PayFail": "Pay Fail",
    "Person": "Person",
    "BuyQuantity": "Buy Quantity",
    "CustomQuantity": "Custom Quantity",
    "PayPlatfrom": "Pay Platfrom",
    "PayAmount": "Pay Amount",
    "Pay": "Pay",
    "ServiceLicenses": "Service Licenses",
    "ActivationRecords": "Activation Records",
    "NoLicense": "No license available, please purchase!",
    "LicensesName": "Licenses Name",
    "ActivationKey": "Code",
    "SelectLicensePurchase": "Please select the license you want to purchase",
    "PaymentFailure": "Order payment failure",
    "BuyNow": "Buy Now",
    "OrderNo": "Order No",
    "OrderStatus": "Order Status",
    "Date": "Date",
    "OrderName": "Order Name",
    "OrderTime": "Order Time",
    "PayTime": "Pay Time",
    "Pending": "Pending",
    "Completed": "Completed",
    "Expired": "Expired",
    "UseAPPScanQRCode": "Use the QvHome APP to scan the code to use the service",
    "RequestParameterError": "Request parameter error",
    "InvalidRequest": "Invalid request",
    "RequestTimeou": "Request timeout",
    "UserNoLogin": "User not logged in",
    "RemoteServiceNotFound": "Remote service not found",
    "ErrorCallingRemoteService": "Error calling remote service",
    "DataNotFound": "Data not found",
    "RequestsTooFrequent": "Requests are too frequent",
    "NoOperationPermission": "No operation permission",
    "DataAlreadyExists": "Data already exists",
    "UserNamePassword": "The user name and password do not match",
    "AccountAlreadyExists": "Account already exists",
    "VerificationCodeError": "Verification code error",
    "UnknownError": "Unknown error",
    "NoAuthorizedCompany": "No authorized company found",
    "NoAuthorizedSite": "No authorized site found",
    "UserNotJoinHouse": "The user did not join the house",
    "CloudPushNotEnabled": "The cloud push service is not enabled",
    "DeviceBound": "Device bound",
    "HouseNumberAlreadyExists": "The house number already exists",
    "HouseNumberNotExists": "The house number does not exist",
    "BlockNumberNotExists": "Tower number does not exist",
    "DeviceUnbound": "Device Unbound",
    "InventoryShortag": "Inventory shortage",
    "PaymentOrderVerificationFailed": "Payment order verification failed",
    "PaymentFailed": "Payment failed",
    "NoPackageFound": "No package opening order found",
    "NoAvailablePackages": "No available packages found",
    "PackageHasModified": "The package has been modified",
    "ChangedOnce": "The package can only be changed once within 7 days",
    "OrdersNonRefundable": "Orders are non-refundable",
    "PaymentConfigurationNotFound": "Payment configuration not found",
    "OrderRefundFailed": "Order refund failed",
    "PackageLessThanOneDay": "There is less than one day left in the package",
    "DataSaveError": "Data save error",
    "DataUpdateError": "Data update error",
    "DataDeleteError": "Data delete error",
    "CompanyAdminExistsSite": "The company administrator already exists in the site",
    "NotAllowDeleteOurself": "Not allow delete ourself",
    "ProductNotSupportBind": "This product does not support binding",
    "DeviceSecurityCheckFail": "The device security code verification fails",
    "DeviceNotSupportServer": "The device does not support this service",
    "OemConfigNotFount": "Oem configuration not found",
    "EmailSendFail": "Email sending failed",
    "UserAccountNotFound": "User account not found",
    "CompanyDataNotFond": "Company data not found",
    "DevicesDataNotFond": "Device data not found",
    "DevicesNoPermission": "Device does not have permission",
    "UserNotBindingHouse": "User does not bind house",
    "PkgNotAvailable": "Package unavailable",
    "UserNotFond": "User not found",
    "AuthNotFound": "Auth not found",
    "IotUnlockFail": "Unlocking failure",
    "UserMismatchOem": "The user Oem does not match",
    "AlarmCancelShareFail": "Alarm cancel sharing failure",
    "AlarmUnbindFail": "Alarm unbind failure",
    "AlarmShareFail": "Alarm sharing failure",
    "InvitationInvalid": "Invitation invalid",
    "UnallocatedLicense": "Unallocated license",
    "HaveBeenUsed": "Have been used",
    "CompanyNameLimit": "The company name must contain 1 to 30 characters",
    "SiteNotFound": "Site not found",
    "SiteNameLimit": "The site name must contain 1 to 30 characters",
    "EmailFormatLimit": "The email format is incorrect",
    "FirstNameLimit": "The first name must be 1 to 20 characters in length",
    "SurnameLimit": "The surname must be 1 to 20 characters in length",
    "PersonalInfo": "Personal Info",
    "BlockNameLimit": "The block name must contain 1 to 30 characters",
    "BlockNumberLimit": "The block Number must contain 1 to 30 characters",
    "DeviceSerialLimit": "The panels serial number must contain 1 to 20 characters",
    "DeviceSecurityCodeLimit": "The panel security code must contain 1 to 64 characters",
    "DeviceNameLimit": "The panel name must contain 1 to 30 characters",
    "HouseNumberLimit": "The house number must contain 1 to 20 characters",
    "LoginExpired": "User login expired, please log in again",
    "ServicesRemainingLimit": "The value of remaining days of service can only be 0 or a positive integer ranging from 0 to 8 digits",
    "LastAdminNotDelete": "The last administrator cannot be deleted",
    "CanNotUpdateOwnRoles": "You cannot update your administrator role",
    "UserUnbindingFailed": "User unbinding failed",
    "AdminAccountLimit": "Admin accounts must contain 1-100 characters",
    "UserNameLimit": "The username must contain 1-30 characters",
    "OrderNoLimit": "The order number must contain 1-64 characters",
    "licensesNumLimit": "Only positive integers between 1 and 999 can be entered",
    "BlockNumberNotZero": "The block number cannot be 0",
    "CompanyOverLimit": "The number of companies created exceeds the limit",
    "SiteOverLimit": "The number of sites created exceeds the limit",
    "ApartmentOverLimit": "The number of apartments created exceeds the limit",
    "Notice": "Notices",
    "PanelLog": "Panel Log",
    "OperationLog": "Operation Log",
    "Panel": "Panel",
    "CallHasBeenAnswered": "The call has been answered",
    "CallNotAnswered": "Call not answered",
    "Time": "Time",
    "Default": "Default",
    "QRCodeUnlock": "QR Code",
    "PasswordUnlock": "Unlock Password",
    "IndoorMachineUnlock": "Indoor Machine Unlock",
    "AppUnlock": "APP Unlock",
    "RfCard": "RFCard",
    "ExitButton": "Exit Button Unlock",
    "DtmfUnlock": "DTMF Unlock",
    "GoogleUnlock": "Google Unlock",
    "AlexaUnlock": "Alexa Unlock",
    "FaceUnlock": "Face Unlock",
    "Success": "Success",
    "Failed": "Failed",
    "Loading": "Loading",
    "ApartmentNumber": "Apartment Number",
    "Image": "Image",
    "CallLog": "Call Log",
    "AccessLog": "Access Log",
    "Export": "Export",
    "UnlockType": "Unlock Type",
    "NoExportData": "No export data!",
    "ExportSuccess": "Export successfully",
    "Return": "Return",
    "APPNotice": "App Notice",
    "DeviceNotice": "Device Notice",
    "Refresh": "Refresh",
    "AddNotice": "Add Notice",
    "Withdraw": "Withdraw",
    "Release": "Release",
    "More": "More",
    "Broadcast": "Broadcast",
    "ChooseDevice": "Choose Device",
    "ReceptionDevice": "Reception Device：",
    "SelectedDeviceNumber": "Selected Device Number：",
    "Title": "Title：",
    "DigitsLimit": "The title length must be 1-50digits",
    "TitlePicture": "Title Picture：",
    "PictureRatio": "Note：We recommend uploading a picture with a 9:16 ratio for the best result.",
    "TimeRange": "Time Range：",
    "CreateNotice": "Create Notice",
    "Update": "Update",
    "Modify": "Modify",
    "Content": "Content",
    "ReleaseStatus": "Release Status",
    "Released": "Released",
    "TitleTable": "Title",
    "Draft": "Draft",
    "TitlePictureEmpty": "Please add the title image",
    "DeleteFailed": "Delete Failed",
    "ImageUploadFailed": "Image Upload Failed",
    "PleaseSelectDevice": "Please select device",
    "MaximumDevicesSelected": "A maximum of 10 devices can be selected",
    "SuccessfulWithdraw": "Successful Withdraw",
    "SuccessfulRelease": "Successful Release",
    "RemoveDeviceNotice": "Remove Device Notice",
    "WithdrawDeviceNotice": "Withdraw Device Notice",
    "ReleaseDeviceNotice": "Release Device Notice",
    "UpdateNotice": "Update Notice",
    "UploadImagejpg": "Please upload the image with.jpg extension!",
    "ConfirmPassword": "Confirm Password",
    "Prompt": "Prompt",
    "AutoJumpLogin": "It will automatically jump to the login page after 0 seconds. Click Confirm to jump to the login page immediately.",
    "RegisteredSuccess": "Registered Successfully",
    "PasswordChangedSuccess": "Password changed successfully",
    "SendVerificationCode": "Send verification code",
    "ResendSeconds": "Resend in 0 seconds",
    "VerificationCode": "Verification Code",
    "PasswordFormatLimit": "Password Must Be 8-16 characters, Must Contain Uppercase And Lowercase Letters,Numbers,And Special Characters.",
    "ChangePassword": "Change Password",
    "NextStep": "Next",
    "EnterVerificationCode": "Please enter the verification code",
    "EnterCorrectEmailFormat": "Please enter the correct email format",
    "PasswordLess": "The password should be no less than 8 characters",
    "PasswordLarger": "The password should not be larger than 16 characters",
    "PasswordNotRules": "The password does not comply with the rules",
    "EntePasswordAgain": "Please enter password again",
    "PasswordNotMatch": "The password and confirmation password do not match",
    "VerificationCodeSentSuccess": "The verification code was sent successfully",
    "UrlExpired": "Url is expired",
    "EmailAccountExists": "Email account already exists",
    "VerificationCodeIncorrect": "The verification code is incorrect",
    "VerificationCodeInvalid": "The verification code is invalid",
    "TermsCondition": "Terms&Condition",
    "PrivacyPolicy": "Privacy Policy",
    "CheckReadAgree": "Please check I have read and agree to the Terms&Condition and Privacy Policy",
    "DisplayName": "Display Name",
    "AccountSettings": "Account Settings",
    "Remark": "Remark",
    "Server": "Server",
    "Port": "Port",
    "TCPOrUDP": "TCP/UDP",
    "DTMFSetting": "DTMF Setting",
    "Lock1": "Lock1",
    "Lock2": "Lock2",
    "Talk": "Talk",
    "DisplayNameEmpty": "The display name must contain 1 to 64 characters",
    "RemarkEmpty": "The remark must contain 1 to 64 characters",
    "ServerEmpty": "The server must contain 1 to 64 characters",
    "PortEmpty": "The port must contain 1 to 20 characters",
    "TCPOrUDPEmpty": "The TCP/UDP cannot be empty",
    "AccountEmpty": "The Account must contain 1 to 64 characters",
    "Lock1Empty": "The Lock1 must contain 1 to 20 characters",
    "Lock2Empty": "The Lock2 must contain 1 to 20 characters",
    "TalkEmpty": "The Talk must contain 1 to 20 characters",
    "NoSip": "No Information,Please Prioritize Adding New Sip Server!",
    "PasswordLimit": "Password must contain 1 to 64 characters",
    "NoGuard": "No Information,Please Prioritize Adding New Guard!",
    "SipSettings": "Sip Settings",
    "GuardSettings": "Guard Settings",
    "ConfirmDelSip": "Confirm deleting Sip Setting?",
    "GuardNameLimit": "Guard Name must contain 1 to 20 characters",
    "AddressEmpty": "The address cannot be empty",
    "GuardName": "Guard Name",
    "CreateGuard": "Create New Guard",
    "EditGuard": "Edit Guard",
    "Address": "Address",
    "ConfirmDelGuard": "Confirm deleting Guard?",
    "AddSipServer": "Add New Sip Server",
    "ConfirmUnbindPanel": "Confirm unbinding the panel?",
    "PleaseSelectPanels": "Please Select Panels",
    "ServerConfigEmpty": "The server config cannot be empty",
    "Mode": "Mode",
    "SeverMode": "Sever Mode",
    "P2PMode": "P2P Mode",
    "ServerConfig": "Server Config",
    "TargetSip": "Target Sip",
    "P2PConfig": "P2P Config",
    "CreateDeviceConfig": "Create Device Config",
    "UpdateDeviceConfig": "Update Device Config",
    "DeleteDeviceConfig": "Delete Device Config",
    "CreateUserHouseConfig": "Create Site Users Config",
    "UpdateUserHouseConfig": "Update Site Users Config",
    "DeleteUserHouseConfig": "Delete Site Users Config",
    "CreateDeviceGuardConfig": "Create Ddevice guard config",
    "UpdateDeviceGuardConfig": "Update Ddevice guard config",
    "DeleteDeviceGuardConfig": "Delete Ddevice guard config",
    "CreateDeviceNotice": "Create device notice",
    "UpdateDeviceNotice": "Update device notice",
    "DeleteDeviceNotice": "Delete device notice",
    "DeviceNoticeRelease": "Release device notice",
    "DeviceNoticeWithdraw": "Withdraw device notice",
    "EnterTitle": "Please enter title",
    "Unused": "Not Use",
    "ServerName": "Server Name",
    "DeviceDelete": "Device Delete",
    "PrivateMessage": "Private Message",
    "Profile": "Profile",
    "EnableProfile": "Enable Profile",
    "DisableProfile": "Disable Profile",
    "WhetherEnableProfile": "Whether profile are enabled",
    "WhetherDisableProfile": "Whether to disable profile",
    "PleaseSelectUser": "Please select user",
    "MaximumUserSelected": "A maximum of 10 users can be selected",
    "SelectedUserNumber": "Selected User Number：",
    "RemoveAppNotice": "Remove App Notice",
    "WithdrawAppNotice": "Withdraw App Notice",
    "ReleaseAppNotice": "Release App Notice",
    "TamperLog": "Tamper Log",
    "ReleaseType": "Release Type",
    "ReadNumber": "Read Number",
    "MaximumCharacterLimit": "Content can only be entered with a maximum of 1 characters",
    "MaximumNoticeReachLimit": "The maximum number of announcements has been reached",
    "UploadedImageLimit": "The size of the uploaded image cannot exceed 2MB!",
    "ContentCannotEmpty": "The content cannot be empty!",
    "EndTimeGreateCurrentTime": "The end time needs to be greater than the current time!",
    "AccountNotExist": "Account does not exist",
    "PasswordError": "Password is error",
    "PictureRatioApp": "Note：We recommend uploading a picture with a 16:9 ratio for the best result.",
    "Name": "Name",
    "NameCannotEmpty": "Names must be 1-64 characters long",
    "CardNumber": "Full Card Number",
    "CardNumberEmpty": "The full card number cannot be empty",
    "UnlockOption": "Unlock Option",
    "UnlockOptionEmpty": "The unlock option cannot be empty",
    "Limited": "Limited",
    "LimitedEmpty": "The limit cannot be empty",
    "CardSettings": "Card Settings",
    "LimitedUse": "Limited Use",
    "ValidityTime": "Validity Time",
    "PanelSync": "Panel Sync",
    "AddCardNumber": "Add Card Number",
    "NumbeOfUses": "Numbe Of Uses",
    "Unlinmited": "Unlinmited",
    "Forever": "Forever",
    "EditCardNumber": "Edit Card Number",
    "ConfirmDeleteCard": "Confirm deleting Card Setting?",
    "NotApplied": "Not Applied",
    "ApplicationComplete": "Application Complete",
    "DataValidationFailed": "Data Validation Failed",
    "TypesNotSupported": "Types Not Supported",
    "DeviceLimitedInput": "Device Limited Input",
    "CCTVSettings": "CCTV Settings",
    "IPRTSPUrl": "IP/RTSP Url",
    "Stream": "Stream",
    "DeletingCCTV": "Confirm deleting CCTV configuration?",
    "MainStream": "Main Stream",
    "SubStream": "Sub Stream",
    "AddCamera": "Add Camera",
    "EditCamera": "Edit Camera",
    "IPRTSPUrlEmpty": "The IP/RTSP Url cannot be empty",
    "UsernameEmpty": "The username cannot be empty",
    "StreamEmpty": "The stream cannot be empty",
    "DeviceVersion": "Device Version",
    "CardNumberExists": "The card number already exists",
    "AddCard": "Add Card",
    "FacilityCodeCardNumber": "Facility Code&Card Number",
    "PleasePlacePard": "Please place a card on the reader",
    "ReaderNotDetected": "Reader is not detected, Please check your driver installation or Cable Connection !",
    "DownloadDriver": "Download Driver",
    "TestDriver": "Test Driver",
    "ChooseDriverDownload": "Please Choose The Corresponding Driver To Download AccordingTo Your PC System:",
    "RestartTest": "Restart To Test",
    "FacilityCode": "Facility Code",
    "FullCardNumLimit": "The card number entered is invalid. Please enter it again",
    "FacilityCodeCardNumberEmpty": "Facility Code&Card Number cannot be empty",
    "FacilityCodeEmpty": "The Facility Code must be numeric and cannot be greater than 255",
    "FacilityCardNumberEmpty": "The Card Number must be a number and cannot be greater than 65535",
    "ManualInput": "Manual Input",
    "Reader": "Reader",
    "SiteList": "Site List",
    "ManageSelectedSite": "Manage Selected Site",
    "ManageSelectEmpty": "Please select the site to be managed",
    "AdminAccountExists": "Admin account already exists",
    "CompanyNameOnly": "Company Name",
    "DeleteCompanyLimit": "Please delete all the sites under the company before deleting the company",
    "CompanyList": "Company List",
    "Rename": "Rename",
    "InputNewCompanyName": "Please input a new company name:",
    "SureDeleteCompany": "Are you sure you want to delete the company?",
    "SelectPayPlatform": "Please select the payment platform",
    "LimitReached": "The limit has been reached",
    "LimitPassword": "The maximum length of the password cannot exceed 64 characters",
    "LimitUserName": "The maximum length of a username cannot exceed 64 characters",
    "LimitUnlockNum": "The maximum number of restrictions cannot exceed 100",
    "LimitHouseNumber": "House numbers are only supported in large and small letters or numbers and must be greater than 0 if they are pure numbers",
    "LimitBlockNumber": "The block number can only be numerical and must be greater than 0",
    "CardNumberWrong": "The card number is wrong",
    "UsersReachLimit": "Add users to reach the limit",
    "DeviceReachLimit": "Add devices to reach the limit",
}