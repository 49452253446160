//导入封装好的新的axios
import request from '@/request/index'

//查询公司下site
export function getCompanySiteListNoPage(params) {
    return request({
        url: '/' + params.rgn + '/company/site/page',
        method: 'get',
        params: {
            companyId: params.companyId,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
        },
    })
}

//License购买记录查询
export function getLicensesPurchaseRecord(params) {
    return request({
        url: '/' + params.rgn + '/site/license/purchase/order',
        method: 'get',
        params: {
            siteId: params.siteId,
            adminName: params.adminName,
            companyId: params.companyId,
            startTime: params.startTime,
            endTime: params.endTime,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
        },
    })
}

export default { getCompanySiteListNoPage, getLicensesPurchaseRecord }