<template>
    <div class="panelsEditBox">
      <!-- Company Admin Detail编辑 -->
      <div class="panelsContent">
        <div class="panelsBox">
            <div class="panelsBoxLeft">
                <div class="adminInfo">
                    <div>{{$t('UserInfo')}}</div>
                    <p><span>{{$t('UserAccount')}} </span> {{updateAdmin.account}}</p>
                    <p><span>{{$t('Nickname')}} </span> {{updateAdmin.nickName}}</p>
                </div>
                <div class="panelsItem">
                    <label>{{$t('FirstName')}}</label><el-input v-model="updateAdmin.firstName" class="editInput" placeholder="Panels Name"></el-input>
                </div>
                <div class="panelsItem">
                    <label>{{$t('Surname')}}</label><el-input v-model="updateAdmin.surname" class="editInput" placeholder="Panels Name"></el-input>
                </div>
            </div>
            <div class="panelsBoxRight">
                <div class="adminInfo">
                    <div>{{$t('UserRole')}}</div>
                    <div>
                        <el-select v-model="updateAdmin.role" class="editInput" @change="changeRole">
                            <el-option
                                v-for="item in updateAdmin.roleOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div v-show = "updateAdmin.role === 'admin'" style="text-align:left;font-weight:300;">
                        <p>{{$t('ManagesSitesAdmins')}}</p>
                        <p>{{$t('AccessBilling')}}</p>
                    </div>
                    <div v-show = "updateAdmin.role === 'site_admin'" style="text-align:left;">
                        <div>{{$t('SiteList')}}</div>
                        <el-select v-model="updateAdmin.site" multiple collapse-tags class="editInput">
                            <el-option
                            v-for="item in updateAdmin.siteOptions"
                            :key="item.id"
                            :label="item.siteName"
                            :value="item.id">
                            </el-option>
                        </el-select>
                        <p style="font-weight:300">{{$t('ManageSelectedSite')}}</p>
                    </div>
                </div>
            </div>
        </div>
        <el-button class="savetBtn" :disabled="updateAdmin.saveBtn" :loading="this.updateAdmin.updateBtnState == 1" @click="saveUpdateAdmin">{{$t('Save')}}</el-button>
      </div>
    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import { mapState } from 'vuex';
import companyadminApi from '@/api/companyadmin/index.js'
export default {
    name:"CompanyAdminEdit",
    data(){
        return{
            updateAdmin:{
                id:"",
                account:"",
                nickName:"",
                firstName:"",
                newFirstName:"",
                surname:"",
                newSurname:"",
                role: "",
                newRole: "",
                roleOptions: [
                    {
                        id:"admin",
                        name:"admin"
                    },
                    {
                        id:"site_admin",
                        name:"site admin"
                    },
                ],
                site: [],
                newSite: [],
                siteOptions: [],
                saveBtn:true,
                updateBtnState:0,
            }
        }
    },
    mounted(){
        // this.$store.commit("CHANGEMODEL","company");
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/companyadmin',
                name:this.$t('CompanyAdmin')
            }
        ];
        this.getCompanySiteList();//获取公司下园区列表
        //this.$generateBreadcrumb(siteMangeBread);
        this.updateAdmin.id = this.$route.params.id
        this.detailCompanyAdmin({companyAdminId:this.updateAdmin.id});
    },
    methods:{
            // ...mapMutations(["OPENINEDITPAGE"]),
        //获取company admin详情
        detailCompanyAdmin(item){
            // console.log("detail",item);
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyAdminId":item.companyAdminId
            };
             // 刷新时，conpanyAdmin数据id丢失，这里做个规避
             if(this.updateAdmin.id === undefined){
                return false
            }
            this.updateAdmin.role = "";
            this.updateAdmin.site = [];
            companyadminApi.detailCompanyAdmin(rgnObj).then(res => {
                if(res.code === 0){
                    this.updateAdmin.account = res.data.userAccount;
                    this.updateAdmin.nickName = res.data.nickName;
                    this.updateAdmin.firstName = res.data.firstName;
                    this.updateAdmin.newFirstName = res.data.firstName;
                    this.updateAdmin.surname = res.data.surname;
                    this.updateAdmin.newSurname = res.data.surname;
                    this.updateAdmin.role = res.data.userRole;
                    this.updateAdmin.newRole = res.data.userRole;
                    if(res.data.siteList != null && res.data.siteList != undefined){
                        this.updateAdmin.site = [];
                        this.updateAdmin.newSite = [];
                        res.data.siteList.map(item=>{
                            this.updateAdmin.site.push(item.siteId);
                            this.updateAdmin.newSite.push(item.siteId);
                        })
                    }else{
                        this.updateAdmin.site = [];
                        this.updateAdmin.newSite = [];
                    }
                    
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },

        //获取公司下site列表
       async getCompanySiteList(){
            let conpanyInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "pageSize":-1,
                "pageNum":1
            }
            await companyadminApi.getCompanySiteListNoPage(conpanyInfoObj).then(res => {
                if(res.code === 0){
                    // console.log(res.data.list == null)
                    if(res.data == null || res.data.length === 0){
                        this.updateAdmin.siteOptions = [];
                    }else{
                        this.updateAdmin.siteOptions = [];
                        res.data.list.map(item => {
                            this.updateAdmin.siteOptions.push(item)
                        })
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
       
        //用户角色改变执行函数
        changeRole(val){
            if(val === "site_admin"){
                // this.updateAdmin.site = [];
            }
        },

        //更新Company Admin基本信息
        saveUpdateAdmin(){
            if((this.updateAdmin.firstName).trim() === ""){
                this.$message.info(this.$t('FirstNameEmpty'));
                return false
            }
            let reg = /^.{1,20}$/;
            if(!reg.test(this.updateAdmin.firstName)){
                this.$message.info(this.$t('FirstNameLimit'));
                return false
            }
            if((this.updateAdmin.surname).trim() === ""){
                this.$message.info(this.$t('SurnameNameEmpty'));
                return false
            }
            if(!reg.test(this.updateAdmin.surname)){
                this.$message.info(this.$t('SurnameLimit'));
                return false
            }
            if(this.updateAdmin.role === "site_admin"){
                if(this.updateAdmin.site.length === 0){
                    this.$message.info(this.$t('ManageSelectEmpty'));
                    return false
                }
            }else{
                this.updateAdmin.site = [];
            }
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let updateAdminObj = {
                "companyAdminId":this.updateAdmin.id,
                "firstName":this.updateAdmin.firstName,
                "surname":this.updateAdmin.surname,
                "userRole":this.updateAdmin.role,
                "siteList":this.updateAdmin.site,
            }
            
            this.updateAdmin.updateBtnState = 1;
            // console.log("updateAdminObj",updateAdminObj);
            // return false
            companyadminApi.updatelCompanyAdmin(rgnObj,updateAdminObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.updateAdmin.saveBtn = true;
                    this.updateAdmin.updateBtnState = 0;
                    this.updateAdmin.newFirstName = this.updateAdmin.firstName;
                    this.updateAdmin.newSurname = this.updateAdmin.surname;
                    this.updateAdmin.newRole = this.updateAdmin.role;
                    this.updateAdmin.newSite = [];
                    this.updateAdmin.newSite = this.updateAdmin.site;
                    //获取详情
                    this.updateAdmin.id = this.$route.params.id
                    this.detailCompanyAdmin({companyAdminId:this.updateAdmin.id});
                }else{
                    this.$handerrcode(res.code);
                    this.updateAdmin.updateBtnState = 0;
                }
            }).catch((err) => {
                console.log(err);
                this.updateAdmin.updateBtnState = 0;
            })
        },
     
       
    },
    computed:{
        ...mapState(["selectCompany","selectSite"]),
    },
    watch:{
        'updateAdmin.firstName'(){
            if(this.updateAdmin.firstName != this.updateAdmin.newFirstName || 
                this.updateAdmin.surname != this.updateAdmin.newSurname ||
                this.updateAdmin.role != this.updateAdmin.newRole ||
                JSON.stringify(this.updateAdmin.site) != JSON.stringify(this.updateAdmin.newSite)
            ){
                this.updateAdmin.saveBtn = false;
            }else{
                this.updateAdmin.saveBtn = true;
            }
            
        },
        'updateAdmin.surname'(){
            if(this.updateAdmin.firstName != this.updateAdmin.newFirstName || 
                this.updateAdmin.surname != this.updateAdmin.newSurname ||
                this.updateAdmin.role != this.updateAdmin.newRole ||
                JSON.stringify(this.updateAdmin.site) != JSON.stringify(this.updateAdmin.newSite)
            ){
                this.updateAdmin.saveBtn = false;
            }else{
                this.updateAdmin.saveBtn = true;
            }
            
        },
        'updateAdmin.role'(){
            if(this.updateAdmin.firstName != this.updateAdmin.newFirstName || 
                this.updateAdmin.surname != this.updateAdmin.newSurname ||
                this.updateAdmin.role != this.updateAdmin.newRole ||
                JSON.stringify(this.updateAdmin.site) != JSON.stringify(this.updateAdmin.newSite)
            ){
                this.updateAdmin.saveBtn = false;
            }else{
                this.updateAdmin.saveBtn = true;
            }
            
        },
        'updateAdmin.site':{
            handler(newVal, oldVal) {
                // 检查新旧数组的值是否相等
                if (this.updateAdmin.firstName != this.updateAdmin.newFirstName || 
                    this.updateAdmin.surname != this.updateAdmin.newSurname ||
                    this.updateAdmin.role != this.updateAdmin.newRole ||
                    JSON.stringify(this.updateAdmin.site) != JSON.stringify(this.updateAdmin.newSite)
                ) {
                    this.updateAdmin.saveBtn = false;
                } else {
                    this.updateAdmin.saveBtn = true;
                }
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.panelsEditBox{
   box-sizing: border-box;
   padding: 15px 30px;
}

.el-button {
    height: 30px;
    width: 140px;
    padding: 6px 20px;
    font-size: 15px;
    background: #ccc;
    color: #000;
}
.el-button:hover{
    background: #6498B5;
    color: #fff;
}
.el-button:active {
    background: #6498B5;
    color: #fff;
}
.el-button:focus {
    background: #6498B5;
    color: #fff;
  }
.adminInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.adminInfo div{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1vw;
}

.adminInfo p{
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
}

::v-deep .el-input {
    font-size: 16px;
    margin-top: 10px;
}
.panelsItem{
    display: flex;
    align-items: baseline;
    margin-top: 1vw;
}
.panelsItem label{
   min-width: 150px;
}
@media screen and (max-width: 600px) {
    .panelsItem{
       justify-content: center;
    }
}
.editInput{
    width: 12vw;
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .editInput{
        width: 170px;
    }
}
::v-deep .editInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #333;
    background: #fff;
}
.panelsItem label{
    width: 135px;
    text-align: left;
}
.panelsItem input{
    width: 300px;
}
::v-deep .editInput .el-input__inner:hover {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #333;
    background: #fff;
}
::v-deep .el-input .el-input--suffix .is-focus{
    border: 1px solid #333 !important;
    border-radius: 4px !important;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
    border: 1px solid #333 !important;
    border-radius: 4px !important;
}
::v-deep .el-select .el-input .el-select__caret {
    color: #333;
    font-weight: bold;
}
::v-deep .el-input .el-input--suffix{
    border: 1px solid #333 !important;
    border-radius: 4px;
}
::v-deep .el-select .el-input__inner:focus {
    border: 1px solid #333 !important;
    border-radius: 4px;
}


::v-deep .el-select .el-input.is-disabled .el-input__inner {
    background: #fff;
}
.savetBtn{
    background: #6498B5;
    color: #fff;
    font-weight: bold;
    position: fixed;
    bottom: 10vw;
}
.panelsContent{
    margin-top: 20px;
}


.panelsBox{
    display: flex;
    flex-wrap: wrap;
}
.panelsBoxLeft{
    /* width: 30%; */
    width: 30vw;
    min-width: 350px;
}
@media screen and (max-width: 600px) {
    .panelsBoxLeft{
        width: 100%;
    }
}
.panelsBoxRight{
    /* width: 30%; */
    width: 30vw;
    /* margin-top: 1vw; */
    margin-left: 5vw;
    min-width: 350px
}
@media screen and (max-width: 600px) {
    .panelsBoxRight{
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }
}


</style>