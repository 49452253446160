<template>
    <div class="companyAdminContent">
        <div class="companyAdminBox" v-show="!isEditPage.isCompanyAdminEdit">
            <el-dialog :title="add.addAdminTitle" 
            :close-on-click-modal="false" 
            :close-on-press-escape="false" 
            :visible.sync="add.addAdminFromBox" 
            center>
                <!--新增Admin弹框(根据账号搜索用户id)-->
                <div v-show="add.addAdminFrom">
                    <el-form :model="add">
                        <el-form-item :label="$t('EmailAccount')">
                            <el-input v-model="add.account"></el-input>
                        </el-form-item>
                        <p>{{$t('RegisteredAccount')}}</p>
                    </el-form>
                    <div class="comAdminDescribe">
                        <div>{{$t('AdminCompanyRole')}}</div>
                        <p>{{$t('ManagesSitesAdmins')}}</p>
                        <p>{{$t('AccessBilling')}}</p>
                    </div>
                    <div slot="footer" class="dialog-footer footerBtn">
                        <el-button @click="add.addAdminFromBox = false">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" :loading="add.addBtnState === 1" @click="searchComAdmin">{{$t('Serach')}}</el-button>
                    </div>
                </div>
                <div v-show="addCompanyAdmin.addComAdminForm">
                    <div class="addsiteUersBox" >
                        <div class="addSiteUserLeft">
                            <div class="adminInfo">
                                <div>{{$t('UserInfo')}}</div>
                                <p><span>{{$t('UserAccount')}}</span> {{add.account}}</p>
                                <p><span>{{$t('Nickname')}} </span> {{add.nickName}}</p>
                            </div>
                            <el-form :model="addCompanyAdmin">
                                <div class="NameBox">
                                    <div style="width:48%">
                                        <el-form-item :label="$t('FirstName')">
                                        <el-input v-model="addCompanyAdmin.firstName"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div style="width:48%">
                                        <el-form-item :label="$t('Surname')">
                                        <el-input v-model="addCompanyAdmin.surname"></el-input>
                                    </el-form-item>
                                    </div>
                                </div>
                            
                            </el-form>
                        </div>
                        <div class="addSiteUserRight">
                            <div class="adminInfo">
                                <!-- <div>{{$t('AdminCompanyRole')}}</div> -->
                                <div>{{$t('UserRole')}}</div>
                                <div>
                                    <el-select v-model="add.role" @change="changeRole">
                                        <el-option
                                            v-for="item in add.roleOptions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div v-show = "add.role === 'admin'">
                                    <p>{{$t('ManagesSitesAdmins')}}</p>
                                    <p>{{$t('AccessBilling')}}</p>
                                </div>
                                <div v-show = "add.role === 'site_admin'">
                                    <div>{{$t('SiteList')}}</div>
                                    <el-select v-model="add.site" multiple collapse-tags>
                                        <el-option
                                        v-for="item in add.siteOptions"
                                        :key="item.id"
                                        :label="item.siteName"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <p style="font-weight:300">{{$t('ManageSelectedSite')}}</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div slot="footer" class="dialog-footer footerBtn">
                        <el-button @click="add.addAdminFromBox = false">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" :loading="addCompanyAdmin.addBtnState === 1" @click="addNewComAdmin">{{$t('Confirm')}}</el-button>
                    </div>
                </div>
                
            </el-dialog>
            <!--删除admin弹框-->
            <el-dialog
                :title="$t('Delete')"
                :visible.sync="del.delAdminFrom"
                :close-on-click-modal="false" 
                :close-on-press-escape="false" 
                width="30%">
                <p class="deleteDesc">{{$t('SureDelete')}}</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="del.delAdminFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="del.delBtnState === 1" @click="delCompanyAdminBtn">{{$t('Confirm')}}</el-button>
                </span>
            </el-dialog>
        
        <div class="addAdminBox">
                <el-button type="primary" @click="openAddAdminFrom">{{$t('AddAdmin')}}</el-button>
        </div>
        <el-divider></el-divider>
        <div class="adminContent">
            <div class="noActivaRecord" v-show="serach.hasNoRecords">{{$t('NoRecord')}}</div>
                <template>
                    <el-table
                    v-show="!serach.hasNoRecords"
                    :data="serach.adminList"
                    @row-dblclick="handleCompanyAdminRow"
                    style="width: 100%;"
                    :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                    >
                    <el-table-column
                        prop="surname"
                        :label="$t('AdminName')"
                        width="150"
                        >
                        <template slot-scope="scope">
                            {{ $handFieldNull(scope.row.adminName)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="userAccount"
                        :label="$t('Account')"
                        width="300"
                        >
                        <template slot-scope="scope">
                            {{ $handFieldNull(scope.row.userAccount)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="userRole"
                        :label="$t('Role')"
                        width="200"
                        >
                        <template slot-scope="scope">
                            {{ handleSiteRole(scope.row.userRole)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="siteList"
                        :label="$t('Sites')"
                        width="300"
                        show-overflow-tooltip
                        >
                        <template slot-scope="scope">
                            {{ handleSiteAdmin(scope.row.siteList)}}
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="createTime"
                        :label="$t('CreateDate')"
                        width="200"
                        >
                        <template slot-scope="scope">
                            {{ utctolocal(scope.row.createTime)}}
                        </template>
                    </el-table-column>
                    <el-table-column width="200"  label="..." align="center">
                            <template slot-scope="scope">
                                <el-image :src="serach.editImg"  @click="editAdmin(scope.row)" style="width:24px;" fit="fill"></el-image>
                                <el-image :src="serach.deleteImg" @click="delAdmin(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>                            
                            </template>
                        </el-table-column>
                    <el-table-column>

                    </el-table-column>
                
                    </el-table>
                </template>
                <div class="pageBox">
                    <el-pagination
                        v-show="!serach.hasNoRecords"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="serach.currentPage"
                        :page-size="10"
                        layout="prev, pager, next, jumper"
                        :total="serach.totalUserSite"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <router-view v-show="isEditPage.isCompanyAdminEdit"></router-view>
    </div>
   
  
</template>

<script>
import {nanoid} from 'nanoid'
import { mapState, mapMutations  } from 'vuex';
import companyadminApi from '@/api/companyadmin/index.js'
import editImg from '@/assets/images/edit.png'
import deleteImg from '@/assets/images/delete.png'
export default {
    name:"CompanyAdmin",
    data(){
        return{
            serach:{
                hasNoRecords:false,
                adminList:[],
                editImg,
                deleteImg,
                userRegion:'',
            },
            add:{
                addAdminFromBox:false,
                addAdminFrom:false,
                addAdminTitle:this.$t('AddAdmin'),
                account:"",
                userId: "",
                nickName: "",
                role: "admin",
                roleOptions: [
                    {
                        id:"admin",
                        name:"admin"
                    },
                    {
                        id:"site_admin",
                        name:"site admin"
                    },
                ],
                site: [],
                siteOptions: [],
                addBtnState:0,
            },
            addCompanyAdmin:{
                addComAdminForm:false,
                firstName:"",
                surname:"",
                addBtnState:0,
            },
            del:{
                delAdminFrom:false,
                id:"",
                delBtnState:0,
            }
        }
    },
    mounted(){
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/companyadmin',
                name:this.$t('CompanyAdmin')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
        //获取公司管理员列表
        let serachAdminObj={
            pageNum:1
        };
        this.getCompanyAdminList(serachAdminObj);
    },
    methods:{
        ...mapMutations(["OPENINEDITPAGE"]),
        //处理园区角色
        handleSiteRole(role){
            let siteRoleStr = "";
            if(role === null || role === undefined || role.trim() === ""){
                siteRoleStr = "--"
            }else if(role === "site_admin"){
                siteRoleStr = "site admin"
            }else{
                siteRoleStr = role;
            }
            return siteRoleStr
            
        },
        //处理园区管理员Sites
        handleSiteAdmin(sites){
            let siteListStr = "";
            let siteArr = [];
            if(sites === null || sites === undefined || sites.length === 0){
                siteListStr = "--"
            }else{
                sites.map((item)=>{
                    siteArr.push(item.siteName);
                    siteListStr = siteArr.join(",")
                })
            }
            return siteListStr
            
        },
        //查询公司管理员列表
        getCompanyAdminList(obj){
            let comAdminObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "pageSize":10,
                "pageNum":obj.pageNum,
            }
            companyadminApi.getCompanyAdminList(comAdminObj).then(res => {
                if(res.code === 0){
                    if(res.data.list.length === 0){
                        this.serach.hasNoRecords = true;
                    }else{
                        this.serach.hasNoRecords = false;
                        this.serach.currentPage = res.data.pageNum;
                        this.serach.totalUserSite = res.data.total;
                        this.serach.adminList = [];
                        for(let i=0;i<res.data.list.length;i++){
                            this.serach.adminList.push(res.data.list[i]);
                        }
                    }
                    
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        openAddAdminFrom(){
            this.add.addAdminFromBox = true;
            this.add.addAdminFrom = true;
            this.addCompanyAdmin.addComAdminForm = false;
            this.add.addAdminTitle = this.$t('AddAdmin');
            this.add.account = "";
            this.add.site.length = [];
            this.getCompanySiteList();//获取园区列表
        },
        //根据用户账号获取用户信息 getAccountInfo
        getAccountInfo(){
            if((this.add.account).trim() === ""){
                this.$message.info(this.$t('EmailEmpty'));
                return false
            }
            let reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
            if(!reg.test(this.add.account)){
                this.$message.info(this.$t('EmailFormatLimit'));
                return false
            }
            // return false
            this.add.addBtnState = 1;
            let accountInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "oemGroupId": webConfig.oemGroupId,
                "userAccount":this.add.account
            }
            companyadminApi.getAccountInfo(accountInfoObj).then(res => {
                if(res.code === 0){
                   this.add.addBtnState = 0;
                   if(res.data.userId === "0"){
                        this.$message.info(this.$t('EmailIncorrect'));
                        return false
                   }
                   this.add.account = res.data.userAccount;
                   this.add.userId = res.data.userId;
                   this.add.nickName = res.data.nickName;
                   this.add.account = res.data.userAccount;
                   this.serach.userRegion = res.data.userRegion;
                   this.add.addAdminFrom = false;
                   this.addCompanyAdmin.addComAdminForm = true;
                   this.add.addAdminTitle = this.$t('AddCompanyAdmin');
                   this.addCompanyAdmin.firstName = "";
                   this.addCompanyAdmin.surname = "";
                   this.add.role = "";
                   this.add.site = [];
                }else{
                    this.add.addBtnState = 0;
                    this.$handerrcode(res.code);
                    // this.$message.info(this.$t('EmailIncorrect'));
                    //获取公司管理员列表
                    let serachAdminObj={
                        pageNum:1
                    };
                    this.getCompanyAdminList(serachAdminObj);
                    //this.add.addAdminFromBox = false;
                }
            }).catch((err) => {
                this.add.addBtnState = 0;
                console.log(err)
            })
            
        },
        //点击serach执行函数
        searchComAdmin(){
            // this.add.addAdminFrom = false;
            // this.addCompanyAdmin.addComAdminForm = true;
            // this.add.addAdminTitle = this.$t('AddCompanyAdmin');
            this.getAccountInfo();
        },
        //点击添加Admin执行函数
        addNewComAdmin(){
            if((this.addCompanyAdmin.firstName).trim() === ""){
                this.$message.info(this.$t('FirstNameEmpty'));
                return false
            };
            let reg = /^.{1,20}$/;
            if(!reg.test(this.addCompanyAdmin.firstName)){
                this.$message.info(this.$t('FirstNameLimit'));
                return false
            }
            if((this.addCompanyAdmin.surname).trim() === ""){
                this.$message.info(this.$t('SurnameNameEmpty'));
                return false
            };
            if(!reg.test(this.addCompanyAdmin.surname)){
                this.$message.info(this.$t('SurnameLimit'));
                return false
            }
            let companyAdminRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            if(this.add.role === "site_admin"){
                if(this.add.site.length === 0){
                    this.$message.info(this.$t('ManageSelectEmpty'));
                    return false
                }
            }else{
                this.add.site = [];
            }
            let companyAdminObj = {
                "userId": this.add.userId,
                "companyId":this.selectCompany.id,
                "userAccount":this.add.account,
                "nickName":this.add.nickName,
                "firstName":this.addCompanyAdmin.firstName,
                "surname":this.addCompanyAdmin.surname,
                "userRole":this.add.role,
                "siteList":this.add.site,
                "userRegion":this.serach.userRegion,
            }
            
            console.log("companyAdminObj",companyAdminObj);
            // return false
            this.addCompanyAdmin.addBtnState = 1;
            companyadminApi.addCompanyAdmin(companyAdminRgnObj,companyAdminObj).then(res => {
                if(res.code === 0){
                    this.addCompanyAdmin.addBtnState = 0; 
                    this.$message.success(this.$t('CreatedSuccessfully'));
                    //获取公司管理员列表
                    let serachAdminObj={
                        pageNum:1
                    };
                    this.getCompanyAdminList(serachAdminObj);
                    this.add.addAdminFromBox = false;
                }else{
                    this.addCompanyAdmin.addBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                this.addCompanyAdmin.addBtnState = 0;
                console.log(err);
            })
        },

        //获取公司下site列表
        getCompanySiteList(){
            let conpanyInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "pageSize":-1,
                "pageNum":1
            }
            companyadminApi.getCompanySiteListNoPage(conpanyInfoObj).then(res => {
                if(res.code === 0){
                    // console.log(res.data.list == null)
                    if(res.data == null || res.data.length === 0){
                        this.add.siteOptions = [];
                    }else{
                        this.add.siteOptions = [];
                        res.data.list.map(item => {
                            this.add.siteOptions.push(item)
                        })
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //用户角色改变执行函数
        changeRole(val){
            if(val === "site_admin"){
                this.add.site = [];
            }
        },

        //双击admin列表执行函数
        handleCompanyAdminRow(row, column, event){
            // console.log("row",row);
            this.OPENINEDITPAGE({name:"isCompanyAdminEdit",value:true}),
            // this.isPanelsEdit = true;

            this.$router.push(
                {
                    name:"companyadminedit",
                    params:row,
                }
            );
        },
         //点击编辑图标
         editAdmin(row){
            this.OPENINEDITPAGE({name:"isCompanyAdminEdit",value:true}),

            this.$router.push(
                {
                    name:"companyadminedit",
                    params:row,
                }
            );
        },
        delAdmin(item){
            this.del.delAdminFrom = true;
            this.del.id = item.id;
        },
        //删除公司管理员确定按钮执行
        delCompanyAdminBtn(){
            let delcompanyAdminRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let delcompanyAdminObj = {
                "companyAdminId": this.del.id
            }
            this.del.delBtnState = 1;
            companyadminApi.delCompanyAdmin(delcompanyAdminRgnObj,delcompanyAdminObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    //获取公司管理员列表
                    let serachAdminObj={
                        pageNum:1
                    };
                    this.getCompanyAdminList(serachAdminObj);
                    this.del.delAdminFrom = false;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
            })
            this.del.delBtnState = 0;
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            let serachAdminObj={
                pageNum:val
            };
            this.getCompanyAdminList(serachAdminObj);
        },
       
    },
    computed:{
        ...mapState(["selectCompany","companyList","isEditPage"])
    },
    watch:{
         // 监听路由信息对象中的path属性
         "$route.path"(path) {
            if(path === "/main/companyadmin"){
                //获取公司管理员列表
                let serachAdminObj={
                    pageNum:1
                };
                this.getCompanyAdminList(serachAdminObj);
            }
        },
        "selectCompany.id"(){
            //获取公司管理员列表
            let serachAdminObj={
                pageNum:1
            };
            this.getCompanyAdminList(serachAdminObj);
        }
    }
}
</script>

<style scoped>
.companyAdminContent{
    padding: 20px 30px;
}
.companyAdminBox{
    margin: 1vw;
}
.addAdminBox{
    display: flex;
    justify-content: left;
}
.addAdminBox button{
    width: 175px;
    height: 35px;
    line-height: 5px;
    border-radius: 8px;
}
.el-divider--horizontal{
    height: 2px;
}
.adminContent{
    margin-top: 20px;
}
::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 635px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        width: 300px;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
    color: #333;
}
.el-form-item {
    margin-top: 20px;
    margin-bottom: 5px;
}
::v-deep .el-input {
    font-size: 16px;
    margin-top: 10px;
}
::v-deep .el-input__inner {
    height: 36px;
    line-height: 36px;
    width: 100%;
    border:none;
    background: #EFEFEF;
}
.comAdminDescribe{
    margin-top: 20px;
}
.comAdminDescribe div{
    font-size: 20px;
    color: #505256;
    font-weight: bold;
    margin-bottom: 10px;
}
.comAdminDescribe p{
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
}
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 60px;
}
::v-deep .el-dialog__footer {
    padding: 10px 20px 35px;
    text-align: center !important;;
}
.footerBtn{
    text-align: center;
    margin-top: 2vw;
}
.pageBox{
    /* position: fixed; */
    margin-top: 20px;
    bottom: 2vw;
    width: 96%;
}
/* 新增conpany Adminr弹框样式 */
.addsiteUersBox{
    display: flex;
}
.addSiteUserLeft{
    width: 50%;
    padding-right: 1vw;
}
.NameBox{
    display: flex;
    justify-content: space-between;
}

.adminInfo div{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 13px;
}

.adminInfo p{
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
}

.addSiteUserRight{
    width: 50%;
    font-size: 18px !important;
    margin-left: 2vw;
}
::v-deep .el-pagination__jump {
    margin-top: -10px !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}

::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}

.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}

::v-deep .el-table{
    min-height: 510px !important;
}
::v-deep  .el-select__tags {
    top: 62%
}
</style>