<template>
    <div class="blockEditBox">
      <div class="controlBtnBox">
        <el-button :class="{selectBtn:selectBtnIndex===1}" @click="changeEditType(1)">{{$t('BlockDetail')}}</el-button>
        <el-button :class="{selectBtn:selectBtnIndex===2}" @click="changeEditType(2)">{{$t('Panels')}}</el-button>
        <el-button :class="{selectBtn:selectBtnIndex===3}" @click="changeEditType(3)">{{$t('Users')}}</el-button>
      </div>
      <!-- Blocks 编辑 -->
      <div class="BlocksContent" v-show="selectBtnIndex === 1">
        <div class="blocksItem">
            <label>{{$t('BlocksName')}}</label><el-input v-model="updateBlocks.name" class="editInput" :placeholder="$t('BlocksName')"></el-input>
        </div>
        <div class="blocksItem">
            <label>{{$t('BlocksNumber')}}</label><el-input v-model="updateBlocks.number" class="editInput" :placeholder="$t('BlocksNumber')"></el-input>
        </div>
        <el-button class="savetBtn" :disabled="updateBlocks.saveBtn" :loading="this.updateBlocks.updateBtnState == 1" @click="saveUpdateBlocks">{{$t('Save')}}</el-button>
      </div>
      <!-- Panels 编辑 -->
      <div class="PanelsContent" v-show="selectBtnIndex === 2">
         <!--删除Panels弹框-->
         <el-dialog
                :title="$t('Delete')"
                :visible.sync="delPanelsObj.delPanelsFrom"
                width="30%"
                :show-close="delPanelsObj.showClose">
                <p class="deleteDesc">{{$t('DeletePanel')}}</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="delPanelsObj.delPanelsFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" @click="delPanelsFromBtn">{{$t('Confirm')}}</el-button>
                </span>
            </el-dialog>
        <div class="noPanels" v-show="updatePanels.hasNoPanels">{{$t('NoPanel')}}!</div>
        <template>
            <el-table
            v-show="!updatePanels.hasNoPanels"
            :data="updatePanels.panelsListData"
            :cell-class-name="getPanelsStatusClass"
            @cell-click="handlePanelsCell"
            @row-dblclick="handlePanelsRow"
            style="width: 100%;"
            :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
            >
            <el-table-column
                prop="deviceId"
                :label="$t('PanelsId')"
                width="200"
                >
            </el-table-column>
            <el-table-column
                prop="deviceName"
                :label="$t('PanelsName')"
                width="200"
                >
            </el-table-column>
            <el-table-column
                prop="apartmentName"
                :label="$t('Blocks')"
                width="200"
                >
                <template slot-scope="scope">
                    {{ handApartmentName(scope.row.apartmentName)}}
                </template>
            </el-table-column>
            <el-table-column
                prop="deviceStatus"
                :label="$t('PanelsStatus')"
                width="200"
                >
                <template slot-scope="scope">
                    {{ getPanelsStatusStr(scope.row.deviceStatus)}}
                </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                :label="$t('CreateDate')"
                width="200"
                >
                <template slot-scope="scope">
                    {{ utctolocal(scope.row.createTime)}}
                </template>
            </el-table-column>
            <!-- <el-table-column  label="操作" fixed="right" width="180px" align="center"> -->
            <el-table-column width="200"  label="..." align="center">
                <template slot-scope="scope">
                    <el-image :src="updatePanels.editImg"  @click="editlPanels(scope.row)" style="width:24px;" fit="fill"></el-image>
                    <el-image :src="updatePanels.deleteImg"  @click="openDelPanelsFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                </template>
            </el-table-column>

            <el-table-column>

            </el-table-column>
        
            </el-table>
        </template>
        <div class="pageBox">
            <el-pagination
                v-show="!updatePanels.hasNoPanels"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="updatePanels.currentPage"
                :page-size="10"
                layout="prev, pager, next, jumper"
                :total="updatePanels.totalPanels"
            >
            </el-pagination>
        </div>
      </div>
      <!-- Users 编辑 -->
      <div class="UsersContent" v-show="selectBtnIndex === 3">
         <!--删除SiteUser弹框-->
         <el-dialog
                :title="$t('Delete')"
                :visible.sync="updateSiteUser.delSiteUserFrom"
                width="30%"
                :show-close="updateSiteUser.showClose">
                <p class="deleteDesc">{{ $t('DeleteUser') }}</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="updateSiteUser.delSiteUserFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" @click="delSiteFromBtn">{{$t('Confirm')}}</el-button>
                </span>
            </el-dialog>
        <div>
            <div class="noPanels" v-show="updateSiteUser.hasSiteUser">{{$t('NoUser')}}</div>
            <template>
                <el-table
                v-show="!updateSiteUser.hasSiteUser"
                :data="updateSiteUser.siteUsersListData"
                :cell-class-name="getServerStatusClass"
                @cell-click="handlePanelsCell"
                @row-dblclick="handleSiteUserRow"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    prop="surname"
                    :label="$t('UserName')"
                    width="150"
                    >
                    <template slot-scope="scope">
                        {{ $handFieldNull(showUserName(scope.row.surname,scope.row.firstName))}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="apartmentName"
                    :label="$t('Blocks') + '/'+ $t('Sites')"
                    width="150"
                    >
                    <template slot-scope="scope">
                        {{scope.row.apartmentId === "0" ? $t('Sites') : scope.row.apartmentName}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="houseNo"
                    :label="$t('HouseNumber')"
                    width="150"
                    >
                    <template slot-scope="scope">
                        {{ $handFieldNull(scope.row.houseNo)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="userAccount"
                    :label="$t('Account')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ $handFieldNull(scope.row.userAccount)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="userRole"
                    :label="$t('UserRole')"
                    width="100"
                    >
                </el-table-column>
                <el-table-column
                    prop="userStatus"
                    :label="$t('UserStatus')"
                    width="150"
                    >
                    <template slot-scope="scope">
                        {{ getUserStatusStr(scope.row.userStatus)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="licenseStatus"
                    :label="$t('ServiceStatus')"
                    width="150"
                    >
                    <template slot-scope="scope">
                        {{ getServerStatusStr(scope.row.licenseStatus)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="licenseRemaining"
                    :label="$t('ServicesRemaining')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{scope.row.licenseRemaining === null ? "--" : (scope.row.licenseRemaining === 0 ? 0 : scope.row.licenseRemaining + " " + $t('Days'))}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    :label="$t('CreateDate')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ utctolocal(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <el-table-column width="200"  label="" align="center">
                    <template slot-scope="scope">
                        <el-image :src="updateSiteUser.editImg"  @click="editlSiteUser(scope.row)" style="width:24px;" fit="fill"></el-image>
                        <el-image :src="updateSiteUser.deleteImg" @click="openDelSiteUserFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                    </template>
                </el-table-column>

                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox">
                <el-pagination
                    v-show="!updateSiteUser.hasSiteUser"
                    @size-change="handleSizeChange"
                    @current-change="handleUserCurrentChange"
                    :current-page.sync="updateSiteUser.currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="updateSiteUser.totalUserSite"
                >
                </el-pagination>
            </div>
        </div>
      </div>

    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import { mapState, mapMutations } from 'vuex';
import sitesBlocksApi from '@/api/blocks/index.js'
import sitesPanelsApi from '@/api/panels/index.js'
import sitesUsersApi from '@/api/siteusers/index.js'
import editImg from '@/assets/images/edit.png'
import deleteImg from '@/assets/images/delete.png'
export default {
    name:"BlocksEdit",
    data(){
        return{
            selectBtnIndex:1,
            updateBlocks:{
                id:"",
                name:"",
                newName:"",
                number:"",
                newNumber:"",
                saveBtn:true,
                updateBtnState:0
            },
            updatePanels:{
                hasNoPanels:false,
                panelsListData:[],
                editImg,
                deleteImg,
                totalPanels:0,
                currentPage: 1,
            },
            delPanelsObj:{
                delPanelsFrom:false,
                showClose:false,
                entranceDeviceId:""
            },
            updateSiteUser:{
                hasSiteUser:false,
                siteUsersListData:[],
                editImg,
                deleteImg,
                totalPanels:0,
                currentPage: 1,
                delSiteUserFrom: false,
                showClose:false,
                userHouseId: "",
            },
        }
    },
    mounted(){
        // this.$store.commit("CHANGEMODEL","company");
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/blocks',
                name:this.$t('Blocks')
            },
            {
                id:nanoid(),
                path:'/main/blocks',
                name:this.$t('BlocksDetail')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);       
        this.updateBlocks.id = this.$route.params.id
        // console.log("updateBlocks",this.$route.params);
        //获取Blocks详情
        this.getBlocksDetail();
    },
    methods:{
        ...mapMutations(["OPENINEDITPAGE"]),
        //点击功能切换按钮
        changeEditType(type){
            this.selectBtnIndex = type;
            if(type === 1){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/blocks',
                        name:this.$t('Blocks')
                    },
                    {
                        id:nanoid(),
                        path:'/main/blocks',
                        name:this.$t('BlocksDetail')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);       
                this.getBlocksDetail();
            }else if(type === 2){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/blocks',
                        name:this.$t('Blocks')
                    },
                    {
                        id:nanoid(),
                        path:'/main/blocks',
                        name:this.$t('Panels')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);       
                        //获取panels列表
                let serachPanelsObj = {
                    "apartmentId":this.updateBlocks.id,
                    // "deviceName":this.serach.deviceName,
                    // "deviceStatus":this.serach.deviceStatus,
                    "pageNum":1
                };
                this.getPanelsList(serachPanelsObj);
            }else{
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/blocks',
                        name:this.$t('Blocks')
                    },
                    {
                        id:nanoid(),
                        path:'/main/blocks',
                        name:this.$t('Users')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);       
                 //搜索Site Usres
                let serachSiteUserObj = {
                    "apartmentId":this.updateBlocks.id,
                    "username":"",
                    "licenseStatus":"",
                    "pageNum":1
                }
                this.getSiteUsersList(serachSiteUserObj);
            }
        },
        //用户名渲染函数
        showUserName(surname,firstName){
            if(surname == null){
                surname = "";
            }
            if(firstName == null){
                firstName = "";
            }
            let userName = surname + " " + firstName;
            return userName.trim();
        },
        //获取Blocks详情
        getBlocksDetail(){
            let blocksObj = {
                "rgn":this.selectCompany.companyRegion,
                "id":this.updateBlocks.id
            }
            // 刷新时，apartmentid丢失，这里做个规避
            if(this.updateBlocks.id === undefined){
                return false
            }
            sitesBlocksApi.detailSiteBlocks(blocksObj).then(res => {
                if(res.code === 0){
                   this.updateBlocks.name = res.data.apartmentName;
                   this.updateBlocks.newName = res.data.apartmentName;
                   this.updateBlocks.number = res.data.apartmentNo;
                   this.updateBlocks.newNumber = res.data.apartmentNo;
                   this.updateBlocks.saveBtn = true;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //更新blocks
        saveUpdateBlocks(){
            if((this.updateBlocks.name).trim() === ""){
                this.$message.info(this.$t('BlocksNameEmpty'));
                return false
            }
            let checkResultBlockName = this.$regularCheckLimit(this.updateBlocks.name,1,30,'BlockNameLimit');
            if(!checkResultBlockName){
                return false
            }
            if((this.updateBlocks.number).trim() === ""){
                this.$message.error(this.$t('BlocksNumberEmpty'));
                return false
            }
            let checkResultBlockReg = /^\d+$/;
            if(!checkResultBlockReg.test(this.updateBlocks.number) || Number(this.updateBlocks.number) <= 0){
                this.$message.info(this.$t('LimitBlockNumber'));
                return false
            }
            let checkResultBlockNum = this.$regularCheckLimit(this.updateBlocks.number,1,20,'BlockNumberLimit');
            if(!checkResultBlockNum){
                return false
            }
            this.updateBlocks.updateBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let updateBlocksObj = {
                "apartmentId":this.$route.params.id,
                "apartmentName":this.updateBlocks.name,
                "apartmentNo": this.updateBlocks.number
            }
            // console.log("updateBlocksObj",updateBlocksObj);
            // return false
            sitesBlocksApi.updateSiteBlocks(rgnObj,updateBlocksObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.getBlocksDetail();
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
            this.updateBlocks.updateBtnState = 0;
        },
        //Panels列表获取
        getPanelsList(serachPanelsObj){
            let panelsInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "apartmentId":serachPanelsObj.apartmentId,
                // "deviceName":serachPanelsObj.deviceName,
                // "deviceStatus":serachPanelsObj.deviceStatus,
                "pageSize":10,
                "pageNum":serachPanelsObj.pageNum,
                // "apartmentName":serachPanelsObj.apartmentName || ""
            }
            sitesPanelsApi.getSitePanelsList(panelsInfoObj).then(res => {
                if(res.code === 0){
                    this.updatePanels.panelsListData = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.updatePanels.hasNoPanels = true;
                    }else{
                        this.updatePanels.hasNoPanels = false;
                        this.updatePanels.panelsListData = res.data.list;
                    }
                    this.updatePanels.currentPage = res.data.pageNum;
                    this.updatePanels.totalPanels = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
           //panel状态渲染函数
           getPanelsStatusClass(obj){
            let getClass = "";
            if(obj.columnIndex === 3){
                // console.log("obj.row.deviceStatus",obj.row.deviceStatus)
                let status = obj.row.deviceStatus;
                switch(status){
                    case 0:
                        getClass = "statusBlue";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusOrange";
                        break
                    case 3:
                        getClass = "statusRed";
                        break
                    default:
                        getClass = "";
                }
            }
            return getClass;
        },
        getPanelsStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('Offine');
                    break;
                case 1:
                    getStr = this.$t('Ready');
                    break
                case 2:
                    getStr = this.$t('NeedConfigration');
                    break
                case 3:
                    getStr = this.$t('NeedVerification');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        //处理apartmentName为空时显示问题
        handApartmentName(name){
            let finalName = "";
            if(name === null || name.trim() === ""){
                finalName = "--"
            }else{
                finalName = name;
            }
            return finalName
        },
        //单击设备名称执行函数
        handlePanelsCell(row, column, cell, event){
            if(column.property === "deviceId"){
                this.OPENINEDITPAGE({name:"isPanelsEdit",value:true}),
                this.$router.push(
                    {
                        name:"panelsedit",
                        params:row,
                    }
                );
            }
        },
         //点击表格行执行函数
         handlePanelsRow(row, column, event){
            this.OPENINEDITPAGE({name:"isPanelsEdit",value:true}),
            this.$router.push(
                {
                    name:"panelsedit",
                    params:row,
                }
            );
        },
        //点击panels编辑图标
        editlPanels(row){
            this.OPENINEDITPAGE({name:"isPanelsEdit",value:true}),
            this.$router.push(
                {
                    name:"panelsedit",
                    params:row,
                }
            );
        },
        //打开删除弹窗
        openDelPanelsFrom(item){
            this.delPanelsObj.delPanelsFrom = true;
            this.delPanelsObj.entranceDeviceId = item.id;
            return false
        },
         //点击确认删除框确认按钮执行函数
         delPanelsFromBtn(){
            this.delPanels({id:this.delPanelsObj.entranceDeviceId});
        },
         //删除
         delPanels(item){
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delPanelsObj = {}
            delPanelsObj.entranceDeviceId = item.id;
            sitesPanelsApi.delSitePanels(rgnObj,delPanelsObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.delPanelsObj.delPanelsFrom = false
                    //获取panels列表
                    let serachPanelsObj = {
                        "apartmentId":this.updateBlocks.id,
                        // "deviceName":this.serach.deviceName,
                        // "deviceStatus":this.serach.deviceStatus,
                        "pageNum":1
                    };
                    this.getPanelsList(serachPanelsObj);
                }else{
                    this.$handerrcode(res.code);
                }
                
            }).catch((err) => {
                console.log('err', err);
            })
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            let serachPanelsObj = {
                "apartmentId":this.updateBlocks.id,
                // "deviceName":this.serach.deviceName,
                // "deviceStatus":this.serach.deviceStatus,
                "pageNum":val
            };
            this.getPanelsList(serachPanelsObj);
        },
        //site user
        //单击设备列执行函数
        // handlePanelsCell(row, column, cell, event){
        //     if(column.property === "deviceId"){
        //         this.OPENINEDITPAGE({name:"isPanelsEdit",value:true}),
        //         this.$router.push(
        //             {
        //                 name:"panelsedit",
        //                 params:row,
        //             }
        //         );
        //     }
        // },
        //site service状态渲染函数
        getServerStatusClass(obj){
            let getClass = "";
            if(obj.columnIndex === 6){
                let status = obj.row.licenseStatus;
                switch(status){
                    case 0:
                        getClass = "statusRed";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusOrange";
                        break
                    case 9:
                        getClass = "";
                        break
                    default:
                        getClass = "";
                }
            }
            if(obj.columnIndex === 5){
                let status = obj.row.userStatus;
                switch(status){
                    case 0:
                        getClass = "";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusRed";
                        break
                    case 3:
                        getClass = "statusOrange";
                        break
                    default:
                        getClass = "";
                }
            }
            return getClass;
        },
        //双击表格行执行函数
        handleSiteUserRow(row, column, event){
            console.log("row",row);
            this.OPENINEDITPAGE({name:"isSiteUserEdit",value:true}),
            this.$router.push(
                {
                    name:"siteusersedit",
                    params:row,
                }
            );
        },
        //用户列表点击分页执行方法
        handleUserCurrentChange(val){
            let serachSiteUserObj = {
                "apartmentId":this.updateBlocks.id,
                "username":"",
                "licenseStatus":"",
                "pageNum":val
            };
            this.getSiteUsersList(serachSiteUserObj);
        },
        //site user列表获取
        getSiteUsersList(serachSiteUserObj){
            let userInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "apartmentId":serachSiteUserObj.apartmentId,
                "username":serachSiteUserObj.username,
                "licenseStatus":serachSiteUserObj.licenseStatus,
                "pageSize":10,
                "pageNum":serachSiteUserObj.pageNum,
            }
            sitesUsersApi.getSiteUsersList(userInfoObj).then(res => {
                if(res.code === 0){
                    this.updateSiteUser.siteUsersListData = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.updateSiteUser.hasSiteUser = true;
                    }else{
                        this.updateSiteUser.hasSiteUser = false;
                        for(let i=0;i<res.data.list.length;i++){
                            this.updateSiteUser.siteUsersListData.push(res.data.list[i]);
                        }
                    }
                    this.updateSiteUser.currentPage = res.data.pageNum;
                    this.updateSiteUser.totalUserSite = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        getUserStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('NotResponding');
                    break;
                case 1:
                    getStr = this.$t('Accepted');
                    break
                case 2:
                    getStr = this.$t('Refuse');
                    break
                case 3:
                    getStr = this.$t('NotBound');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        getServerStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('NotEnabled');
                    break;
                case 1:
                    getStr = this.$t('InUse');
                    break
                case 2:
                    getStr = this.$t('Cancel').replace(' ','');
                    break
                case 9:
                    getStr = this.$t('End');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
         //点击编辑用户图标
         editlSiteUser(row){
            this.OPENINEDITPAGE({name:"isSiteUserEdit",value:true}),
            // this.isPanelsEdit = true;
            this.$router.push(
                {
                    name:"siteusersedit",
                    params:row,
                }
            );
        },
         //打开删除弹窗
         openDelSiteUserFrom(item){
            this.updateSiteUser.delSiteUserFrom = true;
            this.updateSiteUser.userHouseId = item.id;
            return false
        },
         //点击确认删除框确认按钮执行函数
         delSiteFromBtn(){
            this.delSiteUser({id:this.updateSiteUser.userHouseId});
        },
        //删除
        delSiteUser(item){
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delSiteUserObj = {}
            delSiteUserObj.siteId = this.selectSite.id;
            delSiteUserObj.userHouseId = item.id;
            sitesUsersApi.delSiteUser(rgnObj,delSiteUserObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.del.delSiteUserFrom = false;
                    //获取site user列表
                    let serachSiteUserObj = {
                        "apartmentId":this.updateBlocks.id,
                        "username":"",
                        "licenseStatus":"",
                        "pageNum":1
                    }
                    this.getSiteUsersList(serachSiteUserObj);
                }else{
                    this.$handerrcode(res.code);
                }
                
            }).catch((err) => {
                console.log('err', err);
            })
        },

    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    watch:{
        'updateBlocks.name'(){
            if(this.updateBlocks.name != this.updateBlocks.newName || this.updateBlocks.number != this.updateBlocks.newNumber){
                this.updateBlocks.saveBtn = false;
            }else{
                this.updateBlocks.saveBtn = true;
            }
            
        },
        'updateBlocks.number'(){
            if(this.updateBlocks.number != this.updateBlocks.newNumber || this.updateBlocks.name != this.updateBlocks.newName){
                this.updateBlocks.saveBtn = false;
            }else{
                this.updateBlocks.saveBtn = true;
            }
            
        },
    }
}
</script>

<style scoped>
.blockEditBox{
   box-sizing: border-box;
   padding: 15px 30px;
}
.controlBtnBox{
    display: flex;
}
.el-button {
    height: 30px;
    width: 140px;
    padding: 6px 20px;
    font-size: 15px;
    background: #ccc;
    color: #000;
}
.el-button:hover{
    background: #6498b5;
    color: #fff;
}
.el-button:active {
    background: #6498b5;
    color: #fff;
}
.el-button:focus {
    background: #6498b5;
    color: #fff;
  }

.selectBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
}
/* Blocks */
.BlocksContent{
    margin-top: 20px;
}
.blocksItem{
    display: flex;
    align-items: baseline;
    margin-top: 1vw;
}
.editInput{
    width: 12vw;
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .editInput{
        width: 170px;
    }
}
::v-deep .editInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #333;
    background: #fff;
}
.blocksItem label{
    width: 135px;
    text-align: left;
}
.blocksItem input{
    width: 300px;
}
.el-select .el-input .el-select__caret {
    color: #333;
    font-weight: bold;
}
.savetBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
    position: fixed;
    /* bottom: 10vw; */
    bottom: 38px;
}
/* Panels */
.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}
.PanelsContent{
    margin-top: 20px;
}

.noPanels{
    color: #ACACAC;
    margin-top: 10vw;
}
::v-deep .statusGreen{
    color: green;
}
::v-deep .statusOrange{
    color: orange;
}
::v-deep .statusRed{
    color: red;
}
::v-deep .statusBlue{
    color: blue;
}
::v-deep .dialog-footer .el-button--primary {
    color: #fff !important;
    background-color: #6498b5 !important;
    border-color: #6498b5;
    padding: 12px 20px;
    font-size: 15px;
    border-radius: 4px;
}
::v-deep .dialog-footer .el-button{
    width: 90px;
    height: 42px;
    color: #606266;;
    background-color: #fff;
}
/* Users */
.UsersContent{
    margin-top: 20px;
}

.pageBox {
    /* position: fixed; */
    margin-top: 20px;
    bottom: 2vw;
    width: 96%;
    justify-content: center;
}
@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed; */
        margin-top: 20px;
        bottom: 10px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}
::v-deep .el-table{
    min-height: 510px !important;
}
::v-deep .el-button+.el-button{
    margin-left: 4px !important;
}
</style>