//导入封装好的新的axios
import request from '@/request/index'

//获取Blocks列表，渲染site/Block下拉列表框
export function getSiteBlocksListForSelect(params) {
    return request({
        url: '/' + params.rgn + '/site/apartment/list',
        method: 'get',
        params: {
            siteId: params.siteId,
        },
    })
}

//查询Site Users
export function getSiteUsersList(params) {
    return request({
        url: '/' + params.rgn + '/site/house/page',
        method: 'get',
        params: {
            siteId: params.siteId,
            apartmentId: params.apartmentId,
            username: params.username,
            licenseStatus: params.licenseStatus,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
        },
    })
}
//查询Site Users列表(owner除外)
export function getSiteUsersWithoutOwnerList(params) {
    return request({
        url: '/' + params.rgn + '/site/house/list',
        method: 'get',
        params: {
            siteId: params.siteId,
            apartmentId: params.apartmentId,
            username: params.username,
            licenseStatus: params.licenseStatus,
            licenseRemaining: params.licenseRemaining,
            // pageSize: params.pageSize,
            // pageNum: params.pageNum,
            // firstDataId: params.firstDataId,
        },
    })
}
//查询可用License列表
export function getSiteUsersLicenseList(params) {
    return request({
        url: '/' + params.rgn + '/site/license/manage/list',
        method: 'get',
        params: {
            siteId: params.siteId,
            companyId: params.companyId,
        },
    })
}

//解绑用户
export function unbindingSiteUsers(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/unbinding',
        method: 'post',
        data
    })
}

//添加Users
export function addSiteUser(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/add',
        method: 'post',
        data,
    })
}

//绑定Users
export function bindingSiteUser(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/binding',
        method: 'post',
        data,
    })
}
//分配License
export function activationSiteUserLicense(params, data) {
    return request({
        url: '/' + params.rgn + '/site/license/manage/houseActivation',
        method: 'post',
        data,
    })
}

//Site Users详情
export function detailSiteUser(params) {
    return request({
        url: '/' + params.rgn + '/site/house/detail',
        method: 'get',
        params: {
            userHouseId: params.userHouseId,
            siteId: params.siteId
        },
    })
}

//更新Site Users
export function updateSiteUser(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/update',
        method: 'post',
        data,
    })
}

//查询住宅有权限设备列表
export function getSiteUserDevicesList(params) {
    return request({
        url: '/' + params.rgn + '/site/house/findHouseDevicePage',
        method: 'get',
        params: {
            siteId: params.siteId,
            userHouseId: params.userHouseId,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
        },
    })
}
//更新住宅对设备的访问权限
export function updateDevicesAccess(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/updateDeviceAccess',
        method: 'post',
        data,
    })
}
//删除Site Users
export function delSiteUser(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/delete',
        method: 'post',
        data,
    })
}
//查询user sip配置列表
export function getSiteUserSipConfigList(params) {
    return request({
        url: '/' + params.rgn + '/site/house/findEntrancePackPage',
        method: 'get',
        params: {
            siteId: params.siteId,
            userHouseId: params.userHouseId,
            type: params.type,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
        },
    })
}

//添加 user sip 配置
export function addSiteUserSipConfig(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/addEntrancePack',
        method: 'post',
        data,
    })
}
//编辑 user sip 配置
export function updateSiteUserSipConfig(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/updateEntrancePack',
        method: 'post',
        data,
    })
}

//查询panels sip 配置
export function getSitePanelsSipConfigList(params) {
    return request({
        url: '/' + params.rgn + '/site/device/findEntranceConfigPage',
        method: 'get',
        params: {
            siteId: params.siteId,
            entranceDeviceId: params.entranceDeviceId,
            type: params.type,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
        },
    })
}

//单个用户出入口SIP配置
export function getSitePanelsSipEntrancePack(params) {
    return request({
        url: '/' + params.rgn + '/site/house/getEntrancePack',
        method: 'get',
        params: {
            siteId: params.siteId,
            entrancePackId: params.entrancePackId,
        },
    })
}

//头像审核
export function avatarCheck(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/updateUserAvatarCheck',
        method: 'post',
        data,
    })
}

//查询user Card配置列表
export function getSiteUserCardConfigList(params,data) {
    return request({
        url: '/' + params.rgn + '/site/house/getEntranceKeyList',
        method: 'post',
        data
    })
}

//添加 Card 配置
export function addSiteUserCardConfig(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/addEntranceKey',
        method: 'post',
        data,
    })
}

//查询user Card详情
export function getSiteUserCardDetail(params,data) {
    return request({
        url: '/' + params.rgn + '/site/house/getEntranceKey',
        method: 'post',
        data
    })
}

//编辑 Card 配置
export function updateSiteUserCardConfig(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/updateEntranceKey',
        method: 'post',
        data,
    })
}

//删除Card
export function delSiteUserCard(params, data) {
    return request({
        url: '/' + params.rgn + '/site/house/deleteEntranceKey',
        method: 'post',
        data,
    })
}
export default { getSiteBlocksListForSelect, getSiteUsersList, getSiteUsersLicenseList, getSiteUsersWithoutOwnerList, unbindingSiteUsers, addSiteUser, bindingSiteUser, delSiteUser, activationSiteUserLicense, detailSiteUser, updateSiteUser, getSiteUserDevicesList, updateDevicesAccess, getSiteUserSipConfigList, addSiteUserSipConfig, updateSiteUserSipConfig, getSitePanelsSipConfigList, getSitePanelsSipEntrancePack, avatarCheck, getSiteUserCardConfigList, addSiteUserCardConfig, getSiteUserCardDetail, updateSiteUserCardConfig, delSiteUserCard }