//导入封装好的新的axios
import request from '@/request/index'
export function addNewCompany(data) {
    return request({
        url: '/company/add',
        method: 'post',
        data,
    })
}
//查询公司列表
export function getCompanyList(data) {
    return request({
        url: '/company/list',
        method: 'get',
        data,
    })
}
export default { addNewCompany, getCompanyList }