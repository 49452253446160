<template>
    <div>
        <div v-show="!isBlocksEdit" class="blocksBox">
        <!--新增Blocks弹框-->
        <el-dialog :title="$t('Add')" 
        :visible.sync="add.addBlocksFrom" 
        :close-on-click-modal="false" 
        :close-on-press-escape="false"
        center 
        :show-close="showClose">
            <el-form :model="add">
                <el-form-item :label="$t('BlocksName')">
                    <el-input v-model="add.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('BlocksNumber')">
                    <el-input v-model="add.number"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancalBlocks">{{$t('Cancel')}}</el-button>
                <el-button type="primary" :loading="add.addBtnState === 1" @click="addSiteBlocks">{{$t('Confirm')}}</el-button>
            </div>
        </el-dialog>
        <!--删除Blocks弹框-->
        <el-dialog
            :title="$t('Delete')"
            :visible.sync="del.delBlocksFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="30%"
            :show-close="showClose">
            <p class="deleteDesc">{{$t('DeleteBlock')}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="del.delBlocksFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="delDelBlocksFrom">{{$t('Confirm')}}</el-button>
            </span>
        </el-dialog>
        <div class="serachBox">
            <div class="serachLeft">
                <el-input v-model="serach.blocksName" class="serachInput" :placeholder="$t('BlocksName').replace('*','')"></el-input>
                <el-button type="primary" class="serachBtn" :loading="serach.serachBtnState === 1" @click="serachBlocks">{{$t('Serach')}}</el-button>
            </div>
            <div class="serachRight">
                <el-button type="primary" @click="createBlocks">{{$t('CreateBlock')}}</el-button>
            </div>
        </div>
        <el-divider></el-divider>
        <div class="blockContent">
            <div class="noBlocks" v-show="isBlocks">{{$t('NoBlock')}}</div>
            <template>
                <el-table
                v-show="!isBlocks"
                :data="blockListData"
                @cell-click="handleBlocksCell"
                @row-dblclick="handleBlocksRow"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    prop="apartmentName"
                    :label="$t('BlocksName').replace('*','')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="apartmentNo"
                    :label="$t('BlocksNumber').replace('*','')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="deviceCount"
                    :label="$t('PanelsQuantity')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="userCount"
                    :label="$t('UsersQuantity')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    :label="$t('CreateDate')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ utctolocal(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <!-- <el-table-column  label="操作" fixed="right" width="180px" align="center"> -->
                <el-table-column width="180"  label="..." align="center">
                    <template slot-scope="scope">
                        <!-- <el-button size="mini" style="margin-right: 3px;" type="text" @click="detailBlocks(scope.row)">详情</el-button>
                        <el-button size="mini" style="margin-right: 3px;" type="text" @click="delBlocks(scope.row)">删除</el-button> -->
                        <el-image :src="editImg"  @click="editlBlocks(scope.row)" style="width:24px;" fit="fill"></el-image>
                        <el-image :src="deleteImg"  @click="openDelBlocksFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                        <!-- <el-dropdown>
                            <span class="el-dropdown-link" style="font-weight: bold;">
                                ...
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="detailBlocks(scope.row)">详情</el-dropdown-item>
                                <el-dropdown-item @click.native="openDelBlocksFrom(scope.row)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> -->
                    </template>
                </el-table-column>

                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox" v-show="!isBlocks">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="totalBlocks"
                >
                </el-pagination>
            </div>
            
        </div>
        </div>
        <router-view v-show="isBlocksEdit"></router-view>
    </div>
   
  
</template>

<script>
import editImg from '@/assets/images/edit.png'
import deleteImg from '@/assets/images/delete.png'
import sitesBlocksApi from '@/api/blocks/index.js'
import { mapState, mapMutations } from 'vuex';
import {nanoid} from 'nanoid'
export default {
    name:"Blocks",
    // activated(){
    //     this.isBlocksEdit = false;
    // },
    data(){
        return{
            isBlocksEdit:false,
            serach:{
                blocksName:"",
                serachBtnState:0,
            },
            showClose:true,
            totalBlocks:0,
            currentPage: 1,
            add:{
                addBlocksFrom:false,
                addBtnState:0,
                name:"",
                number:""
            },
            // update:{
            //     updateBlocksFrom:false,
            //     updateBtnState:0,
            //     name:"",
            //     number:""
            // },
            del:{
                delBlocksFrom:false,
                apartmentId:'',
            },
            blockListData: [],
            isBlocks:false,
            editImg,
            deleteImg
        }
    },
    mounted(){
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/blocks',
                name:this.$t('Blocks')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
        let serachBlockObj = {
                "apartmentName":this.serach.blocksName,
                "pageNum":1
            };
        this.getBlocksList(serachBlockObj);
        this.isBlocksEdit = false;
        //根据路由携带信息判断是否打开新增blocks弹窗
        if(this.$route.params.createBlocks){
            this.add.addBlocksFrom = true;
        }else{
            this.add.addBlocksFrom = false;
        }
    },
    methods:{
        ...mapMutations(["OPENINEDITPAGE"]),
        //查询blocks列表方法
        getBlocksList(serachBlockObj){
            if((serachBlockObj.apartmentName).trim() != ""){
                let checkResultBlockName = this.$regularCheckLimit(serachBlockObj.apartmentName,1,30,'BlockNameLimit');
                if(!checkResultBlockName){
                    return false
                }
            }
           
            let blocksInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "pageSize":10,
                "pageNum":serachBlockObj.pageNum,
                "apartmentName":serachBlockObj.apartmentName || ""
            }
            // this.serach.serachBtnState = 1;
            sitesBlocksApi.getSiteBlocksList(blocksInfoObj).then(res => {
                if(res.code === 0){
                    this.blockListData = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.isBlocks = true;
                    }else{
                        this.isBlocks = false;
                        this.blockListData = res.data.list;
                    }
                    this.currentPage = res.data.pageNum;
                    this.totalBlocks = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
                // this.serach.serachBtnState = 0;
            }).catch((err) => {
                console.log(err);
                // this.serach.serachBtnState = 0;
            })
            
        },
        //点击搜索执行函数
        serachBlocks(){
            let serachBlockObj = {
                "apartmentName":this.serach.blocksName,
                "pageNum":1
            }
            this.getBlocksList(serachBlockObj);
        },
        //点击名称列进详情
        handleBlocksCell(row, column, cell, event){
            if(column.property === "apartmentName"){
                this.isBlocksEdit = true;
                this.$router.push(
                    {
                        name:"blocksedit",
                        params:row,
                    }
                );
            }
        },
         //双击列表行执行函数
         handleBlocksRow(row){
            this.isBlocksEdit = true;
            this.$router.push(
                {
                    name:"blocksedit",
                    params:row,
                }
            );
        },
        //点击创建Block
        createBlocks(){
            this.add.addBtnState = 0;
            this.add.addBlocksFrom = true;
            this.add.name = '';
            this.add.number = '';
        },
        //取消新增blocks弹框
        cancalBlocks(){
            this.add.addBtnState = 0;
            this.add.addBlocksFrom = false;
        },
        //新增blocks确定执行函数
        addSiteBlocks(){
            if((this.add.name).trim() === ""){
                this.$message.info(this.$t('BlocksNameEmpty'));
                return false
            }
            let checkResultBlockName = this.$regularCheckLimit(this.add.name,1,30,'BlockNameLimit');
            if(!checkResultBlockName){
                return false
            }
            if((this.add.number).trim() === ""){
                this.$message.info(this.$t('BlocksNumberEmpty'));
                return false
            }
            let checkResultBlockReg = /^\d+$/;
            if(!checkResultBlockReg.test(this.add.number) || Number(this.add.number) <= 0){
                this.$message.info(this.$t('LimitBlockNumber'));
                return false
            }
            let checkResultBlockNum = this.$regularCheckLimit(this.add.number,1,20,'BlockNumberLimit');
            if(!checkResultBlockNum){
                return false
            }
            let checkZeroReg = /^(?!0$).*/;
            if(!checkZeroReg.test(this.add.number)){
                this.$message.info(this.$t('BlockNumberNotZero'));
                return false
            }
            // return false
            this.add.addBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addBlocksObj = {
                "companyId":this.selectCompany.id,
                "siteId":this.selectSite.id,
                "apartmentName":this.add.name,
                "apartmentNo": this.add.number
            }
            sitesBlocksApi.addSiteBlocks(rgnObj,addBlocksObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('CreatedSuccessfully'));
                    let serachBlockObj = {
                        "apartmentName":this.serach.blocksName,
                        "pageNum":1
                    };
                    this.getBlocksList(serachBlockObj);
                    this.add.addBtnState = 0;
                    this.add.addBlocksFrom = false;
                }else{
                    this.$handerrcode(res.code);
                    this.add.addBtnState = 0;
                }
            }).catch((err) => {
                console.log(err);
                this.add.addBtnState = 0;
            })
        },
        //更新blocks确定执行函数
        // updateSiteBlocks(item){
        //     if((this.update.name).trim() === ""){
        //         this.$message.error("The blocks name cannot be empty");
        //         return false
        //     }
        //     if((this.update.number).trim() === ""){
        //         this.$message.error("The blocks number cannot be empty");
        //         return false
        //     }
        //     this.update.updateBtnState = 1;
        //     let rgnObj = {
        //         "rgn":this.selectCompany.companyRegion,
        //     }
        //     let updateBlocksObj = {
        //         "apartmentId":item.id,
        //         "apartmentName":this.update.name,
        //         "apartmentNo": this.update.number
        //     }
        //     // console.log("updateBlocksObj",updateBlocksObj);
        //     // return false
        //     sitesBlocksApi.updateSiteBlocks(rgnObj,updateBlocksObj).then(res => {
        //         if(res.code === 0){
        //             this.$message.success("更新成功");
        //             let serachBlockObj = {
        //                 "apartmentName":this.serach.blocksName,
        //                 "pageNum":1
        //             };
        //             this.getBlocksList(serachBlockObj);
        //         }else{
        //             this.$handerrcode(res.code);
        //         }
        //     }).catch((err) => {
        //         console.log(err)
        //     })
        //     this.update.updateBtnState = 0;
        //     this.update.updateBlocksFrom = false;
        // },
        // handleCommand(command) {
        //     console.log("commandcommand",command);
        // },
        //点击编辑图标
        editlBlocks(item){
            this.isBlocksEdit = true;
            this.$router.push(
                {
                    name:"blocksedit",
                    params:item,
                }
            );
        },
        //打开删除弹窗
        openDelBlocksFrom(item){
            this.del.delBlocksFrom = true;
            this.del.apartmentId = item.id;
        },
        //点击确认删除框确认按钮执行函数
        delDelBlocksFrom(){
            // this.del.delBlocksFrom = true;
            this.delBlocks({id:this.del.apartmentId});
        },
        //删除blocks
        delBlocks(item){
            let rgnObj = {
                    "rgn":this.selectCompany.companyRegion,
                };
            const delBlocksObj = {}
            delBlocksObj.apartmentId = item.id;
            sitesBlocksApi.delSiteBlocks(rgnObj,delBlocksObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.del.delBlocksFrom = false
                    let serachBlockObj = {
                        "apartmentName":this.serach.blocksName,
                        "pageNum":1
                    };
                    this.getBlocksList(serachBlockObj);
                }else{
                    this.$handerrcode(res.code);
                }
               
            }).catch((err) => {
                console.log('err', err);
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            let serachBlockObj = {
                "apartmentName":this.serach.blocksName,
                "pageNum":val
            }
            this.getBlocksList(serachBlockObj);
        }
    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    // activated(){
    //     console.log("block is activated");
    // },
    // deactivated(){
    //     console.log("block is deactivated");
    // },
    watch: {
        // 监听路由信息对象中的path属性
        "$route.path"(path) {
            // console.log("to,from",path);
            if(path === "/main/blocks"){
                this.isBlocksEdit = false; // 当path发生变化的时候执行关联操作调用计算函数
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/blocks',
                        name:this.$t('Blocks')
                    }
                ];
                this.$generateBreadcrumb(siteMangeBread);
                let serachBlockObj = {
                        "apartmentName":this.serach.blocksName,
                        "pageNum":1
                    };
                this.getBlocksList(serachBlockObj);
            }else{
                //this.$route.path = "/main/blocks"
                // this.$router.push({name:"blocks"});
            }
            
        },
       
    }
}
</script>

<style scoped>
.blocksBox{
    padding: 10px 30px;
}
@media screen and (max-width: 600px) {
    .blocksBox{
        padding: 10px 0;
    }
}
.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .serachBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 10px;
    }
}
.serachInput{
    width: 12vw;
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
    }
}
::v-deep .serachInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #979797;
    background: #fff;
}
::v-deep .el-pagination .el-input__inner {
    background: #fff;
    border: 1px solid #dcdfe6;
}

.serachBtn{
    margin-left: 24px;
}
@media screen and (max-width: 600px) {
    .serachBtn{
        margin-left: 24px;
    }
}
/* .serachLeft{
    margin-top: 0.5vw;
} */
.serachRight{
    margin-top: 0.5vw;
}
@media screen and (max-width: 600px) {
    .serachLeft{
        margin-top: 5px;
    }
}
@media screen and (max-width: 600px) {
    .serachRight{
        margin-top: 10px;
    }
}
.el-divider--horizontal{
    height: 2px;
}
.noBlocks{
    color: #ACACAC;
    margin-top: 10vw;
}
::v-deep .el-table .el-table__cell{
    padding: 8px 0;
}
.pageBox {
    /* position: fixed; */
    margin-top: 20px;
    bottom: 2vw;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed; */
        margin-top: 20px;
        bottom: 10px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}

::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 635px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        width: 300px;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
    color: #333;
}
.el-form-item {
    margin-top: 20px;
}
::v-deep .el-input {
    font-size: 16px;
    margin-top: 10px;
}
::v-deep .el-input__inner {
    height: 50px;
    line-height: 50px;
    width: 100%;
    border:none;
    background: #EFEFEF;
}
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 60px;
}
::v-deep .el-dialog__footer {
    padding: 10px 20px 35px;
    text-align: center !important;
}

::v-deep .el-pagination__jump {
    margin-top: -10px !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}
::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}

::v-deep .el-table{
    min-height: 510px !important;
}
</style>