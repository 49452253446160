//导入封装好的新的axios
import request from '@/request/index'

//查询公司管理员列表
export function getCompanyAdminList(params) {
    return request({
        url: '/' + params.rgn + '/company/admin/page',
        method: 'get',
        params: {
            companyId: params.companyId,
            firstDataId: params.firstDataId,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
        }
    })
}
//根据账号搜索用户相关信息
export function getAccountInfo(params) {
    return request({
        url: '/' + params.rgn + '/company/admin/findAdminUserInfo',
        method: 'get',
        params: {
            oemGroupId: params.oemGroupId,
            userAccount: params.userAccount,
        }
    })
}

//添加公司管理员
export function addCompanyAdmin(params, data) {
    return request({
        url: '/' + params.rgn + '/company/admin/add',
        method: 'post',
        data,
    })
}

//获取管理员详情
export function detailCompanyAdmin(params) {
    return request({
        url: '/' + params.rgn + '/company/admin/detail',
        method: 'get',
        params: {
            companyAdminId: params.companyAdminId
        }
    })
}

//编辑公司管理员
export function updatelCompanyAdmin(params, data) {
    return request({
        url: '/' + params.rgn + '/company/admin/update',
        method: 'post',
        data
    })
}

//删除公司管理员
export function delCompanyAdmin(params, data) {
    return request({
        url: '/' + params.rgn + '/company/admin/delete',
        method: 'post',
        data,
    })
}

//查询公司下site
export function getCompanySiteListNoPage(params) {
    return request({
        url: '/' + params.rgn + '/company/site/page',
        method: 'get',
        params: {
            companyId: params.companyId,
        },
    })
}

export default { getCompanyAdminList, getAccountInfo, addCompanyAdmin, detailCompanyAdmin, updatelCompanyAdmin, delCompanyAdmin, getCompanySiteListNoPage }