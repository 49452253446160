//导入封装好的新的axios
import request from '@/request/index'
//查询公司列表
export function getCompanyList(data) {
    return request({
        url: '/company/list',
        method: 'get',
        data,
    })
}

//更新company 名称
export function updateCompanyInfo(params, data) {
    return request({
        url: '/' + params.rgn + '/company/site/updateCompany',
        method: 'post',
        data,
    })
}

//删除company
export function deleteCompany(params, data) {
    return request({
        url: '/' + params.rgn + '/company/site/deleteCompany',
        method: 'post',
        data,
    })
}

export default { getCompanyList, updateCompanyInfo, deleteCompany }