<template>
    <div class="siteDashBox">
        <!-- <h1>{{ msg }}</h1> -->
        <div class="siteDashItem">
            <div class="siteDashUp">
                <div> {{$t('Blocks')}} </div>
                <div class="siteDashUpRight" @click="detailSiteBlocks"> {{$t('MoreDetail')}} </div>
            </div>
            <div class="siteDashDown"  v-if="!haveBlocks">
                <el-button type="primary" class="createBtn" @click="siteNewBlocks">
                    <span class="comPlus"><i class="el-icon-plus"></i></span>
                    <span> {{$t('AddBlocks')}} </span>
                </el-button>
            </div>
            <div class="siteBlocksDown" v-if="haveBlocks">
               <div class="blocksTop">{{blocksNum}}</div>
               <div class="blocksCenter">{{$t('BlocksOnSite')}}</div>
               <div class="blocksBottom">
                    <el-button type="primary" class="siteBlocksBtn" @click="siteNewBlocks">{{$t('AddBlocks')}}</el-button>
               </div>
            </div>
        </div>
        <div class="siteDashItem">
            <div class="siteDashUp">
                <div>{{$t('Panels')}}</div>
                <div class="siteDashUpRight" @click="detailSitePanels">{{$t('SeeAllPanels')}}</div>
            </div>
            <div class="siteDashDown" v-if="!havePanels">
                <el-button type="primary" class="createBtn" @click="siteNewPanels">
                    <span class="comPlus"><i class="el-icon-plus"></i></span>
                    <span>{{$t('AddPanels')}}</span>
                </el-button>
            </div>
            <div class="sitePanelsDown" v-if="havePanels">
                <div class="PanelsLeft" style="width: 45%;">
                    <div class="blocksTop">{{panelsNum}}</div>
                    <div class="blocksCenter">{{$t('AddedDecices')}}</div>
                    <div class="blocksBottom">
                            <el-button type="primary" class="siteBlocksBtn" @click="siteNewPanels">{{$t('CreatePanels')}}</el-button>
                    </div>
                </div>
                <div class="PanelsRight">
                    <div class="PanelsRightDiv">
                        <div class="PanelsRightContent"><img  :src="devices_normalImg" /></div>
                        <div class="PanelsRightCenter">{{$t('Normal')}}</div>
                        <div class="PanelsRightContent">{{normalDevice}}</div>
                    </div>
                    <div class="PanelsRightDiv">
                        <div class="PanelsRightContent"><img  :src="devices_configImg" /></div>
                        <div class="PanelsRightCenter">{{$t('NeedConfigration')}}</div>
                        <div class="PanelsRightContent">{{needConfigDevice}}</div>
                    </div>
                    <div class="PanelsRightDiv">
                        <div class="PanelsRightContent"><img  :src="devices_failImg" /></div>
                        <div class="PanelsRightCenter">{{$t('CheckFail')}}</div>
                        <div class="PanelsRightContent">{{checkFailDevice}}</div>
                    </div>
                    <div class="PanelsRightDiv">
                        <div class="PanelsRightContent"><img  :src="devices_offlineImg" /></div>
                        <div class="PanelsRightCenter">{{$t('Offine')}}</div>
                        <div class="PanelsRightContent">{{offlineDevice}}</div>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="siteDashItem">
            <div class="siteDashUp">
                <div>{{$t('Users')}}</div>
                <div class="siteDashUpRight" @click="detailSiteUser">{{$t('SeeAllUsers')}}</div>
            </div>
            <div class="siteDashDown" v-if="!haveUsers">
                <el-button type="primary" class="createBtn" @click="siteNewUser">
                    <span class="comPlus"><i class="el-icon-plus"></i></span>
                    <span>{{$t('AddUsers')}}</span>
                </el-button>
            </div>
            <div class="sitePanelsDown" v-if="haveUsers">
                <div class="PanelsLeft">
                    <div class="blocksTop">{{usersNum}}</div>
                    <div class="blocksCenter">{{$t('AddedUser')}}</div>
                    <div class="blocksBottom">
                            <el-button type="primary" class="siteBlocksBtn" @click="siteNewUser">{{$t('AddUsers')}}</el-button>
                    </div>
                </div>
                <div class="PanelsRight">
                    <div class="PanelsRightDiv">
                        <div class="PanelsRightCenter">{{$t('LicensesNormal')}}</div>
                        <div class="PanelsRightContent">{{licesesNormal}}</div>
                    </div>
                    <div class="PanelsRightDiv">
                        <div class="PanelsRightCenter">{{$t('LicensesExpired')}}</div>
                        <div class="PanelsRightContent">{{licesesExpired}}</div>
                    </div>
                    <div class="PanelsRightDiv">
                        <div class="PanelsRightCenter">{{$t('NotOpened')}}</div>
                        <div class="PanelsRightContent">{{notOpened}}</div>
                    </div>
                    <div class="PanelsRightDiv">
                        <div class="PanelsRightCenter">{{$t('NotAccepted')}}</div>
                        <div class="PanelsRightContent">{{notAccept}}</div>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="siteDashItem">
            <div class="siteDashUp">
                <div>{{$t('Licenses')}}</div>
                <div class="siteDashUpRight" @click="detailSiteLicenses"> {{$t('MoreDetail')}} </div>
            </div>
            <div class="siteDashDown" v-if="!haveLicenses">
                <el-button type="primary" class="createBtn" @click="buySiteLicenses">
                    <span class="comPlus"><i class="el-icon-plus"></i></span>
                    <span>{{$t('BuyLiences')}}</span>
                </el-button>
            </div>
            <div class="siteLicensesDown" v-if="haveLicenses">
                <div class="licensesCentDiv" v-for="item in licensesList" :key="item.id">
                    <div class="licensesLeft">{{item.licenseName}}</div>
                    <div class="licensesRight">{{$t('RemainingQuantit')}} {{item.quantity}}</div>
                </div>
            </div>
            <div class="licensesCentBottom" v-if="haveLicenses">
                <el-button type="primary" class="siteBlocksBtn" @click="buySiteLicenses">{{$t('BuyMore')}}</el-button>
            </div>
        </div> 
    </div>
  
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import {nanoid} from 'nanoid'
import devices_normalImg from '@/assets/images/devices_normal.png'
import devices_configImg from '@/assets/images/devices_config.png'
import devices_failImg from '@/assets/images/devices_fail.png'
import devices_offlineImg from '@/assets/images/devices_offline.png'
import sitedashboardApi from '@/api/sitedashboard/index.js'
export default {
    name:"SiteDashboard",
    data(){
        return{
            msg:"Site dashboard",
            showInit:false,
            devices_normalImg,
            devices_configImg,
            devices_failImg,
            devices_offlineImg,
            haveBlocks:false,
            blocksNum:0,
            havePanels:false,
            panelsNum:0,
            normalDevice:0,
            needConfigDevice:0,
            checkFailDevice:0,
            offlineDevice:0,
            haveUsers:false,
            usersNum:0,
            licesesNormal:0,
            licesesExpired:0,
            notOpened:0,
            notAccept:0,
            haveLicenses:false,
            licensesList:[],
        }
    },
    mounted(){
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.$t('SiteDashboard')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
        //获取site下blocks数量
        this.getBlocksList();
        //获取panels列表
        this.getPanelsList();
        //获取用户列表
        this.getSiteUsersList();
        //获取可用licenses
        this.getSiteLicensesList();
    },
    methods:{
         //查询blocks列表方法
         getBlocksList(){
            let blocksInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "pageSize":10,
                "pageNum":1,
                "apartmentName":""
            }
            sitedashboardApi.getSiteBlocksList(blocksInfoObj).then(res => {
                if(res.code === 0){
                    if(res.data === null || res.data.list.length === 0){
                        this.haveBlocks = false;
                        this.blocksNum = 0;
                    }else{
                        this.haveBlocks = true;
                        this.blocksNum = res.data.total;
                    }
                }else{
                    this.$handerrcode(res.code);
                    this.blocksNum = "--";
                }
            }).catch((err) => {
                console.log(err);
                this.blocksNum = "--";
            })
        }, 
        //site dashboard下点击新建blocks执行方法
        siteNewBlocks(){
            this.$router.push(
                {
                    name:"blocks",
                    params: {
                        createBlocks:true
                    }
                }
            );
        },
        //site dashboard blocks下点击详情执行方法
        detailSiteBlocks(){
            this.$router.push(
                {
                    name:"blocks",
                }
            );
        },
        //Panels列表获取
        getPanelsList(){
            let panelsInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
            }
            sitedashboardApi.getSitePanelsList(panelsInfoObj).then(res => {
                if(res.code === 0){
                    if(res.data === null){
                        this.havePanels = false;
                        this.panelsNum = 0;
                    }else{
                        this.havePanels = true;
                        if(res.data.deviceCount === null || res.data.deviceCount === undefined){
                            this.panelsNum = 0;
                        }else{
                            this.panelsNum = res.data.deviceCount;
                        }
                        if(res.data.needConfigDeviceCount === null || res.data.needConfigDeviceCount === undefined){
                            this.needConfigDevice  = 0;
                        }else{
                            this.needConfigDevice = res.data.needConfigDeviceCount;
                        }
                        if(res.data.checkFailDeviceCount === null || res.data.checkFailDeviceCount === undefined){
                            this.checkFailDevice  = 0;
                        }else{
                            this.checkFailDevice = res.data.checkFailDeviceCount;
                        }
                    }
                    //获取离线panels数量
                    this.getOfflinePanelsList();
                }else{
                    this.$handerrcode(res.code);
                    this.panelsNum = "--";
                    this.needConfigDevice = "--";
                    this.checkFailDevice = "--";
                }
            }).catch((err) => {
                console.log(err);
                this.panelsNum = "--";
                this.needConfigDevice = "--";
                this.checkFailDevice = "--";
            })
        },
        //离线Panels列表获取
        getOfflinePanelsList(){
            let panelsInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
            }
            sitedashboardApi.getSiteOfflinePanelsList(panelsInfoObj).then(res => {
                if(res.code === 0){
                    if(res.data === null){
                        this.offlineDevice = 0;
                        this.normalDevice = 0;
                    }else{
                        if(res.data.deviceOnlineCount === null || res.data.deviceOnlineCount === undefined){
                            this.normalDevice = 0;
                        }else{
                            this.normalDevice = res.data.deviceOnlineCount;
                        }
                        if(res.data.deviceOfflineCount === null || res.data.deviceOfflineCount === undefined){
                            this.offlineDevice = 0;
                        }else{
                            this.offlineDevice = res.data.deviceOfflineCount;
                        }
                       
                    }
                }else{
                    this.$handerrcode(res.code);
                    this.offlineDevice = "--";
                    this.normalDevice = "--";
                   
                }
            }).catch((err) => {
                console.log(err);
                this.offlineDevice = "--";
                this.normalDevice = "--";
               
            })
        },
        //site dashboard下点击新建Panels执行方法
        siteNewPanels(){
            this.$router.push(
                {
                    name:"panels",
                    params: {
                        createPanels:true
                    }
                }
            );
        },
         //site dashboard panels下点击详情执行方法
         detailSitePanels(){
            this.$router.push(
                {
                    name:"panels",
                }
            );
        },
         //site user列表获取
         getSiteUsersList(){
            let userInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
            }
            sitedashboardApi.getSiteUsersList(userInfoObj).then(res => {
                if(res.code === 0){
                    if(res.data === null){
                        this.haveUsers = false;
                        this.usersNum = 0;
                        this.licesesNormal = "--";
                        this.licesesExpired = "--";
                        this.notOpened = "--";
                        this.notAccept = "--";
                    }else{
                        this.haveUsers = true;
                        if( res.data.userCount === null ||  res.data.userCount === undefined){
                            this.usersNum = "--";
                        }else{
                            this.usersNum = res.data.userCount;
                        }
                        if( res.data.licesesNormalCount === null ||  res.data.licesesNormalCount === undefined){
                            this.licesesNormal = "--";
                        }else{
                            this.licesesNormal = res.data.licesesNormalCount;
                        }
                        if( res.data.licesesExpiredCount === null ||  res.data.licesesExpiredCount === undefined){
                            this.licesesExpired = "--";
                        }else{
                            this.licesesExpired = res.data.licesesExpiredCount;
                        }
                        if( res.data.notOpenedCount === null ||  res.data.notOpenedCount === undefined){
                            this.notOpened = "--";
                        }else{
                            this.notOpened = res.data.notOpenedCount;
                        }
                        if( res.data.notAcceptCount === null ||  res.data.notAcceptCount === undefined){
                            this.notAccept = "--";
                        }else{
                            this.notAccept = res.data.notAcceptCount;
                        }

                    }
                }else{
                    this.$handerrcode(res.code);
                    this.usersNum = "--";
                    this.licesesNormal = "--";
                    this.licesesExpired = "--";
                    this.notOpened = "--";
                    this.notAccept = "--";
                }
            }).catch((err) => {
                console.log(err);
                this.usersNum = "--";
                this.licesesNormal = "--";
                this.licesesExpired = "--";
                this.notOpened = "--";
                this.notAccept = "--";
            })
        },
        //site dashboard下点击新建用户执行方法
        siteNewUser(){
            this.$router.push(
                {
                    name:"siteusers",
                    params: {
                        createUser:true
                    }
                }
            );
        },
         //site dashboard User下点击详情执行方法
         detailSiteUser(){
            this.$router.push(
                {
                    name:"siteusers",
                }
            );
        },
        //可用licenses查询
        getSiteLicensesList(){
            let licensesObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "siteId":this.selectSite.id,
            }
            sitedashboardApi.getSiteLicensesList(licensesObj).then(res => {
                if(res.code === 0){
                    this.licensesList = [];
                    if(res.data.length === 0){
                        this.haveLicenses = false;
                    }else{
                        this.haveLicenses = true;
                        for(let i=0;i<res.data.length;i++){
                            this.licensesList.push(res.data[i]);
                        }
                    }
                    
                }else{
                    this.$handerrcode(res.code);
                    this.licensesList = [];
                }
            }).catch((err) => {
                console.log(err);
                this.licensesList = [];
            })
        },
         //detail licenses
         detailSiteLicenses(){
            this.$router.push(
                {
                    name:"payments"
                }
            );
        },
        //点击buy more licenses
        buySiteLicenses(){
            this.$router.push(
                {
                    name:"payments"
                }
            );
        }
    },
    computed:{
        ...mapState(["selectCompany","companyList","selectSite"])
    },
    watch: {
        // 监听路由信息对象中的path属性
        // "$route.path"() {
        //     this.$generateBreadcrumb(); // 当path发生变化的时候执行关联操作调用计算函数
        // },
        // "selectSite.id":{
        //     deep:true,
        //     immediate:true,
        //     handler(){
        //         console.log("this.selectSite.id",this.selectSite.id);
        //     }
        // }
        "selectSite.id"(){
            //获取site下blocks数量
            this.getBlocksList();
            //获取panels列表
            this.getPanelsList();
            //获取用户列表
            this.getSiteUsersList();
            //获取可用licenses
            this.getSiteLicensesList();
        }
    }
}
</script>

<style scoped>
.siteDashBox{
    display: flex;
    justify-content: left;
    padding: 30px 10px;
    flex-wrap:wrap;
}
@media screen and (max-width: 600px) {
    .siteDashBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 10px;
    }
}
.siteDashItem{
    width: 23vw;
    height: 12vw;
    background-color: #F4F9FD;
    margin-left: 1vw;
    min-width: 400px;
    min-height: 240px;
    margin-top: 1vw;
    border-radius: 12px;
}
@media screen and (max-width: 600px) {
    .siteDashItem{
        width: 23vw;
        height: 12vw;
        background-color: #F4F9FD;
        min-width: 400px;
        min-height: 240px;
        margin-top: 10px;
        border-radius: 12px;
    }
}
.siteDashUp{
    width: 100%;
    height: 15%;
    border-bottom: 1px solid #DBE0E3;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    font-weight: bold;
}
.siteDashUpRight{
    color: #6498b5;
    cursor: pointer;;
}
.siteDashDown{
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
}
.comPlus{
    font-size: 24px;
    margin-top: -3px;
    float: left;
    margin-left: 10px;
}
.createBtn{
    font-size: 18px;
    margin-top: 3.5vw;
    height: 2.5vw;
    min-height: 42px;
    width: 10vw;
    min-width: 175px;
    border-radius: 8px;
    
}
@media screen and (max-width: 600px) {
    .createBtn{
        height: 42px;
        width: 175px;
        border-radius: 9px;
    }
}
.siteBlocksDown{
    display: flex;
    flex-direction: column;
}
.blocksTop{
    margin-top: 2vw;
    font-size: 40px;
}
.blocksCenter{
    margin-top: 1vw;
    font-size: 12px;
}
.blocksBottom{
    margin-top: 0.5vw;
}

.sitePanelsDown{
    display: flex;
}
.PanelsLeft{
    width: 35%;
}
.PanelsRight{
    width: 65%;
    padding-top: 2vw;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.PanelsRightDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.PanelsRightContent{
    width: 15%;
}
.PanelsRightCenter{
    width: 60%;
    text-align: left;
    font-size: 14px;
    padding-left: 1vw;
}
.siteLicensesDown{
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly; */
    height:116px;
    overflow-y: auto;
}
.siteLicensesDown div{
    margin-top: 8px;
}
.licensesCentDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.licensesLeft{
    text-align: center;
    font-size: 14px;
    padding-left: 1vw;
    width: 45%;
}
.licensesRight{
    text-align: left;
    font-size: 14px;
    padding-left: 1vw;
    width: 55%;
}
.licensesCentBottom{
    margin-top: 0.5vw;
}
</style>