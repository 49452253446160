import moment from "moment";
export const formatDate = {
    methods: {
        //本地时间转utc时间
        localtoutc(value) {
            var utcTime = "";
            if (value == undefined || value == "" || value == null) {
                utcTime = "";
            } else {
                utcTime = moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
            }
            return utcTime;
        },
        //utc时间转本地时间
        utctolocal(value) {
            var localTime = "";
            if (value == undefined || value == "" || value == null) {
                localTime = "";
            } else {
                localTime = moment.parseZone(value).local().format('YYYY-MM-DD HH:mm:ss');
            }
            return localTime;
        }
    },
}