export default {
    "Language": "语言",
    "UserName": "用户名",
    "Email": "邮箱",
    "PassWord": "密码",
    "Login": "登录",
    "SignUp": "注册",
    "Welcome": "欢迎",
    "ForgotPasswrod": "忘记密码？",
    "NoAccount": "还没有账号，去注册",
    "ServerError": "系统错误",
    "ReadAgree": "我已阅读并同意",
    "And": "和",
    "ScanQRCodeDownloadAPP": "请扫描以下二维码下载App并创建账号",
    "EmailEmpty": "邮箱不能为空",
    "PasswordEmpty": "密码不能为空",
    "PleaseSelect": "请选择",
    "Logout": "退出登录",
    "CreateCompany": "创建公司",
    "CompanyName": "公司名称 *",
    "Confirm": "确 认",
    "Cancel": "取 消",
    "CompanyNameEmpty": "公司名称不能为空",
    "CreatedSuccessfully": "创建成功",
    "CompanyDashboard": "公司仪表盘",
    "Sites": "园区",
    "CompanyAdmin": "公司管理员",
    "PurchaseRecord": "购买记录",
    "MoreDetail": "详情",
    "CreateSite": "创建园区",
    "Admin": "管理员",
    "AdminAccess": "管理具有该公司管理员权限的用户",
    "Licenses": "许可证",
    "PaymentInvioces": "查看您的付款历史和管理公司发票",
    "SiteName": "园区名称 *",
    "SiteAddress": "园区地址 *",
    "Add": "新增",
    "Edit": "编辑",
    "Delete": "删除",
    "DeleteSiteTitle": "您确定要删除该园区吗?",
    "NoData": "无数据",
    "SiteNameEmpty": "园区名称不能为空",
    "SiteAddressEmpty": "园区地址不能为空",
    "UpdateSuccessfully": "更新成功",
    "DeleteSuccessfully": "删除成功",
    "AddAdmin": "添加管理员",
    "AddCompanyAdmin": "添加公司管理员",
    "RegisteredAccount": "请输入注册帐号",
    "EmailAccount": "邮箱帐号 *",
    "AdminCompanyRole": "公司管理员角色:",
    "ManagesSitesAdmins": "管理所有的园区和管理员.",
    "AccessBilling": "付款,并拥有访问账单信息权限.",
    "Serach": "搜索",
    "UserInfo": "用户信息",
    "UserAccount": "用户账号:",
    "Nickname": "昵称:",
    "FirstName": "名 *",
    "Surname": "姓 *",
    "SureDelete": "确定删除?",
    "NoRecord": "无记录",
    "AdminName": "管理员名称",
    "Account": "账号",
    "Role": "角色",
    "CreateDate": "创建时间",
    "EmailEmpty": "邮箱账号不能为空",
    "EmailIncorrect": "邮箱账号不存在或不正确",
    "FirstNameEmpty": "名字不能为空!",
    "SurnameNameEmpty": "姓氏不能为空!",
    "Save": "保存",
    "StartTime": "开始时间",
    "EndTime": "结束时间",
    "NoPurchaseRecord": "没有购买记录!",
    "LicenseName": "许可证名称",
    "Quantity": "数量",
    "Amount": "金额",
    "StartTimeEmptye": "开始时间不能为空",
    "EndTimeEmptye": "结束时间不能为空",
    "StartTimeLaterEndTime": "开始时间不能大于结束时间",
    "SiteDashboard": "园区仪表盘",
    "Blocks": "楼栋",
    "Panels": "设备",
    "SiteUsers": "园区用户",
    "EventRecords": "事件记录",
    "AssessLog": "操作记录",
    "SiteLicenses": "园区许可证",
    "Payments": "付款",
    "MyProfile": "资料",
    "AddBlocks": "添加楼栋",
    "BlocksOnSite": "园区楼栋数",
    "SeeAllPanels": "查看所有设备",
    "AddPanels": "添加设备",
    "AddedDecices": "已添加设备数",
    "Normal": "正常",
    "NeedConfigration": "需要配置",
    "CheckFail": "校验失败",
    "Offine": "离线",
    "SeeAllUsers": "查看所有用户",
    "Users": "用户",
    "AddUsers": "添加用户",
    "AddedUser": "已添加用户数",
    "LicensesNormal": "许可证正常",
    "LicensesExpired": "许可证过期",
    "NotOpened": "未开通",
    "NotAccepted": "未接受",
    "BuyLiences": "购买许可证",
    "RemainingQuantit": "剩余数量:",
    "BuyMore": "购买更多",
    "BlocksName": "楼栋名 *",
    "BlocksNumber": "楼栋号 *",
    "DeleteBlock": "楼栋删除后，将会解绑所有楼栋下的用户，这些用户将无法使用园区的门口机服务；将会解绑楼栋下的所有门口机设备；删除操作后无法恢复；",
    "CreateBlock": "创建楼栋",
    "NoBlock": "没有楼栋数据,请添加楼栋",
    "PanelsQuantity": "设备数量",
    "UsersQuantity": "用户数量",
    "CreateSites": "创建园区",
    "BlocksNameEmpty": "楼栋名不能为空",
    "BlocksNumberEmpty": "楼栋号不能为空",
    "BlockDetail": "楼栋详情",
    "DeletePanel": "确定将该设备从园区中删除吗？删除后将在相关用户账号中自动消失.",
    "NoPanel": "无信息，请添加设备.",
    "PanelsId": "设备ID",
    "PanelsName": "设备名称",
    "PanelsStatus": "设备状态",
    "DeleteUser": "数据删除后，将会解绑用户（和被分享者），用户将无法使用园区的门口机服务；删除操作后无法恢复.",
    "NoUser": "无用户信息，请添加用户.",
    "HouseNumber": "房间号",
    "UserRole": "用户角色",
    "UserStatus": "用户状态",
    "ServiceStatus": "服务状态",
    "ServicesRemaining": "服务余量",
    "BlocksDetail": "楼栋详情",
    "Invalid": "无效的",
    "Ready": "已就绪",
    "NeedVerification": "需要验证",
    "NotResponding": "未响应",
    "Accepted": "已接受",
    "Refuse": "拒绝",
    "NotBound": "未绑定",
    "NotEnabled": "未启用",
    "InUse": "使用中",
    "End": "结束",
    "Days": "天",
    "CreatePanels": "添加设备",
    "PanelsSerialNumber": "设备序列号 *",
    "NotFndSerial": "没有找到序列号？请点击这里",
    "PanelsSecurityCode": "设备安全码 *",
    "PanelsSerialCode": "如果没有找到设备安全码，请点击这里",
    "Or": "或",
    "RolePermission": "角色权限控制",
    "SiteUser": "园区用户",
    "SiteEmployee": "园区雇员",
    "SiteOther": "园区其他人员",
    "Status": "状态",
    "PanelsSerialNumberEmpty": "设备序列号不能为空",
    "PanelsSecurityNumberEmpty": "设备安全码不能为空",
    "PanelsNameEmpty": "设备名称不能为空",
    "SiteOrBlocksEmpty": "园区或楼栋不能为空",
    "AddSuccessful": "添加成功",
    "RevalidateDevice": "重新验证设备",
    "PanelsDetail": "设备详情",
    "AccessControl": "访问控制",
    "DevicesMode": "设备型号",
    "SerialNumber": "序列号",
    "FirmwareVersion": "固件版本",
    "Revalidate": "重新验证",
    "UpdateFailure": "更新失败",
    "AddSiteUser": "添加园区用户",
    "AddingUser": "用户添加中，请等待...",
    "UserEmail": "用户邮箱",
    "ActivationMethod": "激活方式",
    "ChooseActivationMethod": "选择如何为该用户激活该服务",
    "AvailableActivationCodes": "可用激活码",
    "AddUserSuccess": "添加用户成功!",
    "UserHasJoined": "用户已加入",
    "UserServiceActivated": "用户的服务被激活!",
    "ActivationCode": "激活码",
    "UserConfrmation": "用户确认",
    "ConfirmationEmail": "已向用户发送确认邮件，请通知用户按照邮件内容提示完成后续操作.",
    "ScanQRCode": "或者用APP扫描二维码加入!",
    "ServiceActivation": "服务激活",
    "Total": "总共",
    "Select": "选择",
    "Activation": "激活",
    "UserServiceActivation": "用户业务激活，请稍等...",
    "ConsumedActivate": "用户将被激活",
    "BindingUser": "绑定用户",
    "BindingUserWait": "用户绑定中，请稍后...",
    "UnbindingUser": "解绑用户",
    "UnbindingUserDesc": "用户解绑后，将无法使用园区的门口机服务",
    "ActivationTypeOne": "用户自行购买和打开",
    "ActivationTypeTwo": "分配激活码",
    "ActivationTypeThree": "填写激活码",
    "Years": "年",
    "Year": "年",
    "Months": "月",
    "Month": "月",
    "Day": "天",
    "UserEmailEmpty": "用户邮箱不能为空",
    "HouseNumberEmpty": "门牌号不能为空",
    "UserRoleEmpty": "用户角色不能为空",
    "ActivationMethodEmpty": "激活方式不能为空",
    "SelectAtLeastOne": "请选择至少一个搜索条件",
    "SelectAtLeastActivate": "请选择至少一个要激活的用户",
    "SelectLicense": "请选择许可证",
    "InsufficientLicense": "许可证数量不足",
    "ActivationSuccessful": "激活成功",
    "BindingSuccessful": "绑定成功",
    "UnbindSuccessful": "解绑成功",
    "UserDetail": "用户详情",
    "Devices": "设备",
    "UserSetting": "用户设置",
    "Augment": "增加",
    "DeviceName": "设备名称",
    "DeviceId": "设备ID",
    "AccessRights": "访问权限",
    "AssessStatus": "权限状态",
    "SiteDevices": "园区设备",
    "Preview": "预览",
    "PreviewEnd": "预览、",
    "Unlock": "开锁",
    "UnlockEnd": "开锁、",
    "Call": "呼叫",
    "CallEnd": "呼叫、",
    "NoAssessLog": "没有记录操作日志!",
    "OperationModule": "操作模块",
    "OperationContent": "操作内容",
    "Company": "公司",
    "UpdateSite": "更新园区",
    "DeleteSite": "删除园区",
    "UpdateBlock": "更新楼栋",
    "DeleteBlock": "删除楼栋",
    "BindingPanels": "绑定设备",
    "UpdatePanels": "更新设备",
    "UnbindingPanels": "解绑设备",
    "UpdateUser": "更新用户",
    "DeleteUserLog": "删除用户",
    "PurchasingLicence": "购买许可证",
    "UpdateCompanyAdmin": "更新公司管理员",
    "DeleteCompanyAdmin": "删除公司管理员",
    "UserLogin": "用户登录",
    "RocessingPayment": "正在处理支付数据，请稍等...",
    "PaySuccess": "支付成功",
    "PayFail": "支付失败",
    "Person": "人",
    "BuyQuantity": "购买数量",
    "CustomQuantity": "用户自定义数量",
    "PayPlatfrom": "支付平台",
    "PayAmount": "支付金额",
    "Pay": "支付",
    "ServiceLicenses": "服务许可证",
    "ActivationRecords": "激活记录",
    "NoLicense": "没有可用的许可证，请购买!",
    "LicensesName": "许可证名称",
    "ActivationKey": "激活秘钥",
    "SelectLicensePurchase": "请选择要购买的许可证",
    "PaymentFailure": "订单付款失败",
    "BuyNow": "立即购买",
    "OrderNo": "订单号",
    "OrderStatus": "订单状态",
    "Date": "日期",
    "OrderName": "订单名称",
    "OrderTime": "订单时间",
    "PayTime": "支付时间",
    "Pending": "待支付",
    "Completed": "已完成",
    "Expired": "已过期",
    "UseAPPScanQRCode": "使用QvHome APP扫码即可使用",
    "RequestParameterError": "请求参数错误",
    "InvalidRequest": "无效的请求",
    "RequestTimeou": "请求超时",
    "UserNoLogin": "用户未登录",
    "RemoteServiceNotFound": "未找到远程服务",
    "ErrorCallingRemoteService": "调用远程服务错误",
    "DataNotFound": "数据未找到",
    "RequestsTooFrequent": "请求太过频繁",
    "NoOperationPermission": "无操作权限",
    "DataAlreadyExists": "数据已存在",
    "UserNamePassword": "用户名密码不匹配",
    "AccountAlreadyExists": "账号已存在",
    "VerificationCodeError": "验证码错误",
    "UnknownError": "未知错误",
    "NoAuthorizedCompany": "未找到有权限的公司",
    "NoAuthorizedSite": "未找到有权限的园区",
    "UserNotJoinHouse": "用户未加入住宅",
    "CloudPushNotEnabled": "未开通云推送服务",
    "DeviceBound": "设备已绑定",
    "HouseNumberAlreadyExists": "门牌号已存在",
    "HouseNumberNotExists": "门牌号不存在",
    "BlockNumberNotExists": "楼栋号不存在",
    "DeviceUnbound": "设备未绑定",
    "InventoryShortag": "库存不足",
    "PaymentOrderVerificationFailed": "支付订单验证失败",
    "PaymentFailed": "支付未成功",
    "NoPackageFound": "未找到套餐开通订单",
    "NoAvailablePackages": "未找到可用套餐",
    "PackageHasModified": "套餐已被修改",
    "ChangedOnce": "套餐7天内只能变更一次",
    "OrdersNonRefundable": "订单不能退款",
    "PaymentConfigurationNotFound": "支付配置未找到",
    "OrderRefundFailed": "订单退款失败",
    "PackageLessThanOneDay": "套餐剩余时间不满一天",
    "DataSaveError": "数据保存错误",
    "DataUpdateError": "数据更新错误",
    "DataDeleteError": "数据删除错误",
    "CompanyAdminExistsSite": "公司管理员在该园区已存在",
    "NotAllowDeleteOurself": "不允许删除自己",
    "ProductNotSupportBind": "该产品不支持绑定",
    "DeviceSecurityCheckFail": "设备安全码校验失败",
    "DeviceNotSupportServer": "设备不支持该服务",
    "OemConfigNotFount": "未找到Oem配置",
    "EmailSendFail": "邮件发送失败",
    "UserAccountNotFound": "用户账号未找到",
    "CompanyDataNotFond": "公司数据未找到",
    "DevicesDataNotFond": "设备数据未找到",
    "DevicesNoPermission": "设备没有权限",
    "UserNotBindingHouse": "用户未绑定房间",
    "PkgNotAvailable": "套餐不可用",
    "UserNotFond": "用户未发现",
    "AuthNotFound": "认证未发现",
    "IotUnlockFail": "开锁失败",
    "UserMismatchOem": "用户Oem不匹配",
    "AlarmCancelShareFail": "报警取消分享失败",
    "AlarmUnbindFail": "报警解绑失败",
    "AlarmShareFail": "报警分享失败",
    "InvitationInvalid": "邀请无效",
    "UnallocatedLicense": "许可证未分配",
    "HaveBeenUsed": "已被使用",
    "CompanyNameLimit": "公司名称长度必须为1-30个字符",
    "SiteNotFound": "园区未找到",
    "SiteNameLimit": "园区名称长度必须为1-30个字符",
    "EmailFormatLimit": "邮箱格式不正确",
    "FirstNameLimit": "名字长度必须为1-20个字符",
    "SurnameLimit": "姓长度必须为1-20个字符",
    "PersonalInfo": "个人信息",
    "BlockNameLimit": "楼栋名长度必须为1-30个字符",
    "BlockNumberLimit": "楼栋号长度必须为1-20个字符",
    "DeviceSerialLimit": "设备序列号长度必须为1-20个字符",
    "DeviceSecurityCodeLimit": "设备安全码长度必须为1-64个字符",
    "DeviceNameLimit": "设备名称长度必须为1-30个字符",
    "HouseNumberLimit": "房间号长度必须为1-20个字符",
    "LoginExpired": "用户登录过期，请重新登录",
    "ServicesRemainingLimit": "服务剩余天数只能输入0或0到8位的正整数",
    "LastAdminNotDelete": "不允许删除最后一个管理员",
    "CanNotUpdateOwnRoles": "不能更新自己的管理员角色",
    "UserUnbindingFailed": "用户解绑失败",
    "AdminAccountLimit": "管理员账号长度必须为1-100个字符",
    "UserNameLimit": "用户名长度必须为1-30个字符",
    "OrderNoLimit": "订单号长度必须为1-64个字符",
    "licensesNumLimit": "只能输入1到999之间的正整数",
    "BlockNumberNotZero": "楼栋号不能为0",
    "CompanyOverLimit": "创建公司数量超过限制",
    "SiteOverLimit": "创建园区数量超过限制",
    "ApartmentOverLimit": "创建楼栋数量超过限制",
    "Notice": "通知",
    "PanelLog": "设备日志",
    "OperationLog": "操作日志",
    "Panel": "设备",
    "CallHasBeenAnswered": "呼叫已接听",
    "CallNotAnswered": "呼叫未接听",
    "Time": "时间",
    "Default": "默认",
    "QRCodeUnlock": "二维码开锁",
    "PasswordUnlock": "密码开锁",
    "IndoorMachineUnlock": "室内机开锁",
    "AppUnlock": "APP 开锁",
    "RfCard": "RFCard",
    "ExitButton": "Exit Button 开锁",
    "DtmfUnlock": "DTMF 开锁",
    "GoogleUnlock": "Google 开锁",
    "AlexaUnlock": "Alexa 开锁",
    "FaceUnlock": "人脸开锁",
    "Success": "成功",
    "Failed": "失败",
    "Loading": "加载中",
    "ApartmentNumber": "公寓门牌号",
    "Image": "图片",
    "CallLog": "呼叫日志",
    "AccessLog": "开锁日志",
    "Export": "导出",
    "UnlockType": "开锁类型",
    "NoExportData": "无可导出数据！",
    "ExportSuccess": "导出成功",
    "Return": "返回",
    "APPNotice": "APP 通知",
    "DeviceNotice": "设备通知",
    "Refresh": "刷新",
    "AddNotice": "添加通知",
    "Withdraw": "撤回",
    "Release": "发布",
    "More": "更多",
    "Broadcast": "广播",
    "ChooseDevice": "选择设备",
    "ReceptionDevice": "接收设备：",
    "SelectedDeviceNumber": "选择设备数：",
    "Title": "标题：",
    "DigitsLimit": "标题长度必须为1 ~ 50位",
    "TitlePicture": "标题图片：",
    "PictureRatio": "注:建议以图片宽度9:16的比例上传",
    "TimeRange": "时间范围：",
    "CreateNotice": "创建通知",
    "Update": "更新",
    "Modify": "编辑",
    "Content": "内容",
    "ReleaseStatus": "发布状态",
    "Released": "发布时间",
    "TitleTable": "标题",
    "Draft": "草稿",
    "TitlePictureEmpty": "请添加标题图片",
    "DeleteFailed": "删除失败",
    "ImageUploadFailed": "图片上传失败",
    "PleaseSelectDevice": "请选择设备",
    "MaximumDevicesSelected": "最多只能选择10个设备",
    "SuccessfulWithdraw": "撤回成功",
    "SuccessfulRelease": "发布成功",
    "RemoveDeviceNotice": "删除设备通知",
    "WithdrawDeviceNotice": "撤销设备公告",
    "ReleaseDeviceNotice": "发布设备通知",
    "UpdateNotice": "更新通知",
    "UploadImagejpg": "请上传后缀为.jpg的图片！",
    "ConfirmPassword": "确认密码",
    "Prompt": "提示",
    "AutoJumpLogin": "将在0秒后自动跳到登录页面，点击确认立即跳到登录页面。",
    "RegisteredSuccess": "注册成功",
    "PasswordChangedSuccess": "密码修改成功",
    "SendVerificationCode": "发送验证码",
    "ResendSeconds": "0秒后重新发送",
    "VerificationCode": "验证码",
    "PasswordFormatLimit": "密码长度为8 ~ 16个字符，必须包含大小写字母、数字和特殊字符。",
    "ChangePassword": "修改密码",
    "NextStep": "下一步",
    "EnterVerificationCode": "请输入验证码",
    "EnterCorrectEmailFormat": "请输入正确的邮箱格式",
    "PasswordLess": "密码不少于8位",
    "PasswordLarger": "密码不大于16位",
    "PasswordNotRules": "密码不符合规则",
    "EntePasswordAgain": "请再次输入密码",
    "PasswordNotMatch": "密码和确认密码不一致",
    "VerificationCodeSentSuccess": "验证码发送成功",
    "UrlExpired": "Url过期",
    "EmailAccountExists": "邮箱账号已存在",
    "VerificationCodeIncorrect": "验证码不正确",
    "VerificationCodeInvalid": "验证码无效",
    "TermsCondition": "条款和条件",
    "PrivacyPolicy": "隐私权策略",
    "CheckReadAgree": "请勾选我已阅读并同意条款和条件和隐私权策略",
    "DisplayName": "显示名称",
    "AccountSettings": "账户设置",
    "Remark": "备注",
    "Server": "服务器",
    "Port": "端口号",
    "TCPOrUDP": "TCP/UDP",
    "DTMFSetting": "DTMF 设置",
    "Lock1": "锁1",
    "Lock2": "锁2",
    "Talk": "对讲",
    "DisplayNameEmpty": "显示名称长度必须为1-64个字符",
    "RemarkEmpty": "备注长度必须为1-64个字符",
    "ServerEmpty": "服务器长度必须为1-64个字符",
    "PortEmpty": "端口号长度必须为1-20个字符",
    "TCPOrUDPEmpty": "TCP/UDP不能为空",
    "AccountEmpty": "账号长度必须为1-64个字符",
    "Lock1Empty": "锁1长度必须为1-20个字符",
    "Lock2Empty": "锁2长度必须为1-20个字符",
    "TalkEmpty": "对讲长度必须为1-20个字符",
    "NoSip": "无信息，请添加Sip配置",
    "PasswordLimit": "密码长度必须为1-64个字符",
    "NoGuard": "无信息，请添加Guard",
    "SipSettings": "Sip 设置",
    "GuardSettings": "警卫设置",
    "ConfirmDelSip": "确认删除Sip设置?",
    "GuardNameLimit": "警卫别名长度必须为1-20个字符",
    "AddressEmpty": "地址不能为空",
    "GuardName": "警卫别名",
    "CreateGuard": "创建警卫",
    "EditGuard": "编辑警卫",
    "Address": "地址",
    "ConfirmDelGuard": "确认删除警卫?",
    "AddSipServer": "添加Sip服务",
    "ConfirmUnbindPanel": "确认解绑该设备?",
    "PleaseSelectPanels": "请选择设备",
    "ServerConfigEmpty": "服务配置不能为空",
    "Mode": "模式",
    "SeverMode": "服务模式",
    "P2PMode": "P2P 模式",
    "ServerConfig": "服务配置",
    "TargetSip": "目标 Sip",
    "P2PConfig": "P2P 配置",
    "CreateDeviceConfig": "创建设备配置",
    "UpdateDeviceConfig": "更新设备配置",
    "DeleteDeviceConfig": "删除设备配置",
    "CreateUserHouseConfig": "创建园区用户配置",
    "UpdateUserHouseConfig": "更新园区用户配置",
    "DeleteUserHouseConfig": "删除园区用户配置",
    "CreateDeviceGuardConfig": "创建设备警卫配置",
    "UpdateDeviceGuardConfig": "更新设备警卫配置",
    "DeleteDeviceGuardConfig": "删除设备警卫配置",
    "CreateDeviceNotice": "创建设备通知",
    "UpdateDeviceNotice": "更新设备通知",
    "DeleteDeviceNotice": "删除设备通知",
    "DeviceNoticeRelease": "发布设备通知",
    "DeviceNoticeWithdraw": "撤回设备通知",
    "EnterTitle": "请输入标题",
    "Unused": "未使用",
    "ServerName": "服务名称",
    "DeviceDelete": "删除设备",
    "PrivateMessage": "私人消息",
    "Profile": "头像",
    "EnableProfile": "启用头像",
    "DisableProfile": "禁用头像",
    "WhetherEnableProfile": "是否启用头像",
    "WhetherDisableProfile": "是否禁用头像",
    "PleaseSelectUser": "请选择用户",
    "MaximumUserSelected": "最多只能选择10个用户",
    "SelectedUserNumber": "选择用户数：",
    "RemoveAppNotice": "删除App通知",
    "WithdrawAppNotice": "撤销App公告",
    "ReleaseAppNotice": "发布App通知",
    "TamperLog": "防拆日志",
    "ReleaseType": "发布类型",
    "ReadNumber": "阅读数",
    "MaximumCharacterLimit": "内容最多只能输入1个字符",
    "MaximumNoticeReachLimit": "公告发布数量已达上限",
    "UploadedImageLimit": "上传图片大小不能超过2兆!",
    "ContentCannotEmpty": "内容不能为空!",
    "EndTimeGreateCurrentTime": "结束时间需要大于当前时间!",
    "AccountNotExist": "账号不存在",
    "PasswordError": "密码错误",
    "PictureRatioApp": "注:建议以图片宽度16:9的比例上传",
    "Name": "名称",
    "NameCannotEmpty": "名称长度必须为1-64个字符",
    "CardNumber": "完整卡号",
    "CardNumberEmpty": "完整卡号不能为空",
    "UnlockOption": "开锁选项",
    "UnlockOptionEmpty": "开锁选项不能为空",
    "Limited": "限制",
    "LimitedEmpty": "限制不能为空",
    "CardSettings": "卡片设置",
    "LimitedUse": "使用限制",
    "ValidityTime": "有效时间",
    "PanelSync": "同步设备",
    "AddCardNumber": "添加卡号",
    "NumbeOfUses": "使用次数",
    "Unlinmited": "无限制",
    "Forever": "永久有效",
    "EditCardNumber": "编辑卡号",
    "ConfirmDeleteCard": "确定删除卡片设置？",
    "NotApplied": "未应用",
    "ApplicationComplete": "应用完成",
    "DataValidationFailed": "数据验证失败",
    "TypesNotSupported": "不支持类型",
    "DeviceLimitedInput": "设备限制输入",
    "CCTVSettings": "CCTV设置",
    "IPRTSPUrl": "IP/RTSP Url",
    "Stream": "码流",
    "DeletingCCTV": "确认删除CCTV配置?",
    "MainStream": "主码流",
    "SubStream": "子码流",
    "AddCamera": "添加设备",
    "EditCamera": "编辑设备",
    "IPRTSPUrlEmpty": "IP/RTSP Url 不能为空",
    "UsernameEmpty": "用户名不能为空",
    "StreamEmpty": "码流不能为空",
    "DeviceVersion": "设备版本号",
    "CardNumberExists": "卡号已存在",
    "AddCard": "添加卡",
    "FacilityCodeCardNumber": "设备码&序列号",
    "PleasePlacePard": "请把卡片放在读卡器上",
    "ReaderNotDetected": "未检测到阅读器，请检查您的驱动程序安装或电缆连接!",
    "DownloadDriver": "下载驱动程序",
    "TestDriver": "测试驱动",
    "ChooseDriverDownload": "请根据您的电脑系统选择相应的驱动程序下载:",
    "RestartTest": "重新启动测试",
    "FacilityCode": "设备码",
    "FullCardNumLimit": "输入的卡号无效请重新输入",
    "FacilityCodeCardNumberEmpty": "设备码&序列号不能为空",
    "FacilityCodeEmpty": "设备码必须为数字，且不能大于255",
    "FacilityCardNumberEmpty": "序列号必须为数字，且不能大于65535",
    "ManualInput": "手动输入",
    "Reader": "读卡",
    "SiteList": "园区列表",
    "ManageSelectedSite": "选择园区管理",
    "ManageSelectEmpty": "请选择需要管理的园区",
    "AdminAccountExists": "管理员账号已存在",
    "CompanyNameOnly": "公司名称",
    "DeleteCompanyLimit": "请删除该公司下所有园区后，再删除该公司",
    "CompanyList": "公司列表",
    "Rename": "修改名称",
    "InputNewCompanyName": "请输入新的公司名称：",
    "SureDeleteCompany": "您确定要删除该公司吗?",
    "SelectPayPlatform": "请选择支付平台",
    "LimitReached": "数量已达上限",
    "LimitPassword": "密码最大长度不能超过64个字符",
    "LimitUserName": "用户名最大长度不能超过64个字符",
    "LimitUnlockNum": "限制次数最大不能超过100",
    "LimitHouseNumber": "房间号只支持字母或数字,若为纯数字，则必须大于0",
    "LimitBlockNumber": "楼栋号只能为数字,且必须大于0",
    "CardNumberWrong": "卡号有误",
    "UsersReachLimit": "添加用户达到上限",
    "DeviceReachLimit": "添加设备达到上限",
}