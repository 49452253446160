//导入封装好的新的axios
import request from '@/request/index'

//查询blocks
export function getSiteBlocksList(params) {
    return request({
        url: '/' + params.rgn + '/site/apartment/page',
        method: 'get',
        params: {
            siteId: params.siteId,
            pageSize: params.pageSize,
            pageNum: params.pageNum,
            firstDataId: params.firstDataId,
            apartmentName: params.apartmentName,
        },
    })
}
//添加blocks
export function addSiteBlocks(params, data) {
    return request({
        url: '/' + params.rgn + '/site/apartment/add',
        method: 'post',
        data,
    })
}

//blocks详情
export function detailSiteBlocks(params) {
    return request({
        url: '/' + params.rgn + '/site/apartment/detail',
        method: 'get',
        params: {
            apartmentId: params.id
        },
    })
}

//更新blocks
export function updateSiteBlocks(params, data) {
    return request({
        url: '/' + params.rgn + '/site/apartment/update',
        method: 'post',
        data,
    })
}

//删除blocks
export function delSiteBlocks(params, data) {
    return request({
        url: '/' + params.rgn + '/site/apartment/delete',
        method: 'post',
        data,
    })
}


export default { getSiteBlocksList, addSiteBlocks, detailSiteBlocks, updateSiteBlocks, delSiteBlocks }