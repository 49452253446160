<template>
    <div class="companyMenu">
        <el-menu :default-active="activeMenu()" class="el-menu-demo" mode="horizontal" @select="handleCompany">
            <el-menu-item v-show="showComMenu" index="/main/companydashboard" :disabled="companyList.length === 1"	>
                {{$t('CompanyDashboard')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item index="/main/sitesmanage" :disabled="companyList.length === 1">
                {{$t('Sites')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showComMenu" index="/main/companyadmin" :disabled="companyList.length === 1">
                {{$t('CompanyAdmin')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showComMenu" index="/main/purchaserecord" :disabled="companyList.length === 1">
                {{$t('PurchaseRecord')}}
            </el-menu-item>
            <el-menu-item style="float: right;">
                <el-image
                    style="width: 84px;height: 30px;"
                    :src="menuAesImg"
                    fit="contain">
                </el-image>
            </el-menu-item>
            
        </el-menu>
        
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import menuAesImg from '@/assets/images/menu_aes.png'
export default {
    name:"CompanyMenu",
    data(){
        return{
            // activeIndex: "company_sites",
            showComMenu:this.$store.state.selectCompany.role === "admin",
            menuAesImg
         }
    },
    mounted(){
        // this.$router.push({name:"companydashboard"});
        //根据用户的公司角色展示不同的公司菜单
        this.getactiveComPanyMenu();
    },
    methods:{
        handleCompany(key, keyPath) {
            // console.log(key, keyPath);
            if(key === "/main/companydashboard"){
                this.$router.push({name:"companydashboard"});
            }else if(key === "/main/sitesmanage"){
                this.$router.push({name:"sitesmanage"});
            }else if(key === "/main/companyadmin"){
                this.$router.push({name:"companyadmin"});
            }else if(key === "/main/purchaserecord"){
                this.$router.push({name:"purchaserecord"});
            }
        },
        activeMenu(){
            if(this.$route.meta.isChildren){
                return this.$route.meta.usePath;
            }else{
                return this.$route.path;
            }
        },
        getactiveComPanyMenu(){
            // console.log("ddddddddddddddd")
            // console.log("selectCompany",this.selectCompany.id)
            if(this.$store.state.selectCompany.role === "admin"){
                this.showComMenu = true;
            }else{
                this.showComMenu = false;
            }
            if(this.showComMenu === false){
                this.$router.push({name:"sitesmanage"});
            }
        }
    },
    computed:{
        ...mapState(["companyList" ,"selectCompany"])
    },
    watch:{
        "$store.state.selectCompany.role"(){
            if(this.$store.state.selectCompany.role === "admin"){
                this.showComMenu = true;
                //改变公司后，直接切换到公司首页
                this.$router.push({name:"companydashboard"});
            }else{
                this.showComMenu = false;
                this.$router.push({name:"sitesmanage"});
            }
        }
    }
    

}
</script>
  
<style scoped>
 .companyMenu{
    background-color: transparent;
    border-radius:15px 15px 0px 0px;
    width: 100%;
    overflow: auto;
 }
 @media screen and (max-width: 600px) {
    .companyMenu{
       width: 100%;
       /* overflow: auto; */
       height: 60px;
    }
}
 .el-menu{
    border-radius:15px 15px 0px 0px;
    /* background-color: transparent; */
    border-bottom-color: none;
 }
 .el-menu.el-menu--horizontal{
    border-radius:15px 15px 0px 0px;
    border-bottom: none;
    min-width: 600px;
    /* overflow-x: auto; */
 }
 @media screen and (max-width: 600px) {
    .el-menu.el-menu--horizontal{
       width: 100%;
       height: 60px;
       /* display: flex;
       justify-items: flex-start; */
       /* overflow-x: auto; */
    }
}
 .el-menu--horizontal>.el-menu-item{
    color: #000;
    font-weight: bold;
    border: none;
 }
 .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #6498b5;
    background-color: transparent;
}
 .el-menu--horizontal>.el-menu-item.is-active{
    border-radius:15px 0px 0px 0px;
    color: #6498b5;
    border: none;
 }
 .el-divider--vertical {
    display: inline-block;
    width: 1px;
    height: 1.1em;
    /* margin: 3px 0px; */
    vertical-align: middle;
    position: relative;
    margin-left: 10px;
    margin-right: -20px;
}

.el-divider {
    background-color: #8BADFC;
    position: relative;
}
</style>