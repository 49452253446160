<template>
    <div>
        <el-container>
        <el-header height="120px">
            <TopLogo></TopLogo>
            <div class="topMenu">
                <CompanyMenu :key="new Date().getTime()" v-if="selectModel === 'company'"></CompanyMenu>
                <SitesMenu :key="new Date().getTime() + 1" v-if="selectModel === 'site'"></SitesMenu>
            </div>
        </el-header>
        <el-main>
            <div class="mainBox">
                <div>
                    <CreateCompany v-if="companyList.length === 1 || addCompany"></CreateCompany>
                    <router-view v-if="companyList.length > 1 && (!addCompany)"></router-view>
                </div>
            </div>
        </el-main>
        </el-container>
    </div>
</template>
<script>
import {mapState} from "vuex"
import TopLogo from '@/components/TopLogo.vue';
import CompanyMenu from '@/components/CompanyMenu.vue';
import SitesMenu from '@/components/SitesMenu.vue';
import CreateCompany from "@/components/CreateCompany.vue";
export default{
    name: 'Main',
    components:{
        TopLogo,
        CompanyMenu,
        SitesMenu,
        CreateCompany,
    },
    data(){
        return{
           addCompanys:this.addCompany
        }
    },
    mounted(){
        this.$store.commit("CHANGEMODEL","company");
        if(this.companyList.length === 1){

        }else{
            if(this.selectCompany.role === "admin"){
                this.$router.push({name:"companydashboard"});
            }else{
                this.$router.push({name:"sitesmanage"});
            }
            // return false
        }
    },
    computed:{
        ...mapState(["selectModel","addCompany","companyList","selectCompany"])
    },
    watch:{
        addCompanys(){
            
        }
    }
}
</script>
<style scoped>
.el-header{
    background-color: #6498b5;
    /* background-color: #ffffff; */
    background: -webkit-linear-gradient(to right, #A4BDD2, #6498b5);
    /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to right, #A4BDD2, #6498b5);
    /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to right, #A4BDD2, #6498b5);
    /* Firefox 3.6-15 */
    background: linear-gradient(to right, #A4BDD2, #6498b5);
    padding: 0;
    position: fixed;
    top:0;
    width: 100%;
    z-index: 1;
}
@media screen and (max-width: 600px) {
    .el-header {
       height: 180px !important;
    }
}

.topMenu{
    background-color:#E9F6FF;
    border: 1px solid #E9F6FF;
    box-sizing: border-box; 
    height: 60px;
    width: 98%;
    margin-left: 1%;
    border-radius:15px 15px 0px 0px;
    border-bottom: 1px solid #ccc;
    box-shadow: 0px 3px 6px #999;
}
@media screen and (max-width: 600px) {
    .topMenu {
        background-color:#E9F6FF;
        border: 1px solid #E9F6FF;
        /* background-color: #ffffff; */
        background: -webkit-linear-gradient(to right, #A4BDD2, #6498b5);
        /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to right, #A4BDD2, #6498b5);
        /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to right, #A4BDD2, #6498b5);
        /* Firefox 3.6-15 */
        background: linear-gradient(to right, #A4BDD2, #6498b5);
        box-sizing: border-box; 
        height: 60px;
        width: 100%;
        margin-left: 0%;
        border-radius:15px 15px 0px 0px;
        border-bottom: 1px solid #ccc;
        box-shadow: 0px 3px 6px #999;
    }
}
.el-main {
    padding: 0;
    /* height: calc(100vh - 120px); */
    background-color:#E9F6FF;
    /* background-color: #ffffff; */
    background: -webkit-linear-gradient(to right, #A4BDD2, #6498b5);
    /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to right, #A4BDD2, #6498b5);
    /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to right, #A4BDD2, #6498b5);
    /* Firefox 3.6-15 */
    background: linear-gradient(to right, #A4BDD2, #6498b5);
    margin-top: 120px;
}
@media screen and (max-width: 600px) {
    .el-main {
        margin-top: 180px;
    }
}
.mainBox{
    margin-top: 10px;
    margin-bottom: 20px;
    width: 98%;
    height: calc(100vh - 150px);
    margin-left: 1%;
    background-color:#fff;
    overflow: auto;
}
@media screen and (max-width: 600px) {
    .mainBox {
        margin-top: 10px;
        margin-bottom: 20px;
        height: calc(100vh - 210px);
        background-color:#fff;
        overflow: auto;
    }
}
</style>