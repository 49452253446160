<template>
  <div>
    <!--选择设备弹框-->
    <el-dialog :title="$t('ChooseDevice')" 
            :close-on-click-modal="false" 
            :close-on-press-escape="false" 
            :visible.sync="addDevice.addDevForm" 
            center 
            :show-close="addDevice.showClose"
        >
        <div>
            <div class="addDevBox">
                <div class="serachDevBox">
                    <div class="serachLeft">
                        <el-input v-model="addDevice.deviceName" class="serachInput" :placeholder="$t('DeviceName')"></el-input>
                    </div>
                    <div class="serachRight">
                        <el-button type="primary" class="serachBtn" :loading="addDevice.serachBtnState === 1"  @click="serachNoticeDevice">{{$t('Serach')}}</el-button>
                    </div>
                </div>
            </div>
            <div class="chooseDevTableBox">
                <template>
                    <el-table
                    ref="addSelDevTable"
                    :data="addDevice.noticeDevList"
                    @selection-change="handleSelectDev"
                    style="width: 100%; border-right: 3px solid #6498b5;"
                    :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                    >
                    <el-table-column
                        label="#"
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        prop="deviceName"
                        :label="$t('DeviceName')"
                        width="150"
                        >
                        
                    </el-table-column>

                    <el-table-column>

                    </el-table-column>
                
                    </el-table>
                </template>
            </div>
            <div slot="footer" class="dialog-footer chooseDevBtn">
                <el-button @click="closeSelDev">{{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="confirmSelDev">{{$t('Confirm')}}</el-button>
            </div>
        </div>
    </el-dialog>
    <!--详情设备列表-->
    <el-dialog :title="$t('ChooseDevice')" 
            :close-on-click-modal="false" 
            :close-on-press-escape="false" 
            :visible.sync="detailDevice.detailDevForm" 
            center 
            :show-close="detailDevice.showClose"
        >
        <div>
            <div class="chooseDevTableBox">
                <template>
                    <el-table
                    :data="detailDevice.noticeDevList"
                    style="width: 100%; border-right: 3px solid #6498b5;"
                    :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                    >
                    <el-table-column
                        label="#"
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column
                        prop="deviceName"
                        :label="$t('DeviceName')"
                        width="150"
                        >
                        
                    </el-table-column>

                    <el-table-column>

                    </el-table-column>
                
                    </el-table>
                </template>
            </div>
            <div slot="footer" class="dialog-footer chooseDevBtn">
                <el-button @click="detailCloseSelDev">{{$t('Cancel')}}</el-button>
            </div>
        </div>
    </el-dialog>
    <!--删除Notice弹框-->
    <el-dialog
        :title="$t('Delete')"
        :visible.sync="del.delDevNoticeFrom"
        :close-on-click-modal="false" 
        :close-on-press-escape="false"
        width="30%"
        :show-close="del.showClose">
        <p class="deleteDesc">{{$t('RemoveDeviceNotice')}}</p>
        <span slot="footer" class="dialog-footer">
            <el-button @click="del.delDevNoticeFrom = false">{{$t('Cancel')}}</el-button>
            <el-button type="primary" :loading="del.delStatusBtn === 1" @click="delDevNotice">{{$t('Confirm')}}</el-button>
        </span>
    </el-dialog>
    <!--改变 Notice状态弹框-->
    <el-dialog
        :title="noticeStatus.statusType==='Withdraw' ? $t('Withdraw') : $t('Release')"
        :visible.sync="noticeStatus.statusFrom"
        :close-on-click-modal="false" 
        :close-on-press-escape="false"
        width="30%"
        :show-close="noticeStatus.showClose">
        <p class="deleteDesc" v-show="noticeStatus.statusType === 'Withdraw'">{{$t('WithdrawDeviceNotice')}}</p>
        <p class="deleteDesc" v-show="noticeStatus.statusType === 'Release'">{{$t('ReleaseDeviceNotice')}}</p>
        <span slot="footer" class="dialog-footer">
            <el-button @click="noticeStatus.statusFrom = false">{{$t('Cancel')}}</el-button>
            <el-button type="primary" :loading="noticeStatus.noticeStatusBtn === 1" @click="statusDevNotice">{{$t('Confirm')}}</el-button>
        </span>
    </el-dialog>
    <div class="siteNoticeBox" v-show="showModel === 1">
        <div class="serachBox">
            <div class="serachLeft">
                <el-input v-model="serach.title" class="serachInput" :placeholder="$t('EnterTitle')"></el-input>
                 <el-select v-model="serach.status" class="serachSel" :placeholder="$t('ReleaseStatus')" clearable>
                    <el-option
                    v-for="item in serach.statusOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
                <div class="dataBox">
                    <div>
                        <el-date-picker
                        :placeholder="$t('StartTime')"
                        v-model="serach.startTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="dateDivider">_</div>
                <div class="dataBox">
                    <div>
                        <el-date-picker
                        :placeholder="$t('EndTime')"
                        v-model="serach.endTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <el-button type="primary" class="serachBtn" :loading="serach.serachBtnState === 1" @click="serachDeviceNotice">{{$t('Serach')}}</el-button>
                <el-button type="primary" class="serachBtn" :loading="serach.refreshBtnState === 1" @click="refreshDevNoticeList">{{$t('Refresh')}}</el-button>
            </div>
            <div class="serachRight">
                <el-button type="primary" class="serachBtn" @click="addDeviceNotice">{{$t('AddNotice')}}</el-button>
            </div>
        </div>
        <el-divider></el-divider>
        <div>
            <div class="noDeviceNotice" v-show="hasNoDeviceNotice">{{$t('NoData')}}</div>
            <template>
                <el-table
                v-show="!hasNoDeviceNotice"
                :data="deviceNoticeList"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    prop="status"
                    :label="$t('Status')"
                    width="100"
                    >
                    <template slot-scope="scope">
                        <img v-show="scope.row.status == 1" :src="release2" style="width: 20px; height: 20px; line-height: 20px;">
                        <img v-show="scope.row.status == 2" :src="release1" style="width: 20px; height: 20px; line-height: 20px;">
                        <img v-show="scope.row.status == 3" :src="release2" style="width: 20px; height: 20px; line-height: 20px;">
                    </template>
                </el-table-column>
                <el-table-column
                    prop="title"
                    :label="$t('TitleTable')"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="scope"
                    :label="$t('ReleaseType')"
                    width="150"
                    >
                    <template slot-scope="scope">
                    {{scope.row.scope === 1 ? $t('Broadcast') : $t('PrivateMessage')}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    :label="$t('ReleaseStatus')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ getNoticeStatusStr(scope.row.status)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="releaseTime"
                    :label="$t('Released')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ handDateNull(scope.row.releaseTime)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    :label="$t('CreateDate')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ utctolocal(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <el-table-column width="300"  label="..." align="center">
                    <template slot-scope="scope">
                        <span @click="moreInfo(scope.row)" style="color: #6498b5; cursor: pointer;">{{$t('More')}}</span>
                        <span v-show="scope.row.status === 2" @click="openChangeStatuFrom(scope.row,'Withdraw')" style="color: green; cursor: pointer;margin-left: 10px;">{{$t('Withdraw')}}</span>
                        <span v-show="scope.row.status === 1" @click="openChangeStatuFrom(scope.row,'Release')" style="color: orange; cursor: pointer;margin-left: 10px;">{{$t('Release')}}</span>
                        <span @click="openDelDevNoticeFrom(scope.row)" style="color: red; cursor: pointer;margin-left: 10px;">{{$t('Delete')}}</span>
                        <!-- <el-image :src="deleteImg"  @click="openDelDevNoticeFrom(scope.row)" style="width:20px;margin-left:1vw" fit="fill"></el-image> -->
                    </template>
                </el-table-column>
                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox">
                <el-pagination
                    v-show="!hasNoDeviceNotice"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="totalDevNotices"
                >
                </el-pagination>
            </div>
        </div>
    </div>
    <!--新增 Notice-->
    <div class="addNoticeBox" v-show="showModel === 2">
        <div class="addNoticeTitle"><el-divider direction="vertical"></el-divider> <span>{{addNotice.isAddNotice ? $t('AddNotice') : $t('UpdateNotice')}}</span></div>
        <div class="addNoticeConent">
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft redStar">{{$t('ReceptionDevice')}}</div> 
                <div class="addNoticeItemRight">
                    <el-button type="primary" class="noSelectBtn" :class="{'selectBtn':selNoticeTypeBtn===1}" @click="broadcastBtn">{{$t('Broadcast')}}</el-button>
                    <el-button type="primary" class="noSelectBtn" :class="{'selectBtn':selNoticeTypeBtn===2}" @click="chooseDeviceBtn">{{$t('ChooseDevice')}}</el-button>
                    <span style="margin-left: 5px; color: #C7C7C7;">{{$t('SelectedDeviceNumber') + addDevice.confirmSelDevList.length + "/10"}} </span>
                </div>
            </div>
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft redStar">{{$t('Title')}}</div> 
                <div class="addNoticeItemRight">
                    <el-input v-model="addNotice.noticeTitle" class="serachInput" style="width:480px" :placeholder="$t('DigitsLimit')"></el-input>
                </div>
            </div>
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft redStar">{{$t('TitlePicture')}}</div> 
                <div class="addNoticeItemRight">
                    <el-upload
                        class="avatar-uploader"
                        ref="addNoticeUpload"
                        accept=".jpg"
                        :before-upload="checkFileType"
                        :action="addNotice.actionUrl" 
                        :disabled="addNotice.isUpload" 
                        :show-file-list="false"
                        :limit="1"
                        :multiple="false"
                        :on-success="handleAvatarSuccess"
                        :on-error="handleAvatarError"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="addNotice.fileList"
                        >
                        <div class="addImg" v-loading="addNotice.updateImg">
                            <img :src="addNotice.addImg" class="avatar">
                            <img v-if="addNotice.isUpload" :src="addNotice.delImg" class="delImg" @click.stop.once="removeImg">
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="addNoticeItem" style="margin-top: 1vw;">
                <div class="addNoticeItemLeft"></div> 
                <div class="addNoticeItemRight">
                    <p>{{$t('PictureRatio')}}</p>
                </div>
            </div>
            
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft">{{$t('TimeRange')}}</div> 
                <div class="addNoticeItemRight" style="display:flex;">
                    <div class="dataBox">
                    <div>
                        <el-date-picker
                        :placeholder="$t('StartTime')"
                        v-model="addNotice.startTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="dateDivider">_</div>
                <div class="dataBox">
                    <div>
                        <el-date-picker
                        :placeholder="$t('EndTime')"
                        v-model="addNotice.endTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                </div>
            </div>
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft"></div> 
                <div class="addNoticeItemRight">
                    <el-button type="primary" style="width: 236px;" v-show="addNotice.isAddNotice" class="noSelectBtn" :class="{'selectBtn':addNotice.selCreateNoticeBtn===1}" :loading="addNotice.addBtnState === 1"  @click="crateDeviceNotice">{{$t('CreateNotice')}}</el-button>
                    <el-button type="primary" style="width: 236px;" v-show="!addNotice.isAddNotice" class="selectBtn" :loading="addNotice.updateBtnState === 1" @click="UpdateDeviceNotice">{{$t('Update')}}</el-button>
                    <el-button type="primary" style="width: 236px;" class="noSelectBtn" :class="{'selectBtn':addNotice.selCreateNoticeBtn===2}"  @click="cancelDeviceNotice">{{$t('Cancel')}}</el-button>
                </div>
            </div>
        </div>
    </div>
    <!-- 详情 -->
    <div class="addNoticeBox" v-show="showModel === 3">
        <!-- <div class="addNoticeTitle"><el-divider direction="vertical"></el-divider> <span>Add Notice</span></div> -->
        <div class="addNoticeConent">
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft">{{$t('ReceptionDevice')}}</div> 
                <div class="addNoticeItemRight">
                    <el-button type="primary" class="noSelectBtn" v-if="detailNotice.scope === 1" :class="{'selectBtn':selNoticeTypeBtn===1,'selectBtn':detailNotice.scope === 1}" @click="detailBroadcastBtn">{{$t('Broadcast')}}</el-button>
                    <el-button type="primary" class="noSelectBtn" v-if="detailNotice.scope === 2" :class="{'selectBtn':selNoticeTypeBtn===2,'selectBtn':detailNotice.scope ===2}" @click="detailChooseDeviceBtn">{{$t('ChooseDevice')}}</el-button>
                    <span style="margin-left: 5px; color: #C7C7C7;" v-if="detailNotice.scope === 2">{{$t('SelectedDeviceNumber') + detailDevice.noticeDevList.length + "/10"}}</span>
                </div>
            </div>
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft">{{$t('Title')}}</div> 
                <div class="addNoticeItemRight">
                    <!-- The input length must be 1-50digits,and special sym -->
                    {{ detailNotice.noticeTitle }}
                </div>
            </div>
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft">{{$t('TitlePicture')}}</div> 
                <div class="addNoticeItemRight">
                    <img :src="detailNotice.detailImg" style="width: 80px; height: 80px;">
                </div>
            </div>
            <div class="addNoticeItem" style="margin-top: 1vw;">
                <div class="addNoticeItemLeft"></div> 
                <div class="addNoticeItemRight">
                    <p>{{$t('PictureRatio')}}</p>
                </div>
            </div>
            
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft">{{$t('TimeRange')}}</div> 
                <div class="addNoticeItemRight" style="display:flex;">
                   {{ (detailNotice.startTime == 0 ? "?" : utctolocal(detailNotice.startTime)) + " - " + (detailNotice.endTime == 0 ? "?" : utctolocal(detailNotice.endTime))}}
                </div>
            </div>
            <div class="addNoticeItem">
                <div class="addNoticeItemLeft"></div> 
                <div class="addNoticeItemRight">
                    <el-button type="primary" style="width: 236px;" class="selectBtn" v-show="detailNotice.isShowEdit"  @click="modifyDeviceNotice">{{$t('Modify')}}</el-button>
                    <el-button type="primary" style="width: 236px;" class="selectBtn"  @click="returnDeviceNotice">{{$t('Return')}}</el-button>
                </div>
            </div>
        </div>
    </div>
  </div>
    
</template>

<script>
import { Notification } from 'element-ui'
import Cookies from 'js-cookie' // 封装了获取，设置，删除cookie的方法
import { mapState, mapMutations } from 'vuex';
import deleteImg from '@/assets/images/delete.png'
import devNoticeApi from '@/api/sitenotice/devnotice/index.js'
import release1 from "@/assets/images/release1.png"
import release2 from "@/assets/images/release2.png"
import delImg from "@/assets/images/deleteImg.png"
import addImg from "@/assets/images/addImg.png"
import defaultImg from "@/assets/images/addImg.png"

export default {
    name:"DeviceNotice",
    data(){
        return{
            deleteImg,
            serach:{
                title:"",
                status:"",
                statusOptions:[
                    {
                    id:1,
                    name:this.$t('Draft')
                    },
                    {
                    id:2,
                    name:this.$t('Release')
                    },
                    {
                    id:3,
                    name:this.$t('Expired')
                    },
                ],
                startTime:"",
                endTime:"",
                serachBtnState:0,
                refreshBtnState:0,
            },
            hasNoDeviceNotice:false,
            totalDevNotices:0,
            currentPage: 1,
            deviceNoticeList:[],
            release1,
            release2,
            showModel:this.selectDevNoticeModel,
            selNoticeTypeBtn:1,
            addNotice:{
                addBtnState:0,
                updateBtnState:0,
                id:"",
                defaultImg,
                delImg,
                addImg:addImg,
                fileKey:"",
                isAddNotice:true,
                actionUrl:"",
                scope:1,
                noticeTitle:"",
                fileList: [],
                isUpload:false,
                startTime:"",
                endTime:"",
                selCreateNoticeBtn:1,
                updateImg:false,
            },
            addDevice:{
                showClose:true,
                addDevForm:false,
                deviceName:"",
                noticeDevList:[],
                selNoticeDevList:[],
                confirmSelDevList:[],
                serachBtnState:0,
            },
            detailNotice:{
                detailImg:"",
                imageKey:"",
                scope:1,
                selNoticeDevList:[],
                noticeTitle:"",
                startTime:"",
                endTime:"",
                isShowEdit:true,
            },
            detailDevice:{
                showClose:true,
                detailDevForm:false,
                deviceName:"",
                noticeDevList:[],
            },
            del:{
                showClose:true,
                delDevNoticeFrom:false,
                delStatusBtn:0,
                id:""
            },
            noticeStatus:{
                showClose:true,
                statusFrom:false,
                noticeStatusBtn:0,
                statusType:"withdraw",
                id:""
            }
          
        }
    },
    props:["selectDevNoticeModel"],
    mounted(){
       const hostUrl = sessionStorage.getItem('URL');
       this.addNotice.actionUrl = hostUrl + `/entranceserver/web-api/${this.selectCompany.companyRegion}/notice/device/picUpload`;
       this.getDevNoticeList(1);
    },
    methods:{
        // ...mapMutations(["OPENINEDITPAGE"]),
        //点击搜索执行
        serachDeviceNotice(){
            this.getDevNoticeList(1);
        },
        //获取设备通知列表
        getDevNoticeList(pageNum){

            if(this.serach.startTime == null){
                this.serach.startTime = "";
            }
            if(this.serach.endTime == null){
                this.serach.endTime = "";
            }

            if(this.serach.startTime != "" && this.serach.endTime === ""){
                this.$message.info(this.$t('EndTimeEmptye'));
                return false
            }
            if(this.serach.startTime === "" && this.serach.endTime != ""){
                this.$message.info(this.$t('StartTimeEmptye'));
                return false
            }
            if(this.serach.startTime != "" && this.serach.endTime != ""){
                if(this.serach.startTime >= this.serach.endTime){
                    this.$message.info(this.$t('StartTimeLaterEndTime'));
                    return false
                }
            }
            let deviceNoticeObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "title":this.serach.title,
                "status":this.serach.status,
                "startTime": this.serach.startTime,
                "endTime": this.serach.endTime,
                "pageSize":10,
                "pageNum":pageNum,
            }
            this.serach.serachBtnState = 1;
            devNoticeApi.getDevNoticeList(deviceNoticeObj).then(res=>{
                if(res.code === 0){
                    this.serach.serachBtnState = 0;
                    this.deviceNoticeList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.hasNoDeviceNotice = true;
                    }else{
                        this.hasNoDeviceNotice = false;
                        this.deviceNoticeList = res.data.list;
                    }
                    this.currentPage = res.data.pageNum;
                    this.totalDevNotices = res.data.total;
                }else{
                    this.serach.serachBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                this.serach.serachBtnState = 0;
                console.log(err);
            })
        },
        //点击刷新执行
        refreshDevNoticeList(){

            if(this.serach.startTime == null){
                this.serach.startTime = "";
            }
            if(this.serach.endTime == null){
                this.serach.endTime = "";
            }

            if(this.serach.startTime != "" && this.serach.endTime === ""){
                this.$message.info(this.$t('EndTimeEmptye'));
                return false
            }
            if(this.serach.startTime === "" && this.serach.endTime != ""){
                this.$message.info(this.$t('StartTimeEmptye'));
                return false
            }
            if(this.serach.startTime != "" && this.serach.endTime != ""){
                if(this.serach.startTime >= this.serach.endTime){
                    this.$message.info(this.$t('StartTimeLaterEndTime'));
                    return false
                }
            }
            let deviceNoticeObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "title":this.serach.title,
                "status":this.serach.status,
                "startTime": this.serach.startTime,
                "endTime": this.serach.endTime,
                "pageSize":10,
                "pageNum":this.currentPage,
            }
            this.serach.refreshBtnState = 1;
            devNoticeApi.getDevNoticeList(deviceNoticeObj).then(res=>{
                if(res.code === 0){
                    this.serach.refreshBtnState = 0;
                    this.deviceNoticeList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.hasNoDeviceNotice = true;
                    }else{
                        this.hasNoDeviceNotice = false;
                        this.deviceNoticeList = res.data.list;
                    }
                    this.currentPage = res.data.pageNum;
                    this.totalDevNotices = res.data.total;
                }else{
                    this.serach.refreshBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                this.serach.refreshBtnState = 0;
                console.log(err);
            })
        },
        //Notice状态
        getNoticeStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 1:
                    getStr = this.$t('Draft');
                    break;
                case 2:
                    getStr = this.$t('Release');
                    break
                case 3:
                    getStr = this.$t('Expired');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        //处理日期为0等异常情况
        handDateNull(name) {
            let fieldName = "";
            if (name === undefined || name === null || name === 0) {
                fieldName = "--"
            } else {
                fieldName = this.utctolocal(name);
            }
            return fieldName
        },
        //更多信息
        moreInfo(item){
            // console.log("item",item)
            this.addNotice.id = item.id;
            this.showModel = 3;
            if(item.status == 1){
                this.detailNotice.isShowEdit = true;
            }else{
                this.detailNotice.isShowEdit = false;
            }
            this.detailNotice.scope = item.scope;
            this.selNoticeTypeBtn = item.scope;
            // this.detailNotice.selNoticeDevList = item.deviceInfos;
            this.detailDevice.noticeDevList = item.deviceInfos;
            this.addDevice.confirmSelDevList = item.deviceInfos;
            this.detailNotice.noticeTitle = item.title;
            this.detailNotice.detailImg = "";
            this.detailNotice.detailImg = item.imageUrl;
            this.detailNotice.imageKey = item.imageKey;
            this.detailNotice.startTime = item.releaseTime;
            this.detailNotice.endTime = item.endTime;
        },
        //添加Device通知消息
        addDeviceNotice(){
            this.showModel = 2;
            this.selNoticeTypeBtn = 1;
            this.addNotice.scope = 1;
            this.addNotice.noticeTitle = "";
            this.addNotice.startTime = "";
            this.addNotice.endTime = "";
            this.addDevice.confirmSelDevList = [];
            this.addNotice.addImg = this.addNotice.defaultImg;
            this.addNotice.isUpload = false;
            this.addNotice.isAddNotice = true;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage = val
            this.getDevNoticeList(val);
        },
        // 添加通知
        broadcastBtn(){
            this.selNoticeTypeBtn = 1;
            this.addNotice.scope = 1;
        },
        handleRemove(file, fileList) {
            this.addNotice.isUpload = false;
        },
        //限制图片类型
        checkFileType(file){
            const fileName = file.name;
            const fileType = fileName.substring(fileName.lastIndexOf('.'));
            if(fileType.toLocaleLowerCase() !== '.jpg'){
                this.$message.info(this.$t("UploadImagejpg"));
                this.addNotice.isUpload = false;
                return false;
            }
            const fileSize = file.size / 1024 / 1024 <= 2;
            if (!fileSize) {
                this.$message.info(this.$t('UploadedImageLimit'));
                return false;
            }
            this.addNotice.updateImg = true;
            return true;
        },
        //删除已经上传的图片
        removeImg(){
            let removeImgRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let removeImg =  {
                    "requestFrom": "1",
                    "fileKey": this.detailNotice.imageKey
                }
            devNoticeApi.deleteNoticePic(removeImgRgnObj,removeImg).then(res=>{
                if(res.code === 0){
                    this.addNotice.addImg = this.addNotice.defaultImg;
                    this.addNotice.isUpload = !this.addNotice.isUpload;
                    this.addNotice.fileList = [];
                    this.$message.success(this.$t("DeleteSuccessfully"))
                }else{
                    this.$message.error(this.$t("DeleteFailed"))
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        handlePreview(file) {
            console.log(file);
        },
        //上传图片成功后回调
        handleAvatarSuccess(response, file, fileList){
            this.$refs.addNoticeUpload.clearFiles();
            //需要清空上传文件列表，否则在当前页面无法再次上传
            this.addNotice.fileList = [];
            //后端返回回来的值
            if(response.code === 0){
                this.addNotice.addImg = response.data.fileUrl;
                this.addNotice.fileKey = response.data.fileKey;
                this.detailNotice.imageKey = response.data.fileKey;
                //上传一张图片后, 禁用上传
                this.addNotice.isUpload = !this.addNotice.isUpload
            }else if (response.code === 5) {
                Notification.error({
                    title: this.$t('LoginExpired'),
                    duration: 5000
                })
                Cookies.remove('token');
                this.$router.push({name:"login"});
              }else{
                this.addNotice.addImg = this.addNotice.defaultImg;
                this.$message.info(this.$t("ImageUploadFailed"));
            }
            this.addNotice.updateImg = false;
        },
        //上传图片失败
        handleAvatarError(err, file, fileList){
            this.addNotice.addImg = this.addNotice.defaultImg;
            this.addNotice.updateImg = false;
        },
        //新增通知 点击选择设备按钮执行方法
        chooseDeviceBtn(){
            this.selNoticeTypeBtn = 2;
            this.addNotice.scope = 2;
            this.addDevice.addDevForm = true;
            this.addDevice.deviceName = "";
            //获取可选择的设备列表
            this.getChooseDevList();
        },
        //查询可选择的通知的设备列表
        getChooseDevList() {
            let noticeDevObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "deviceName":this.addDevice.deviceName,
            }
            this.addDevice.serachBtnState = 1;
            devNoticeApi.getNoticeDevList(noticeDevObj).then(res=>{
                if(res.code === 0){
                    this.addDevice.serachBtnState = 0;
                    this.addDevice.noticeDevList = [];
                    if(res.data === null || res.data.length === 0){
                        // this.hasNoDeviceNotice = true;
                        this.addDevice.noticeDevList = [];
                    }else{
                        // this.hasNoDeviceNotice = false;
                        this.addDevice.noticeDevList = res.data;
                        let selNoticeDevArr = [];
                        //获取当前选中的设备，并渲染
                        selNoticeDevArr = this.addDevice.confirmSelDevList;
                        //console.log("next--this.addDevice.selNoticeDevList",this.addDevice.confirmSelDevList,this.addDevice.noticeDevList)
                        this.$nextTick(()=>{
                            //this.$refs.addSelDevTable.toggleRowSelection(this.addDevice.noticeDevList[0]);
                            selNoticeDevArr.forEach((item)=>{
                                this.addDevice.noticeDevList.forEach((item2,index2)=>{
                                    if(item.entranceDeviceId === item2.entranceDeviceId){
                                        this.$refs.addSelDevTable.toggleRowSelection(this.addDevice.noticeDevList[index2]);
                                    }
                                })
                            })
                        })
                       
                    }
                }else{
                    this.addDevice.serachBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                this.addDevice.serachBtnState = 0;
                console.log(err);
            })
        },
        //设备选择弹窗点击搜索执行函数
        serachNoticeDevice(){
            this.getChooseDevList();
        },
        //选择设备弹框 设备选择发生变化时执行函数
        handleSelectDev(selValArr){
            this.addDevice.selNoticeDevList = [];
            selValArr.forEach(item=>{
                this.addDevice.selNoticeDevList.push(item);
            })
        },
        //设备选择框点击取消执行函数
        closeSelDev(){
            this.addDevice.addDevForm = false;
        },
        //设备选择框点击确认
        confirmSelDev(){
            // console.log("this.addDevice.selNoticeDevList",this.addDevice.selNoticeDevList);
            if(this.addDevice.selNoticeDevList.length === 0){
                this.$message.info(this.$t('PleaseSelectDevice'));
                return false
            }
            if(this.addDevice.selNoticeDevList.length > 10){
                this.$message.info(this.$t('MaximumDevicesSelected'));
                return false
            }
            this.addDevice.confirmSelDevList = this.addDevice.selNoticeDevList;
            this.addDevice.addDevForm = false;
        },
        //创建notice
        crateDeviceNotice(){
            this.addNotice.selCreateNoticeBtn = 1;
            if(this.addNotice.scope === 2){
                if(this.addDevice.confirmSelDevList.length === 0){
                    this.$message.info(this.$t('PleaseSelectDevice'));
                    return false
                }
                if(this.addDevice.confirmSelDevList.length > 10){
                    this.$message.info(this.$t('MaximumDevicesSelected'));
                    return false
                }
            }else{
                this.addDevice.confirmSelDevList = [];
            }
            let checkNoticeTitle = this.$regularCheckLimit(this.addNotice.noticeTitle,1,50,'DigitsLimit');
            if(!checkNoticeTitle){
                return false
            }
            let selUploadImg = "";
            if(this.addNotice.addImg == this.addNotice.defaultImg){
                selUploadImg = "";
                this.$message.info(this.$t("TitlePictureEmpty"));
                return false
            }else{
                selUploadImg = this.addNotice.addImg
            }
            if(this.addNotice.startTime == null){
                this.addNotice.startTime = "";
            }
            if(this.addNotice.endTime == null){
                this.addNotice.endTime = "";
            }
            // if(this.addNotice.startTime === ""){
            //     this.$message.info(this.$t('StartTimeEmptye'));
            //     return false
            // }
            // if(this.addNotice.endTime === ""){
            //     this.$message.info(this.$t('EndTimeEmptye'));
            //     return false
            // }
            if(this.addNotice.startTime != "" && this.addNotice.endTime != ""){
                if(this.addNotice.startTime > this.addNotice.endTime){
                    this.$message.info(this.$t('StartTimeLaterEndTime'));
                    return false
                }
            }
            let addDeviceNoticeRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let selDevIdArr = [];
            this.addDevice.confirmSelDevList.forEach((item)=>{
                selDevIdArr.push(item.entranceDeviceId)
            })
            this.addNotice.addBtnState = 1;
            let addDeviceNoticeObj = {
                "companyId":this.selectCompany.id,
                "siteId":this.selectSite.id,
                "scope":this.addNotice.scope,
                "entranceDeviceIds": selDevIdArr,
                "image":this.addNotice.fileKey,
                "title":this.addNotice.noticeTitle,
                "startTime": this.addNotice.startTime,
                "endTime": this.addNotice.endTime,
            }
            // console.log("addDeviceNoticeObj",addDeviceNoticeObj)
            // return false
            devNoticeApi.addDevNotice(addDeviceNoticeRgnObj,addDeviceNoticeObj).then(res=>{
                if(res.code === 0){
                    this.addNotice.addBtnState = 0;
                    this.$message.success(this.$t('CreatedSuccessfully'));
                    this.showModel = 1;
                    this.getDevNoticeList(1);
                }else{
                    this.addNotice.addBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                this.addNotice.addBtnState = 0;
                console.log(err);
            })
        },
        //创建通知页面点击取消执行函数
        cancelDeviceNotice(){
            // console.log("cancel device notice");
            this.addNotice.selCreateNoticeBtn = 2;
            this.addDevice.confirmSelDevList = this.detailDevice.noticeDevList;
            if(this.addNotice.isAddNotice){
                this.showModel = 1;
            }else{
                this.showModel = 3;
            }
            
        },
       
        // 详情 广播按钮
        detailBroadcastBtn(){
            this.selNoticeTypeBtn = 1;
        },
        // 详情 选择设备按钮
        detailChooseDeviceBtn(){
            this.selNoticeTypeBtn = 2;
            this.detailDevice.detailDevForm = true;
        },
        //详情 返回按钮
        returnDeviceNotice(){
            this.showModel = 1;
        },
        //详情设备列表弹窗取消按钮
        detailCloseSelDev(){
            this.detailDevice.detailDevForm = false;
        },
        //编辑
        modifyDeviceNotice(){
            this.showModel = 2;
            this.addNotice.isAddNotice = false;
            this.addNotice.noticeTitle = this.detailNotice.noticeTitle;
            if(this.detailNotice.startTime === 0){
                this.addNotice.startTime = null
            }else{
                this.addNotice.startTime = this.detailNotice.startTime;
            }
            if(this.detailNotice.endTime === 0){
                this.addNotice.endTime = null
            }else{
                this.addNotice.endTime = this.detailNotice.endTime;
            }
            this.addNotice.addImg = this.detailNotice.detailImg;
            this.selNoticeTypeBtn = this.detailNotice.scope;
            this.addNotice.isUpload = true;
            this.addNotice.selCreateNoticeBtn = 1;
            
        },
        //保存编辑
        UpdateDeviceNotice(){
            if(this.addNotice.scope === 2){
                if(this.addDevice.confirmSelDevList.length === 0){
                    this.$message.info(this.$t('PleaseSelectDevice'));
                    return false
                }
                if(this.addDevice.confirmSelDevList.length > 10){
                    this.$message.info(this.$t('MaximumDevicesSelected'));
                    return false
                }
            }else{
                this.addDevice.confirmSelDevList = [];
            }
            let checkNoticeTitle = this.$regularCheckLimit(this.addNotice.noticeTitle,1,50,'DigitsLimit');
            if(!checkNoticeTitle){
                return false
            }
            let selUploadImg = "";
            if(this.addNotice.addImg == this.addNotice.defaultImg){
                selUploadImg = "";
                this.$message.info(this.$t("TitlePictureEmpty"));
                return false
            }else{
                selUploadImg = this.addNotice.addImg
            }
            if(this.addNotice.startTime == null){
                this.addNotice.startTime = "";
            }
            if(this.addNotice.endTime == null){
                this.addNotice.endTime = "";
            }
            // if(this.addNotice.startTime === ""){
            //     this.$message.info(this.$t('StartTimeEmptye'));
            //     return false
            // }
            // if(this.addNotice.endTime === ""){
            //     this.$message.info(this.$t('EndTimeEmptye'));
            //     return false
            // }
            if(this.addNotice.startTime != "" && this.addNotice.endTime != ""){
                if(this.addNotice.startTime > this.addNotice.endTime){
                    this.$message.info(this.$t('StartTimeLaterEndTime'));
                    return false
                }
            }
            let updateDeviceNoticeRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let selDevIdArr = [];
            this.addDevice.confirmSelDevList.forEach((item)=>{
                selDevIdArr.push(item.entranceDeviceId)
            })
            let updateDeviceNoticeObj = {
                "siteId":this.selectSite.id,
                "id":this.addNotice.id,
                "scope":this.addNotice.scope,
                "entranceDeviceIds": selDevIdArr,
                "image":this.addNotice.fileKey,
                "title":this.addNotice.noticeTitle,
                "startTime": this.addNotice.startTime,
                "endTime": this.addNotice.endTime,
            }
            // console.log("updateDeviceNoticeObj",updateDeviceNoticeObj);
            this.addNotice.updateBtnState = 1;
            devNoticeApi.updateDevNotice(updateDeviceNoticeRgnObj,updateDeviceNoticeObj).then(res=>{
                if(res.code === 0){
                    this.addNotice.updateBtnState = 0;
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.showModel = 1;
                    this.getDevNoticeList(1);
                }else{
                    this.addNotice.updateBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch(err=>{
                this.addNotice.updateBtnState = 0;
                console.log(err);
            })
        },

        //打开删除弹窗
        openDelDevNoticeFrom(item){
            this.del.delDevNoticeFrom = true;
            this.del.id = item.id;
        },
        //删除notice
        delDevNotice(){
            let delDevNoticeRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delDevNoticeObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "id":this.del.id,
            }
            this.del.delStatusBtn = 1;
            devNoticeApi.deleteDevNotice(delDevNoticeRgnObj,delDevNoticeObj).then(res => {
                if(res.code === 0){
                    this.del.delStatusBtn = 0;
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.del.delDevNoticeFrom = false;
                    this.getDevNoticeList(1);
                }else{
                    this.del.delStatusBtn = 0;
                    this.$handerrcode(res.code);
                }
               
            }).catch((err) => {
                this.del.delStatusBtn = 0;
                console.log('err', err);
            })
        },
        //打开改变Notice状态弹窗
        openChangeStatuFrom(item,type){
            if(type === "Withdraw"){
                this.noticeStatus.statusType = "Withdraw";
            }else if(type === "Release"){
                this.noticeStatus.statusType = "Release";
            }
            this.noticeStatus.statusFrom = true;
            this.noticeStatus.id = item.id;
        },
        //改变通知状态
        statusDevNotice(){
            // console.log("this.noticeStatus.statusType",this.noticeStatus.statusType)
            let statuType = 1;
            if(this.noticeStatus.statusType === "Withdraw"){
                statuType = 1;
            }else if(this.noticeStatus.statusType === "Release"){
                statuType = 2;
            }
            let statuRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const statuObj = {
                "siteId":this.selectSite.id,
                "id":this.noticeStatus.id,
                "status":statuType
            }
            this.noticeStatus.noticeStatusBtn = 1;
            devNoticeApi.updateDevNoticeStatus(statuRgnObj,statuObj).then(res => {
                if(res.code === 0){
                    this.noticeStatus.noticeStatusBtn = 0;
                    if(this.noticeStatus.statusType === "Withdraw"){
                        this.$message.success(this.$t("SuccessfulWithdraw"));
                    }else if(this.noticeStatus.statusType === "Release"){
                        this.$message.success(this.$t('SuccessfulRelease'));
                    }
                    this.noticeStatus.statusFrom = false;
                    this.getDevNoticeList(1);
                }else{
                    this.noticeStatus.noticeStatusBtn = 0;
                    this.$handerrcode(res.code);
                }
               
            }).catch((err) => {
                this.noticeStatus.noticeStatusBtn = 0;
                console.log('err', err);
            })
        },
    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    watch: {
        
    }
}
</script>

<style scoped>
.siteNoticeBox{
   box-sizing: border-box;
   padding: 15px 30px;
}

.serachLeft{
    margin-top: 0.5vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
    .serachLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }
    .serachLeft div{
        margin-top: 10px;
    }
}

.serachRight{
    margin-top: 0.5vw;
}

@media screen and (max-width: 600px) {
    .serachRight{
        margin-top: 10px;
        width: 170px;
    }
}

.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .serachBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 10px;
    }
}

::v-deep .serachInput .el-input__inner {
    height: 42px!important;
    line-height: 42px!important;
    border: 1px solid #979797;
    background: #fff;
}
::v-deep .el-pagination .el-input__inner {
    background: #fff;
    border: 1px solid #dcdfe6;
}
::v-deep .el-date-editor .el-input__inner {
    height: 42px!important;
    line-height: 42px!important;
    border: 1px solid #979797;
    background: #fff;
}
.el-select .el-input.is-focus .el-input__inner {
    border: none;
}

.serachBtn{
    margin-left: 24px;
}
@media screen and (max-width: 600px) {
    .serachBtn{
        margin-left: 24px;
    }
}
.serachInput{
    width: 12vw;
    min-width: 170px;
    margin-right: 10px;
}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
    }
}
.dataBox{
    display: flex;
    align-items: center;
}
.dateDivider{
    line-height: 24px;
    padding: 0 6px;
}
.serachSel{
    width: 12vw;
    min-width: 170px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff;
    border-radius: 4px;
}
@media screen and (max-width: 600px) {
    .serachSel{
        width: 170px;
        margin-top: 10px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}

::v-deep .serachSel .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input__inner:focus {
    border: none;
}

.pageBox {
    /* position: fixed;
    bottom: 2vw; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed;
        bottom: 10px; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}

.noDeviceNotice{
    color: #ACACAC;
    margin-top: 10vw;
}

/* add notice */
.addNoticeTitle{
    text-align: left;
    margin-top: 1vw;
}
.addNoticeTitle span{
    font-size: 14px;
    font-weight: bold;
}
.el-divider--vertical{
    width: 4px;
    margin: 0 !important;
    background-color: #6498b5;
}
.addNoticeConent{
    margin-top: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.addNoticeItem{
    margin-top: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addNoticeItemLeft{
    width: 200px; 
    text-align: right;
}

.addNoticeItemRight{
    width: 600px; 
    margin-left: 5px;
    text-align: left;
}

.noSelectBtn {
    font-weight: bold;
    background: #B2B2B2;
    color: #fff;
    border-color: #B2B2B2;
}

.selectBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
}

::v-deep .el-upload-list--picture .el-upload-list__item-status-label {
    background: #6498b5 !important;
}

::v-deep .redStar::before {
    content: "*";
    color: red;
}

/* choose device */

::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 1080px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        min-width: 300px;
        overflow: auto;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
.serachDevBox{
    display: flex;
}
.chooseDevTableBox{
    margin-top: 1vw;
    /* height: 16vw; */
    overflow-y: auto;
    max-height: 16vw;
    margin-bottom: 1vw;
}
.chooseDevTableBox .el-table{
    min-height: 300px !important;
} 
::v-deep .el-table-column--selection .cell {
    padding-left: 10px;
}
.chooseDevBtn{
    display: flex;
    justify-content: center;
}

.addImg {
    position: relative;
    margin-left: 10px;
    display: inline-block;
}
.avatar {
    width: 80px;
    height: 80px;
    cursor:pointer;
}
.delImg{
    width: 16px;
    position: absolute;
    top: -7px;
    right: -7px;
    background: #fff;
    border-radius: 22px;
}

/* 删除 */
.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 12px;
}
::v-deep .el-dialog__footer {
    padding: 10px 20px 20px;
    text-align: center;
}
::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}
::v-deep .el-table{
    min-height: 510px !important;
}
</style>