<template>
    <div class="siteMenu">
        <el-menu :default-active="activeMenu()" class="el-menu-demo" mode="horizontal" @select="handleSiteMenu">
            <el-menu-item v-show="showSiteMenu" index="/main/sitedashboard">
                {{$t('SiteDashboard')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showSiteMenu" index="/main/blocks">
                {{$t('Blocks')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showSiteMenu" index="/main/panels">
                {{$t('Panels')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showSiteMenu" index="/main/siteusers">
                {{$t('SiteUsers')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="false" index="/main/eventrecords">
                {{$t('EventRecords')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showSiteMenu" index="/main/operationlog">
                {{$t('OperationLog')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showSiteMenu" index="/main/panellog">
                {{$t('PanelLog')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showSiteMenu" index="/main/sitelicenses">
                {{$t('SiteLicenses')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showSiteMenu" index="/main/payments">
                {{$t('Payments')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="!showSiteMenu" index="/main/profile">
                {{$t('MyProfile')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>
            <el-menu-item v-show="showSiteMenu" index="/main/notice">
                {{$t('Notice')}}
                <el-divider direction="vertical"></el-divider>
            </el-menu-item>

            <el-menu-item style="float: right;">
                <el-image
                    style="width: 84px;height: 30px;"
                    :src="menuAesImg"
                    fit="contain">
                </el-image>
            </el-menu-item>
        </el-menu>
    </div>
</template>
  
<script>
import {mapState} from 'vuex'
import menuAesImg from '@/assets/images/menu_aes.png'
export default {
    name:"SitesMenu",
    data(){
        return{
            // activeIndex: '/main/blocks',
            showSiteMenu:this.$store.state.selectSite.role === "admin" || this.$store.state.selectSite.role === "site_admin",
            menuAesImg
         }
    },
    mounted(){
        // this.$router.push({name:"blocks"});
        this.getactiveMenu();
    },
    methods:{
        handleSiteMenu(key, keyPath) {
            // console.log(key, keyPath);
           if(key === "/main/sitedashboard"){
                this.$router.push({name:"sitedashboard"});
            }else if(key === "/main/blocks"){
                this.$router.push({name:"blocks"});
            }else if(key === "/main/panels"){
                this.$router.push({name:"panels"});
            }else if(key === "/main/siteusers"){
                this.$router.push({name:"siteusers"});
            }else if(key === "/main/eventrecords"){
                this.$router.push({name:"eventrecords"});
            }else if(key === "/main/operationlog"){
                this.$router.push({name:"operationlog"});
            }else if(key === "/main/panellog"){
                this.$router.push({name:"panellog"});
            }else if(key === "/main/sitelicenses"){
                this.$router.push({name:"sitelicenses"});
            }else if(key === "/main/payments"){
                this.$router.push({name:"payments"});
            }else if(key === "/main/profile"){
                this.$router.push({name:"profile"});
            }else if(key === "/main/notice"){
                this.$router.push({name:"notice"});
            }
        },
        activeMenu(){
            if(this.$route.meta.isChildren){
                return this.$route.meta.usePath;
            }else{
                return this.$route.path;
            }
        },
        getactiveMenu(){
            if(this.showSiteMenu === false){
                this.$router.push({name:"profile"});
            }
        }
    },
    computed:{
        ...mapState(["selectSite"])
    },
    watch:{
        "$store.state.selectSite.role"(){
            this.showSiteMenu = this.$store.state.selectSite.role === "admin" || this.$store.state.selectSite.role === "site_admin";
        }
    }

}
</script>
  
<style scoped>
 .siteMenu{
    background-color: transparent;
    border-radius:15px 15px 0px 0px;
    width: 100%;
    overflow: auto;
 }
 @media screen and (max-width: 600px) {
    .siteMenu{
       width: 100%;
       /* overflow: auto; */
       height: 60px;
    }
}
 .el-menu{
    border-radius:15px 15px 0px 0px;
    /* background-color: transparent; */
    border-bottom-color: none;
 }
 .el-menu.el-menu--horizontal{
    border-radius:15px 15px 0px 0px;
    border-bottom: none;
    min-width: 1100px;
    /* display: flex;
    justify-items: flex-start; */
    /* overflow-x: auto; */
 }
 @media screen and (max-width: 600px) {
    .el-menu.el-menu--horizontal{
       width: 100%;
       /* overflow: auto; */
       height: 60px;
       /* display: flex;
       justify-items: flex-start; */
    }
}
 .el-menu--horizontal>.el-menu-item{
    color: #000;
    font-weight: bold;
    border: none;
 }
 .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #6498b5;
    background-color: transparent;
}
 .el-menu--horizontal>.el-menu-item.is-active{
    border-radius:15px 0px 0px 0px;
    color: #6498b5;
    border: none;
 }
 .el-divider--vertical {
    display: inline-block;
    width: 1px;
    height: 1.1em;
    /* margin: 3px 0px; */
    vertical-align: middle;
    position: relative;
    margin-left: 10px;
    margin-right: -20px;
}

.el-divider {
    background-color: #8BADFC;
    position: relative;
}
</style>