<template>
    <div class="operationlogBox">
        <div class="serachBox">
            <div class="serachLeft">
                <el-input v-model="serach.userName" class="serachInput" :placeholder="$t('UserName')"></el-input>
                <div class="dataBox">
                    <!-- <div>{{$t('StartTime')}}</div> -->
                    <div>
                        <el-date-picker
                        :placeholder="$t('StartTime')"
                        v-model="serach.startTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="dateDivider">_</div>
                <div class="dataBox">
                    <!-- <div> - {{$t('EndTime')}}</div> -->
                    <div>
                        <el-date-picker
                        :placeholder="$t('EndTime')"
                        v-model="serach.endTime"
                        type="datetime"
                        value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <el-button type="primary" class="serachBtn" :loading="serach.serachBtnState === 1" @click="serachOperationLog">{{$t('Serach')}}</el-button>
            </div>
           
        </div>
        <el-divider></el-divider>
        <div class="assContent">
            <div class="noAccLog" v-show="isNoAssLog">{{$t('NoAssessLog')}}</div>
            <template>
                <el-table
                v-show="!isNoAssLog"
                :data="assessListData"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    prop="adminName"
                    :label="$t('UserName')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ $handFieldNull(scope.row.adminName)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="doModule"
                    :label="$t('OperationModule')"
                    width="200" 
                    >
                    <template slot-scope="scope">
                        {{ handDoModule(scope.row.doModule)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="doMethod"
                    :label="$t('OperationContent')"
                    width="300"
                    >
                    <template slot-scope="scope">
                        {{ handDoMethod(scope.row.doMethod)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    :label="$t('CreateDate')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ utctolocal(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox" v-show="!isNoAssLog">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="totalLogs"
                >
                </el-pagination>
            </div>
            
        </div>
    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import { mapState } from 'vuex';
import operationlogApi from '@/api/operationlog/index.js'
export default {
    name:"OperationLog",
    data(){
        return{
            isNoAssLog:false,
            serach:{
                userName:"",
                startTime:"",
                endTime:"",
                serachBtnState:0,
            },
            assessListData:[],
            totalLogs:0,
            currentPage: 1,
        }
    },
    mounted(){
        let siteAssessBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/operationlog',
                name:this.$t('OperationLog')
            },
           
        ];
        this.$generateBreadcrumb(siteAssessBread);
        //获取assess log列表
        let serachAssessObj = {
                "pageNum":1
            };
        this.serachOperationLog(serachAssessObj);
    },
    methods:{
        //搜索assess log
        serachOperationLog(obj){

            if((this.serach.userName).trim() != ""){
                let checkResultSurname = this.$regularCheckLimit(this.serach.userName,1,30,'UserNameLimit');
                if(!checkResultSurname){
                    return false
                }
            }
            if(this.serach.startTime == null){
                this.serach.startTime = "";
            }
            if(this.serach.endTime == null){
                this.serach.endTime = "";
            }

            if(this.serach.startTime != "" && this.serach.endTime === ""){
                this.$message.info(this.$t('EndTimeEmptye'));
                return false
            }
            if(this.serach.startTime === "" && this.serach.endTime != ""){
                this.$message.info(this.$t('StartTimeEmptye'));
                return false
            }
            if(this.serach.startTime > this.serach.endTime){
                this.$message.info(this.$t('StartTimeLaterEndTime'));
                return false
            }

            let operationlogObj = {
                "rgn":this.selectCompany.companyRegion,
                "userName":this.serach.userName,
                "startTime":this.serach.startTime,
                "endTime":this.serach.endTime,
                "siteId":this.selectSite.id,
                "pageSize":10,
                "pageNum":obj.pageNum,
            }
            operationlogApi.getOperationLogList(operationlogObj).then(res => {
                if(res.code === 0){
                    if(res.data.list.length === 0){
                        this.isNoAssLog = true;
                    }else{
                        this.isNoAssLog = false;
                        this.currentPage = res.data.pageNum;
                        this.totalLogs = res.data.total;
                        this.assessListData = [];
                        for(let i=0;i<res.data.list.length;i++){
                            this.assessListData.push(res.data.list[i]);
                        }
                    }
                    
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            //获取assess log列表
            let serachAssessObj = {
                    "pageNum":val
                };
            this.serachOperationLog(serachAssessObj);
        },
        //操作模块处理函数
        handDoModule(module){
            let moduleStr = "";
            switch(module){
                case "site":
                    moduleStr = this.$t('Sites');
                    break;
                case "apartment":
                    moduleStr = this.$t('Blocks');
                    break
                case "device":
                    moduleStr = this.$t('Panels');
                    break
                case "user_house":
                    moduleStr = this.$t('SiteUsers');
                    break
                case "licence":
                    moduleStr = this.$t('SiteLicenses');
                    break
                case "company":
                    moduleStr = this.$t('Company');
                    break
                case "company_admin":
                    moduleStr = this.$t('CompanyAdmin');
                    break
                case "user":
                    moduleStr = this.$t('SiteUsers');
                    break
                case "notice":
                    moduleStr = this.$t('Notice');
                    break
                default:
                    moduleStr = "";
            }
            return moduleStr

        },
         //操作方法处理函数
         handDoMethod(method){
            let methodStr = "";
            switch(method){
                case "site_create":
                    methodStr = this.$t('CreateSite');
                    break;
                case "site_update":
                    methodStr = this.$t('UpdateSite');
                    break
                case "site_delete":
                    methodStr = this.$t('DeleteSite');
                    break
                case "apartment_create":
                    methodStr = this.$t('CreateBlock');
                    break
                case "apartment_update":
                    methodStr = this.$t('UpdateBlock');
                    break
                case "apartment_delete":
                    methodStr = this.$t('DeleteBlock');
                    break
                case "device_binding":
                    methodStr = this.$t('BindingPanels');
                    break
                case "device_update":
                    methodStr = this.$t('UpdatePanels');
                    break
                case "device_unbinding":
                    methodStr = this.$t('UnbindingPanels');
                    break
                case "device_delete":
                    methodStr = this.$t('DeviceDelete');
                    break
                case "user_house_add":
                    methodStr = this.$t('AddUsers');
                    break
                case "user_house_binding":
                    methodStr = this.$t('BindingUser');
                    break
                case "user_house_update":
                    methodStr = this.$t('UpdateUser');
                    break
                case "user_house_unbinding":
                    methodStr = this.$t('UnbindingUser');
                    break
                case "user_house_delete":
                    methodStr = this.$t('DeleteUserLog');
                    break
                case "licence_buy":
                    methodStr = this.$t('PurchasingLicence');
                    break
                case "licence_code":
                    methodStr = this.$t('ActivationCode');
                    break
                case "licence_apply":
                    methodStr = this.$t('BuyLiences');
                    break
                case "company_create":
                    methodStr = this.$t('CreateCompany');
                    break
                case "company_admin_add":
                    methodStr = this.$t('AddCompanyAdmin');
                    break
                case "company_admin_update":
                    methodStr = this.$t('UpdateCompanyAdmin');
                    break
                case "company_admin_delete":
                    methodStr = this.$t('DeleteCompanyAdmin');
                    break
                case "user_login":
                    methodStr = this.$t('UserLogin');
                    break
                case "device_config_create":
                    methodStr = this.$t('CreateDeviceConfig');
                    break
                case "device_config_update":
                    methodStr = this.$t('UpdateDeviceConfig');
                    break
                case "device_config_delete":
                    methodStr = this.$t('DeleteDeviceConfig');
                    break
                    case "device_guard_create":
                    methodStr = this.$t('CreateDeviceGuardConfig');
                    break
                case "device_guard_update":
                    methodStr = this.$t('UpdateDeviceGuardConfig');
                    break
                case "device_guard_delete":
                    methodStr = this.$t('DeleteDeviceGuardConfig');
                    break
                case "user_house_config_create":
                    methodStr = this.$t('CreateUserHouseConfig');
                    break
                case "user_house_config_update":
                    methodStr = this.$t('UpdateUserHouseConfig');
                    break
                case "user_house_config_delete":
                    methodStr = this.$t('DeleteUserHouseConfig');
                    break
                case "notice_device_create":
                    methodStr = this.$t('CreateDeviceNotice');
                    break
                case "notice_device_update":
                    methodStr = this.$t('UpdateDeviceNotice');
                    break
                case "notice_device_delete":
                    methodStr = this.$t('DeleteDeviceNotice');
                    break
                case "notice_device_draft":
                    methodStr = this.$t('DeviceNoticeWithdraw');
                    break
                case "notice_device_release":
                    methodStr = this.$t('DeviceNoticeRelease');
                    break
                default:
                    methodStr = "";
            }
            return methodStr
        }
    },
    computed:{
        ...mapState(["selectCompany","companyList","selectSite"])
    },
    watch:{
      
    }
}
</script>

<style scoped>
.operationlogBox{
    padding: 20px 30px;
}
@media screen and (max-width: 600px) {
    .operationlogBox{
        padding: 20px 0;
    }
}
.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .serachBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 10px;
    }
}
.serachInput{
    width: 12vw;
    min-width: 170px;
    margin-right: 10px;
}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
    }
}
.dataBox{
    display: flex;
    align-items: center;
}
/* .dataBox div{
    margin-right: 10px;
} */
.dateDivider{
    line-height: 24px;
    padding: 0 6px;
}
::v-deep .serachInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #979797;
    background: #fff;
}
::v-deep .el-pagination .el-input__inner {
    background: #fff;
    border: 1px solid #dcdfe6;
}
::v-deep .el-date-editor .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #979797;
    background: #fff;
}
.el-select .el-input.is-focus .el-input__inner {
    border: none;
}

.serachBtn{
    margin-left: 24px;
}
@media screen and (max-width: 600px) {
    .serachBtn{
        margin-left: 24px;
    }
}
.serachLeft{
    margin-top: 0.5vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
    .serachLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }
    .serachLeft div{
        margin-top: 10px;
    }
}


.el-divider--horizontal{
    height: 2px;
}
.noAccLog{
    color: #ACACAC;
    margin-top: 10vw;
}
/* ::v-deep .el-table .el-table__cell{
    padding: 8px 0;
} */
::v-deep .el-table{
    min-height: 510px !important;
}
.pageBox {
    /* position: fixed;
    bottom: 2vw; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed;
        bottom: 10px; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}

::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}
</style>